import { ProgramSaleOrigin } from '@one/api-models/lib/Admin/Sales/ProgramSaleOrigin';
import { Certificate } from '@one/api-models/lib/Membership/Account/Certificate';

export const getAssetOriginName = (cert: Certificate) => {
  if (!cert.assetOrigin) return;

  const assetOriginType = cert?.assetOrigin.$type;

  switch (assetOriginType) {
    case ProgramSaleOrigin.$type:
      const programSaleOrigin = cert.assetOrigin as ProgramSaleOrigin;
      return `${programSaleOrigin.name} [${programSaleOrigin.orderNumber}]`;
    default:
      return cert?.assetOrigin?.name;
  }
};
