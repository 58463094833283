import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import qs from 'qs';

import { GridFilterModel } from '@mui/x-data-grid';

import { FiltersComparisonType, FiltersOperatorsType } from 'utils/salesListFilters';

export const useFiltersToQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const saveFiltersToQs = useCallback(
    (filters: GridFilterModel, page: number, allBrandsEnabled: boolean) => {
      const paramsObject: Record<string, any> = {};
      for (const filterItem of filters.items) {
        const { field: filterType, value: filterValue, operator } = filterItem;

        if (!filterValue) {
          continue;
        }

        // Handle amount-based comparisons (e.g., greater than, less than)
        if (filterValue.comparison !== 'is' && filterValue.amount) {
          paramsObject[filterType] = {
            [filterValue.comparison === FiltersComparisonType.greaterThan ? 'gt' : 'lt']: filterValue.amount,
          };
          continue;
        }

        // Handle date ranges (e.g., dateBetween operator)
        if (operator === FiltersOperatorsType.dateBetween && filterValue.start) {
          paramsObject[filterType] = {
            from: filterValue.start,
            to: filterValue.end || undefined,
          };
          continue;
        }

        // Handle name-based equality (e.g., firstName:lastName)
        if (operator === FiltersOperatorsType.nameEquals && filterValue.firstName && filterValue.lastName) {
          paramsObject[filterType] = {
            firstName: filterValue.firstName,
            lastName: filterValue.lastName,
          };
          continue;
        }

        paramsObject[filterType] = {
          [operator]: filterValue,
        };
      }

      if (page !== 0) {
        paramsObject.page = page;
      }

      if (allBrandsEnabled) {
        paramsObject.allBrands = true;
      }

      const queryString = qs.stringify(paramsObject, { encode: true });

      if (searchParams.toString() === queryString) {
        return;
      }
      setSearchParams(queryString);
    },
    [searchParams, setSearchParams],
  );

  return { saveFiltersToQs };
};
