import qs from 'qs';

import { GridFilterModel } from '@mui/x-data-grid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store/store';

import { RouteKey } from 'models/RouteKey';
import { RoutesPaths } from 'models/RoutePath';
import { loadFiltersFromQueryParams } from 'utils/salesListFilters';

interface SalesTransactionsListData {
  allBrandsEnabled: boolean;
  filtersModel: GridFilterModel;
  page: number;
}

const getInitialState = (): SalesTransactionsListData => {
  const initialFilters: GridFilterModel = { items: [] };
  const routePath = window.location.pathname;

  if (routePath !== RoutesPaths[RouteKey.AllTransactions]) {
    return {
      allBrandsEnabled: false,
      filtersModel: initialFilters,
      page: 0,
    };
  }

  const parsed = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const showAllBrands = parsed.allBrands ? true : false;
  const currentPage = parsed.page ? parseInt(parsed.page as string, 10) : 0;

  return {
    allBrandsEnabled: showAllBrands,
    filtersModel: !Object.keys(parsed).length ? initialFilters : loadFiltersFromQueryParams(parsed),
    page: currentPage,
  };
};

const salesTransactionsListDataSlice = createSlice({
  name: 'salesTransactionsListData',
  initialState: getInitialState(),
  reducers: {
    setAllBrandsEnabled: (state, action: PayloadAction<boolean>) => {
      state.allBrandsEnabled = action.payload;
    },
    setFiltersModel: (state, action: PayloadAction<GridFilterModel>) => {
      state.filtersModel = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
  },
});

export const { setAllBrandsEnabled, setFiltersModel, setCurrentPage } = salesTransactionsListDataSlice.actions;

export const selectAllBrandsEnabled = (state: RootState) => state.salesTransactionsListData.allBrandsEnabled;
export const selectFiltersModel = (state: RootState) => state.salesTransactionsListData.filtersModel;
export const selectCurrentPage = (state: RootState) => state.salesTransactionsListData.page;

export default salesTransactionsListDataSlice.reducer;
