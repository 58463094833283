import { Avatar, Box, Button, Divider, Paper, Typography, useTheme } from '@mui/material';
import { SummaryLoadResponse } from '@one/api-models/lib/Membership/Account/SummaryLoadResponse';

import { useFormat } from 'hooks/useFormat';
import { CustomerStatus } from 'modules/customer/components/CustomerStatus';
import { colorByInitial } from 'utils/avatar';

import { SummaryViewSkeleton } from './skeletons/SummaryViewSkeleton';
import { assetCtaStyle } from './styles';

interface CustomerSummaryProps {
  memberLoadData?: SummaryLoadResponse;
  isFetchingMemberLoadData: boolean;
  testId: string;
}
export const CustomerSummary = (props: CustomerSummaryProps) => {
  const { memberLoadData, isFetchingMemberLoadData, testId } = props;
  const { formatDate } = useFormat();
  const theme = useTheme();

  return (
    <Paper
      elevation={2}
      sx={{
        py: 3,
        px: { xs: 3, sm: 8, md: 3 },
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: { xs: 'flex-start', md: 'center' },
        flexDirection: 'column',
      }}
    >
      {!memberLoadData || isFetchingMemberLoadData ? (
        <SummaryViewSkeleton />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            overflow: 'hidden',
            width: 'auto',
            maxWidth: '100%',
          }}
        >
          <Box display={{ xs: 'none', xl: 'flex' }} justifyContent="flex-end" alignItems="center" mb={1}>
            <CustomerStatus status={memberLoadData.accountSummary.memberStatus} />
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Avatar
              sx={{
                ...colorByInitial(memberLoadData.accountSummary.profileSummary.lastName || 'S'),
                width: { sm: 44, md: 38, lg: 38 },
                height: { sm: 44, md: 38, lg: 38 },
                fontSize: '1.5em',
                mr: 1,
              }}
            >
              {memberLoadData.accountSummary.profileSummary.firstName.substring(0, 1)}
            </Avatar>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
              <Typography variant="h5">
                {`${memberLoadData.accountSummary.profileSummary.firstName} ${memberLoadData.accountSummary.profileSummary.lastName}`}
              </Typography>
              <Box display={{ xs: 'flex', xl: 'none' }} justifyContent="flex-end" alignItems="center">
                <CustomerStatus status={memberLoadData.accountSummary.memberStatus} />
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" my={2}>
            <Typography
              variant="body1"
              sx={{
                color: `${theme.palette.primary.main}`,
                fontSize: '12px',
                maxWidth: '100%',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {memberLoadData.accountSummary.profileSummary.email.toLowerCase()}
            </Typography>
            <Typography variant="body1" sx={{ color: `${theme.palette.primary.main}` }}>
              {memberLoadData.accountSummary.profileSummary.mobilePhone}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Divider />
            <Box display="flex">
              <Typography variant="caption" display="block" sx={{ color: '#adadad' }}>
                Customer since:&nbsp;
              </Typography>
              <Typography variant="caption" display="block" sx={{ color: '#adadad' }}>
                {formatDate(new Date(memberLoadData.accountSummary.createdOn), true, 'MMM dd, yyyy')}
              </Typography>
            </Box>
            <Divider />

            <Box display="flex" mt={2}>
              <Typography variant="overline" display="block" sx={{ lineHeight: 1.4, mr: 0.5 }}>
                MEMBERSHIP:&nbsp;
              </Typography>
              <Typography sx={{ lineHeight: 1.2, textAlign: 'center', textTransform: 'uppercase' }}>
                {memberLoadData.accountSummary.membershipTier
                  ? memberLoadData.accountSummary.membershipTier.displayName
                  : '-'}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="caption" display="block" sx={{ mr: 0.5, lineHeight: 1.3 }}>
                Internal Membership Name:&nbsp;
              </Typography>

              <Typography variant="caption" sx={{ textAlign: 'center', textTransform: 'uppercase', lineHeight: 1.3 }}>
                {memberLoadData.accountSummary.membershipTier ? memberLoadData.accountSummary.membershipTier.name : '-'}
              </Typography>
            </Box>
            <Box display="flex" mt={1}>
              <Typography variant="caption">
                <b>Expiry Date: &nbsp;</b>
              </Typography>
              <Typography variant="caption">
                {memberLoadData.accountSummary.membershipTier
                  ? formatDate(new Date(memberLoadData.accountSummary.membershipTier.expiry), true, 'MMM dd, yyyy')
                  : '-'}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button variant="outlined" sx={{ ...assetCtaStyle }} data-testid={`${testId}UpgradeButton`} disabled>
                Upgrade
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Paper>
  );
};
