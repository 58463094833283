import { useDispatch, useSelector } from 'react-redux';

import { Brand } from '@one/api-models/lib/Admin/Common/Brand';
import { Partner } from '@one/api-models/lib/Admin/Common/Partner';

import { selectPermissions, setActiveBrand, setActivePartner } from 'store/slices/applicationDataSlice';

import { setActiveBrandAndPartnerInStorage } from 'config/sessionStorage';

export const useAppHelpers = () => {
  const dispatch = useDispatch();
  const permissions = useSelector(selectPermissions);

  const hasPermission = (perms: string[]) => {
    return permissions ? permissions.filter((permission: string) => perms.includes(permission)).length > 0 : false;
  };

  const setActivePartnerHandler = (partner: Partner | undefined, brand: Brand | undefined) => {
    setActiveBrandAndPartnerInStorage(brand?.key as string, partner?.key as string);
    dispatch(setActivePartner(partner));
    dispatch(setActiveBrand(brand));
  };

  const setActiveBrandHandler = (brand: Brand) => {
    setActiveBrandAndPartnerInStorage(brand?.key as string, undefined);
    dispatch(setActiveBrand(brand));
  };

  return {
    hasPermission,
    setActivePartner: setActivePartnerHandler,
    setActiveBrand: setActiveBrandHandler,
  };
};
