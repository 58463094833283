import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { useMutation, useQuery } from 'react-query';

import AddIcon from '@mui/icons-material/Add';
import { Box, Button, CircularProgress, Grid } from '@mui/material';

import { ApiError } from 'apiAccess/api-client';
import { SectionTitle } from 'common/SectionTitle';
import { useApiHelpers } from 'hooks/useApiHelpers';
import { useToastMessage } from 'hooks/useToastMessage';
import { AssignEditRequest } from 'models/productTags/AssignEditRequest';
import { EditRequest } from 'models/productTags/EditRequest';
import { EditResponse } from 'models/productTags/EditResponse';
import { LoadInitialCriteria } from 'models/productTags/LoadInitialCriteria';
import { LoadResponse } from 'models/productTags/LoadResponse';
import { LoadSubtreeCriteria } from 'models/productTags/LoadSubtreeCriteria';
import { ProductTag } from 'models/productTags/ProductTag';
import { RemoveEditRequest } from 'models/productTags/RemoveEditRequest';
import { Tag } from 'models/productTags/Tag';

import { AssignedChipList } from './AssignedChipList';
import { TaggingTree } from './TaggingTree';

enum TagActions {
  Assign,
  Remove,
}
interface TaggingManagerProps {
  productId: string;
  testId: string;
}

export const TaggingManager = ({ productId, testId }: TaggingManagerProps) => {
  const { addApiError, showMessage } = useToastMessage();
  const { api } = useApiHelpers();

  const [assignedTags, setAssignedTags] = useState<ProductTag[]>([]);
  const [treeTags, setTreeTags] = useState<Tag[]>([]);
  const [addButtonDisabled, setAddButtonDisabled] = useState(true);

  // load data
  //#region initialLoad
  const qRes = useQuery<LoadResponse, ApiError>(
    'tagging',
    () => {
      return api.products.tagsLoad({
        $type: LoadInitialCriteria.$Type,
        productId,
      } as LoadInitialCriteria);
    },
    {
      enabled: true,
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      onError: (error: ApiError) => addApiError(error),
    },
  );

  const { data, isLoading } = qRes;
  useEffect(() => {
    if (data && data?.assignedTags) {
      setAssignedTags([...data?.assignedTags]);
    }
    if (data && data?.tags) {
      setTreeTags([...data?.tags]);
    }
  }, [data]);
  //#endregion initialLoad

  //#region load subtree
  const loadSubtreeMutation = useMutation<LoadResponse, ApiError, { tag?: Tag; searchTerm?: string }, unknown>(
    async ({ tag, searchTerm }: { tag?: Tag; searchTerm?: string }) => {
      return await api.products.tagsLoad({
        $type: LoadSubtreeCriteria.$Type,
        productId,
        searchTerm,
        tagKey: tag?.key,
      } as LoadSubtreeCriteria);
    },
    {
      onSuccess: (value, variables) => {
        if (value) {
          if (variables.searchTerm) {
            if (value && value.tags) {
              setTreeTags(value.tags);
            }
          }

          if (variables.tag) {
            variables.tag.descendants = value.tags;
          }
        }
      },
      onError: (error: ApiError) => addApiError(error),
    },
  );
  //#endregion load subtree

  //#region Save
  const saveMutation = useMutation<EditResponse, ApiError, { tags: ProductTag[]; action: TagActions }, unknown>(
    async ({ tags, action }: { tags: ProductTag[]; action: TagActions }) => {
      let criteria: EditRequest;
      const criteriaData = {
        productId,
        tags: tags.map((x) => x.tagKey),
      } as any;

      switch (action) {
        case TagActions.Assign:
          criteria = {
            $type: AssignEditRequest.$Type,
            ...criteriaData,
          } as AssignEditRequest;
          break;
        case TagActions.Remove:
          criteria = {
            $type: RemoveEditRequest.$Type,
            ...criteriaData,
          } as RemoveEditRequest;
          break;
        default:
          throw Error(`Invalid edit action: ${action}`);
      }

      return await api.products.tagsEdit(criteria);
    },
    {
      onSuccess: (value, variables) => {
        if (value) {
          switch (variables.action) {
            case TagActions.Assign:
              showMessage(`"${variables.tags[0].name}" tag successfully assigned`, 'success');

              break;
            case TagActions.Remove:
              showMessage(`"${variables.tags[0].name}" tag successfully removed`, 'success');
              break;
          }
        }
      },
      onError: (error: ApiError) => addApiError(error),
    },
  );
  //#endregion Save

  const { tags } = data || ({ tags: [] } as LoadResponse);

  const assignTagHandler = (productTag: ProductTag, assignedTagsCollection?: ProductTag[]) => {
    const tags = assignedTagsCollection || assignedTags;
    if (!tags.find((x) => x.tagKey === productTag.tagKey)) {
      setAssignedTags([...tags, productTag]);
      saveMutation.mutate({ tags: [productTag], action: TagActions.Assign });
      setAddButtonDisabled(true);
    }
  };

  const removeTagHandler = (productTag: ProductTag) => {
    const idx = assignedTags.findIndex((x) => x.tagKey === productTag.tagKey);
    if (idx !== undefined && idx > -1) assignedTags.splice(idx, 1);
    setAssignedTags([...assignedTags]);
    saveMutation.mutate({ tags: [productTag], action: TagActions.Remove });
  };

  const searchHandler = (searchTerm: string) => {
    if (searchTerm && searchTerm.length > 2) {
      setTreeTags([]);
      loadSubtreeMutation.mutate({ tag: undefined, searchTerm });
    } else {
      setTreeTags([...tags]);
    }
  };

  const treeTagSelectHandler = (tag?: Tag): void => {
    if (tag && tag.hasDescendants && (!tag.descendants || tag.descendants.length === 0)) {
      loadSubtreeMutation.mutate({ tag: tag, searchTerm: undefined });
    }
    setAddButtonDisabled(!tag || !tag.allowAssign || assignedTags.find((x) => x.tagKey === tag.key) !== undefined);
  };

  return isLoading ? (
    <Box sx={{ minHeight: '50vh', width: '100%' }} justifyContent="center" alignItems="center" display="flex">
      <CircularProgress data-testid="LoadingSpinner" />
    </Box>
  ) : (
    <TaggingView
      assignedTags={assignedTags}
      tags={treeTags}
      onAssignTag={assignTagHandler}
      onRemoveTag={removeTagHandler}
      onSearch={searchHandler}
      loadingTree={loadSubtreeMutation.isLoading}
      onTreeTagSelect={treeTagSelectHandler}
      addButtonDisabled={addButtonDisabled}
      testId={testId}
    />
  );
};

interface TaggingViewProps {
  assignedTags: ProductTag[];
  tags: Tag[];
  onAssignTag: (productTag: ProductTag, assignedTags?: ProductTag[]) => void;
  onRemoveTag: (productTag: ProductTag) => void;
  onSearch: (term: string) => void;
  loadingTree: boolean;
  onTreeTagSelect: (tag?: Tag) => void;
  addButtonDisabled: boolean;
  testId: string;
}

const TaggingView = ({
  assignedTags,
  tags,
  onAssignTag,
  onRemoveTag,
  onSearch,
  loadingTree,
  onTreeTagSelect,
  addButtonDisabled,
  testId,
}: TaggingViewProps) => {
  const [selectedProductTag, setSelectedProductTag] = useState<ProductTag | undefined>(undefined);

  const dndProvider = isMobile ? TouchBackend : HTML5Backend;

  const deniedTreeTags = assignedTags.map((x) => x.tagKey);

  const onTreeTagSelectHandler = (tag?: Tag, productTag?: ProductTag) => {
    setSelectedProductTag(productTag);
    onTreeTagSelect(tag);
  };

  const assignTagClickHandler = () => {
    if (selectedProductTag) {
      onAssignTag(selectedProductTag, undefined);
    }
  };

  return (
    <DndProvider backend={dndProvider}>
      <Grid container>
        <Grid item xs={12} md={7} sx={{ mb: 4 }}>
          <SectionTitle title="Product Tags (Mock)" />
          <AssignedChipList assignedTags={assignedTags} onAssignTag={onAssignTag} onRemoveTag={onRemoveTag} />
        </Grid>
        <Grid item xs={12} md={5}>
          <SectionTitle title="Tags (Mock)" />
          <TaggingTree
            tags={tags}
            deniedTagKeys={deniedTreeTags}
            onSearch={onSearch}
            loading={loadingTree}
            onSelect={onTreeTagSelectHandler}
            testId={testId}
          />
          <Button
            variant="contained"
            sx={{ mt: 3 }}
            startIcon={<AddIcon />}
            title="Or drag from the tree"
            disabled={addButtonDisabled}
            onClick={assignTagClickHandler}
            data-testid={`${testId}AssignTagToProductButton`}
          >
            Assign tag to product
          </Button>
        </Grid>
      </Grid>
    </DndProvider>
  );
};
