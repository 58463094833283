import { Program } from '@one/api-models/lib/Admin/ProgramSales/ChooseProgram/Program';
import { BillingDetails } from '@one/api-models/lib/Admin/ProgramSales/Purchase/BillingDetails';
import { CalculatedInstallmentPlan } from '@one/api-models/lib/Admin/ProgramSales/Purchase/CalculatedInstallmentPlan';
import { InstallmentPlan } from '@one/api-models/lib/Admin/ProgramSales/Purchase/InstallmentPlan';
import { LoadResponse } from '@one/api-models/lib/Admin/ProgramSales/Purchase/LoadResponse';
import { ReservationPlan } from '@one/api-models/lib/Admin/ProgramSales/Purchase/ReservationPlan';
import { SplitPaymentPlan } from '@one/api-models/lib/Admin/ProgramSales/Purchase/SplitPaymentPlan';
import { Metadata } from '@one/api-models/lib/Metadata/Metadata';
import { MetadataKey } from '@one/api-models/lib/Metadata/MetadataKey';
import { Money } from '@one/api-models/lib/Money';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store/store';

import { Customer } from 'models/customers/Customer';

export enum PaymentOptions {
  OneTime = 'one-time',
  PaymentPlan = 'payment-plan',
  Reservation = 'reservation',
  RequestPayment = 'request-payment',
  SplitPayment = 'split-payment',
}
interface PaymentPlan {
  paymentPlan: InstallmentPlan | ReservationPlan | SplitPaymentPlan | undefined;
  totalDueToday: Money;
}

interface SalesOrderData {
  //TODO clean-up selectedProgram
  selectedProgram: Program | undefined;

  selectedPrograms: Program[];
  selectedCustomer: Customer | null;
  purchaseData: LoadResponse | undefined;
  billingDetails: BillingDetails | undefined;
  selectedPaymentOption: PaymentOptions;
  paymentPlan: PaymentPlan | undefined;
  calculatedInstallmentPlan: CalculatedInstallmentPlan | undefined;
  orderKey: string | undefined;
  paymentError: string | undefined;
  isLoadingOrderPayment: boolean;
  isCompleteWithNoPayment: boolean;
  paymentProviderType: string | undefined;
  metadataKeys: MetadataKey[];
  metadata: Metadata[];
}

const initialState: SalesOrderData = {
  selectedProgram: undefined,
  selectedPrograms: [],
  selectedCustomer: null,
  purchaseData: undefined,
  billingDetails: undefined,
  selectedPaymentOption: PaymentOptions.OneTime,
  paymentPlan: undefined,
  calculatedInstallmentPlan: undefined,
  orderKey: undefined,
  paymentError: undefined,
  isLoadingOrderPayment: false,
  isCompleteWithNoPayment: false,
  paymentProviderType: undefined,
  metadataKeys: [],
  metadata: [],
};

const salesOrderDataSlice = createSlice({
  name: 'salesOrderData',
  initialState,
  reducers: {
    setSelectedProgram: (state, action: PayloadAction<Program | undefined>) => {
      state.selectedProgram = action.payload;
    },
    setSelectedPrograms: (state, action: PayloadAction<Program[]>) => {
      state.selectedPrograms = action.payload;
    },
    setSelectedCustomer: (state, action: PayloadAction<Customer | null>) => {
      state.selectedCustomer = action.payload;
    },
    setPurchaseData: (state, action: PayloadAction<LoadResponse | undefined>) => {
      state.purchaseData = action.payload;
    },
    setBillingDetails: (state, action: PayloadAction<BillingDetails | undefined>) => {
      state.billingDetails = action.payload;
    },
    setSelectedPaymentOption: (state, action: PayloadAction<PaymentOptions>) => {
      state.selectedPaymentOption = action.payload;
    },
    setPaymentPlan: (state, action: PayloadAction<PaymentPlan | undefined>) => {
      state.paymentPlan = action.payload;
    },
    setCalculatedInstallmentPlan: (state, action: PayloadAction<CalculatedInstallmentPlan | undefined>) => {
      state.calculatedInstallmentPlan = action.payload;
    },
    setOrderKey: (state, action: PayloadAction<string | undefined>) => {
      state.orderKey = action.payload;
    },
    setPaymentError: (state, action: PayloadAction<string | undefined>) => {
      state.paymentError = action.payload;
    },
    setPaymentProviderType: (state, action: PayloadAction<string | undefined>) => {
      state.paymentProviderType = action.payload;
    },
    setMetadataKeys: (state, action: PayloadAction<MetadataKey[]>) => {
      state.metadataKeys = action.payload;
    },
    setMetadata: (state, action: PayloadAction<Metadata[]>) => {
      state.metadata = action.payload;
    },
    setIsLoadingOrderPayment: (state, action: PayloadAction<boolean>) => {
      state.isLoadingOrderPayment = action.payload;
    },
    setIsCompleteWithNoPayment: (state, action: PayloadAction<boolean>) => {
      state.isCompleteWithNoPayment = action.payload;
    },
    resetSalesOrderDataSliceState: (state, action: PayloadAction<boolean>) => {
      state.calculatedInstallmentPlan = undefined;
      state.paymentPlan = undefined;
      state.orderKey = undefined;
      state.paymentError = undefined;
      state.purchaseData = undefined;
      state.selectedPaymentOption = PaymentOptions.OneTime;
      state.selectedPrograms = [];
      state.isLoadingOrderPayment = false;
      state.isCompleteWithNoPayment = false;
      state.paymentProviderType = undefined;
      state.metadataKeys = [];
      state.metadata = [];
      if (!action.payload) {
        state.selectedCustomer = null;
        state.billingDetails = undefined;
      }
    },
  },
});

export const {
  setSelectedProgram,
  setSelectedPrograms,
  setSelectedCustomer,
  setPurchaseData,
  setBillingDetails,
  setSelectedPaymentOption,
  setPaymentPlan,
  setCalculatedInstallmentPlan,
  setOrderKey,
  setPaymentError,
  setPaymentProviderType,
  setMetadataKeys,
  setMetadata,
  setIsLoadingOrderPayment,
  setIsCompleteWithNoPayment,
  resetSalesOrderDataSliceState,
} = salesOrderDataSlice.actions;

export const selectSelectedProgram = (state: RootState) => state.salesOrderData.selectedProgram;
export const selectSelectedPrograms = (state: RootState) => state.salesOrderData.selectedPrograms;
export const selectSelectedCustomer = (state: RootState) => state.salesOrderData.selectedCustomer;
export const selectPurchaseData = (state: RootState) => state.salesOrderData.purchaseData;
export const selectBillingDetails = (state: RootState) => state.salesOrderData.billingDetails;
export const selectSelectedPaymentOption = (state: RootState) => state.salesOrderData.selectedPaymentOption;
export const selectPaymentPlan = (state: RootState) => state.salesOrderData.paymentPlan;
export const selectCalculatedInstallmentPlan = (state: RootState) => state.salesOrderData.calculatedInstallmentPlan;
export const selectOrderKey = (state: RootState) => state.salesOrderData.orderKey;
export const selectPaymentError = (state: RootState) => state.salesOrderData.paymentError;
export const selectPaymentProviderType = (state: RootState) => state.salesOrderData.paymentProviderType;
export const selectIsLoadingOrderPayment = (state: RootState) => state.salesOrderData.isLoadingOrderPayment;
export const selectIsCompleteWithNoPayment = (state: RootState) => state.salesOrderData.isCompleteWithNoPayment;
export const selectMetadataKeys = (state: RootState) => state.salesOrderData.metadataKeys;
export const selectMetadata = (state: RootState) => state.salesOrderData.metadata;
export default salesOrderDataSlice.reducer;
