import { Box, Typography } from '@mui/material';

import { SectionTitle } from 'common/SectionTitle';

export const PaymentPlanNoteSection = () => {
  return (
    <Box sx={{ mb: 6 }}>
      <SectionTitle title="Note" variant="h4" />

      <Typography variant="body1" sx={{ mb: 1 }}>
        Coming soon: ability to take notes
      </Typography>
    </Box>
  );
};
