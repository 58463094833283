import { useDispatch, useSelector } from 'react-redux';

import { Box, useTheme } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Program } from '@one/api-models/lib/Admin/ProgramSales/ChooseProgram/Program';

import { selectSelectedPrograms, setSelectedPrograms } from 'store/slices/salesOrderDataSlice';

import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { DataGrid } from 'common/dataGrid/DataGrid';
import { useFormat } from 'hooks/useFormat';
import { Autocomplete, Button, IconButton, TextField, Typography } from 'styled';

interface SelectSalesOrderItemsProps {
  testId: string;
  programs: Program[];
}
export const SelectSalesOrderItems = ({ testId, programs }: SelectSalesOrderItemsProps) => {
  const { formatCurrencyWithCode } = useFormat();
  const theme = useTheme();
  const dispatch = useDispatch();
  const selectedPrograms = useSelector(selectSelectedPrograms);
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Summary',
      flex: 2,
      sortable: true,
    },
    {
      field: 'price.amount',
      headerName: 'Amount',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      sortable: true,
      valueGetter: (_, row) => row?.price?.amount,
      renderCell: (params: any) => (
        <>{formatCurrencyWithCode(params?.row?.price?.amount, params?.row?.price?.currency)}</>
      ),
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      flex: 0.5,
      maxWidth: 50,
      align: 'right',
      renderCell: (params: any) => (
        <IconButton onClick={() => handleRemoveOrderItem(params?.row)} data-testid={`${testId}RemoveOrderItemButton`}>
          <TrashIcon />
        </IconButton>
      ),
    },
  ];

  const handleRemoveOrderItem = (program: Program) => {
    dispatch(setSelectedPrograms(selectedPrograms?.filter((p) => p.id !== program.id)));
  };

  const handleOnChange = (program: Program | null) => {
    //for now only one item can be added
    const newSelectedPrograms = [];
    if (program) {
      newSelectedPrograms.push(program);
    }
    dispatch(setSelectedPrograms(newSelectedPrograms));
  };

  return (
    <Box sx={{ mt: 7 }}>
      <Typography variant="h5">Items</Typography>
      {selectedPrograms.length > 0 ? (
        <Box sx={{ mt: 2.5 }}>
          <DataGrid
            rows={selectedPrograms.map((item: Program) => ({ ...item }))}
            columns={columns}
            rowHeight={45}
            hideFooter={true}
          />
        </Box>
      ) : (
        <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
          Add single items or multiple items to sell.
        </Typography>
      )}
      {selectedPrograms.length > 0 ? (
        <Box display="flex" flexDirection="row" justifyContent="space-between" mt={2}>
          <Button variant="text" disabled data-testId={`${testId}AddItem`}>
            Add Item
          </Button>
          <Box display="flex" flexDirection="column" justifyContent="flex-end">
            <Button
              variant="text"
              sx={{ justifyContent: 'flex-end' }}
              disabled
              data-testId={`${testId}AddSalesIncentive`}
            >
              Add Sales Incentive
            </Button>
            <Button variant="text" sx={{ justifyContent: 'flex-end' }} disabled data-testId={`${testId}AddPromoCode`}>
              Add Promo Code
            </Button>
          </Box>
        </Box>
      ) : (
        <Box maxWidth={{ xs: '100%', sm: '70%' }}>
          <Autocomplete
            data-testId={`${testId}AddOrderItemAutocomplete`}
            disabled={programs?.length === 0 || selectedPrograms?.length > 0}
            options={programs || []}
            noOptionsText="No items available"
            onChange={(_, value) => handleOnChange(value)}
            renderOption={(props, option) => <OrderItemSelectorOption props={props} option={option} />}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Find an item..."
                autoComplete="off"
                size="small"
                onChange={(e) => {
                  e.preventDefault();
                }}
                inputProps={{
                  ...params.inputProps,
                  'data-testid': `${testId}AddOrderItemInput`,
                }}
              />
            )}
          />
        </Box>
      )}
    </Box>
  );
};

const OrderItemSelectorOption = ({ props, option }: any) => {
  const { formatCurrency } = useFormat();
  return (
    <Box
      key={`ChooseProgram_${option.name}_${option.price.amount}`}
      data-testid={`ChooseProgram_${option.name}_${option.price.amount}`}
      {...props}
      sx={{
        '&.MuiAutocomplete-option': {
          justifyContent: 'space-between',
          gap: 1,
        },
      }}
    >
      <Typography variant="body1">{option.name}</Typography>
      <Typography variant="body1">{formatCurrency(option.price.amount, option.price.currency)}</Typography>
    </Box>
  );
};
