import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { combineReducers, configureStore } from '@reduxjs/toolkit';

import applicationDataReducer from 'store/slices/applicationDataSlice';
import customersDataReducer from 'store/slices/customersDataSlice';
import paymentDataReducer from 'store/slices/paymentDataSlice';
import salesOrderDataSliceReducer from 'store/slices/salesOrderDataSlice';
import salesOrderListDataSlice from 'store/slices/salesOrderListDataSlice';
import salesReservationListDataSlice from 'store/slices/salesReservationListDataSlice';
import salesTransactionsListDataSlice from 'store/slices/salesTransactionsListDataSlice';
import tagsDataReducer from 'store/slices/tagsDataSlice';
import userDataReducer from 'store/slices/userDataSlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['applicationData'],
};

const applicationDataConfig = {
  key: 'applicationData',
  version: 1,
  storage,
  blacklist: ['activeAgency'],
};

const applicationDataPersistReducer = persistReducer(applicationDataConfig, applicationDataReducer);

const localStoredReducer = combineReducers({
  user: userDataReducer,
  applicationData: applicationDataPersistReducer,
});

const persistedReducer = persistReducer(persistConfig, localStoredReducer);

const rootReducer = combineReducers({
  localData: persistedReducer,
  customersData: customersDataReducer,
  paymentData: paymentDataReducer,
  salesOrderData: salesOrderDataSliceReducer,
  tagsData: tagsDataReducer,
  salesOrderListData: salesOrderListDataSlice,
  salesReservationListData: salesReservationListDataSlice,
  salesTransactionsListData: salesTransactionsListDataSlice,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

export { store, persistor };
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
