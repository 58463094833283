import { useMutation, useQuery } from 'react-query';

import CloseIcon from '@mui/icons-material/Close';
import { Alert, Box, CircularProgress, Dialog, Typography, useTheme } from '@mui/material';
import { CalculateCancellationOutcomeRequest } from '@one/api-models/lib/Admin/Orders/Common/CalculateCancellationOutcomeRequest';
import { CalculateCancellationOutcomeResponse } from '@one/api-models/lib/Admin/Orders/Common/CalculateCancellationOutcomeResponse';
import { CancelOrderRequest } from '@one/api-models/lib/Admin/Orders/Common/CancelOrderRequest';
import { CancelOrderResponse } from '@one/api-models/lib/Admin/Orders/Common/CancelOrderResponse';
import { LoadCriteria } from '@one/api-models/lib/Admin/Products/LoadCriteria';
import { Order } from '@one/api-models/lib/Admin/Sales/Order';
import { CruiseOrderItemSummary } from '@one/api-models/lib/Admin/Sales/Travel/Cruise/CruiseOrderItemSummary';
import { ExperienceOrderItemSummary } from '@one/api-models/lib/Admin/Sales/Travel/Experience/ExperienceOrderItemSummary';
import { CarOrderItemSummary } from '@one/api-models/lib/Membership/Account/Travel/Car/CarOrderItemSummary';

import { ApiError } from 'apiAccess/api-client';
import { AccessControlGuard } from 'common/AccessControlGuard';
import { useApiHelpers } from 'hooks/useApiHelpers';
import { useToastMessage } from 'hooks/useToastMessage';
import { CancellationForm } from 'modules/salesOrders/components/cancelOrder/CancellationForm';
import { CancellationOutcome } from 'modules/salesOrders/components/cancelOrder/CancellationOutcome';
import { CancellationPolicy } from 'modules/salesOrders/components/cancelOrder/CancellationPolicy';
import { DialogContent, DialogTitle, IconButton } from 'styled';

interface Props {
  order: Order;
  testId: string;
  isCancellationOpen: boolean;
  handleClose: () => void;
  onOrderCancellationFinished: () => void;
}

export const CancelOrderDialog = ({
  order,
  testId,
  onOrderCancellationFinished,
  handleClose,
  isCancellationOpen,
}: Props) => {
  const theme = useTheme();
  const { api } = useApiHelpers();
  const { apiErrorHandler, addMessage } = useToastMessage();

  const orderItemSummary = order.orderItems[0];
  const orderItemType = orderItemSummary?.$type;

  const { data, isFetching } = useQuery<CalculateCancellationOutcomeResponse, ApiError>(
    ['orderCancellation', order.orderNumber],
    () => {
      const criteria = {
        orderNumber: order.orderNumber,
        orderItemIds: order.orderItems.map((orderItem: any) => orderItem.id),
        cultureCode: 'en-US',
        $Type: LoadCriteria.$type,
      } as CalculateCancellationOutcomeRequest;

      return api.sales.calculateCancellationOutcome(criteria);
    },
    {
      enabled: isCancellationOpen,
      keepPreviousData: true,
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onError: apiErrorHandler,
    },
  );

  const {
    isLoading,
    data: cancelOrderResponse,
    mutate,
  } = useMutation<CancelOrderResponse, ApiError, CancelOrderRequest, unknown>(
    (request) => api.sales.cancelOrder(request),
    {
      mutationKey: 'cancelOrderMutation',
      onSuccess: (value: CancelOrderResponse) => {
        if (value.isSuccessful) {
          addMessage({
            label: 'Order cancelled with success.',
            severity: 'success',
          });
        }
        onOrderCancellationFinished();
      },
      onError: (error) => {
        apiErrorHandler(error);
        onOrderCancellationFinished();
      },
    },
  );

  if (order.isCancellable !== true) return null;
  const isNotCancellable = data == null || data.isCancellable !== true;
  const handleSubmitForm = (request: CancelOrderRequest): void => {
    mutate(request);
  };

  return (
    <Dialog
      open={isCancellationOpen}
      onClose={handleClose}
      scroll="paper"
      sx={{
        '.MuiDialog-paper': {
          width: '100%',
          maxWidth: '800px',
        },
      }}
    >
      <DialogTitle>
        Cancel Order
        <IconButton onClick={handleClose} size="small" disableRipple data-testid={`${testId}CloseButton`}>
          <CloseIcon sx={{ color: theme.customPalette.icons.light }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <AccessControlGuard permission={['Sales:Manage']}>
          {isCancellationOpen && (
            <>
              {isFetching || isLoading ? (
                <Box sx={{ height: 150 }} justifyContent="center" alignItems="center" display="flex">
                  <CircularProgress data-testid="LoadingSpinner" />
                </Box>
              ) : (
                <Box>
                  {cancelOrderResponse != null ? (
                    <Box mb={2}>
                      <Alert
                        variant="standard"
                        sx={{ mb: 2 }}
                        severity={cancelOrderResponse.isSuccessful ? 'success' : 'error'}
                      >
                        {cancelOrderResponse.isSuccessful ? (
                          'Order cancelled with success.'
                        ) : (
                          <>
                            <strong>An error has occurred!</strong> The order was not cancelled.
                          </>
                        )}
                      </Alert>

                      {cancelOrderResponse.messages != null && (
                        <ul>
                          {cancelOrderResponse.messages.map((message: string) => (
                            <li key={message}>{message}</li>
                          ))}
                        </ul>
                      )}
                    </Box>
                  ) : (
                    <>
                      {orderItemType === CruiseOrderItemSummary.$type &&
                        (orderItemSummary as CruiseOrderItemSummary)?.productSummary?.cruiseLine?.id && (
                          <CancellationPolicy
                            cruiseLineId={(orderItemSummary as CruiseOrderItemSummary)?.productSummary?.cruiseLine.id}
                          />
                        )}

                      {isNotCancellable ? (
                        <Box mb={2} mx={2}>
                          <Typography variant="subtitle1">This order cannot be canceled.</Typography>
                        </Box>
                      ) : (
                        <Box mb={2}>
                          <CancellationOutcome data={data} />
                          <CancellationForm
                            data={data}
                            orderNumber={order.orderNumber}
                            onCloseForm={handleClose}
                            onSubmitForm={handleSubmitForm}
                            alwaysCancelAtSupplier={
                              orderItemType === ExperienceOrderItemSummary.$type ||
                              orderItemType === CarOrderItemSummary.$type
                            }
                            testId={testId}
                          />
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              )}
            </>
          )}
        </AccessControlGuard>
      </DialogContent>
    </Dialog>
  );
};
