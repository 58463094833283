import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { CybersourceGatewayConfiguration } from '@one/api-models/lib/Sales/Payment/Transaction/CybersourceGatewayConfiguration';
import { SetupAdhocPayment } from '@one/api-models/lib/Sales/Payment/Transaction/SetupAdhocPayment';
import { SetupPaymentResponse } from '@one/api-models/lib/Sales/Payment/Transaction/SetupPaymentResponse';
import { SetupProgramPayment } from '@one/api-models/lib/Sales/Payment/Transaction/SetupProgramPayment';
import { Shift4GatewayConfiguration } from '@one/api-models/lib/Sales/Payment/Transaction/Shift4GatewayConfiguration';
import { StripeGatewayConfiguration } from '@one/api-models/lib/Sales/Payment/Transaction/StripeGatewayConfiguration';

import { selectActiveBrand } from 'store/slices/applicationDataSlice';
import { setPaymentError, setPaymentProviderType } from 'store/slices/salesOrderDataSlice';

import { ApiError } from 'apiAccess/api-client';
import { useApiHelpers } from 'hooks/useApiHelpers';
import { useToastMessage } from 'hooks/useToastMessage';

const ConfigurationMap = {
  paymentPlans: SetupAdhocPayment,
  programSale: SetupProgramPayment,
};

export type PaymentSetupType = 'programSale' | 'paymentPlans';

type PaymentGatewayConfigurationType =
  | StripeGatewayConfiguration
  | Shift4GatewayConfiguration
  | CybersourceGatewayConfiguration;

export const usePaymentSetup = (
  type: PaymentSetupType,
  setIsLoadingConfiguration: (isLoading: boolean) => void,
  setPaymentGatewayConfiguration: (paymentGatewayConfiguration: PaymentGatewayConfigurationType) => void,
) => {
  const dispatch = useDispatch();
  const { addApiError } = useToastMessage();
  const { api } = useApiHelpers();
  const activeBrand = useSelector(selectActiveBrand);

  const PaymentSetupRequest = ConfigurationMap[type];

  const setupMutation = () => {
    dispatch(setPaymentError(undefined));
    return api.payment.paymentSetup({
      $Type: PaymentSetupRequest.$type,
      brandKey: activeBrand?.key || '',
    });
  };

  const onSuccess = (value: SetupPaymentResponse) => {
    setIsLoadingConfiguration(false);
    setPaymentGatewayConfiguration(
      value.paymentConfiguration.paymentGatewayConfiguration as PaymentGatewayConfigurationType,
    );
    dispatch(setPaymentProviderType(value.paymentConfiguration.paymentGatewayConfiguration.$type));
  };

  const onError = (error: ApiError) => {
    setIsLoadingConfiguration(false);
    addApiError(error);
  };

  return useMutation(setupMutation, {
    onSuccess,
    onError,
  });
};
