import React, { useEffect, useState } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent, Divider, Grid, Typography } from '@mui/material';

import { selectActiveBrand } from 'store/slices/applicationDataSlice';
import { selectCAStatesList, selectCountryList, selectUSStatesList } from 'store/slices/customersDataSlice';

import { LEAD_SOURCES } from 'common/constants/LeadSources';
import { SALUTATIONS } from 'common/constants/Salutations';
import { AddressAutocomplete, SelectedAddress } from 'common/inputs/defaultFields/AddressAutocomplete';
import ControlledAutocomplete, {
  ControlledAutocompleteOption,
} from 'common/inputs/defaultFields/ControlledAutocomplete';
import ControlledDatePicker from 'common/inputs/defaultFields/ControlledDatePicker';
import { ControlledInternationalCountryCallingCodesSelect } from 'common/inputs/defaultFields/ControlledInternationalCountryCallingCodesSelect';
import { ControlledPhoneNumberInput } from 'common/inputs/defaultFields/ControlledPhoneNumberInput';
import ControlledTextField from 'common/inputs/defaultFields/ControlledTextField';
import { Brand } from 'models/Brand';
import { isPhoneNumberValid } from 'utils/validatePhoneNumber';

export type CustomerDetailsForm = {
  memberKey?: string;
  salutation?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  dateOfBirth?: Date | null;
  phoneNumber?: string;
  internationalCountryCallingCode?: string;
  countryCode?: string;
  city?: string;
  externalMemberKey?: string;
  leadSource?: string;
  address?: string;
  address2?: string;
  stateCode?: string;
  zipCode?: string;
  notes?: string;
  partnerLoyaltyId?: string;
};

interface Props {
  phone?: string;
  country?: string;
  defaultValues: CustomerDetailsForm;
  isLoading: boolean;
  handleCustomerSubmit: (data: CustomerDetailsForm) => void;
  handleCancel: any;
  testId: string;
}

export const EditCustomerForm: React.FC<Props> = ({
  defaultValues,
  isLoading,
  testId,
  handleCustomerSubmit,
  handleCancel,
}) => {
  const activeBrand = useSelector(selectActiveBrand);
  const countryList = useSelector(selectCountryList);
  const usStatesList = useSelector(selectUSStatesList);
  const caStatesList = useSelector(selectCAStatesList);

  const validationSchema: yup.SchemaOf<CustomerDetailsForm> = yup.object().shape({
    memberKey: yup.string().trim(),
    salutation: yup.string().trim(),
    firstName: yup.string().trim().required('First Name is required.').matches(/.{2,}/, {
      excludeEmptyString: true,
      message: 'Use at least 2 characters.',
    }),
    lastName: yup.string().trim().required('Last Name is required.').matches(/.{2,}/, {
      excludeEmptyString: true,
      message: 'Use at least 2 characters.',
    }),
    email: yup.string().trim().required('Email is required.').email('Must be a valid email.'),
    phoneNumber: yup
      .string()
      .trim()
      .required('Phone number is required.')
      .test('phoneNumber', 'Phone number is not valid.', (value) => isPhoneNumberValid(value))
      .nullable(),
    internationalCountryCallingCode: yup.string().trim().required('Country calling code is required.'),
    countryCode: yup.string().trim(),
    dateOfBirth: yup.date().nullable().typeError('Invalid Date Format.'),
    externalMemberKey: yup.string().trim(),
    leadSource: yup.string().trim(),
    address: yup.string().trim(),
    address2: yup.string().trim(),
    city: yup.string().trim(),
    stateCode: yup.string().trim(),
    zipCode:
      activeBrand?.key === Brand.GuestBookingsAMZ
        ? yup.string().trim()
        : yup
            .string()
            .trim()
            .when('countryCode', {
              is: 'CA',
              then: yup.string().matches(/^[a-zA-Z]\d[a-zA-Z](\s?\d[a-zA-Z]\d)?$/, 'Zip Code is invalid'),
              otherwise: yup
                .string()
                .trim()
                .when('countryCode', {
                  is: 'US',
                  then: yup
                    .string()
                    .required('Zip Code is required')
                    .matches(/^\d{5}(?:-\d{4})?$/, 'Zip Code is invalid'),
                }),
            }),
    notes: yup.string().trim(),
    partnerLoyaltyId: yup.string().trim(),
  });
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    watch,
    trigger,
  } = useForm<CustomerDetailsForm>({ mode: 'onBlur', defaultValues, resolver: yupResolver(validationSchema) });

  // #region handling Google Place Autocomplete
  const [selectedAddress, setSelectedAddress] = useState<SelectedAddress | null | undefined>(null);

  useEffect(() => {
    if (selectedAddress) {
      setValue('address', selectedAddress.street);
      setValue('city', selectedAddress.city);
      setValue('countryCode', selectedAddress.countryCode);
      setValue('zipCode', selectedAddress.zipCode);

      if (selectedAddress.countryCode === 'US' || selectedAddress.countryCode === 'CA') {
        setValue('stateCode', selectedAddress.stateCode);
      } else {
        setValue('stateCode', selectedAddress.state);
      }

      // trigger validation for all the set fields to avoid showing redundant validaiton messages
      trigger('address');
      trigger('city');
      trigger('countryCode');
      trigger('zipCode');
      trigger('stateCode');
    }
  }, [selectedAddress, setValue, trigger]);
  // #endregion handling Google Place Autocomplete

  const salutations = SALUTATIONS.map((salutation) => ({ code: salutation.value, label: salutation.label })).sort(
    (a: ControlledAutocompleteOption, b: ControlledAutocompleteOption) => a.label.localeCompare(b.label),
  );

  const leadSources = LEAD_SOURCES.map((leadSource) => ({ code: leadSource.value, label: leadSource.label })).sort(
    (a: ControlledAutocompleteOption, b: ControlledAutocompleteOption) => a.label.localeCompare(b.label),
  );

  const handleCountryChange = (value: string | undefined) => {
    setValue('stateCode', '');

    // trigger validation for state field
    trigger('stateCode');
  };

  const countryCode = watch('countryCode');
  const internationalCountryCallingCode = watch('internationalCountryCallingCode');
  const isSelectedCountryStateCodeRequired = countryCode === 'US' || countryCode === 'CA';

  return (
    <form onSubmit={handleSubmit(handleCustomerSubmit)}>
      <DialogContent>
        <Grid container spacing={2} rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Personal Information</Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <ControlledAutocomplete
              isAutoFocused
              name="salutation"
              label="Salutation"
              placeholder="Salutation"
              options={salutations || []}
              error={errors.salutation != null}
              helperText={errors.salutation?.message}
              control={control as unknown as Control<FieldValues, object>}
              testId={`${testId}Salutation`}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <ControlledTextField
              control={control as unknown as Control<FieldValues, object>}
              name="firstName"
              fullWidth
              label="First Name"
              placeholder="First Name"
              error={errors?.firstName?.message != null}
              helperText={errors?.firstName?.message}
              testId={`${testId}FirstName`}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <ControlledTextField
              control={control as unknown as Control<FieldValues, object>}
              name="lastName"
              fullWidth
              label="Last Name"
              placeholder="Last Name"
              error={errors?.lastName?.message != null}
              helperText={errors?.lastName?.message}
              testId={`${testId}LastName`}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              control={control as unknown as Control<FieldValues, object>}
              name="email"
              fullWidth
              label="Email"
              placeholder="Email"
              error={errors?.email?.message != null}
              helperText={errors?.email?.message}
              testId={`${testId}Email`}
            />
          </Grid>
          <Grid item xs={5} sm={4} md={2} sx={{ pl: 1 }}>
            <ControlledInternationalCountryCallingCodesSelect
              control={control as unknown as Control<FieldValues, object>}
              name="internationalCountryCallingCode"
              label="Country"
              fullWidth
              error={errors?.internationalCountryCallingCode?.message != null}
              helperText={errors?.internationalCountryCallingCode?.message}
              testId={testId}
            />
          </Grid>
          <Grid item xs={7} sm={8} md={4}>
            <ControlledPhoneNumberInput
              control={control as unknown as Control<FieldValues, object>}
              name="phoneNumber"
              label="Phone Number"
              fullWidth
              error={errors?.phoneNumber?.message != null}
              helperText={errors?.phoneNumber?.message}
              country={internationalCountryCallingCode}
              testId={testId}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledDatePicker
              control={control as unknown as Control<FieldValues, object>}
              name="dateOfBirth"
              label="Date of Birth"
              placeholder="Date of Birth"
              disableFuture
              openTo="year"
              error={errors?.dateOfBirth?.message != null}
              helperText={errors?.dateOfBirth?.message}
              fullWidth
              testId={`${testId}DateOfBirth`}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AddressAutocomplete
              name="address"
              label="Street Address"
              placeholder="Street Address"
              error={errors?.address?.message != null}
              helperText={errors?.address?.message}
              setSelectedAddress={setSelectedAddress}
              control={control as unknown as Control<FieldValues, object>}
              testId={testId}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              control={control as unknown as Control<FieldValues, object>}
              name="address2"
              fullWidth
              label="Apt or suite number"
              placeholder="Apt or suite number"
              error={errors?.address2?.message != null}
              helperText={errors?.address2?.message}
              testId={`${testId}AptNumber`}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              control={control as unknown as Control<FieldValues, object>}
              name="city"
              fullWidth
              label="City"
              placeholder="City"
              error={errors?.city?.message != null}
              helperText={errors?.city?.message}
              testId={`${testId}City`}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledAutocomplete
              name="countryCode"
              label="Country"
              placeholder="Country"
              options={countryList || []}
              error={errors.countryCode != null}
              helperText={errors.countryCode?.message}
              control={control as unknown as Control<FieldValues, object>}
              requiredMessage="Country is required"
              onChange={handleCountryChange}
              testId={`${testId}Country`}
            />
          </Grid>
          {!isSelectedCountryStateCodeRequired && (
            <Grid item xs={12} md={6}>
              <ControlledTextField
                control={control as unknown as Control<FieldValues, object>}
                name="stateCode"
                fullWidth
                label="State"
                placeholder="State"
                error={errors?.stateCode?.message != null}
                helperText={errors?.stateCode?.message}
                testId={`${testId}State`}
              />
            </Grid>
          )}
          {isSelectedCountryStateCodeRequired && (
            <Grid item xs={12} md={6}>
              <ControlledAutocomplete
                name="stateCode"
                label="State"
                placeholder="State"
                options={countryCode === 'US' ? usStatesList : countryCode === 'CA' ? caStatesList : []}
                error={errors.stateCode != null}
                helperText={errors.stateCode?.message}
                control={control as unknown as Control<FieldValues, object>}
                testId={`${testId}State`}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <ControlledTextField
              control={control as unknown as Control<FieldValues, object>}
              name="zipCode"
              fullWidth
              label="Zip Code"
              placeholder="Zip Code"
              error={errors?.zipCode?.message != null}
              helperText={errors?.zipCode?.message}
              testId={`${testId}ZipCode`}
            />
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <Divider sx={{ mt: 1 }} />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Reference Information</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              control={control as unknown as Control<FieldValues, object>}
              name="externalMemberKey"
              fullWidth
              label="PARTNER Customer Id"
              placeholder="PARTNER Customer Id"
              error={errors?.externalMemberKey?.message != null}
              helperText={errors?.externalMemberKey?.message}
              testId={`${testId}PartnerCustomerId`}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              control={control as unknown as Control<FieldValues, object>}
              name="partnerLoyaltyId"
              fullWidth
              label="PARTNER Loyalty ID"
              placeholder="PARTNER Loyalty ID"
              error={errors?.partnerLoyaltyId?.message != null}
              helperText={errors?.partnerLoyaltyId?.message}
              testId={`${testId}PartnerLoyaltyId`}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledAutocomplete
              name="leadSource"
              label="Lead Source"
              placeholder="Lead Source"
              options={leadSources || []}
              error={errors.leadSource != null}
              helperText={errors.leadSource?.message}
              control={control as unknown as Control<FieldValues, object>}
              testId={`${testId}LeadSourceSelect`}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <ControlledTextField
              control={control as unknown as Control<FieldValues, object>}
              name="notes"
              fullWidth
              multiline
              minRows="2"
              label="Notes"
              placeholder="Notes"
              error={errors?.notes?.message != null}
              helperText={errors?.notes?.message}
              testId={`${testId}Notes`}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          onClick={() => handleCancel(isDirty)}
          disabled={isLoading}
          variant="outlined"
          data-testid={`${testId}CancelButton`}
          disableRipple
        >
          Cancel
        </Button>
        <LoadingButton
          disabled={isLoading}
          disableRipple
          variant="contained"
          type="submit"
          size="small"
          loading={isLoading}
          data-testid={`${testId}SaveChangesButton`}
        >
          Save Changes
        </LoadingButton>
      </DialogActions>
    </form>
  );
};
