import { useSelector } from 'react-redux';

import MenuIcon from '@mui/icons-material/Menu';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import { selectActiveBrand } from 'store/slices/applicationDataSlice';

import { ReactComponent as BellIcon } from 'assets/icons/bell.svg';
import { ReactComponent as QuestionMarkIcon } from 'assets/icons/questionMark.svg';
import { SettingsMenu } from 'common/layout/SettingsMenu';
import { LayoutConfig } from 'models/LayoutConfig';
import { AppBar, Button, IconButton, Toolbar, Tooltip } from 'styled';

import { SendFeedback } from '../SendFeedback';

import { AddMenu } from './components/AddMenu';
import { AppSearchAutocomplete } from './components/AppSearchAutocomplete';

interface AppTopBarProps {
  testId: string;
  handleDrawerOpen: () => void;
  config?: LayoutConfig;
}

export const AppTopBar = ({ handleDrawerOpen, testId, config }: AppTopBarProps) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const activeBrand = useSelector(selectActiveBrand);
  const brandName = activeBrand?.name;

  return (
    <AppBar marginRight={{ medium: '40px' }}>
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {smallScreen ? (
          <Button onClick={handleDrawerOpen} sx={{ mr: 5, p: 0, color: theme.palette.text.primary }}>
            <MenuIcon sx={{ mr: 1 }} />
            <Typography
              sx={(theme) => ({
                display: 'block',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                fontSize: '0.875rem',
                lineHeight: '1rem',
                color: theme.palette.text.primary,
              })}
            >
              {config?.showPartnerSelect && brandName}
            </Typography>
          </Button>
        ) : (
          <AppSearchAutocomplete testId={testId} />
        )}
        <Box display="flex" alignItems="center">
          <SendFeedback testId={testId} />
          <Tooltip title="Guidelines, How to, and Help will be placed here">
            <IconButton size="small" sx={{ mr: 1 }} data-testid={`${testId}HelpButton`}>
              <QuestionMarkIcon fill={theme.palette.text.primary} />
            </IconButton>
          </Tooltip>
          <Tooltip title="No notifications">
            <IconButton size="small" sx={{ mr: 1 }} data-testid={`${testId}NotificationButton`}>
              <BellIcon fill={theme.palette.text.primary} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Setting">
            <SettingsMenu testId={`${testId}SettingMenuButton`} />
          </Tooltip>
          <AddMenu testId={testId} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};
