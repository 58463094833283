import { Grid, Paper } from '@mui/material';

import { SectionTitle } from 'common/SectionTitle';

interface FieldBoxProps {
  title?: string;
  children?: React.ReactNode;
}

export const FieldBox = ({ title, children }: FieldBoxProps) => {
  return (
    <Paper sx={{ p: 2 }}>
      <Grid container direction="row" alignItems="stretch">
        {title && (
          <Grid item xs={12}>
            <SectionTitle title={title} />
          </Grid>
        )}
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Paper>
  );
};
