import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { BillingDetails } from '@one/api-models/lib/Admin/ProgramSales/Purchase/BillingDetails';
import { CompleteOrderRequest } from '@one/api-models/lib/Admin/ProgramSales/Purchase/CompleteOrderRequest';
import { CompleteOrderResponse } from '@one/api-models/lib/Admin/ProgramSales/Purchase/CompleteOrderResponse';
import { InitOrderRequest } from '@one/api-models/lib/Admin/ProgramSales/Purchase/InitOrderRequest';
import { InitOrderResponse } from '@one/api-models/lib/Admin/ProgramSales/Purchase/InitOrderResponse';
import { PaymentPlanBase } from '@one/api-models/lib/Admin/ProgramSales/Purchase/PaymentPlanBase';
import { PaymentSource } from '@one/api-models/lib/Admin/ProgramSales/Purchase/PaymentSource';
import { PaymentToken } from '@one/api-models/lib/Admin/ProgramSales/Purchase/PaymentToken';
import { Metadata } from '@one/api-models/lib/Metadata/Metadata';

import { selectActiveAgency, selectActiveBrand, selectActivePartner } from 'store/slices/applicationDataSlice';
import {
  resetSalesOrderDataSliceState,
  selectPaymentPlan,
  selectSelectedCustomer,
  selectSelectedPrograms,
  setIsLoadingOrderPayment,
  setOrderKey,
  setPaymentError,
} from 'store/slices/salesOrderDataSlice';

import { ApiError } from 'apiAccess/api-client';
import { PAYMENT_AVS_MISMATCH_ERROR } from 'core/payment/constants/CybersourcePayment';
import { useApiHelpers } from 'hooks/useApiHelpers';
import { useToastMessage } from 'hooks/useToastMessage';

export const useCybersourceProgramSale = () => {
  const dispatch = useDispatch();
  const { api } = useApiHelpers();
  const { apiErrorHandler } = useToastMessage();
  const navigate = useNavigate();

  const activePartner = useSelector(selectActivePartner);
  const activeBrand = useSelector(selectActiveBrand);
  const activeAgency = useSelector(selectActiveAgency);
  const selectedPrograms = useSelector(selectSelectedPrograms);
  const selectedCustomer = useSelector(selectSelectedCustomer);
  const paymentPlan = useSelector(selectPaymentPlan);

  const initOrderMutation = useMutation<
    InitOrderResponse,
    ApiError,
    {
      billingDetails: BillingDetails;
      paymentPlan?: PaymentPlanBase;
      token: string;
      paymentMethodExpiration: Date;
      metadata?: Metadata[];
    },
    unknown
  >(
    async ({ billingDetails, paymentPlan, token, paymentMethodExpiration, metadata }) => {
      return await api.programSales.initOrder({
        billingDetails: billingDetails,
        paymentPlan: paymentPlan
          ? {
              ...paymentPlan,
            }
          : undefined,
        memberKey: selectedCustomer?.memberKey,
        programId: selectedPrograms[0]?.id,
        partnerKey: activePartner?.key,
        brandKey: activeBrand?.key,
        brandId: activeBrand ? parseInt(activeBrand?.key) : undefined,
        paymentMethodToken: token,
        paymentMethodExpiration,
        agencyKey: activeAgency?.key,
        metadata: metadata,
      } as InitOrderRequest);
    },
    {
      onSuccess: (value) => {
        if (value) {
          dispatch(setOrderKey(value.orderNumber));
        }
      },
      onError: (error) => {
        if (error.errors != null && error.errors.length > 0) {
          dispatch(setPaymentError(error.errors.map((e) => e.message).join('\n')));
        } else {
          dispatch(setPaymentError(error.message));
        }
        apiErrorHandler(error, undefined, undefined, PAYMENT_AVS_MISMATCH_ERROR);
      },
    },
  );

  const performCompleteOrderWithPayment = async (
    token: string,
    paymentMethodExpiration: Date,
    billingDetails: BillingDetails | undefined,
    paymentPlan?: PaymentPlanBase,
    metadata?: Metadata[],
  ) => {
    if (!billingDetails) throw new Error('Failed to get billingDetails');

    dispatch(setPaymentError(undefined));

    const order = await initOrderMutation.mutateAsync({
      billingDetails,
      paymentPlan,
      token,
      paymentMethodExpiration,
      metadata,
    });
    performCompleteOrder(order.orderNumber, order.paymentTokens[0]);
  };

  const completeOrderMutation = useMutation<
    CompleteOrderResponse,
    ApiError,
    { orderKey: string; transactionId: number; paymentIntent: string; tokenReference?: string },
    unknown
  >(
    async ({ orderKey, transactionId, paymentIntent, tokenReference }) => {
      const payment: PaymentSource = {
        amount:
          paymentPlan && paymentPlan?.totalDueToday
            ? paymentPlan?.totalDueToday
            : { amount: 0, currency: 'USD', isEstimated: false },
        transactionId: transactionId,
        paymentIntentReference: paymentIntent,
        paymentMethodReference: tokenReference,
        isPaymentMethodDefault: true,
      };
      return await api.programSales.completeOrder({
        memberKey: selectedCustomer?.memberKey,
        orderNumber: orderKey,
        partnerKey: activePartner?.key,
        brandKey: activeBrand?.key,
        paymentSources: [payment],
        agencyKey: activeAgency?.key,
      } as unknown as CompleteOrderRequest);
    },
    {
      onSuccess: (value) => {
        if (value) {
          navigate(`/sales/orders/${value.orderConfirmationNumber}`);
          dispatch(resetSalesOrderDataSliceState(true));
        }
      },
      onError: (error) => {
        if (error.errors != null && error.errors.length > 0) {
          dispatch(setPaymentError(error.errors.map((e) => e.message).join('\n')));
        } else {
          dispatch(setPaymentError(error.message));
        }
        apiErrorHandler(error);
      },
    },
  );

  const performCompleteOrder = (orderKey: string, paymentToken: PaymentToken) => {
    completeOrderMutation.mutate({
      orderKey,
      transactionId: paymentToken?.transactionId,
      paymentIntent: paymentToken?.secret,
      tokenReference: paymentToken?.paymentMethodGatewayReference,
    });
  };

  useEffect(() => {
    dispatch(setIsLoadingOrderPayment(initOrderMutation.isLoading || completeOrderMutation.isLoading));
  }, [dispatch, initOrderMutation.isLoading, completeOrderMutation.isLoading]);

  return {
    performCompleteOrderWithPayment,
  };
};
