import { RefObject, useImperativeHandle, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@mui/material';
import { BillingDetails } from '@one/api-models/lib/BillingDetails';
import { PaymentMethod } from '@one/api-models/lib/Sales/Payment/PaymentMethod/PaymentMethod';
import { StripeGatewayConfiguration } from '@one/api-models/lib/Sales/Payment/Transaction/StripeGatewayConfiguration';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardNumberElement } from '@stripe/stripe-js';

import { selectCreatePaymentMethodError } from 'store/slices/paymentDataSlice';

import { BillingDetailsComponentRef, BillingDetailsForm } from 'common/billingDetails/BillingDetailsForm';
import { useStripeCreatePayment } from 'hooks/useStripeCreatePayment';
import { AddPaymentMethodComponentRef } from 'models/AddPaymentMethodComponentRef';
import { InternalValidationErrors, StripeForm } from 'modules/sales/components/payment/StripeForm';
import { SectionTitle } from 'styled/SectionTitle';
import { validateStripeCard } from 'utils/stripe';

export interface AddPaymentMethodStripeComponentProps {
  testId: string;
  memberId: string;
  paymentGatewayConfiguration: StripeGatewayConfiguration;
  callback: (paymentMethod: PaymentMethod) => void;
  resetCreatePaymentMethodError: () => void;
  setAddPaymentModalOpen: (value: boolean) => void;
  billingDetails: BillingDetails;
  componentRef: RefObject<AddPaymentMethodComponentRef>;
}

export const AddPaymentMethodStripeComponent = (props: AddPaymentMethodStripeComponentProps) => {
  const {
    testId,
    memberId,
    paymentGatewayConfiguration,
    callback,
    setAddPaymentModalOpen,
    resetCreatePaymentMethodError,
    billingDetails,
    componentRef,
  } = props;
  const stripe = useStripe();
  const { performCreatePaymentMethod } = useStripeCreatePayment();

  const createPaymentMethodError = useSelector(selectCreatePaymentMethodError);
  const billingDetailsFormRef = useRef<BillingDetailsComponentRef>(null);

  const [cardErrors, setCardErrors] = useState<InternalValidationErrors>({
    cardNumberError: undefined,
    cardExpiryError: undefined,
    cardCvcError: undefined,
  });

  const elements = useElements();

  const createPaymentMethodFormSubmitHandler = async () => {
    resetCreatePaymentMethodError();
    if (elements) {
      const isBillingValid = await billingDetailsFormRef.current?.validate();
      const { cardElement, creditCardErrors } = validateStripeCard(elements);
      setCardErrors(creditCardErrors);

      if (
        creditCardErrors.cardNumberError ||
        creditCardErrors.cardExpiryError ||
        creditCardErrors.cardCvcError ||
        !isBillingValid
      )
        return;

      const billingDetails = await billingDetailsFormRef.current?.getBillingDetails();
      if (!billingDetails) return;
      handleSubmitAddPaymentMethod(cardElement, false, memberId, billingDetails);
    }
  };

  const handleSubmitAddPaymentMethod = async (
    card: StripeCardNumberElement,
    isDefault: boolean,
    memberKey: string | undefined,
    billingDetails: BillingDetails,
  ) => {
    await performCreatePaymentMethod(
      stripe,
      card,
      isDefault,
      paymentGatewayConfiguration.gatewayIdentifier,
      memberKey,
      billingDetails,
      callback,
      setAddPaymentModalOpen,
    );
  };

  useImperativeHandle(componentRef, () => ({
    submitAddPaymentMethod: createPaymentMethodFormSubmitHandler,
    validate: async () => {
      const isBillingValid = await billingDetailsFormRef.current?.validate();
      if (!elements) return false;
      const { creditCardErrors } = validateStripeCard(elements);
      setCardErrors(creditCardErrors);

      if (
        creditCardErrors.cardNumberError ||
        creditCardErrors.cardExpiryError ||
        creditCardErrors.cardCvcError ||
        !isBillingValid
      )
        return false;

      return true;
    },
  }));

  return (
    <>
      <StripeForm
        errors={cardErrors}
        error={createPaymentMethodError}
        resetError={resetCreatePaymentMethodError}
        testId={testId}
      />
      <Box mt={3}>
        <SectionTitle>Billing to</SectionTitle>
        <BillingDetailsForm testId={testId} initialDetails={billingDetails} componentRef={billingDetailsFormRef} />
      </Box>
    </>
  );
};
