import { useSelector } from 'react-redux';

import { Box, useTheme } from '@mui/material';

import { selectActiveBrand, selectActivePartner } from 'store/slices/applicationDataSlice';

import CRPIcon from 'assets/rewards/CCM.CRP.SR.png';
import HBCIcon from 'assets/rewards/CCM.HBC.CCC.png';
import { ReactComponent as ECDRewardsIcon } from 'assets/rewards/ECD.ELX.ECR.svg';
import { ReactComponent as ECDPointsIcon } from 'assets/rewards/ECD.ELX.EP.svg';
import CVYIcon from 'assets/rewards/GPR.CVY.GMP.png';
import GBKIcon from 'assets/rewards/guestBooking/rewards_white_border.png';
import LRSShoppingCreditsIcon from 'assets/rewards/LRS.LER.SC.png';
import LRSWineCreditsIcon from 'assets/rewards/LRS.LER.WC.png';
import MBOIcon from 'assets/rewards/MBR.MBO.MS.png';
import PBTIcon from 'assets/rewards/PBT.EPB.ERC.png';
import { ReactComponent as CANIcon } from 'assets/rewards/PPR.CAN.HCN.svg';
import { ReactComponent as FCPHCIcon } from 'assets/rewards/PPR.FCP.HC.svg';
import { ReactComponent as FCPIcon } from 'assets/rewards/PPR.FCP.PPP.svg';
import { ReactComponent as PCUIcon } from 'assets/rewards/PPR.PCU.HCU.svg';
import { ReactComponent as CPRIcon } from 'assets/rewards/PRI.CPR.CP.svg';
import { ReactComponent as MCMIcon } from 'assets/rewards/PRI.MCM.MCR.svg';
import { ReactComponent as PPRIcon } from 'assets/rewards/PRI.PPR.PPP.svg';
import MGWIcon from 'assets/rewards/RSI.MGW.GWR.png';
import { ReactComponent as VICIcon } from 'assets/rewards/RYT.VIC.VTR.svg';
import { ReactComponent as UVCPointsIcon } from 'assets/rewards/UVC.UVX.UP.svg';
import { ReactComponent as UVCIcon } from 'assets/rewards/UVC.UVX.UVCR.svg';
import { ReactComponent as UXCIcon } from 'assets/rewards/UVC.UXC.UXCAR.svg';
import { ReactComponent as ASPPointsIcon } from 'assets/rewards/VID.ASP.ASPP.svg';
import { ReactComponent as ASPRewardsIcon } from 'assets/rewards/VID.ASP.ASPR.svg';
import { AlternativeCurrency } from 'models/AlternativeCurrency';
import { Brand } from 'models/Brand';
import { Partner } from 'models/Partner';

type Props = {
  size?: string;
  color?: string;
  alternativeCurrency?: string;
  fallback?: React.ReactElement | null;
};

export const AlternativeCurrenciesIcon = ({ alternativeCurrency, size = '1rem', color, fallback = null }: Props) => {
  const theme = useTheme();
  const activePartner = useSelector(selectActivePartner);
  const activeBrand = useSelector(selectActiveBrand);

  const partnerKey = activePartner?.key;
  const brandKey = activeBrand?.key;
  const renderAltCurrency = () => {
    switch (`${partnerKey}.${brandKey}.${alternativeCurrency}`.trim()) {
      case `${Partner.Princess}.${Brand.MedallionClassMarket}.${AlternativeCurrency.MedallionClassRewards}`:
        return <MCMIcon height={size} fill={color ?? theme.palette.primary.main} />;
      case `${Partner.PrincessPromotions}.${Brand.FutureCruisePackages}.${AlternativeCurrency.FutureCruiseCredits}`:
      case `${Partner.Princess}.${Brand.ClubPrincess}.${AlternativeCurrency.CruisePoints}`:
        return <CPRIcon height={size} fill={color ?? theme.palette.primary.main} />;
      case `${Partner.Princess}.${Brand.PrincessPartnerRewards}.${AlternativeCurrency.PrincessPartnerPoints}`:
        return <PPRIcon height={size} fill={color ?? theme.palette.primary.main} />;
      case `${Partner.PrincessPromotions}.${Brand.FutureCruisePackages}.${AlternativeCurrency.PrincessPromoPoints}`:
        return <FCPIcon height={size} fill={color ?? theme.palette.primary.main} />;
      case `${Partner.PrincessPromotions}.${Brand.FutureCruisePackages}.${AlternativeCurrency.HotelCredits}`:
        return <FCPHCIcon height={size} fill={color ?? theme.palette.primary.main} />;
      case `${Partner.PrincessPromotions}.${Brand.FutureCruisePackagesCanada}.${AlternativeCurrency.HotelCreditsCanada}`:
        return <CANIcon height={size} fill={color ?? theme.palette.primary.main} />;
      case `${Partner.PrincessPromotions}.${Brand.FutureCruisePackagesUk}.${AlternativeCurrency.HotelCreditsUK}`:
        return <PCUIcon height={size} fill={color ?? theme.palette.primary.main} />;
      case `${Partner.GuestBookings}.${Brand.GuestBookings}.${AlternativeCurrency.Rewards}`:
      case `${Partner.GuestBookings}.${Brand.TravelGuestBookings}.${AlternativeCurrency.TravelGuestBookingsRewards}`:
        return <ImgIcon src={GBKIcon} size={size} />;
      case `${Partner.GuestBookings}.${Brand.Vivri}.${AlternativeCurrency.Rewards}`:
        return <ImgIcon src={GBKIcon} size={size} />;
      case `${Partner.GuestBookings}.${Brand.GuestBookingsAMZ}.${AlternativeCurrency.Credits}`:
        return <ImgIcon src={GBKIcon} size={size} />;
      case `${Partner.GuestBookingsUK}.${Brand.GuestBookingsUK}.${AlternativeCurrency.GuestBookingsUKRewards}`:
        return <ImgIcon src={GBKIcon} size={size} />;
      case `${Partner.MagicBreak}.${Brand.MagicBreak}.${AlternativeCurrency.MagicStars}`:
        return <ImgIcon src={MBOIcon} size={size} />;
      case `${Partner.GrandPacificResorts}.${Brand.ClubVacay}.${AlternativeCurrency.GPRMarketPoints}`:
        return <ImgIcon src={CVYIcon} size={size} />;
      case `${Partner.RSI}.${Brand.MemberGetaways}.${AlternativeCurrency.RewardCredits}`:
        return <ImgIcon src={MGWIcon} size={size} />;
      case `${Partner.CCM}.${Brand.HotelsByCruisecom}.${AlternativeCurrency.CruiseComCredits}`:
        return <ImgIcon src={HBCIcon} size={size} />;
      case `${Partner.CCM}.${Brand.CruiseComPlus}.${AlternativeCurrency.CRPSavingRewards}`:
        return <ImgIcon src={CRPIcon} size={size} />;
      case `${Partner.RedeemYourTravel}.${Brand.Victra}.${AlternativeCurrency.TravelRewards}`:
      case `${Partner.RedeemYourTravel}.${Brand.PrincessCUG}.${AlternativeCurrency.CUGTravelRewards}`:
        return <VICIcon height={size} />;
      case `${Partner.ElCid}.${Brand.ElCidExperiences}.${AlternativeCurrency.ElCidRewardPoints}`:
        return <ECDRewardsIcon height={size} fill={color ?? theme.palette.primary.main} />;
      case `${Partner.ElCid}.${Brand.ElCidExperiences}.${AlternativeCurrency.ElCidPoints}`:
        return <ECDPointsIcon height={size} fill={color ?? theme.palette.common.white} />;
      case `${Partner.UVC}.${Brand.UVCExperiences}.${AlternativeCurrency.UVCRewards}`:
      case `${Partner.UVC}.${Brand.UExperiencesMexico}.${AlternativeCurrency.UXMRewards}`:
        return <UVCIcon height={size} fill={color ?? theme.palette.primary.main} />;
      case `${Partner.UVC}.${Brand.UVCExperiences}.${AlternativeCurrency.UVCPoints}`:
      case `${Partner.UVC}.${Brand.UExperiencesMexico}.${AlternativeCurrency.UXMPoints}`:
        return <UVCPointsIcon height={size} fill={color ?? theme.palette.primary.main} />;
      case `${Partner.UVC}.${Brand.UExperiencesCanada}.${AlternativeCurrency.UXCPoints}`:
        return <UVCPointsIcon height={size} fill={color ?? theme.palette.primary.main} />;
      case `${Partner.UVC}.${Brand.UExperiencesCanada}.${AlternativeCurrency.UXCRewards}`:
        return <UXCIcon height={size} fill={color ?? theme.palette.primary.main} />;
      case `${Partner.Vidanta}.${Brand.Aspirex}.${AlternativeCurrency.ASPRewards}`:
        return <ASPRewardsIcon height={size} fill={color ?? theme.palette.primary.main} />;
      case `${Partner.Vidanta}.${Brand.Aspirex}.${AlternativeCurrency.ASPPoints}`:
        return <ASPPointsIcon height={size} fill={color ?? theme.palette.primary.main} />;
      case `${Partner.PuebloBonito}.${Brand.ExploreByPuebloBonito}.${AlternativeCurrency.ExploreRewards}`:
      case `${Partner.PuebloBonito}.${Brand.ExploreByPuebloBonitoMexico}.${AlternativeCurrency.ExploreMexicoRewards}`:
        return <ImgIcon src={PBTIcon} size={size} />;
      case `${Partner.LeisureRewards}.${Brand.LeisureRewards}.${AlternativeCurrency.LRSShoppingCredits}`:
        return <ImgIcon src={LRSShoppingCreditsIcon} size={size} />;
      case `${Partner.LeisureRewards}.${Brand.LeisureRewards}.${AlternativeCurrency.LRSWineCredits}`:
        return <ImgIcon src={LRSWineCreditsIcon} size={size} />;
      default:
        return fallback;
    }
  };

  return renderAltCurrency();
};

type ImgIconProps = {
  src: string;
  size?: string;
};

const ImgIcon = ({ src, size }: ImgIconProps) => {
  const activeBrand = useSelector(selectActiveBrand);
  const brandKey = activeBrand?.key;
  return (
    <Box
      component="img"
      sx={{
        height: size,
      }}
      src={src}
      alt={`${brandKey}-Logo`}
    />
  );
};
