import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { UseMutationResult } from 'react-query';

import UploadIcon from '@mui/icons-material/Upload';
import { Alert, Box, Collapse, IconButton, Skeleton, Typography } from '@mui/material';
import { AddFilesBase } from '@one/api-models/lib/Admin/Files/AddFilesBase';
import { StoreRequestBase } from '@one/api-models/lib/Admin/Files/StoreRequestBase';
import { StoreResponseBase } from '@one/api-models/lib/Admin/Files/StoreResponseBase';

import { ApiError } from 'apiAccess/api-client';
import { ActionButton } from 'common/ActionButton';

import { useStyles } from '../fileUploadDropZone/Styles';

import { FileConfiguration } from './FileConfiguration';
import { FileDetails } from './FileDetails';
import { QueueListing, UploadStatus } from './QueueListing';

interface Props<T> {
  acceptedFiles: string;
  disabled?: boolean;
  multiple?: boolean;
  uploadOnDrop?: boolean;
  storeRequestBase: T;
  storeRecordMutation: UseMutationResult<StoreResponseBase, ApiError, { storeRequest: StoreRequestBase }, unknown>;
  buttonText?: string;
  helperText?: string;
  testId: string;
}

export const FileDropZone = <T extends StoreRequestBase>({
  acceptedFiles,
  disabled,
  multiple,
  uploadOnDrop,
  storeRequestBase,
  storeRecordMutation,
  buttonText,
  helperText,
  testId,
}: Props<T>) => {
  const [files, setFiles] = useState<FileDetails[]>([]);
  const [uploadStatus, setUploadStatus] = useState(UploadStatus.new);
  const classes = useStyles();
  const [hideList, setHideList] = useState(false);

  useEffect(() => {
    if (storeRecordMutation) {
      if (storeRecordMutation.isLoading) {
        setUploadStatus(UploadStatus.uploading);
      } else if (storeRecordMutation.isSuccess) {
        setUploadStatus(UploadStatus.completed);
      } else if (storeRecordMutation.isError) {
        setUploadStatus(UploadStatus.error);
      }
    } else {
      setFiles([]);
    }
  }, [storeRecordMutation]);

  const onAddFiles = useCallback(
    (newFiles: FileDetails[]) => {
      const addActions = newFiles.map((f) => FileConfiguration.fileDetailsToAddFileRequest(f));
      setFiles(newFiles);
      Promise.all(addActions)
        .then((results) => {
          const files = results.filter((f) => f) as AddFilesBase[];

          let storeRequest: T = { ...storeRequestBase } as T;

          if ('files' in storeRequest) storeRequest = { ...storeRequest, files } as T;

          storeRecordMutation.mutate({
            storeRequest,
          });
        })
        .catch((e) => {
          console.error(e);
        });
    },
    [storeRecordMutation, storeRequestBase],
  );

  const onHideListHandler = () => {
    setFiles([]);
    setUploadStatus(UploadStatus.new);
    setHideList(true);
  };

  const openFilePicker = () => {
    if (!disabled) {
      open();
    }
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const files = acceptedFiles.map(
          (x: any) =>
            ({
              name: x.name,
              size: x.size,
              type: `${x.type}`,
              file: x,
            } as FileDetails),
        );
        if (uploadOnDrop) onAddFiles(files);
        else setFiles(files);
        setUploadStatus(UploadStatus.new);

        setHideList(false);
      }
    },
    [onAddFiles, uploadOnDrop],
  );

  const { getRootProps, getInputProps, open, isDragActive, isDragAccept, isDragReject } = useDropzone({
    noClick: true,
    noDrag: disabled,
    onDrop,
    accept: acceptedFiles,
    multiple,
  });

  return (
    <>
      <Box>
        <Box
          {...getRootProps({
            isDragActive,
            isDragAccept,
            isDragReject,
            className:
              classes.uploadDropZone +
              ' ' +
              (isDragActive && classes.uploadDropZoneHover) +
              ' ' +
              (isDragReject && classes.uploadDropZoneRejected),
          })}
        >
          <input {...getInputProps()} data-testid={`${testId}Input`} />
          <IconButton
            size="large"
            sx={{ mt: 2 }}
            className="uploadTextColor"
            onClick={openFilePicker}
            disabled={disabled}
            data-tesid={`${testId}UploadButton`}
          >
            <UploadIcon fontSize="large" />
          </IconButton>
          <Typography
            variant="h5"
            sx={{ mb: 2, cursor: disabled ? 'default' : 'pointer' }}
            className="uploadTextColor"
            onClick={openFilePicker}
          >
            Drop files here to upload
          </Typography>
          {isDragReject && <Alert severity="error">At least one of the files is not in the accepted format</Alert>}
          <Collapse in={files.length > 0 && !hideList}>
            <Box>
              <QueueListing files={files} onHide={onHideListHandler} status={uploadStatus} testId={testId} />
            </Box>
          </Collapse>
          {!uploadOnDrop && files.length > 0 && (
            <>
              <Box mt={2} mb={2} display="flex" justifyContent="flex-end">
                <ActionButton
                  type="button"
                  onClick={() => onAddFiles(files)}
                  disabled={uploadStatus !== UploadStatus.new}
                  icon={<UploadIcon />}
                  testId={`${testId}ProcessFile`}
                >
                  {buttonText || 'Upload'}
                </ActionButton>
              </Box>
              {helperText && (
                <Typography component={Box} variant="caption" sx={{ textAlign: 'left' }}>
                  {helperText}
                </Typography>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export const MediaDropZoneSkeleton = () => {
  return (
    <Box>
      <Skeleton variant="rectangular" height={158} />
    </Box>
  );
};
