import { Fragment, useMemo } from 'react';

import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { TendersListResponse } from '@one/api-models/lib/Admin/Members/MyAccount/TendersListResponse';
import { Wallet } from '@one/api-models/lib/Admin/Members/Response/Wallet';
import { Certificate } from '@one/api-models/lib/Membership/Account/Certificate';
import { Portfolio } from '@one/api-models/lib/Membership/Account/Portfolio';

import { SectionTitle } from 'common/SectionTitle';
import { useFormat } from 'hooks/useFormat';

import { Benefits } from './assets/Benefits';
import { Certificates } from './assets/Certificates';
import { ItemInfo } from './assets/ItemInfo';

interface Props {
  assets?: TendersListResponse;
  memberKey: string;
  testId: string;
  onRedemptionSuccess: () => void;
  isLoading: boolean;
}

export const AssetsSection = ({ assets, memberKey, testId, onRedemptionSuccess, isLoading }: Props) => {
  const { formatNumber } = useFormat();

  const portfolio: Portfolio = useMemo(
    () => ({ certificates: assets?.portfolio.certificates || [], benefits: assets?.portfolio.benefits || [] }),
    [assets],
  );

  const wallet: Wallet = useMemo(() => ({ tenders: assets?.wallet.tenders || [] }), [assets]);

  const certificates: Certificate[] = useMemo(() => {
    if (portfolio && portfolio.certificates) {
      return portfolio.certificates.sort((a, b) => b.status - a.status) as Certificate[];
    }
    return [];
  }, [portfolio]);

  return (
    <Fragment>
      <Box sx={{ width: '100%' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            <SectionTitle title="Wallet" />
            <Box sx={{ mb: { md: 2 } }}>
              {isLoading ? (
                <Skeleton variant="rectangular" width={300} height={25} />
              ) : wallet && wallet.tenders && wallet.tenders.length ? (
                <Fragment>
                  {wallet.tenders.map((item) => (
                    <ItemInfo
                      key={item.id}
                      type={'deal'}
                      name={`${formatNumber(parseInt(item.value))} ${item.name}`}
                      activeFrom={item.activeFrom}
                      expiresOn={item.expiresOn}
                      testId={`${testId}ItemInfo`}
                    />
                  ))}
                </Fragment>
              ) : (
                <Typography variant="body1">The wallet is empty</Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <SectionTitle title="Assets" />
            {isLoading ? (
              <Skeleton variant="rectangular" width={300} height={25} />
            ) : (
              <>
                <Certificates
                  isLoading={isLoading}
                  onRedemptionSuccess={onRedemptionSuccess}
                  memberKey={memberKey}
                  certificates={certificates}
                  testId={`${testId}Certificates`}
                />
                {portfolio?.benefits && portfolio?.benefits?.length > 0 && <Benefits benefits={portfolio?.benefits} />}
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};
