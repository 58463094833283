import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Box, Grid, TextField } from '@mui/material';
import { ProductContentBase } from '@one/api-models/lib/Admin/Products/ProductContentBase';
import { ProductDescriptionType } from '@one/api-models/lib/Admin/Products/ProductDescriptionType';
import { UpdateProductBase } from '@one/api-models/lib/Admin/Products/UpdateProductBase';

import { ActionButton } from 'common/ActionButton';

interface Props {
  product: ProductContentBase;
  onSave: (data: UpdateProductBase) => void;
  isLoading: boolean;
  onFormDirty: (isDirty: boolean) => void;
  disableEdit?: boolean;
  testId: string;
}

export const DescriptionsTab = ({ product, onSave, isLoading, onFormDirty, disableEdit, testId }: Props) => {
  const defaultValues = (product: ProductContentBase) => ({
    descriptions: product.descriptions,
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm<UpdateProductBase>({
    mode: 'onBlur',
    defaultValues: defaultValues(product),
  });

  useEffect(() => {
    onFormDirty(isDirty);
  }, [onFormDirty, isDirty]);

  useEffect(() => {
    reset(defaultValues(product));
  }, [reset, product]);

  return (
    <Box sx={{ mt: 1, width: '100%' }}>
      <form onSubmit={handleSubmit(onSave)} autoComplete="off">
        <Grid container>
          {Object.keys(product.descriptions).map((key) => (
            <Grid item xs={12} sx={{ pr: 3 }} key={key}>
              <TextField
                fullWidth
                multiline
                size="small"
                label={key.replace(/([a-z])([A-Z])/g, (_, g1, g2) => `${g1} ${g2}`)}
                sx={{ mb: 3 }}
                inputProps={{
                  readOnly: disableEdit,
                  'data-testid': `${testId}TextInput`,
                }}
                variant="standard"
                disabled={isLoading}
                {...register(`descriptions.${key}` as any)}
                error={
                  errors?.descriptions != null && errors.descriptions[key as ProductDescriptionType]?.message != null
                }
                helperText={
                  errors?.descriptions != null ? errors.descriptions[key as ProductDescriptionType]?.message : undefined
                }
              />
            </Grid>
          ))}
          {!disableEdit && (
            <Grid item sx={{ pr: 3 }} xs={12} container direction="row" justifyContent="flex-end" alignItems="baseline">
              <Grid item>
                <ActionButton
                  icon="edit"
                  loading={isLoading}
                  type="submit"
                  disabled={!isDirty}
                  testId={`${testId}Save`}
                >
                  SAVE
                </ActionButton>
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
    </Box>
  );
};
