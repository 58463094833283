import { useNavigate } from 'react-router-dom';

import { Box, Divider } from '@mui/material';
import { Order } from '@one/api-models/lib/Admin/Sales/Order';

import { DataDisplay } from 'common';
import { orderFulfillmentStatusOptions, orderPaymentStatusOptions } from 'common/constants/StatusOptions';
import { StatusChip } from 'common/StatusChip';
import { useFormat } from 'hooks/useFormat';

interface SecondaryOrderDetailsProps {
  order?: Order;
}

export const SecondaryOrderDetails = ({ order }: SecondaryOrderDetailsProps) => {
  const { formatDate } = useFormat();
  const navigate = useNavigate();

  return (
    <Box display="flex" flexDirection={{ xs: 'column', lg: 'row' }} gap={{ xs: 1, lg: 4 }}>
      <DataDisplay label="Date" value={`${formatDate(order?.createdDate, false, 'MMMM dd, yyyy hh:mm aa')} (UTC)`} />

      <Divider orientation="vertical" flexItem />

      <DataDisplay
        label="Customer"
        value={`${order?.customer?.firstName} ${order?.customer?.lastName}`}
        onClick={() => navigate(`/customers/${order?.customer?.memberKey}`, { state: { refetch: true } })}
      />

      <Divider orientation="vertical" flexItem />

      <DataDisplay label="Risk evaluation" value="n/a" />

      <Divider orientation="vertical" flexItem />

      <DataDisplay
        label="Payment"
        value={
          <StatusChip
            status={
              order?.orderPaymentStatus
                ? orderPaymentStatusOptions[order?.orderPaymentStatus] || order?.orderPaymentStatus
                : '-'
            }
            width="auto"
          />
        }
      />

      <Divider orientation="vertical" flexItem />
      <DataDisplay
        label="Fulfillment"
        value={
          <StatusChip
            status={
              order?.orderFulfillmentStatus
                ? orderFulfillmentStatusOptions[order?.orderFulfillmentStatus] || order?.orderFulfillmentStatus
                : '-'
            }
            width="auto"
          />
        }
      />
    </Box>
  );
};
