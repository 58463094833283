import { useRef } from 'react';
import { DefaultToastContainer, ToastProps, useToasts } from 'react-toast-notifications';

import CheckIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import WarningIcon from '@mui/icons-material/WarningAmber';
import { alpha, Box, IconButton, keyframes } from '@mui/material';

import { ApiError } from 'apiAccess/api-client';
import { PAYMENT_AVS_MISMATCH_ERROR } from 'core/payment/constants/CybersourcePayment';

interface ApplicationMessage {
  severity: 'error' | 'warning' | 'info' | 'success';
  width?: string;
  label: string | React.ReactNode;
  errorId?: string;
  autoDismiss?: boolean;
  autoDismissTimeout?: number;
}

const toastStates = {
  entering: { transform: 'translate3d(0,-120%, 0)' },
  entered: { transform: 'translate3d(0,0,0)' },
  exiting: { transform: 'scale(0.66)', opacity: 0 },
  exited: { transform: 'scale(0.66)', opacity: 0 },
};

const shrinkKeyframes = keyframes`from { width: 100%; } to { width: 0% }`;

export const ToastContainer = (props: any) => (
  <DefaultToastContainer {...props} style={{ zIndex: '1400', marginTop: '16px' }} />
);

export const Toast = (props: any) => <CustomToast {...props} />;

const CustomToast = (props: ToastProps) => {
  const { appearance, onDismiss, transitionState, autoDismiss, isRunning, autoDismissTimeout, children } = props;

  const id = Math.random().toString(5);
  const elementRef = useRef<HTMLDivElement>();

  return (
    <Box
      ref={elementRef}
      sx={{
        height: '100%',
        transition: `100% 120ms 100ms`,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: { xs: '100%', md: '680px' },
          height: 'fit-content',
          boxShadow: '0px 4px 8px rgba(195, 186, 180, 0.9)',
          backgroundColor: alpha('#FFFFFF', 0.92),
          borderRadius: 1,
          border: '0.5px solid #E2E8F0',
          borderBottom: 'unset',
          overflow: 'hidden',
          mb: 1,
          transition: `transform 220ms cubic-bezier(0.2, 0, 0, 1), opacity 220ms`,
          ...toastStates[transitionState],
        }}
      >
        <Box
          sx={{
            position: 'relative',
            minHeight: '46px',
            display: 'flex',
            alignItems: 'center',
            gap: { xs: 1, md: 1.5 },
            px: 2,
            py: 1,
          }}
        >
          {appearances[appearance].icon}
          <Box
            sx={{
              maxWidth: 'calc(100% - 86px)',
              height: 'fit-content',
              whiteSpace: 'pre-line',
              wordBreak: { xs: 'break-all', md: 'normal' },
              lineHeight: 1.3,
            }}
          >
            {children}
          </Box>
          <IconButton
            onClick={() => onDismiss(id)}
            aria-label="close"
            sx={{ position: 'absolute', top: '50%', transform: 'translate(0, -50%)', right: 2 }}
            data-testid="ToastCloseButton"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            position: 'relative',
            overflow: 'hidden',
            backgroundColor: `${appearances[appearance].fg}`,
            color: `${appearances[appearance].fg}`,
            height: '6px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              animation: `${shrinkKeyframes} ${autoDismissTimeout}ms linear`,
              animationPlayState: isRunning ? 'running' : 'paused',
              backgroundColor: 'rgba(0,0,0,0.2)',
              position: 'absolute',
              bottom: 0,
              height: '6px',
              left: 0,
              opacity: autoDismiss ? 1 : 0,
              width: '100%',
            }}
          ></Box>
        </Box>
      </Box>
    </Box>
  );
};

export const useToastMessage = () => {
  const { addToast } = useToasts();

  const addMessage = (value: ApplicationMessage, customError?: string) => {
    addMessages([value], customError);
  };

  const addMessages = (value: ApplicationMessage[], customError?: string) => {
    value.forEach((appMessage: ApplicationMessage) => {
      const message = mapMessage(appMessage, customError);

      return addToast(
        <>
          <Box fontWeight={600}>{message.label}</Box>
          {message.errorId && <Box>Error ID: {message.errorId}</Box>}
        </>,
        {
          appearance: message.severity,
          autoDismiss: message.autoDismiss == null ? true : message.autoDismiss,
          autoDismissTimeout:
            message.autoDismiss == null ? (appMessage.errorId ? 25000 : 10000) : message.autoDismissTimeout,
          width: message.width || '500px',
        },
      );
    });
  };

  const addApiError = (value: ApiError, _variables?: any, _context?: any | undefined, customError?: string) => {
    if (value == null) return;
    if (value.errors != null && value.errors.length > 0) {
      addMessages(
        value.errors.map((e) => ({ severity: 'error', label: e.message, errorId: value.requestErrorId })),
        customError,
      );
    } else if (value.message != null) {
      addMessages([{ severity: 'error', label: value.message, errorId: value.requestErrorId }], customError);
    }
  };

  const apiErrorHandler = (error: any, variables?: any, context?: any | undefined, customError?: string) => {
    const apiError = error as ApiError;
    const errorId = error.requestErrorId;

    if (apiError) {
      addApiError(apiError, variables, context, customError);
    } else {
      const generalError = error as Error;
      if (generalError) {
        addMessages([{ label: error.message, severity: 'error', errorId }], customError);
      } else {
        addMessages([{ label: 'API communication failed', severity: 'error', errorId }], customError);
      }
    }
  };

  const showMessage = (text: string, severity: 'error' | 'warning' | 'info' | 'success', autoDismiss?: boolean) => {
    addMessage({ label: text, severity, autoDismiss });
  };

  const mapMessage = (appMessage: ApplicationMessage, customError?: string): ApplicationMessage => {
    switch (customError) {
      // Cybersource AVS Mismatch
      case PAYMENT_AVS_MISMATCH_ERROR:
        if (typeof appMessage.label === 'string' && appMessage.label.includes(PAYMENT_AVS_MISMATCH_ERROR)) {
          return {
            ...appMessage,
            label: 'Payment error, please verify name, billing address and credit card details.',
            errorId: undefined,
          };
        } else return appMessage;
      default:
        return appMessage;
    }
  };

  return { addMessage, addMessages, addApiError, apiErrorHandler, showMessage };
};

const appearances = {
  success: {
    icon: <CheckIcon sx={{ color: '#4caf50' }} />,
    text: 'black',
    fg: '#4caf50',
    bg: 'white',
  },
  error: {
    icon: <ErrorIcon sx={{ color: '#e53935' }} />,
    text: 'black',
    fg: '#e53935',
    bg: '#white',
  },
  warning: {
    icon: <WarningIcon sx={{ color: '#ffb300' }} />,
    text: 'black',
    fg: '#ffb300',
    bg: 'white',
  },
  info: {
    icon: <InfoIcon sx={{ color: '#64b5f6' }} />,
    text: 'black',
    fg: '#64b5f6',
    bg: 'white',
  },
};
