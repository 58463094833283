import { useState } from 'react';

import {
  Alert,
  Box,
  Button,
  Grid,
  Link,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { AddMasterProductResponse } from '@one/api-models/lib/Admin/Files/Product/Travel/Accommodation/AddMasterProductResponse';
import { StoreResponseBase } from '@one/api-models/lib/Admin/Files/StoreResponseBase';

import { PageHeader } from 'common/layout/components/PageHeader';
import { ReadMore } from 'common/ReadMore';
import { useToastMessage } from 'hooks/useToastMessage';
import { FileManager } from 'modules/products/components/CreateProduct/FileManager';

export const CreateProduct = () => {
  const { showMessage } = useToastMessage();
  const [result, setResult] = useState<AddMasterProductResponse | undefined>(undefined);
  const handleSuccess = (result: StoreResponseBase) => setResult(result as AddMasterProductResponse);
  const testIdPrefix = 'CreateProduct';

  return (
    <Box>
      <PageHeader title="Create Accommodation Product" testId={testIdPrefix} />
      <Paper sx={{ mb: 2, p: 3 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid xs={12}>
            <Stepper orientation="vertical">
              <Step active={result == null}>
                <StepLabel>Download and fill the template</StepLabel>
                <StepContent>
                  <Typography>
                    Click{' '}
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://docs.google.com/spreadsheets/d/19MBKGV7m2qOJLKQbtxBTgcMdfOt-wyav"
                      data-testid={`${testIdPrefix}DownloadTemplateLink`}
                    >
                      here
                    </Link>{' '}
                    to download the template.
                  </Typography>
                </StepContent>
              </Step>
              <Step active={result == null}>
                <StepLabel>Upload the completed file</StepLabel>
                <StepContent>
                  <FileManager
                    successMessage="File processed with success"
                    acceptedFiles=""
                    onSuccess={handleSuccess}
                    testId={testIdPrefix}
                  />
                </StepContent>
              </Step>
              <Step active={result != null}>
                <StepLabel>Results</StepLabel>
                <StepContent>
                  {result != null && (
                    <>
                      {result.validationErrors != null && result.validationErrors.length > 0 ? (
                        <Alert severity="error" sx={{ mb: 2 }}>
                          An error has been occurred.
                          {result.validationErrors.map((e) => (
                            <Typography variant="caption" component={Box}>
                              {e.message}
                            </Typography>
                          ))}
                        </Alert>
                      ) : (
                        <>
                          {result.productId != null && (
                            <>
                              <Alert severity="success" sx={{ mb: 2 }}>
                                Product created with success.
                              </Alert>
                              <Box display="flex" justifyContent="space-between">
                                <Typography variant="body2">
                                  Product Id: <strong>{result.productId}</strong>
                                </Typography>
                                <Button
                                  onClick={() => {
                                    navigator.clipboard.writeText(result.productId || '');
                                    showMessage('Copied to clipboard', 'success');
                                  }}
                                  data-testid={`${testIdPrefix}CopyToClipboardButton`}
                                >
                                  Copy to clipboard
                                </Button>
                              </Box>
                            </>
                          )}
                          <Box display="flex" justifyContent="space-between">
                            <Typography variant="body2">Result: </Typography>
                            <Button
                              onClick={() => {
                                navigator.clipboard.writeText(result.result || '');
                                showMessage('Copied to clipboard', 'success');
                              }}
                              data-testid={`${testIdPrefix}CopyToClipboardButton`}
                            >
                              Copy to clipboard
                            </Button>
                          </Box>
                          <Box sx={{ p: 2, mb: 2 }}>
                            <ReadMore
                              text={result.result || ''}
                              maxLength={100}
                              typographyProps={{ variant: 'body1', sx: { whiteSpace: 'pre-wrap' } }}
                              testId={testIdPrefix}
                            />
                          </Box>
                        </>
                      )}
                    </>
                  )}

                  <Button
                    onClick={() => setResult(undefined)}
                    variant="outlined"
                    data-testid={`${testIdPrefix}UploadNewFileButton`}
                  >
                    Upload new file
                  </Button>
                </StepContent>
              </Step>
            </Stepper>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};
