import { MouseEvent, useState } from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import TreeView from '@mui/lab/TreeView';
import { Box, LinearProgress } from '@mui/material';
import { Tag } from '@one/api-models/lib/Admin/Tags/View/Tag';

import { useTagsHelpers } from 'hooks/useTagsHelpers';

import { TagTreeContextMenu } from './TagTreeContextMenu';
import { StyledTreeItem } from './TagTreeStyles';
import { TreeEditAction } from './TreeEditAction';

type Props = {
  onSelect: (tag?: Tag) => void;
  tagTree: Tag;
  defaultSelected?: Tag;
  onAction: (tag: Tag, action: TreeEditAction, newParentTag?: Tag) => void;
  disabled?: boolean;
};

export function TagTree({ tagTree, onSelect, defaultSelected, onAction, disabled }: Props) {
  const { getTag } = useTagsHelpers();

  const [contextMenuAnchor, setContextMenuAnchor] = useState<any | undefined>(undefined);
  const contextMenuTagState = useState<Tag | undefined>(undefined);
  const contextTagToMoveState = useState<Tag | undefined>(undefined);

  const getItems = (node: Tag) => {
    if (node.descendants != null && Array.isArray(node.descendants) && node.descendants.length > 0) {
      const sorted = [...node.descendants].sort();
      return sorted.map((node) => renderTree(node));
    }

    if (node.hasDescendants)
      return (
        <Box pt={2} pb={2}>
          <LinearProgress />
        </Box>
      );

    return undefined;
  };

  const renderTree = (node: Tag) => {
    return (
      <StyledTreeItem
        key={node.key}
        nodeId={node.key}
        label={node.name}
        onContextMenu={(e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          e.preventDefault();
          setContextMenuAnchor(e.target);
          contextMenuTagState[1](node);
          onSelect(node);
        }}
        disabled={disabled}
      >
        {getItems(node)}
      </StyledTreeItem>
    );
  };

  const tagItems = tagTree.descendants.map((subtreeTags) => renderTree(subtreeTags));

  return (
    <>
      <TagTreeContextMenu
        tagState={contextMenuTagState}
        tagToMoveState={contextTagToMoveState}
        contextMenuAnchor={contextMenuAnchor}
        onAction={onAction}
      />
      <TreeView
        defaultSelected={defaultSelected?.key}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultEndIcon={<LocalOfferIcon sx={{ color: 'gray' }} />}
        onNodeSelect={(e: any, n: string) => {
          onSelect(getTag(tagTree, n));
        }}
        selected={defaultSelected?.key}
        sx={{ maxHeight: 680, overflow: 'auto' }}
      >
        {tagItems}
      </TreeView>
    </>
  );
}
