type Props = {
  children?: React.ReactNode;
};

export const CodeSnippet = (props: Props) => {
  return (
    <pre>
      <code>{props.children}</code>
    </pre>
  );
};
