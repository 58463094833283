import React, { useState } from 'react';
import { Country, parsePhoneNumber } from 'react-phone-number-input';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogTitle, IconButton } from '@mui/material';
import { MemberStatus } from '@one/api-models/lib/Admin/Members/MemberStatus';
import { EditMemberRequest } from '@one/api-models/lib/Admin/Members/Request/EditMemberRequest';
import { EditMemberResponse } from '@one/api-models/lib/Admin/Members/Response/EditMemberResponse';
import { AccountSummary } from '@one/api-models/lib/Membership/Account/AccountSummary';

import { selectActiveBrand } from 'store/slices/applicationDataSlice';

import { ApiError } from 'apiAccess/api-client';
import { ConfirmationDialog } from 'common/ConfirmationDialog';
import { useApiHelpers } from 'hooks/useApiHelpers';
import { useFormat } from 'hooks/useFormat';
import { useToastMessage } from 'hooks/useToastMessage';
import { getFirstCountryCodeByCountryCallingCode } from 'utils/getFirstCountryCodeByCountryCallingCode';

import { CustomerDetailsForm, EditCustomerForm } from '../EditCustomerForm';

interface Props {
  isOpen: boolean;
  accountSummary: AccountSummary;
  testId: string;
  handleClose: () => void;
}

export const EditCustomer: React.FC<Props> = ({ isOpen, accountSummary, testId, handleClose }: Props) => {
  const { api } = useApiHelpers();
  const { apiErrorHandler, addMessage } = useToastMessage();
  const { currentZonedDateToISODate, isoDateToCurrentZonedDate } = useFormat();
  const navigate = useNavigate();
  const activeBrand = useSelector(selectActiveBrand);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const parsedPhoneNumber = parsePhoneNumber(accountSummary.profileSummary.mobilePhone ?? '');
  const internationalCountryCallingCode = parsedPhoneNumber?.country
    ? parsedPhoneNumber.country
    : getFirstCountryCodeByCountryCallingCode(parsedPhoneNumber?.countryCallingCode as string);

  const defaultValues: CustomerDetailsForm = {
    memberKey: accountSummary.memberKey,
    salutation: accountSummary.profileSummary.salutation ?? '',
    firstName: accountSummary.profileSummary.firstName ?? '',
    lastName: accountSummary.profileSummary.lastName ?? '',
    email: accountSummary.profileSummary.email ?? '',
    dateOfBirth: isoDateToCurrentZonedDate(accountSummary.profileSummary.dateOfBirth) ?? null,
    phoneNumber:
      parsedPhoneNumber && accountSummary.profileSummary.mobilePhone
        ? accountSummary.profileSummary.mobilePhone
        : parsePhoneNumber(
            accountSummary.profileSummary.mobilePhone ?? '',
            (accountSummary.profileSummary.country as Country) ?? 'US',
          )?.number,
    internationalCountryCallingCode:
      accountSummary.profileSummary.mobilePhone && internationalCountryCallingCode
        ? internationalCountryCallingCode
        : accountSummary.profileSummary.country ?? 'US',
    countryCode: accountSummary.profileSummary.country ?? 'US',
    address: accountSummary.profileSummary.address ?? '',
    address2: accountSummary.profileSummary.address2 ?? '',
    city: accountSummary.profileSummary.city ?? '',
    stateCode: accountSummary.profileSummary.state ?? '',
    zipCode: accountSummary.profileSummary.zipCode ?? '',
    externalMemberKey: accountSummary.profileSummary.holderAccountExternalMemberKey ?? '',
    partnerLoyaltyId: accountSummary.profileSummary.holderAccountPartnerLoyaltyId ?? '',
    notes: accountSummary.profileSummary.holderAccountNotes ?? '',
    leadSource: accountSummary.profileSummary.holderAccountLeadSource ?? '',
  };

  const editCustomerMutation = useMutation<EditMemberResponse, ApiError, EditMemberRequest, unknown>(
    (request) => {
      return api.members.edit(request);
    },
    {
      onSuccess: (value: EditMemberResponse) => {
        addMessage({
          label: 'Customer record changed',
          severity: 'success',
        });
        if (value?.memberKey) {
          navigate(`/customers/${value.memberKey}`, { state: { refetch: true } });
        }
        handleClose();
      },
      onError: apiErrorHandler,
    },
  );

  const handleCustomerSubmit = (data: CustomerDetailsForm) => {
    const request: EditMemberRequest = {
      $Type: EditMemberRequest.$type,
      firstName: data.firstName || '',
      lastName: data.lastName,
      emailAddress: data.email || '',
      phoneNumber: data.phoneNumber,
      dateOfBirth: currentZonedDateToISODate(data.dateOfBirth),
      brandKey: activeBrand?.key ?? '',
      status: MemberStatus.Inactive,
      country: data.countryCode,
      city: data.city,
      zipCode: data.zipCode,
      state: data.stateCode,
      externalMemberKey: data.externalMemberKey,
      leadSource: data.leadSource,
      address: data.address,
      address2: data.address2,
      notes: data.notes,
      memberKey: accountSummary.memberKey,
      salutation: data.salutation,
      profileId: accountSummary.profileSummary.id,
      partnerLoyaltyId: data.partnerLoyaltyId,
    };

    editCustomerMutation.mutate(request);
  };

  const handleCancel = (event?: object, reason?: 'backdropClick' | 'escapeKeyDown'): void => {
    if (reason === 'backdropClick' && editCustomerMutation.isLoading) return;
    if (event) setIsConfirmationOpen(true);
    else handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleCancel} maxWidth="md">
      <DialogTitle variant="h6" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Box>Edit Customer Information</Box>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleCancel}
          aria-label="close"
          size="small"
          disableRipple
          data-testid={`${testId}CloseButton`}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <EditCustomerForm
        country={accountSummary.profileSummary.country}
        defaultValues={defaultValues}
        isLoading={editCustomerMutation.isLoading}
        handleCustomerSubmit={handleCustomerSubmit}
        handleCancel={handleCancel}
        key={accountSummary.profileSummary.id}
        testId={testId}
      />

      <ConfirmationDialog
        confirmButton="Discard"
        abortButton="Cancel"
        onClose={(result: boolean) => {
          setIsConfirmationOpen(false);
          if (result) {
            handleClose();
          }
        }}
        open={isConfirmationOpen}
        title="Discard the changes?"
        question="You are about to leave the form and loose the changes."
        testId={`${testId}DiscardChangesDialog`}
      />
    </Dialog>
  );
};
