import { useDrag } from 'react-dnd';

import { style } from '@mui/system';

import TreeItem from './TreeItem';

export const DraggableTreeItem = (props: any) => {
  const name = 'droppableObjName';
  const { isDraggable, itemData, ...treeItemProps } = props;

  const [{ opacity }, drag] = useDrag(
    () => ({
      type: 'DraggableTreeItem',
      item: { item: props.itemData },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [name],
  );

  return <TreeItem {...treeItemProps} ref={isDraggable ? drag : null} style={{ ...style, opacity }} />;
};
