import { useEffect, useState } from 'react';
import { useIsMutating, useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Alert, Divider, Paper, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import { Promotion } from '@one/api-models/lib/Campaign/Promotion/Promotion';
import { PromotionAward } from '@one/api-models/lib/Campaign/Promotion/PromotionAward';
import { PromotionQualifier } from '@one/api-models/lib/Campaign/Promotion/PromotionQualifier';
import { QualifierType } from '@one/api-models/lib/Campaign/Promotion/QualifierType';
import { AddAwardRequest } from '@one/api-models/lib/Campaign/Promotion/Request/AddAwardRequest';
import { AddQualifierRequest } from '@one/api-models/lib/Campaign/Promotion/Request/AddQualifierRequest';
import { RemoveAwardRequest } from '@one/api-models/lib/Campaign/Promotion/Request/RemoveAwardRequest';
import { RemoveQualifierRequest } from '@one/api-models/lib/Campaign/Promotion/Request/RemoveQualifierRequest';
import { UpdateRequest } from '@one/api-models/lib/Campaign/Promotion/Request/UpdateRequest';
import { AddAwardResponse } from '@one/api-models/lib/Campaign/Promotion/Response/AddAwardResponse';
import { AddQualifierResponse } from '@one/api-models/lib/Campaign/Promotion/Response/AddQualifierResponse';
import { RemoveAwardResponse } from '@one/api-models/lib/Campaign/Promotion/Response/RemoveAwardResponse';
import { RemoveQualifierResponse } from '@one/api-models/lib/Campaign/Promotion/Response/RemoveQualifierResponse';
import { RetrieveResponse } from '@one/api-models/lib/Campaign/Promotion/Response/RetrieveResponse';
import { UpdateResponse } from '@one/api-models/lib/Campaign/Promotion/Response/UpdateResponse';
import { FiatCurrencyDefinition } from '@one/api-models/lib/FiatCurrencyDefinition';

import { selectActiveBrand } from 'store/slices/applicationDataSlice';

import { ApiError } from 'apiAccess/api-client';
import { PageHeader } from 'common/layout/components/PageHeader';
import { LoadingScreen } from 'common/LoadingScreen';
import { a11yProps, TabPanel } from 'common/tabs/TabPanel';
import { setBreadcrumbsInStorage } from 'config/sessionStorage';
import { useApiHelpers } from 'hooks/useApiHelpers';
import { useToastMessage } from 'hooks/useToastMessage';
import { RouteKey } from 'models/RouteKey';

import { PromotionAudienceView } from './components/promotionTabs/PromotionAudienceView';
import { PromotionAwardsView } from './components/promotionTabs/PromotionAwardsView';
import { PromotionPromoCodesView } from './components/promotionTabs/PromotionPromoCodesView';
import { PromotionQualifiersView } from './components/promotionTabs/PromotionQualifiersView';
import { PromotionSummaryView } from './components/promotionTabs/PromotionSummaryView';

export const PromotionDetailView = () => {
  const { api } = useApiHelpers();
  const { apiErrorHandler, showMessage } = useToastMessage();
  const [selectedTab, setSelectedTab] = useState(0);
  const { id: promotionId } = useParams<{ id: string }>() as { id: string };
  const isLoading = useIsMutating({ mutationKey: 'retrievePromotion' }) > 0;
  const [promotion, setPromotion] = useState<Promotion | undefined>(undefined);
  const [audience, setAudience] = useState<PromotionQualifier[]>([]);
  const [qualifiers, setQualifiers] = useState<PromotionQualifier[]>([]);
  const [availableLanguageCodes, setAvailableLanguageCodes] = useState<string[]>([]);
  const [availableCurrencies, setAvailableCurrencies] = useState<FiatCurrencyDefinition[]>([]);
  const activeBrand = useSelector(selectActiveBrand);
  const brandKey = activeBrand?.key || '';
  const testIdPrefix = 'PromotionDetail';

  useEffect(() => {
    retrievePromotion.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotionId]);

  const retrievePromotion = useMutation<RetrieveResponse, ApiError>(
    () =>
      api.promotions.retrieve({
        id: parseInt(promotionId),
        brandKey,
      }),
    {
      mutationKey: 'retrievePromotion',
      onSuccess: (value: RetrieveResponse) => {
        setPromotion(value.promotion);
        setAvailableCurrencies(value.availableCurrencies);
        setAvailableLanguageCodes(value.availableLanguageCodes);

        setBreadcrumbsInStorage(RouteKey.PromotionDetails, {
          id: value.promotion?.id,
          label: value.promotion?.promotionSummary.name,
        });
        const audienceQualifiers = [QualifierType.CustomerGroup, QualifierType.LeadSource, QualifierType.Referral];
        setAudience(
          value.promotion?.qualifiers.filter((item) => audienceQualifiers.includes(item.qualifierType)) || [],
        );
        setQualifiers(
          value.promotion?.qualifiers.filter((item) => !audienceQualifiers.includes(item.qualifierType)) || [],
        );
      },
      onError: apiErrorHandler,
    },
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleSavePromotion = async (data: Promotion) => {
    await updatePromotionMutation.mutateAsync({
      promotion: data,
      brandKey,
    });
  };

  const updatePromotionMutation = useMutation<UpdateResponse, ApiError, UpdateRequest, unknown>(
    (request) => api.promotions.update(request),
    {
      mutationKey: 'updatePromotionMutation',
      onSuccess: (value: UpdateResponse) => {
        retrievePromotion.mutate();
        showMessage('Promotion updated successfully.', 'success');
      },
      onError: apiErrorHandler,
    },
  );

  const removeQualifierMutation = useMutation<RemoveQualifierResponse, ApiError, RemoveQualifierRequest, unknown>(
    (request) => api.promotions.removeQualifier(request),
    {
      mutationKey: 'removeQualifierMutation',
      onSuccess: () => {
        showMessage('Successfully removed.', 'success');
        retrievePromotion.mutate();
      },
      onError: apiErrorHandler,
    },
  );

  const handleRemoveQualifier = async (qualifierId: number) => {
    await removeQualifierMutation.mutateAsync({
      promotionQualifierIds: [qualifierId],
      brandKey,
    });
  };

  const addQualifierMutation = useMutation<AddQualifierResponse, ApiError, AddQualifierRequest, unknown>(
    (request) => api.promotions.addQualifier(request),
    {
      mutationKey: 'addQualifierMutation',
      onSuccess: (value: AddQualifierResponse) => {
        showMessage('Successfully added.', 'success');
        retrievePromotion.mutate();
      },
      onError: apiErrorHandler,
    },
  );

  const handleAddQualifier = async (item: PromotionQualifier) => {
    await addQualifierMutation.mutateAsync({
      promotionQualifiers: [item],
      brandKey,
    });
  };

  const removeAwardMutation = useMutation<RemoveAwardResponse, ApiError, RemoveAwardRequest, unknown>(
    (request) => api.promotions.removeAward(request),
    {
      mutationKey: 'removeAwardMutation',
      onSuccess: () => {
        showMessage('Award successfully removed.', 'success');
        retrievePromotion.mutate();
      },
      onError: apiErrorHandler,
    },
  );

  const handleRemoveAward = async (awardId: number) => {
    await removeAwardMutation.mutateAsync({
      promotionAwardIds: [awardId],
      brandKey,
    });
  };

  const addAwardMutation = useMutation<AddAwardResponse, ApiError, AddAwardRequest, unknown>(
    (request) => api.promotions.addAward(request),
    {
      mutationKey: 'addQualifierMutation',
      onSuccess: (value: AddAwardResponse) => {
        showMessage('Award successfully added.', 'success');
        retrievePromotion.mutate();
      },
      onError: apiErrorHandler,
    },
  );

  const handleAddAward = async (item: PromotionAward) => {
    await addAwardMutation.mutateAsync({
      promotionAwards: [item],
      brandKey,
    });
  };

  return (
    <Box>
      <LoadingScreen open={isLoading} message={'Loading...'} />
      <LoadingScreen
        open={
          updatePromotionMutation.isLoading ||
          addAwardMutation.isLoading ||
          removeAwardMutation.isLoading ||
          addQualifierMutation.isLoading ||
          removeQualifierMutation.isLoading
        }
        message={'Saving your changes...'}
      />
      {!isLoading && (
        <>
          <PageHeader
            backButtonUrl="/marketing/promotions"
            title={promotion?.promotionSummary.name}
            testId={testIdPrefix}
          />
          <Paper sx={{ p: 3, pt: 0 }}>
            {promotion ? (
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={selectedTab} onChange={handleTabChange} aria-label="promotion tabs">
                    <Tab
                      label="Summary"
                      {...a11yProps(0)}
                      sx={{ textTransform: 'none', fontSize: '16px' }}
                      data-testid={`${testIdPrefix}SummaryTab`}
                    />
                    <Tab
                      label="Promo Codes"
                      {...a11yProps(1)}
                      sx={{ textTransform: 'none', fontSize: '16px' }}
                      data-testid={`${testIdPrefix}PromoCodes`}
                    />
                  </Tabs>
                </Box>

                <TabPanel value={selectedTab} index={0}>
                  <PromotionSummaryView
                    handleSavePromotion={handleSavePromotion}
                    promotion={promotion}
                    availableCurrencies={availableCurrencies}
                    availableLanguageCodes={availableLanguageCodes}
                    testId={`${testIdPrefix}Summary`}
                  />
                  <Divider sx={{ pt: 3 }} />
                  <PromotionAudienceView
                    paddingTop={3}
                    promotion={promotion}
                    qualifiers={audience}
                    handleRemoveQualifier={handleRemoveQualifier}
                    handleAddQualifier={handleAddQualifier}
                    testId={`${testIdPrefix}Audience`}
                  />
                  <PromotionQualifiersView
                    paddingTop={5}
                    promotion={promotion}
                    qualifiers={qualifiers}
                    handleRemoveQualifier={handleRemoveQualifier}
                    handleAddQualifier={handleAddQualifier}
                    testId={`${testIdPrefix}Qualifiers`}
                  />
                  <PromotionAwardsView
                    paddingTop={5}
                    promotion={promotion}
                    handleRemoveAward={handleRemoveAward}
                    handleAddAward={handleAddAward}
                    testId={`${testIdPrefix}Awards`}
                  />
                </TabPanel>

                <TabPanel value={selectedTab} index={1}>
                  <PromotionPromoCodesView
                    promotion={promotion}
                    handleRemoveQualifier={handleRemoveQualifier}
                    handleAddQualifier={handleAddQualifier}
                    testId={`${testIdPrefix}PromoCodes`}
                  />
                </TabPanel>
              </Box>
            ) : (
              <Alert severity="warning" variant="outlined">
                No promotion available.
              </Alert>
            )}
          </Paper>
        </>
      )}
    </Box>
  );
};
