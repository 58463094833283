import { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Alert, Box, FormHelperText, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';

import { SectionTitle } from 'common/SectionTitle';
import { mapCardIcon } from 'core/payment/utils/utils';

import { StripeInput } from './StripeInput';

import 'paymentfont/css/paymentfont.min.css';

export interface InternalValidationErrors {
  cardNumberError?: string;
  cardExpiryError?: string;
  cardCvcError?: string;
}

export interface StripeFormParams {
  error?: string;
  resetError: () => void;
  errors?: InternalValidationErrors;
  title?: string;
  testId: string;
}

export const StripeForm = ({ error, resetError, errors, title = 'Payment', testId }: StripeFormParams) => {
  const [cardNumberError, setCardNumberError] = useState<string | null>(null);
  const [cardExpiryError, setCardExpiryError] = useState<string | null>(null);
  const [cardCvcError, setCardCvcError] = useState<string | null>(null);
  const [cardIcon, setCardIcon] = useState('pf pf-credit-card');

  useEffect(() => {
    setCardNumberError(errors?.cardNumberError || null);
    setCardExpiryError(errors?.cardExpiryError || null);
    setCardCvcError(errors?.cardCvcError || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <Box sx={{ pt: title ? 2 : 0 }}>
      {title && <SectionTitle title={title} />}
      {error && (
        <Alert
          severity="error"
          sx={{ mb: 2 }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                resetError();
              }}
              data-testid={`${testId}CloseButton`}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {error}
        </Alert>
      )}
      <Grid container spacing={2} rowSpacing={2} display="flex" flexDirection="row" justifyContent="space-between">
        <Grid item xs={12}>
          <Grid item xs={12}>
            <TextField
              name="card"
              label="Card Number"
              error={cardNumberError !== null}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardNumberElement,
                  onChange: (event: any) => {
                    setCardIcon(mapCardIcon(event.brand));
                    setCardNumberError(event && event.error ? event.error.message : null);
                  },
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <i className={cardIcon} />
                  </InputAdornment>
                ),
              }}
              size="small"
              fullWidth
              inputProps={{
                'data-testid': `${testId}CardNumberInput`,
              }}
            />
          </Grid>
          {cardNumberError && (
            <Grid item xs={12}>
              <FormHelperText error={cardNumberError !== null}>{cardNumberError}</FormHelperText>
            </Grid>
          )}
        </Grid>
        <Grid item xs={6}>
          <Grid item xs={7}>
            <TextField
              label="Expiration date"
              error={cardExpiryError !== null}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardExpiryElement,
                  onChange: (event: any) => {
                    setCardExpiryError(event && event.error ? event.error.message : null);
                  },
                },
              }}
              size="small"
              fullWidth
              inputProps={{
                'data-testid': `${testId}ExpirationDateInput`,
              }}
            />
          </Grid>
          {cardExpiryError && (
            <Grid item xs={12}>
              <FormHelperText error={cardExpiryError !== null}>{cardExpiryError}</FormHelperText>
            </Grid>
          )}
        </Grid>
        <Grid item container xs={6}>
          <Grid item xs={7} />
          <Grid item xs={5}>
            <TextField
              label="CVC"
              error={cardCvcError !== null}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardCvcElement,
                  onChange: (event: any) => {
                    setCardCvcError(event && event.error ? event.error.message : null);
                  },
                },
              }}
              size="small"
              fullWidth
              inputProps={{
                'data-testid': `${testId}CVCInput`,
              }}
            />
          </Grid>
          {cardCvcError && (
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
              <FormHelperText error={cardCvcError !== null}>{cardCvcError}</FormHelperText>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
