import { ReactComponent as MasterCardLogo } from 'assets/icons/mastercardLogo.svg';
import { ReactComponent as VisaLogo } from 'assets/icons/visaLogo.svg';

export const getCardLogo = (cardBrand?: string) => {
  switch (cardBrand) {
    case 'Visa':
      return <VisaLogo height={'18px'} width={'40px'} />;
    case 'Mastercard':
      return <MasterCardLogo height={'18px'} width={'33px'} />;
    default:
      return <></>;
  }
};
