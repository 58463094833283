import { useMutation } from 'react-query';

import { Box } from '@mui/material';
import { AddFile } from '@one/api-models/lib/Admin/Files/AddFile';
import { AddMasterProductRequest } from '@one/api-models/lib/Admin/Files/Product/Travel/Accommodation/AddMasterProductRequest';
import { StoreRequestBase } from '@one/api-models/lib/Admin/Files/StoreRequestBase';
import { StoreResponseBase } from '@one/api-models/lib/Admin/Files/StoreResponseBase';

import { ApiError } from 'apiAccess/api-client';
import { FileDropZone } from 'common/fileManager/FileDropZone';
import { useApiHelpers } from 'hooks/useApiHelpers';
import { useToastMessage } from 'hooks/useToastMessage';

interface Props {
  acceptedFiles: string;
  onSuccess?: (response: StoreResponseBase) => void;
  successMessage?: string;
  testId: string;
}

export const FileManager: React.FC<Props> = ({ acceptedFiles, onSuccess, successMessage, testId }: Props) => {
  const { addApiError, showMessage } = useToastMessage();
  const { api } = useApiHelpers();

  const storeRecordMutation = useMutation<
    StoreResponseBase,
    ApiError,
    {
      storeRequest: StoreRequestBase;
    },
    unknown
  >(
    async ({ storeRequest }: { storeRequest: StoreRequestBase }) => {
      return await api.products.filesStore(storeRequest);
    },
    {
      onSuccess: (value, variables) => {
        if (value) {
          showActionSuccessMessage();
          if (onSuccess) onSuccess(value);
        }
      },
      onError: (error: ApiError) => {
        addApiError(error);
      },
    },
  );

  const showActionSuccessMessage = () => {
    if (successMessage) showMessage(successMessage, 'success');
  };

  return (
    <Box sx={{ width: '100%' }}>
      {
        <FileDropZone<AddMasterProductRequest>
          acceptedFiles={acceptedFiles}
          storeRecordMutation={storeRecordMutation}
          storeRequestBase={{ $Type: AddMasterProductRequest.$type, files: [] as AddFile[] } as AddMasterProductRequest}
          buttonText="Process file"
          helperText='* by pressing the "PROCESS FILE" button, you are going to create a new product.'
          testId={`${testId}FileDropZone`}
        />
      }
    </Box>
  );
};

FileManager.defaultProps = {
  successMessage: 'File Saved',
};
