import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Box, IconButton } from '@mui/material';
import { TranslationItem } from '@one/api-models/lib/Admin/Common/TranslationItem';
import { UpdateDetails } from '@one/api-models/lib/Admin/Tags/Edit/UpdateDetails';
import { Details as TagDetails } from '@one/api-models/lib/Admin/Tags/View/Details';

import { selectSettings } from 'store/slices/tagsDataSlice';

import { DetailsForm, FormData } from './DetailsForm';
import { TreeEditAction } from './TreeEditAction';

export interface TagBasic {
  key: string;
  name: string;
  enabled: boolean;
}

interface Props {
  basicData: TagBasic;
  details: TagDetails;
  disableEdit?: boolean;
  onSave: (tag: UpdateDetails) => void;
  setEditing: (editing: boolean) => void;
  allowDelete: boolean;
  allowAddChild: boolean;
  testId: string;
  onToolbarButtonAction: (action: TreeEditAction) => void;
}

export function DetailsAndEdit({
  basicData,
  details,
  disableEdit,
  onSave,
  setEditing,
  allowDelete,
  allowAddChild,
  testId,
  onToolbarButtonAction,
}: Props) {
  const settings = useSelector(selectSettings);
  const [formData, setFormData] = useState<FormData>();

  useEffect(() => {
    if (details) {
      const displayNames = details
        ? settings?.translationCultureCodes.map(
            (langCode) =>
              details?.displayName.find((x) => x.cultureCode === langCode) ??
              ({ cultureCode: langCode, value: '' } as TranslationItem),
          )
        : [];

      setFormData({
        ...basicData,
        ...details,
        rank: `${details.rank}`,
        displayName: displayNames || [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  const handleSave = (data: FormData) => {
    if (data) {
      const displayNames: TranslationItem[] =
        data.displayName
          ?.map((val, index) => {
            return {
              cultureCode: settings?.translationCultureCodes[index] || '',
              value: val.value,
            } as TranslationItem;
          })
          .filter((x) => x.value) || [];

      onSave({
        name: data.name,
        displayName: displayNames,
        rank: parseInt(data.rank, 10),
        enabled: data.enabled,
      } as UpdateDetails);
    }
  };

  return formData ? (
    <>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            mt: -5.5,
            right: 0,
          }}
        >
          <IconButton
            title="Add Child"
            sx={{ mr: 0, ml: 'auto' }}
            disabled={!allowAddChild}
            onClick={() => onToolbarButtonAction(TreeEditAction.AddChild)}
            data-testid={`${testId}AddChildButton`}
          >
            <AddIcon fontSize="small" />
          </IconButton>
          <IconButton
            title="Delete"
            disabled={!allowDelete}
            onClick={() => onToolbarButtonAction(TreeEditAction.Delete)}
            data-testid={`${testId}DeleteButton`}
          >
            <DeleteOutlinedIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <DetailsForm
        data={formData}
        disableEdit={disableEdit}
        tagTypes={formData.type ? [formData.type] : []}
        onSave={handleSave}
        setEditing={setEditing}
        testId={testId}
      />
    </>
  ) : null;
}
