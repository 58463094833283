import { useEffect, useState } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { useIsMutating, useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { allTimezones, useTimezoneSelect } from 'react-timezone-select';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Divider, Grid, Link, MenuItem, Typography } from '@mui/material';
import { ListingData } from '@one/api-models/lib/Admin/Common/ListingData';
import { CodeGenerationMethod } from '@one/api-models/lib/Campaign/PromoCodeDefinition/CodeGenerationMethod';
import { PromoCodeDefinition } from '@one/api-models/lib/Campaign/PromoCodeDefinition/PromoCodeDefinition';
import { PromotionStatus } from '@one/api-models/lib/Campaign/Promotion/PromotionStatus';
import { PromotionSummary } from '@one/api-models/lib/Campaign/Promotion/PromotionSummary';
import { ListRequest } from '@one/api-models/lib/Campaign/Promotion/Request/ListRequest';
import { ListResponse } from '@one/api-models/lib/Campaign/Promotion/Response/ListResponse';

import { selectActiveBrand } from 'store/slices/applicationDataSlice';

import { ApiError } from 'apiAccess/api-client';
import ControlledAutocomplete from 'common/inputs/defaultFields/ControlledAutocomplete';
import ControlledSelect from 'common/inputs/defaultFields/ControlledSelect';
import ControlledTextField from 'common/inputs/defaultFields/ControlledTextField';
import { useApiHelpers } from 'hooks/useApiHelpers';
import { useFormat } from 'hooks/useFormat';
import { useToastMessage } from 'hooks/useToastMessage';

import { promoCodeDefinitionStatusOptions, promoCodeDefinitionTypeOptions } from '../utils/promoCodeHelpers';

export interface PromoCodeDefForm {
  promoCode: string;
  name: string;
  promotionId: string;
  description?: string | null;
  promoCodeDefinitionType: number;
  maxRedemptionsPerPromoCode: number;
  maxRedemptionsPerCustomer: number;
  status: number;
  validFromDate: Date | null | string;
  validToDate: Date | null | string;
  timeZone?: string | null;
}

interface PromoCodeDefinitionFormProps {
  promoCodeDefinitionDetails?: PromoCodeDefinition | null;
  handleSavePromoCodeDefinition: (data: PromoCodeDefinition) => void;
  hideSubmitButton?: boolean;
  promotionId?: number;
  testId: string;
}

export const PromoCodeDefinitionForm = ({
  handleSavePromoCodeDefinition,
  hideSubmitButton,
  promoCodeDefinitionDetails,
  promotionId,
  testId,
}: PromoCodeDefinitionFormProps) => {
  const defaultValues = () => ({
    promoCode: promoCodeDefinitionDetails?.promoCode,
    name: promoCodeDefinitionDetails?.name,
    promotionId: promoCodeDefinitionDetails?.promotionId
      ? `${promoCodeDefinitionDetails?.promotionId}`
      : promotionId
      ? `${promotionId}`
      : undefined,
    description: promoCodeDefinitionDetails?.description,
    promoCodeDefinitionType: promoCodeDefinitionDetails?.promoCodeDefinitionType,
    maxRedemptionsPerPromoCode: promoCodeDefinitionDetails?.maxRedemptionsPerPromoCode,
    maxRedemptionsPerCustomer: promoCodeDefinitionDetails?.maxRedemptionsPerCustomer,
    status: promoCodeDefinitionDetails?.status,
    validFromDate: formatDate(promoCodeDefinitionDetails?.validFromDate, false, "yyyy-MM-dd'T'HH:mm"),
    validToDate: formatDate(promoCodeDefinitionDetails?.validToDate, false, "yyyy-MM-dd'T'HH:mm"),
    timeZone: 'Etc/GMT',
  });

  const { api } = useApiHelpers();
  const { apiErrorHandler } = useToastMessage();
  const [promotions, setPromotions] = useState<PromotionSummary[]>([]);
  const activeBrand = useSelector(selectActiveBrand);
  const brandKey = activeBrand?.key || '';
  const { customZonedDateToISODate, formatDate } = useFormat();
  const listIsLoading = useIsMutating({ mutationKey: 'listPromotionsMutation' }) > 0;
  const labelStyle = 'original';
  const timezones = {
    ...allTimezones,
  };
  const { options, parseTimezone } = useTimezoneSelect({ labelStyle, timezones });

  const validationSchema: yup.SchemaOf<PromoCodeDefForm> = yup.object().shape(
    {
      name: yup
        .string()
        .trim()
        .required('Promo Code Display Name is required.')
        .max(128, 'Maximum 128 characters allowed.')
        .min(3, 'Minimum 3 characters required.'),
      promoCode: yup
        .string()
        .trim()
        .matches(/^\S+$/, {
          excludeEmptyString: true,
          message: 'White spaces not allowed',
        })
        .required('Promo Code is required.')
        .max(128, 'Maximum 128 characters allowed.')
        .min(3, 'Minimum 3 characters required.'),
      promotionId: yup.string().trim().required('Promotion is required.').nullable(),
      description: yup.string().trim().max(1024, 'Maximum 1024 characters allowed.').nullable(),
      timeZone: yup.string().trim().nullable().required('Timezone is required.'),
      promoCodeDefinitionType: yup
        .number()
        .transform((_, val) => (val !== '' ? Number(val) : undefined))
        .nullable()
        .required('Promo Code Type is required.'),
      maxRedemptionsPerPromoCode: yup
        .number()
        .transform((_, val) => (val !== '' ? Number(val) : undefined))
        .nullable()
        .required('Max Redemptions Per Promo Code is required.')
        .min(0, 'Minimum value is 0.')
        .test(
          'maxRedemptionsPerPromoCode must be divisible by maxRedemptionsPerCustomer.',
          'Max Redemptions Per Promo Code must be divisible by Max Redemptions Per Customer.',
          function (value, context) {
            const maxRedemptionsPerCustomer = context.parent.maxRedemptionsPerCustomer;
            const maxRedemptionsPerPromoCode = value;
            if (!maxRedemptionsPerCustomer || !maxRedemptionsPerPromoCode) {
              clearErrors('maxRedemptionsPerCustomer');
              return true;
            }
            const isDivisible = maxRedemptionsPerPromoCode % maxRedemptionsPerCustomer === 0;
            if (isDivisible) {
              clearErrors('maxRedemptionsPerCustomer');
              return true;
            } else {
              setError('maxRedemptionsPerCustomer', {
                type: 'manual',
                message: '',
              });
              return false;
            }
          },
        )
        .typeError('Invalid number'),
      maxRedemptionsPerCustomer: yup
        .number()
        .transform((_, val) => (val !== '' ? Number(val) : undefined))
        .nullable()
        .required('Max Redemptions Per Customer is required.')
        .min(0, 'Minimum value is 0.')
        .test(
          'maxRedemptionsPerPromoCode must be divisible by maxRedemptionsPerCustomer.',
          '',
          function (value, context) {
            const maxRedemptionsPerCustomer = value;
            const maxRedemptionsPerPromoCode = context.parent.maxRedemptionsPerPromoCode;
            if (!maxRedemptionsPerCustomer || !maxRedemptionsPerPromoCode) {
              clearErrors('maxRedemptionsPerPromoCode');
              return true;
            }
            const isDivisible = maxRedemptionsPerPromoCode % maxRedemptionsPerCustomer === 0;
            if (isDivisible) {
              clearErrors('maxRedemptionsPerPromoCode');
              return true;
            } else {
              setError('maxRedemptionsPerPromoCode', {
                type: 'manual',
                message: 'Max Redemptions Per Promo Code must be divisible by Max Redemptions Per Customer.',
              });
              return false;
            }
          },
        )
        .typeError('Invalid number.'),
      status: yup
        .number()
        .transform((_, val) => (val !== '' ? Number(val) : undefined))
        .nullable()
        .typeError('Invalid number.')
        .required('Status is required.'),
      validFromDate: yup
        .date()
        .nullable()
        .required('Valid From is required.')
        .when('validToDate', (validToDate: Date, schema) => {
          if (validToDate) {
            return schema.test('date-comparison', '', (validFromDate: Date) => {
              if (validFromDate && validFromDate > validToDate) {
                setError('validToDate', {
                  type: 'manual',
                  message: 'Valid Until date must be equal to or greater than Valid From date.',
                });
                return false;
              } else {
                clearErrors('validToDate');
                return true;
              }
            });
          }
          return schema;
        })
        .typeError('Invalid date.'),
      validToDate: yup
        .date()
        .nullable()
        .required('Valid Until is required.')
        .when('validFromDate', (validFromDate: Date, schema) => {
          if (validFromDate) {
            return schema.test(
              'date-comparison',
              'Valid Until date must be equal to or greater than Valid From date.',
              (validToDate: Date) => {
                if (validToDate && validFromDate > validToDate) {
                  setError('validFromDate', {
                    type: 'manual',
                    message: '',
                  });
                  return false;
                } else {
                  clearErrors('validFromDate');
                  return true;
                }
              },
            );
          }
          return schema;
        })
        .typeError('Invalid date.'),
    },
    [['validFromDate', 'validToDate']],
  );

  const { control, handleSubmit, formState, setError, clearErrors } = useForm<PromoCodeDefForm>({
    mode: 'onChange',
    defaultValues: defaultValues(),
    resolver: yupResolver(validationSchema),
  });
  const { errors } = formState;

  useEffect(() => {
    listPromotionsMutation.mutateAsync({
      brandKey,
      statuses: [PromotionStatus.Draft, PromotionStatus.Active, PromotionStatus.Running, PromotionStatus.Paused],
      listHandling: { take: 0, skip: 0, ascending: true },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandKey]);

  const handlePromoCodeDefinitionSubmit = (data: PromoCodeDefForm) => {
    const newPromoCodeDefinition: PromoCodeDefinition = {
      id: promoCodeDefinitionDetails ? promoCodeDefinitionDetails.id : 0,
      name: data.name,
      description: data?.description ?? '',
      promoCode: data.promoCode,
      promotionId: Number(data.promotionId),
      promoCodeDefinitionType: data.promoCodeDefinitionType,
      //TODO: This is no longer used in the UI for Create/Update Promo Code Definition, always false for now.
      useAutoGeneration: false,
      codeGenerationMethod: promoCodeDefinitionDetails
        ? promoCodeDefinitionDetails.codeGenerationMethod
        : CodeGenerationMethod.Manual,
      maxRedemptionsPerPromoCode: data.maxRedemptionsPerPromoCode,
      maxRedemptionsPerCustomer: data.maxRedemptionsPerCustomer,
      //TODO: This is not used in the UI for Create/Update Promo Code Definition, sending 0 for create
      issuedPromoCodesCount: promoCodeDefinitionDetails ? promoCodeDefinitionDetails.issuedPromoCodesCount : 0,
      //TODO: This is not used in the UI for Create/Update Promo Code Definition, sending 0 for create
      redeemedPromoCodesCount: promoCodeDefinitionDetails ? promoCodeDefinitionDetails.redeemedPromoCodesCount : 0,
      status: data.status,
      validFromDate: customZonedDateToISODate(data.validFromDate as Date, data.timeZone),
      validToDate: customZonedDateToISODate(data.validToDate as Date, data.timeZone),
      createdAt: promoCodeDefinitionDetails ? promoCodeDefinitionDetails.createdAt : new Date(),
      updatedAt: promoCodeDefinitionDetails ? promoCodeDefinitionDetails.updatedAt : new Date(),
      brandKey,
    };
    handleSavePromoCodeDefinition(newPromoCodeDefinition);
  };

  const listPromotionsMutation = useMutation<ListResponse, ApiError, ListRequest, unknown>(
    (request) => api.promotions.load(request),
    {
      mutationKey: 'listPromotionsMutation',
      onSuccess: (value: ListResponse) => {
        if (value) {
          setPromotions((value.promotionSummaries as ListingData<PromotionSummary>).items);
        }
      },
      onError: apiErrorHandler,
    },
  );

  const promotionOptions = promotions?.map((promotion) => ({ code: `${promotion.id}`, label: promotion.name }));

  return (
    <Box>
      <form
        id="add-promo-code-definition-form"
        onSubmit={handleSubmit(handlePromoCodeDefinitionSubmit)}
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Information</Typography>
            <Divider sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ControlledTextField
              control={control as unknown as Control<FieldValues, object>}
              name="promoCode"
              fullWidth
              label="Promo Code"
              placeholder="Promo Code"
              isAutoFocused
              error={errors.promoCode != null}
              helperText={errors.promoCode?.message}
              testId={`${testId}PromoCode`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ControlledTextField
              control={control as unknown as Control<FieldValues, object>}
              name="name"
              fullWidth
              label="Promo Code Display Name"
              placeholder="Promo Code Display Name"
              error={errors.name != null}
              helperText={errors.name?.message}
              testId={`${testId}PromoCodeDisplayName`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ControlledAutocomplete
              control={control as unknown as Control<FieldValues, object>}
              name="promotionId"
              label={listIsLoading ? 'Loading...' : 'Promotion'}
              size="small"
              options={promotionOptions}
              error={errors.promotionId != null}
              helperText={errors.promotionId?.message}
              testId={`${testId}PromotionSelect`}
              disabled={listIsLoading}
            />
            {promotions && promotions?.length === 0 && !listIsLoading && (
              <Link
                component={RouterLink}
                to={'/marketing/promotions/add'}
                data-testid={`${testId}CreatePromotionLink`}
              >
                Create Promotion
              </Link>
            )}
          </Grid>

          <Grid item xs={12}>
            <ControlledTextField
              control={control as unknown as Control<FieldValues, object>}
              name="description"
              fullWidth
              label="Description"
              placeholder="Description"
              multiline
              minRows="3"
              error={errors.description != null}
              helperText={errors.description?.message}
              testId={`${testId}Description`}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledSelect
              control={control as unknown as Control<FieldValues, object>}
              name="promoCodeDefinitionType"
              label="Type"
              fullWidth
              size="small"
              error={errors.promoCodeDefinitionType != null}
              helperText={errors.promoCodeDefinitionType?.message}
              testId={`${testId}Type`}
            >
              {promoCodeDefinitionTypeOptions.map((opt: any) => (
                <MenuItem key={opt.code} value={opt.code} data-testid={`${testId}TypeSelectItem`}>
                  {opt.label}
                </MenuItem>
              ))}
            </ControlledSelect>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" mt={3}>
              Redemptions
            </Typography>
            <Divider sx={{ mt: 1 }} />
          </Grid>

          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} sm={4}>
              <ControlledTextField
                control={control as unknown as Control<FieldValues, object>}
                name="maxRedemptionsPerPromoCode"
                fullWidth
                type="number"
                label="Max Redemptions Per Promo Code"
                placeholder="Redemptions Per Promo Code"
                error={errors.maxRedemptionsPerPromoCode != null}
                helperText={errors.maxRedemptionsPerPromoCode?.message}
                testId={`${testId}MaxRedemptionsPerPromoCode`}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} sm={4}>
              <ControlledTextField
                control={control as unknown as Control<FieldValues, object>}
                name="maxRedemptionsPerCustomer"
                fullWidth
                type="number"
                label="Max Redemptions Per Customer"
                placeholder="Redemptions Per Customer"
                error={errors.maxRedemptionsPerCustomer != null}
                helperText={errors.maxRedemptionsPerCustomer?.message}
                testId={`${testId}MaxRedemptionPerCustomer`}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" mt={3}>
              Scheduling
            </Typography>
            <Divider sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ControlledSelect
              control={control as unknown as Control<FieldValues, object>}
              name="status"
              label="Status"
              fullWidth
              size="small"
              error={errors.status != null}
              helperText={errors.status?.message}
              testId={`${testId}Status`}
            >
              {promoCodeDefinitionStatusOptions.map((opt: any) => (
                <MenuItem key={opt.code} value={opt.code}>
                  {opt.label}
                </MenuItem>
              ))}
            </ControlledSelect>
          </Grid>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <ControlledSelect
                control={control as unknown as Control<FieldValues, object>}
                name="timeZone"
                label="Timezone"
                fullWidth
                size="small"
                onChange={(e) => parseTimezone(e as any)}
                error={errors.timeZone != null}
                helperText={errors.timeZone?.message}
                testId={`${testId}Timezone`}
                selectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'center',
                    },
                    transformOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                    PaperProps: { sx: { maxHeight: 350 } },
                  },
                }}
              >
                {options.map((opt: any) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ControlledTextField
                control={control as unknown as Control<FieldValues, object>}
                type="datetime-local"
                name="validFromDate"
                label="Valid From"
                placeholder="Valid From"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors.validFromDate != null}
                helperText={errors.validFromDate?.message}
                testId={`${testId}ValidFrom`}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ControlledTextField
                control={control as unknown as Control<FieldValues, object>}
                type="datetime-local"
                name="validToDate"
                label="Valid Until"
                placeholder="Valid Until"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors.validToDate != null}
                helperText={errors.validToDate?.message}
                testId={`${testId}ValidUntil`}
              />
            </Grid>
          </Grid>
          {!hideSubmitButton && (
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <Button
                type="submit"
                size="medium"
                variant="contained"
                disabled={listIsLoading}
                data-testid={`${testId}SaveButton`}
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
    </Box>
  );
};
