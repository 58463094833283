import { BrowserRouter } from 'react-router-dom';
import { InitWrapper } from 'InitWrapper';

import { RenderRoutes } from 'router/Routes';

export default function AppAuthenticated() {
  return (
    <InitWrapper>
      <BrowserRouter>
        <RenderRoutes />
      </BrowserRouter>
    </InitWrapper>
  );
}
