import { Link } from '@mui/material';

interface Props {
  cruiseLineId: CruiseLine;
}

enum CruiseLine {
  Carnival = 1,
  Princess = 2,
  Cunard = 3,
  RoyalCaribbean = 4,
  Celebrity = 5,
  HollandAmerica = 6,
  Seabourn = 7,
  Msc = 8,
  Ncl = 9,
  Ama = 10,
  VirginVoyages = 12,
  Uniworld = 13,
  Azamara = 14,
}

const cruiseLineCancellationPolicies = {
  [CruiseLine.Carnival]: 'carnival-cruise-line-cancellation-policies',
  [CruiseLine.Princess]: 'princess-cancellation-policies',
  [CruiseLine.Cunard]: 'cunard-cruises-cancellation-policies',
  [CruiseLine.RoyalCaribbean]: 'royal-caribbean-cancellation-policies',
  [CruiseLine.Celebrity]: 'celebrity-cruises-cancellation-policies',
  [CruiseLine.HollandAmerica]: 'holland-america-cancellation-policies',
  [CruiseLine.Seabourn]: 'seabourn-cancellation-policies',
  [CruiseLine.Msc]: 'msc-cruises-cancellation-policies',
  [CruiseLine.Ncl]: 'norwegian-cruise-line-cancellation-policies',
  [CruiseLine.Ama]: 'ama-waterways-cruises-cancellation-policies',
  [CruiseLine.VirginVoyages]: 'virgin-voyages-cancellation-policies',
  [CruiseLine.Uniworld]: 'uniworld-cruises-cancellation-policies',
  [CruiseLine.Azamara]: 'azamara-cruises-cancellation-policies',
};

export const CancellationPolicy = ({ cruiseLineId }: Props) => {
  return (
    <Link
      sx={{ mb: 2 }}
      target="_blank"
      rel="noopener noreferrer"
      href={`https://onemembersclub.com/cruises/rules-and-restrictions/${cruiseLineCancellationPolicies[cruiseLineId]}`}
    >
      Cruise line's cancellation policy
    </Link>
  );
};
