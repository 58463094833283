import { Link as RouterLink } from 'react-router-dom';

import CampaignOutlined from '@mui/icons-material/CampaignOutlined';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import EmailOutlined from '@mui/icons-material/EmailOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import LoyaltyOutlinedIcon from '@mui/icons-material/LoyaltyOutlined';
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import { Box, Grid, Link } from '@mui/material';

import { PageHeader } from 'common/layout/components/PageHeader';
import { SectionTitle } from 'common/SectionTitle';

const marketingURLs = {
  promoCodes: [
    { url: '/marketing/promo-codes', label: 'List all', addSpacing: true },
    { url: '/marketing/promo-codes/add', label: 'Add Code' },
  ],
  privateSales: [
    { url: '/marketing/coming-soon', label: 'Events' },
    { url: '/marketing/coming-soon', label: ' Invitations Only' },
  ],
  communications: [
    { url: '/marketing/coming-soon', label: 'Email Templates' },
    { url: '/marketing/coming-soon', label: 'SMS' },
    { url: '/marketing/coming-soon', label: 'Email Campaigns' },
    { url: '/marketing/coming-soon', label: 'Email Reminders' },
  ],
  seo: [
    { url: '/marketing/coming-soon', label: 'URL Rewrites' },
    { url: '/marketing/coming-soon', label: 'Deeplinks' },
  ],
  merchandise: [
    { url: '/marketing/coming-soon', label: 'Recommendation Rules' },
    { url: '/marketing/coming-soon', label: 'AI / Machine Learning' },
  ],
  promotions: [
    { url: '/marketing/promotions', label: 'List all' },
    { url: '/marketing/promotions/add', label: 'Create Promotion' },
    { url: '/marketing/coming-soon', label: 'Rules' },
    { url: '/marketing/coming-soon', label: 'Rewards', addSpacing: true },
    { url: '/marketing/coming-soon', label: 'First time customer' },
    { url: '/marketing/coming-soon', label: 'Gift Cards' },
    { url: '/marketing/coming-soon', label: 'Referrals' },
    { url: '/marketing/coming-soon', label: 'Product Promotions' },
    { url: '/marketing/coming-soon', label: 'Order Promotions' },
    { url: '/marketing/coming-soon', label: 'Shipping Promotions' },
  ],
};
const testIdPrefix = 'MarketingDashboard';

export const MarketingDashboard = () => {
  return (
    <Box>
      <PageHeader title="Marketing" testId={testIdPrefix} />
      <Grid container rowGap={5}>
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <SectionTitle title="Promotions" icon={<CampaignOutlined fontSize="medium" sx={{ mr: 0.5 }} />} />
          <Box display="flex" flexDirection="column">
            {marketingURLs.promotions.map((item) => (
              <Link
                key={item.label}
                component={RouterLink}
                to={item.url}
                mt={item.addSpacing ? 2 : 0}
                underline="none"
                data-testid={`${testIdPrefix}PromotionsLink`}
              >
                {item.label}
              </Link>
            ))}
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={3} lg={2}>
          <SectionTitle title="Private Sales" icon={<LoyaltyOutlinedIcon fontSize="medium" sx={{ mr: 0.5 }} />} />
          <Box display="flex" flexDirection="column">
            {marketingURLs.privateSales.map((item) => (
              <Link
                key={item.label}
                component={RouterLink}
                to={item.url}
                underline="none"
                data-testid={`${testIdPrefix}PrivateSalesLink`}
              >
                {item.label}
              </Link>
            ))}
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={3} lg={2}>
          <SectionTitle title="Communications" icon={<EmailOutlined fontSize="medium" sx={{ mr: 0.5 }} />} />
          <Box display="flex" flexDirection="column">
            {marketingURLs.communications.map((item) => (
              <Link
                key={item.label}
                component={RouterLink}
                to={item.url}
                underline="none"
                data-testid={`${testIdPrefix}CommunicationsLink`}
              >
                {item.label}
              </Link>
            ))}
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={3} lg={2}>
          <SectionTitle title="SEO" icon={<ZoomInOutlinedIcon fontSize="medium" sx={{ mr: 0.5 }} />} />
          <Box display="flex" flexDirection="column">
            {marketingURLs.seo.map((item) => (
              <Link
                key={item.label}
                component={RouterLink}
                to={item.url}
                underline="none"
                data-testid={`${testIdPrefix}SEOLink`}
              >
                {item.label}
              </Link>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <SectionTitle title="Merchandise" icon={<FolderOutlinedIcon fontSize="medium" sx={{ mr: 0.5 }} />} />
          <Box display="flex" flexDirection="column">
            {marketingURLs.merchandise.map((item) => (
              <Link
                key={item.label}
                component={RouterLink}
                to={item.url}
                underline="none"
                data-testid={`${testIdPrefix}MerchandiseLink`}
              >
                {item.label}
              </Link>
            ))}
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={3} lg={2}>
          <SectionTitle title="Promo Codes" icon={<DiscountOutlinedIcon fontSize="medium" sx={{ mr: 0.5 }} />} />
          <Box display="flex" flexDirection="column">
            {marketingURLs.promoCodes.map((item) => (
              <Link
                key={item.label}
                component={RouterLink}
                to={item.url}
                underline="none"
                data-testid={`${testIdPrefix}PromoCodesLink`}
              >
                {item.label}
              </Link>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
