import { useEffect } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, Grid, InputAdornment } from '@mui/material';
import { SplitPaymentPlan } from '@one/api-models/lib/Admin/ProgramSales/Purchase/SplitPaymentPlan';
import { SplitPaymentSettings } from '@one/api-models/lib/Admin/ProgramSales/Purchase/SplitPaymentSettings';

import { selectSelectedPrograms, setPaymentPlan } from 'store/slices/salesOrderDataSlice';

import ControlledTextField from 'common/inputs/labeledFields/ControlledTextField';
import { mapCurrency } from 'core/payment/utils/utils';
import { useFormat } from 'hooks/useFormat';
import { Typography } from 'styled';

export type SplitPayment = {
  downPayment: number;
  minDownPayment: number;
  maxDownPayment: number;
};

interface SplitPaymentFormProps {
  splitPaymentSettings: SplitPaymentSettings;
  validatePaymentForm: any;
  testId: string;
}

const validationSchema: yup.SchemaOf<SplitPayment> = yup.object().shape(
  {
    downPayment: yup
      .number()
      .required('Down payment is required')
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable(true)
      .test({
        name: 'minDownPaymentMin',
        test: function (value: number | undefined | null) {
          const { minDownPayment, maxDownPayment } = this.parent;
          if (!!value && value < minDownPayment) {
            return this.createError({
              path: 'downPayment',
              message: 'Amount smaller than minimum allowed',
            });
          }
          if (!!value && value > maxDownPayment) {
            return this.createError({
              path: 'downPayment',
              message: 'Amount exceeds total value',
            });
          }
          return true;
        },
      }),
    minDownPayment: yup.number().required(),
    maxDownPayment: yup.number().required(),
  },
  [
    ['downPayment', 'minDownPayment'],
    ['downPayment', 'maxDownPayment'],
  ],
);

export const SplitPaymentForm = ({ splitPaymentSettings, validatePaymentForm, testId }: SplitPaymentFormProps) => {
  const dispatch = useDispatch();
  const { formatCurrency } = useFormat();

  const selectedPrograms = useSelector(selectSelectedPrograms);

  const defaultValues: SplitPayment = {
    downPayment: splitPaymentSettings?.minDownPaymentAmount?.amount,
    minDownPayment: splitPaymentSettings?.minDownPaymentAmount?.amount || 0,
    maxDownPayment: selectedPrograms[0]?.price?.amount || 0,
  };

  const {
    control,
    formState: { errors },
    getValues,
    trigger,
  } = useForm<SplitPayment>({ mode: 'onBlur', defaultValues, resolver: yupResolver(validationSchema) });

  const balanceAmount = selectedPrograms[0]?.price.amount - getValues().downPayment;

  useEffect(() => {
    validatePaymentForm.current = trigger;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedHandlePlanChanges = debounce(function () {
    const plan: SplitPaymentPlan = {
      $type: SplitPaymentPlan.$Type,
      paymentPlanConfigId: splitPaymentSettings?.id,
      downPaymentAmount: {
        currency: splitPaymentSettings?.minDownPaymentAmount.currency,
        amount: getValues().downPayment || 0,
        isEstimated: false,
      },
    };
    const paymentPlan = {
      paymentPlan: plan,
      totalDueToday: plan.downPaymentAmount,
    };
    dispatch(setPaymentPlan(paymentPlan));
  }, 300);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">Split Payment on Multiple Cards</Typography>
      </Grid>
      <Grid item xs={4} mt={2}>
        <Typography variant="body2">Down Payment</Typography>
        <Typography variant="caption">{`minimum ${formatCurrency(
          splitPaymentSettings?.minDownPaymentAmount?.amount,
          splitPaymentSettings?.minDownPaymentAmount?.currency,
        )}`}</Typography>
      </Grid>
      <Grid item xs={8}>
        <ControlledTextField
          control={control as unknown as Control<FieldValues, object>}
          name="downPayment"
          placeholder="0.00"
          type="number"
          sx={{ width: '200px' }}
          onChange={() => {
            debouncedHandlePlanChanges();
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {mapCurrency(splitPaymentSettings?.minDownPaymentAmount?.currency)}
              </InputAdornment>
            ),
            inputProps: {
              min: splitPaymentSettings?.minDownPaymentAmount?.amount,
              max: selectedPrograms[0]?.price.amount,
            },
          }}
          error={errors.downPayment?.message != null}
          helperText={errors.downPayment?.message}
          testId={`${testId}DownPayment`}
        />
      </Grid>

      <Grid item xs={4}>
        <Typography variant="body2">Balance</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="body1">
          {formatCurrency(
            balanceAmount >= 0 ? balanceAmount : selectedPrograms[0]?.price.amount,
            selectedPrograms[0]?.price.currency,
          )}
        </Typography>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Divider />
      </Grid>
    </>
  );
};
