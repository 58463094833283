import { useNavigate } from 'react-router-dom';

import { Box, Grid, Skeleton, Typography } from '@mui/material';
import {
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridToolbarQuickFilter,
  GridToolbarQuickFilterProps,
} from '@mui/x-data-grid-pro';
import { OrderSummary } from '@one/api-models/lib/Admin/Sales/OrderSummary';

import { orderStatusOptions } from 'common/constants/StatusOptions';
import { DataGrid } from 'common/dataGrid/DataGrid';
import { SectionTitle } from 'common/SectionTitle';
import { StatusChip } from 'common/StatusChip';
import { useFormat } from 'hooks/useFormat';
import { Tooltip } from 'styled/Tooltip';

interface Props {
  memberKey: string;
  isFetchingMemberLoadData?: boolean;
  orders: OrderSummary[] | undefined;
  ordersTotalCount: number;
  pageOffset: number;
  pageSize: number;
  testId: string;
  handlePageChange: (data: GridPaginationModel) => void;
  handleFilterChange: (filters: GridFilterModel) => void;
}

export const PurchasesSection = (props: Props) => {
  const {
    orders,
    ordersTotalCount,
    pageOffset,
    pageSize,
    isFetchingMemberLoadData,
    handlePageChange,
    handleFilterChange,
  } = props;
  const navigate = useNavigate();
  const { formatDate, formatCurrency: formatCurrencyFunc } = useFormat();
  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };

  const handleOrderClick = (orderNumber: string) => {
    navigate(`/sales/orders/${orderNumber}`, {
      state: { orderNumber },
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'totalPrice',
      headerName: 'Amount',
      headerAlign: 'right',
      align: 'right',
      minWidth: 84,
      flex: 1,
      sortable: true,
      valueGetter: (_, row) => row?.orderTotalPrice?.amount,
      renderCell: (params: any) => {
        const amount = formatCurrency(params?.row?.orderTotalPrice?.amount, params?.row?.orderTotalPrice?.currency);
        return (
          <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} title={amount || undefined}>
            {amount}
          </Box>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: true,
      minWidth: 126,
      flex: 1.5,
      renderCell: (params: any) =>
        params.row.orderStatus ? (
          <Tooltip
            arrow
            title={orderStatusOptions[params?.row?.orderStatus]?.name ?? ''}
            fontSize="0.0775"
            maxWidth="900px"
          >
            <Box width="100%">
              <StatusChip status={orderStatusOptions[params?.row?.orderStatus] || params?.row?.orderStatus} />
            </Box>
          </Tooltip>
        ) : (
          <StatusChip status={'-'} />
        ),
    },
    {
      field: 'product',
      headerName: 'Product',
      sortable: true,
      filterable: true,
      flex: 3,
      valueGetter: (_, row) => row?.orderItems[0]?.name,
      renderCell: (params: any) => {
        const item = params?.row?.orderItems[0];
        return (
          <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} title={item?.name}>
            {item?.name}
          </Box>
        );
      },
    },
    {
      field: 'orderDate',
      headerName: 'Date',
      sortable: true,
      minWidth: 105,
      flex: 2,
      renderCell: (params: any) => <>{formatDate(new Date(params?.row?.createdDate), true, 'MMM dd, yyyy')}</>,
    },
    {
      field: 'orderNumber',
      headerName: 'Order Number',
      flex: 4,
      minWidth: 210,
      sortable: true,
      filterable: true,
      renderCell: (params: any) => (
        <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} title={params?.row?.id}>
          {params?.row?.id}
        </Box>
      ),
    },
  ];
  const StyledGridToolbarQuickFilter = (props: GridToolbarQuickFilterProps) => (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'end',
        pb: 1,
      }}
    >
      <GridToolbarQuickFilter
        {...props}
        placeholder="Search by product or order number"
        sx={{ width: { xs: '100%', md: '50%' } }}
      />
    </Box>
  );

  const renderPurchasesList = () => {
    let renderItem = null;
    if (isFetchingMemberLoadData && !orders) {
      renderItem = <Skeleton variant="rectangular" width={300} height={25} />;
    } else if (orders && orders.length > 0) {
      renderItem = (
        <DataGrid
          rows={orders.map((item: OrderSummary) => ({ ...item, id: item.orderNumber }))}
          columns={columns}
          pagination
          useDefaultPagination
          onPaginationModelChange={handlePageChange}
          onFilterModelChange={handleFilterChange}
          loading={isFetchingMemberLoadData}
          paginationModel={{ page: pageOffset, pageSize }}
          initialState={{
            pagination: {
              paginationModel: {
                page: pageOffset,
                pageSize: pageSize,
              },
            },
          }}
          rowCount={ordersTotalCount ?? 0}
          paginationMode="server"
          filterMode="server"
          rowHeight={45}
          onRowClick={(param: any, _event: any) => handleOrderClick(param.row.orderNumber)}
          hideFooter={ordersTotalCount <= pageSize}
          sx={{
            width: { xs: 800, lg: '100%' },
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
          }}
          slots={{ toolbar: () => <StyledGridToolbarQuickFilter /> }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              placeholder: 'Search by order number',
            },
          }}
        />
      );
    } else {
      <Typography variant="body1">No purchases available for this customer</Typography>;
    }

    return renderItem;
  };

  return (
    <Box sx={{ mb: 6 }}>
      <SectionTitle title="Orders" />
      <Grid container sx={{ width: '100%', overflow: 'auto' }}>
        <Grid item xs={12}>
          {renderPurchasesList()}
        </Grid>
      </Grid>
    </Box>
  );
};
