import { Box } from '@mui/material';
import { PaymentPlanSummary } from '@one/api-models/lib/Admin/PaymentPlan/PaymentPlanSummary';
import { BillingDetails } from '@one/api-models/lib/BillingDetails';

import { SectionTitle } from 'common/SectionTitle';
import { PaymentPlansList } from 'modules/paymentPlans/PaymentPlansList';

interface PaymentPlansSectionProps {
  isFetchingPaymentPlanList: boolean;
  paymentPlans: PaymentPlanSummary[];
  handleUpdatePaymentPlanSuccess: () => void;
  memberId: string;
  defaultBillingDetails: BillingDetails;
  testId: string;
}

export const PaymentPlansSection = (props: PaymentPlansSectionProps) => {
  const {
    isFetchingPaymentPlanList,
    paymentPlans,
    handleUpdatePaymentPlanSuccess,
    memberId,
    defaultBillingDetails,
    testId,
  } = props;
  return (
    <Box sx={{ mb: 6 }}>
      <SectionTitle title="Payment Plans" />
      <PaymentPlansList
        defaultBillingDetails={defaultBillingDetails}
        isFetchingPaymentPlanList={isFetchingPaymentPlanList}
        paymentPlans={paymentPlans}
        handleUpdatePaymentPlanSuccess={handleUpdatePaymentPlanSuccess}
        memberId={memberId}
        testId={testId}
      />
    </Box>
  );
};
