import { useAppHelpers } from 'hooks/useAppHelpers';

import { AgentDashboard } from '../agency/components/AgentDashboard';

import { AdminDashboard } from './AdminDashboard';

export const Dashboard = () => {
  const { hasPermission } = useAppHelpers();

  return <>{hasPermission(['Agency.Dashboard:View']) ? <AgentDashboard /> : <AdminDashboard />}</>;
};
