import { Control, FieldValues, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material';
import { Promotion } from '@one/api-models/lib/Campaign/Promotion/Promotion';
import { PromotionQualifier } from '@one/api-models/lib/Campaign/Promotion/PromotionQualifier';

import ControlledSelect from 'common/inputs/defaultFields/ControlledSelect';
import ControlledTextField from 'common/inputs/defaultFields/ControlledTextField';

import { AudienceQualifiersTypes, QualifierTypeValues } from './promotionHelpers';

interface PromotionQualifierForm {
  type: number;
  qualifierId?: string;
  minQuantity?: number | null;
  minAmount?: number | null;
}

interface AddPromotionQualifierDialogProps {
  promotion: Promotion;
  open: boolean;
  testId: string;
  handleClose: () => void;
  handleAddQualifier: (item: PromotionQualifier) => Promise<void>;
}

const validationSchema: yup.SchemaOf<PromotionQualifierForm> = yup.object().shape({
  qualifierId: yup.string().trim().max(128, 'Maximum 128 characters allowed.'),
  type: yup
    .number()
    .transform((_, val) => (val !== '' ? Number(val) : undefined))
    .required('Qualifier type is required.')
    .typeError('Invalid number'),
  minQuantity: yup
    .number()
    .transform((_, val) => (val !== '' ? Number(val) : undefined))
    .nullable()
    .min(0, 'Minimum value is 0.')
    .typeError('Invalid number'),
  minAmount: yup
    .number()
    .transform((_, val) => (val !== '' ? Number(val) : undefined))
    .nullable()
    .min(0, 'Minimum value is 0.')
    .typeError('Invalid number'),
});

export const AddPromotionQualifierDialog = ({
  open,
  promotion,
  testId,
  handleClose,
  handleAddQualifier,
}: AddPromotionQualifierDialogProps) => {
  const { control, handleSubmit, formState } = useForm<PromotionQualifierForm>({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });
  const { errors } = formState;

  const qualifierTypes = QualifierTypeValues.filter((item) => !AudienceQualifiersTypes.includes(item.id));

  const handleAddQualifierToPromotion = async (data: PromotionQualifierForm) => {
    await handleAddQualifier({
      id: 0,
      promotionId: promotion.id,
      qualifierType: data.type,
      qualifierId: data?.qualifierId ?? '',
      qualifierName: '',
      minAmount: data.minAmount ?? 0,
      minQuantity: data.minQuantity ?? 0,
    });
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <form onSubmit={handleSubmit(handleAddQualifierToPromotion)} autoComplete="off">
        <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          {promotion.promotionSummary.name}
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            size="small"
            disableRipple
            data-testid={`${testId}DialogCloseButton`}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ my: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={2} display="flex" alignItems="center">
              <Typography variant="subtitle1" fontWeight="bold">
                Qualifier:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <ControlledSelect
                control={control as unknown as Control<FieldValues, object>}
                name="type"
                label="Qualifier Type"
                fullWidth
                size="small"
                error={errors.type != null}
                helperText={errors.type?.message}
                testId={`${testId}QualifierType`}
              >
                {qualifierTypes.map((opt: any) => (
                  <MenuItem key={opt.id} value={opt.id} data-testid={`${testId}QualifierTypeSelectItem`}>
                    {opt.label}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </Grid>
            <Grid item xs={12} sm={5}>
              <ControlledTextField
                control={control as unknown as Control<FieldValues, object>}
                name="qualifierId"
                fullWidth
                label="Qualifier ID"
                placeholder="Qualifier ID"
                error={errors.qualifierId != null}
                helperText={errors.qualifierId?.message}
                testId={`${testId}QualifierId`}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
          </Grid>
          <Grid container item xs={12} spacing={2} mb={2}>
            <Grid item xs={6} sm={4} display="flex" alignItems="center">
              <Typography variant="subtitle1" fontWeight="bold">
                Minimum Quantity:
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <ControlledTextField
                control={control as unknown as Control<FieldValues, object>}
                name="minQuantity"
                fullWidth
                label="Qty"
                placeholder="Qty"
                type="number"
                error={errors.minQuantity != null}
                helperText={errors.minQuantity?.message}
                testId={`${testId}MinQuantity`}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={6} sm={4} display="flex" alignItems="center">
              <Typography variant="subtitle1" fontWeight="bold">
                Minimum Amount:
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <ControlledTextField
                control={control as unknown as Control<FieldValues, object>}
                name="minAmount"
                fullWidth
                label="Amount"
                placeholder="Amount"
                type="number"
                error={errors.minAmount != null}
                helperText={errors.minAmount?.message}
                testId={`${testId}MinAmount`}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            size="small"
            disableRipple
            data-testid={`${testId}DialogCancelButton`}
          >
            Cancel
          </Button>
          <Button type="submit" variant="contained" size="small" data-testid={`${testId}DialogAddButton`}>
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
