import { Divider, Grid, Link, Stack, Typography } from '@mui/material';
import { ExperienceOrderItemSummary } from '@one/api-models/lib/Admin/Sales/Travel/Experience/ExperienceOrderItemSummary';

import { DataDisplay } from 'common/DataDisplay';
import { SectionTitle } from 'common/SectionTitle';
import { useFormat } from 'hooks/useFormat';

import { ExperienceCancellationInfo } from './ExperienceCancellationInfo';
import { ExperiencePriceDetails } from './ExperiencePriceDetails';
import { TravelersDetails } from './TravelersDetails';

interface ExperienceOrderItemDetailsProps {
  orderItem: ExperienceOrderItemSummary;
}

export const ExperienceOrderItemDetails = ({ orderItem }: ExperienceOrderItemDetailsProps) => {
  const { formatDate, customZonedDateToISODate } = useFormat();

  const utcDate = customZonedDateToISODate(orderItem.travelDate, 'UTC')?.toISOString();
  const utcTime = customZonedDateToISODate(orderItem.startTime, 'UTC')?.toISOString();

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Stack spacing={2}>
          <DataDisplay label="Name" value={orderItem.productSummary.name} />
          <DataDisplay label="Product Option" value={orderItem.productSummary.productOption.title} />
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack spacing={2}>
          <DataDisplay label="Travel Date" value={formatDate(utcDate, false, 'MM/dd/yyyy')} />
          {orderItem.startTime && <DataDisplay label="Start Time" value={formatDate(utcTime, false, 'hh:mm a')} />}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <TravelersDetails orderItem={orderItem} />
      </Grid>
      {orderItem.purchaseSummary && (
        <Grid item xs={12}>
          <ExperiencePriceDetails
            purchaseSummary={orderItem.purchaseSummary}
            productOption={orderItem.productSummary.productOption}
          />
        </Grid>
      )}
      {(!!orderItem.voucherInformation?.url || orderItem.productSummary.productOption.languageGuides.length > 0) && (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      )}
      {orderItem.voucherInformation?.url && (
        <Grid item xs={6}>
          <SectionTitle title="Voucher Information" variant="h6" />
          <Link
            href={orderItem.voucherInformation?.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
          >
            View Voucher
          </Link>
        </Grid>
      )}
      {orderItem.languageGuide && (
        <Grid item xs={6}>
          <SectionTitle title="Language Guide" variant="h6" />
          <Typography variant="body1" sx={(theme) => ({ color: theme.customPalette.text.primary })}>
            {`${orderItem.languageGuide.languageCode.toUpperCase()} - ${orderItem.languageGuide.type}`}
          </Typography>
        </Grid>
      )}
      {orderItem.productSummary.cancellationPolicy && (
        <Grid item xs={12}>
          <Divider sx={{ mb: 2 }} />
          <SectionTitle title={'Cancellation Policy'} variant="h6" />
          <ExperienceCancellationInfo cancellationPolicy={orderItem.productSummary.cancellationPolicy} />
        </Grid>
      )}
    </Grid>
  );
};
