import { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';
import { format, startOfDay } from 'date-fns'; // eslint-disable-line import/no-duplicates
import { enUS } from 'date-fns/locale'; // eslint-disable-line import/no-duplicates
import { format as formatTz, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import { RecurringInterval } from '@one/api-models/lib/Admin/PaymentPlan/RecurringInterval';

export const useFormat = () => {
  const currencyCode = 'USD';
  const cultureCode = 'en-US';

  function formatNumber(
    value: number | null | undefined,
    maximumFractionDigits: number | undefined = undefined,
    minimumFractionDigits: number | undefined = undefined,
    isCurrency?: boolean,
    currency: string | undefined = undefined,
  ): string | null {
    if (value == null) return null;

    return new Intl.NumberFormat(cultureCode, {
      style: isCurrency ? 'currency' : undefined,
      currency: !isCurrency ? undefined : currency || currencyCode,
      maximumFractionDigits,
      minimumFractionDigits,
    }).format(value);
  }

  function formatCurrency(
    amount: number | null | undefined,
    currency: string | undefined = undefined,
    fractionDigits: number | undefined = undefined,
  ): string | null {
    if (amount == null) return null;
    const value = fractionDigits != null && fractionDigits > 0 ? amount : Math.ceil(amount);
    const formattedValue = formatNumber(value, fractionDigits || 0, fractionDigits || 0, true, currency);
    return formattedValue;
  }

  function formatCurrencyWithCode(
    amount: number | null | undefined,
    currency: string | undefined = undefined,
    fractionDigits: number | undefined = undefined,
  ): string | null {
    const formattedValue = formatCurrency(amount, currency, fractionDigits);
    return `${formattedValue} ${currency || currencyCode}`;
  }

  const formatCurrencySymbol = (currency: string): string =>
    formatNumber(1, 0, 0, true, currency)!.replace(/\d/g, '').trim();

  function formatDate(date: Date | string | null | undefined, isLocalTime: boolean, template?: string): string | null {
    if (date == null) return null;
    let dateFormat: Date | undefined = undefined;

    if (typeof date === 'string' && !isNaN(Date.parse(date))) {
      dateFormat = new Date(date);
    }

    if (date instanceof Date) {
      dateFormat = date;
    }

    if (!dateFormat) return null;

    const convertedDate = isLocalTime ? dateFormat : utcToZonedTime(dateFormat, 'UTC');

    if (template != null)
      return format(convertedDate, template, {
        locale: enUS,
      });

    return convertedDate.toLocaleDateString(cultureCode);
  }

  function customZonedDateToISODate(
    date: Date | null | undefined,
    timezone: string | null | undefined,
  ): Date | undefined {
    if (date == null) return undefined;
    let dateFormat: Date | undefined = undefined;

    if (typeof date === 'string' && !isNaN(Date.parse(date))) {
      dateFormat = new Date(date);
    }

    if (date instanceof Date) {
      dateFormat = date;
    }

    if (!dateFormat) return undefined;

    const convertedDate = new Date(
      formatTz(dateFormat, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX", { timeZone: timezone ?? 'UTC' }),
    );

    return convertedDate;
  }

  function currentZonedDateToISODate(date: Date | null | undefined): Date | undefined {
    if (!date) {
      return undefined;
    }
    return zonedTimeToUtc(startOfDay(date), 'UTC');
  }

  function isoDateToCurrentZonedDate(date: Date | null | undefined) {
    if (!date) {
      return undefined;
    }
    return startOfDay(utcToZonedTime(date, 'UTC'));
  }

  function formatTime(date: Date | null | undefined): string | null {
    if (date == null || !((date as any) instanceof Date)) return null;

    return date.toLocaleTimeString(cultureCode);
  }

  function dateIsValid(dateStr: string | undefined) {
    const regex = /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/;
    if (dateStr?.match(regex) === null) {
      return false;
    }
    return true;
  }

  function getNationalPhone(value: string) {
    return formatPhoneNumber(value).replace(/[^0-9]/g, '');
  }

  function formatRecurringInterval(
    value: number | null | undefined,
    intervalType: RecurringInterval | undefined = undefined,
    showValue?: boolean,
  ): string | null {
    if (value == null || value === undefined) return '';

    const intervalLabel = intervalType != null ? RecurringInterval[intervalType].toLowerCase() : '';
    return showValue ? `${value} ${intervalLabel}${value > 1 ? 's' : ''}` : `${intervalLabel}${value > 1 ? 's' : ''}`;
  }

  function formatRecurringInvoice(
    value: string | null | undefined,
    intervalType: RecurringInterval | undefined = undefined,
  ): string | null {
    if (value == null || value === undefined) return '';

    const intervalLabel = intervalType != null ? RecurringInterval[intervalType].toLowerCase() : '';
    return `${value} / ${intervalLabel}`;
  }

  function getRecurringIntervalTypeLabel(intervalType: RecurringInterval | undefined = undefined): string | null {
    if (intervalType === undefined) return null;

    let intervalTypeLabel;
    switch (intervalType) {
      case RecurringInterval.Day:
        intervalTypeLabel = 'Daily';
        break;
      case RecurringInterval.Week:
        intervalTypeLabel = 'Weekly';
        break;
      case RecurringInterval.Month:
        intervalTypeLabel = 'Monthly';
        break;
      case RecurringInterval.Year:
        intervalTypeLabel = 'Yearly';
        break;
      default:
        intervalTypeLabel = 'Monthly';
        break;
    }

    return intervalTypeLabel;
  }

  function getStartDate(date: Date | undefined): Date | undefined {
    if (!date) {
      return undefined;
    }

    const startOfDay = new Date(date.setHours(0, 0, 0, 0));
    const formattedDate = new Date(startOfDay.getTime());

    return formattedDate;
  }

  function getEndDate(date: Date | undefined): Date | undefined {
    if (!date) {
      return undefined;
    }

    const endOfDay = new Date(date.setHours(23, 59, 59, 999));
    const formattedDate = new Date(endOfDay.getTime());

    return formattedDate;
  }

  return {
    formatNumber,
    formatCurrency,
    formatCurrencyWithCode,
    formatCurrencySymbol,
    formatDate,
    customZonedDateToISODate,
    currentZonedDateToISODate,
    dateIsValid,
    formatTime,
    isoDateToCurrentZonedDate,
    formatPhoneNumber: formatPhoneNumberIntl,
    getNationalPhone,
    formatRecurringInterval,
    formatRecurringInvoice,
    getRecurringIntervalTypeLabel,
    getStartDate,
    getEndDate,
  };
};
