import { ProductContent as MerchandiseProductContent } from '@one/api-models/lib/Admin/Products/Retail/Merchandise/ProductContent';
import { ProductContentCriteria as MerchandiseProductContentCriteria } from '@one/api-models/lib/Admin/Products/Retail/Merchandise/ProductContentCriteria';
import { UpdateProduct as UpdateMerchandise } from '@one/api-models/lib/Admin/Products/Retail/Merchandise/UpdateProduct';
import { ProductContent as WineProductContent } from '@one/api-models/lib/Admin/Products/Retail/Wine/ProductContent';
import { ProductContentCriteria as WineProductContentCriteria } from '@one/api-models/lib/Admin/Products/Retail/Wine/ProductContentCriteria';
import { UpdateProduct as UpdateWine } from '@one/api-models/lib/Admin/Products/Retail/Wine/UpdateProduct';
import { DestinationContent as DestinationProductContent } from '@one/api-models/lib/Admin/Products/Travel/Cruise/DestinationContent';
import { DestinationContentCriteria } from '@one/api-models/lib/Admin/Products/Travel/Cruise/DestinationContentCriteria';
import { SailingContent as SailingProductContent } from '@one/api-models/lib/Admin/Products/Travel/Cruise/SailingContent';
import { SailingContentCriteria } from '@one/api-models/lib/Admin/Products/Travel/Cruise/SailingContentCriteria';
import { ShipContent as ShipProductContent } from '@one/api-models/lib/Admin/Products/Travel/Cruise/ShipContent';
import { ShipContentCriteria } from '@one/api-models/lib/Admin/Products/Travel/Cruise/ShipContentCriteria';
import { UpdateDestination } from '@one/api-models/lib/Admin/Products/Travel/Cruise/UpdateDestination';
import { UpdateSailing } from '@one/api-models/lib/Admin/Products/Travel/Cruise/UpdateSailing';
import { UpdateShip } from '@one/api-models/lib/Admin/Products/Travel/Cruise/UpdateShip';

import { DestinationDetailsTab } from './DetailsTab/DestinationDetailsTab';
import { DetailsTabProps } from './DetailsTab/DetailsBase';
import { MerchandiseDetailsTab } from './DetailsTab/MerchandiseDetailsTab';
import { SailingDetailsTab } from './DetailsTab/SailingDetailsTab';
import { ShipDetailsTab } from './DetailsTab/ShipDetailsTab';
import { WineDetailsTab } from './DetailsTab/WineDetailsTab';

export enum ProductType {
  Accommodation = 'Accommodation',
  Merchandise = 'Merchandise',
  Wine = 'Wine',
  Itinerary = 'Itinerary',
  Port = 'Port',
  Sailing = 'Sailing',
  Ship = 'Ship',
}

export interface ProductTypeConfig {
  code: ProductType;
  label?: string;
  detailCriteriaApiType: string;
  detailsResponseApiType: string;
  detailsUpdateApiType: string;
  detailsComponent: (props: DetailsTabProps) => React.ReactNode;
  disableEdit?: boolean;
}

export const productTypes: ProductTypeConfig[] = [
  // {
  //   code: ProductType.Accommodation,
  //   detailCriteriaApiType: AccommodationProductContentCriteria.$Type,
  //   detailedResponseApiType: AccommodationProductContent.$type,
  //    disableEdit: true
  // },
  {
    code: ProductType.Merchandise,
    detailCriteriaApiType: MerchandiseProductContentCriteria.$Type,
    detailsResponseApiType: MerchandiseProductContent.$type,
    detailsUpdateApiType: UpdateMerchandise.$Type,
    detailsComponent: MerchandiseDetailsTab,
    disableEdit: true,
  },
  {
    code: ProductType.Wine,
    detailCriteriaApiType: WineProductContentCriteria.$Type,
    detailsResponseApiType: WineProductContent.$type,
    detailsUpdateApiType: UpdateWine.$Type,
    detailsComponent: WineDetailsTab,
    disableEdit: true,
  },
  {
    code: ProductType.Itinerary,
    label: 'Cruise - Itinerary',
    detailCriteriaApiType: DestinationContentCriteria.$Type,
    detailsResponseApiType: DestinationProductContent.$type,
    detailsUpdateApiType: UpdateDestination.$Type,
    detailsComponent: DestinationDetailsTab,
  },
  {
    code: ProductType.Port,
    label: 'Cruise - Port',
    detailCriteriaApiType: DestinationContentCriteria.$Type,
    detailsResponseApiType: DestinationProductContent.$type,
    detailsUpdateApiType: UpdateDestination.$Type,
    detailsComponent: DestinationDetailsTab,
  },
  {
    code: ProductType.Sailing,
    label: 'Cruise - Sailings',
    detailCriteriaApiType: SailingContentCriteria.$Type,
    detailsResponseApiType: SailingProductContent.$type,
    detailsUpdateApiType: UpdateSailing.$Type,
    detailsComponent: SailingDetailsTab,
  },
  {
    code: ProductType.Ship,
    label: 'Cruise - Ships',
    detailCriteriaApiType: ShipContentCriteria.$Type,
    detailsResponseApiType: ShipProductContent.$type,
    detailsUpdateApiType: UpdateShip.$Type,
    detailsComponent: ShipDetailsTab,
  },
];

export const getProductType = (
  code: string,
  defaultValue: ProductType | undefined = undefined,
): ProductType | undefined => {
  return Object.values(ProductType).indexOf(code as ProductType) >= 0 ? (code as unknown as ProductType) : defaultValue;
};

export const getProductTypeConfig = (
  value: string | undefined,
  defaultValue: ProductTypeConfig | undefined = undefined,
): ProductTypeConfig | undefined => {
  if (!value) return defaultValue;
  const lcValue = `${value}`.toLowerCase();
  const result = productTypes.find(
    (x) => `${x.code}`.toLowerCase() === lcValue || `${x.detailsResponseApiType}`.toLowerCase() === lcValue,
  );
  return result ?? defaultValue;
};
