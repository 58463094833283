import { RefObject, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@mui/material';
import { BillingDetails } from '@one/api-models/lib/BillingDetails';
import { PaymentMethod } from '@one/api-models/lib/Sales/Payment/PaymentMethod/PaymentMethod';
import { CybersourceGatewayConfiguration } from '@one/api-models/lib/Sales/Payment/Transaction/CybersourceGatewayConfiguration';

import { selectCreatePaymentMethodError, selectIsLoadingCreatePaymentMethod } from 'store/slices/paymentDataSlice';

import { BillingDetailsComponentRef, BillingDetailsForm } from 'common/billingDetails/BillingDetailsForm';
import { PaymentBackdropLoading } from 'common/payment/components/PaymentBackdropLoading';
import { PaymentSkeleton } from 'common/payment/components/PaymentSkeleton';
import { useCybersourceCreatePayment } from 'hooks/useCybersourceCreatePayment';
import { AddPaymentMethodComponentRef } from 'models/AddPaymentMethodComponentRef';
import { CybersourceForm, CybersourceFormRefHandle } from 'modules/sales/components/payment/CybersourceForm';
import { SectionTitle } from 'styled/SectionTitle';

interface AddPaymentMethodComponentProps {
  testId: string;
  memberId: string;
  paymentGatewayConfiguration: CybersourceGatewayConfiguration;
  paymentGatewayIdentifier: string;
  isLoadingConfiguration: boolean;
  billingDetails: BillingDetails;
  componentRef: RefObject<AddPaymentMethodComponentRef>;
  refetchConfiguration: () => void;
  callback: (paymentMethod: PaymentMethod) => void;
  resetCreatePaymentMethodError: () => void;
  setAddPaymentModalOpen: (value: boolean) => void;
}

export const AddPaymentMethodCybersourceComponent = (props: AddPaymentMethodComponentProps) => {
  const {
    testId,
    memberId,
    paymentGatewayConfiguration,
    billingDetails,
    isLoadingConfiguration,
    componentRef,
    refetchConfiguration,
    resetCreatePaymentMethodError,
  } = props;

  const { performCreatePaymentMethod } = useCybersourceCreatePayment();
  const createPaymentMethodError = useSelector(selectCreatePaymentMethodError);
  const isLoadingCreatePaymentMethod = useSelector(selectIsLoadingCreatePaymentMethod);

  const [isSubmittingPayment, setIsSubmittingPayment] = useState(false);
  const cybersourceFormRef = useRef<CybersourceFormRefHandle>(null);
  const billingDetailsFormRef = useRef<BillingDetailsComponentRef>(null);

  useEffect(() => {
    if (!isLoadingCreatePaymentMethod) {
      setIsSubmittingPayment(false);
    }
  }, [isLoadingCreatePaymentMethod]);

  const createPaymentMethodFormSubmitHandler = async (token: string, paymentMethodExpiration: Date) => {
    const billingData = await billingDetailsFormRef?.current?.getBillingDetails();
    if (billingData) {
      handleSubmitAddPaymentMethod(token, paymentMethodExpiration, false, memberId, billingData);
    }
  };

  const handleSubmitAddPaymentMethod = async (
    token: string,
    paymentMethodExpiration: Date,
    isDefault: boolean,
    memberKey: string | undefined,
    billingDetails: BillingDetails,
  ) => {
    await performCreatePaymentMethod(
      token,
      memberKey,
      paymentMethodExpiration,
      isDefault,
      paymentGatewayConfiguration.gatewayIdentifier,
      billingDetails,
      props.callback,
      props.setAddPaymentModalOpen,
    );
  };

  const updatePaymentData = () => {
    setIsSubmittingPayment(false);
    resetCreatePaymentMethodError();
    refetchConfiguration();
  };

  const submitForm = async () => {
    if (cybersourceFormRef.current) {
      const isValidBillingDetails = await billingDetailsFormRef.current?.validate();
      const isValidCybersource = await cybersourceFormRef.current?.validate();
      if (!isValidBillingDetails || !isValidCybersource) return;
      cybersourceFormRef.current.handleFormSubmit();
    }
  };

  useImperativeHandle(componentRef, () => ({
    submitAddPaymentMethod: submitForm,
    validate: async () => {
      const isValidBillingDetails = await billingDetailsFormRef.current?.validate();
      const isValidCybersource = await cybersourceFormRef.current?.validate();
      if (!isValidBillingDetails || !isValidCybersource) return false;
      return true;
    },
  }));

  return (
    <Box sx={{ position: 'relative', mt: 2 }}>
      {!isLoadingCreatePaymentMethod || isSubmittingPayment ? (
        <>
          <CybersourceForm
            ref={cybersourceFormRef}
            paymentGatewayConfiguration={paymentGatewayConfiguration}
            updatePaymentData={updatePaymentData}
            submitPayment={createPaymentMethodFormSubmitHandler}
            setIsSubmittingPayment={setIsSubmittingPayment}
            isSubmittingPayment={isSubmittingPayment}
            submitForm={submitForm}
            error={createPaymentMethodError}
            testId={testId}
          />
          <Box mt={3}>
            <SectionTitle>Billing to</SectionTitle>
            <BillingDetailsForm testId={testId} initialDetails={billingDetails} componentRef={billingDetailsFormRef} />
          </Box>
        </>
      ) : (
        <PaymentSkeleton />
      )}

      <PaymentBackdropLoading
        isCompleteWithNoPayment={false}
        isLoading={isLoadingCreatePaymentMethod}
        isLoadingConfiguration={isLoadingConfiguration}
        isSubmittingPayment={isSubmittingPayment}
      />
    </Box>
  );
};
