import { EarnAlternativeCurrency } from '@one/api-models/lib/Membership/Account/Earn/EarnAlternativeCurrency';
import { EarnDefinitionBase } from '@one/api-models/lib/Membership/Account/Earn/EarnDefinitionBase';

import { AlternativeCurrency } from 'common/alternativeCurrency/AlternativeCurrency';

type Props = {
  options?: EarnDefinitionBase[];
};

export const EarnOptionsDisplay = ({ options }: Props) => (
  <>
    {options?.map((option, index) => {
      const currency = option as EarnAlternativeCurrency;
      return (
        <AlternativeCurrency
          data={{
            alternativeCurrencyDefinition: currency.alternativeCurrencyDefinition,
            total: currency.alternativeCurrencyValue,
          }}
        />
      );
    })}
  </>
);
