import AddIcon from '@mui/icons-material/Add';
import { Box, Divider, Paper } from '@mui/material';

import { useAgentAccess } from 'hooks/useAgentAccess';
import { Button, Typography } from 'styled';

interface NotesProps {
  testId: string;
}
export const Notes = ({ testId }: NotesProps) => {
  const { agentAccessRequiredBrandConfiguration } = useAgentAccess();

  return (
    <>
      {agentAccessRequiredBrandConfiguration('restrictNotes') && (
        <Paper
          elevation={0}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mt: 3,
            p: 1,
            border: 1,
            borderColor: '#F5E6BE',
            backgroundColor: '#FDF9DD',
          }}
        >
          <Box display="flex" width="100%" justifyContent="space-between" alignItems="center" my={1}>
            <Typography variant="h5">Notes</Typography>
            <Button
              startIcon={<AddIcon />}
              variant="outlined"
              size="small"
              sx={{
                backgroundColor: '#fff',
                color: '#363A43',
              }}
              onClick={() => {}}
              disabled
              data-testid={`${testId}OpenAddNoteButton`}
            >
              Note
            </Button>
          </Box>
          <Box mt={1}>
            <Divider />
            <Box sx={{ py: 1, display: 'flex' }}>Notes section coming soon.</Box>
          </Box>
        </Paper>
      )}
    </>
  );
};
