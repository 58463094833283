import { useIsMutating, useMutation } from 'react-query';
import { useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { PromoCodeDefinition } from '@one/api-models/lib/Campaign/PromoCodeDefinition/PromoCodeDefinition';
import { UpdateRequest } from '@one/api-models/lib/Campaign/PromoCodeDefinition/Request/UpdateRequest';
import { UpdateResponse } from '@one/api-models/lib/Campaign/PromoCodeDefinition/Response/UpdateResponse';

import { selectActiveBrand } from 'store/slices/applicationDataSlice';

import { ApiError } from 'apiAccess/api-client';
import { LoadingScreen } from 'common/LoadingScreen';
import { useApiHelpers } from 'hooks/useApiHelpers';
import { useToastMessage } from 'hooks/useToastMessage';

import { PromoCodeDefinitionForm } from './PromoCodeDefinitionForm';

interface PromoCodeDefinitionDialogProps {
  open: boolean;
  onClose: (shouldRefresh: boolean) => void;
  testId: string;
  promoCodeDefinitionDetails?: PromoCodeDefinition | null;
}

export const PromoCodeDefinitionDialog = ({
  open,
  onClose,
  testId,
  promoCodeDefinitionDetails,
}: PromoCodeDefinitionDialogProps) => {
  const { api } = useApiHelpers();
  const { apiErrorHandler, showMessage } = useToastMessage();
  const updateIsLoading = useIsMutating({ mutationKey: 'updatePromoCodeDefMutation' }) > 0;
  const listIsLoading = useIsMutating({ mutationKey: 'listPromotionsMutation' }) > 0;
  const activeBrand = useSelector(selectActiveBrand);
  const brandKey = activeBrand?.key || '';

  const updatePromoCodeDefMutation = useMutation<UpdateResponse, ApiError, UpdateRequest, unknown>(
    (request) => api.promoCodeDefinition.update(request),
    {
      mutationKey: 'updatePromoCodeDefMutation',
      onSuccess: (value: UpdateResponse) => {
        if (value) {
          showMessage('Promo Code Definition updated successfully.', 'success');
          onClose(true);
        }
      },
      onError: apiErrorHandler,
    },
  );

  const handleUpdatePromoCodeDefSubmit = (data: PromoCodeDefinition) => {
    updatePromoCodeDefMutation.mutateAsync({
      brandKey,
      promoCodeDefinition: data,
    });
  };

  return (
    <Box>
      <LoadingScreen open={updateIsLoading} message="Saving your changes..." />
      <Dialog open={open} onClose={() => onClose(false)} fullWidth={true} maxWidth="md">
        <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          Edit Promo Code Definition
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => onClose(false)}
            aria-label="close"
            size="small"
            disableRipple
            data-testid={`${testId}CloseButton`}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <PromoCodeDefinitionForm
            handleSavePromoCodeDefinition={handleUpdatePromoCodeDefSubmit}
            promoCodeDefinitionDetails={promoCodeDefinitionDetails}
            hideSubmitButton
            testId={testId}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => onClose(false)}
            variant="outlined"
            size="small"
            disableRipple
            data-testid={`${testId}CancelButton`}
          >
            Cancel
          </Button>
          <Button
            form={'add-promo-code-definition-form'}
            type="submit"
            variant="contained"
            size="small"
            disabled={listIsLoading}
            data-testid={`${testId}SaveButton`}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
