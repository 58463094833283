import { endOfMonth, isAfter, parse } from 'date-fns';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import CurrencyEuroIcon from '@mui/icons-material/Euro';
import { BillingDetails } from '@one/api-models/lib/BillingDetails';

export const mapCurrency = (currency?: string) => {
  switch (currency) {
    case 'USD':
      return <AttachMoneyIcon sx={{ fontSize: '1rem' }} />;
    case 'GBP':
      return <CurrencyPoundIcon sx={{ fontSize: '1rem' }} />;
    case 'EUR':
      return <CurrencyEuroIcon sx={{ fontSize: '1rem' }} />;
    default:
      return undefined;
  }
};

export const isBillingDetailsRequiredByPaymentProviderValid = (billingDetails?: BillingDetails) => {
  return (
    !billingDetails ||
    (!!billingDetails &&
      (!billingDetails?.billingAddress?.countryCode ||
        !billingDetails?.billingAddress?.postalCode ||
        (['US', 'CA'].includes(billingDetails?.billingAddress?.countryCode ?? '') &&
          !billingDetails?.billingAddress?.stateCode) ||
        !billingDetails?.firstName ||
        !billingDetails?.lastName ||
        !billingDetails?.email))
  );
};

export const isExpirationInvalid = (expirationMonth: string, expirationYear: string) => {
  if (expirationMonth.length !== 2 || expirationYear.length !== 2) {
    return true;
  }

  const numericMonth = parseInt(expirationMonth, 10);
  const numericYear = parseInt(expirationYear, 10);

  const expirationDate = endOfMonth(parse(`20${numericYear}-${numericMonth}`, 'yyyy-MM', new Date()));
  const currentDate = new Date();

  const isValid = isAfter(expirationDate, currentDate);

  return !isValid;
};

export const mapCardIcon = (brand: string) => {
  switch (brand) {
    case 'visa':
      return 'pf pf-visa';
    case 'mastercard':
      return 'pf pf-mastercard';
    case 'amex':
      return 'pf pf-american-express';
    case 'discover':
      return 'pf pf-discover';
    case 'diners':
      return 'pf pf-diners';
    case 'jcb':
      return 'pf pf-jcb';

    default:
      return 'pf pf-credit-card';
  }
};
