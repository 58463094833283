import { useDispatch } from 'react-redux';

import { AccountInfo, InteractionRequiredAuthError, SilentRequest } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

import { setUsername } from 'store/slices/userDataSlice';
import { persistor } from 'store/store';

import appConfig from 'config/appConfig';
import { deleteSSOSessionFromStorage, deleteTokenFromStorage, getTokenFromStorage } from 'config/localStorage';
import { handleTravelAgentToken, isSSOSession, isTravelAgentPortal } from 'core/auth/auth';
import { loginRequest } from 'core/auth/msalConfig';

const refreshBufferInMS = 1000 * 60 * 5; // 5 minutes

export function useAuthWrapperHelpers() {
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const isAgentPortal = isTravelAgentPortal();
  const isCustomAuth = isSSOSession();

  async function requestMsalToken(): Promise<string | undefined> {
    const idTokenClaims: any = accounts && accounts[0] && accounts[0].idTokenClaims;
    let idToken;
    const refreshCustomToken = false;
    let refreshToken = false;

    dispatch(setUsername(accounts[0]?.name));

    if (idTokenClaims && idTokenClaims.exp) {
      const expCheck = new Date(idTokenClaims.exp * 1000 - refreshBufferInMS);
      if (expCheck < new Date()) {
        refreshToken = true;
      }
    }

    const request: SilentRequest = {
      ...loginRequest,
      account: accounts[0] as AccountInfo,
      forceRefresh: true,
    };

    try {
      const silentResponse = await instance.acquireTokenSilent(request);
      idToken = silentResponse.idToken;
      // Update token expiration time
      const updatedAccounts = instance.getAllAccounts();
      if (updatedAccounts.length > 0) {
        const updatedAccount = updatedAccounts.find((acc) => acc.username === accounts[0]?.username);
        if (updatedAccount) {
          idTokenClaims.exp = updatedAccount.idTokenClaims?.exp;
        }
      }
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(request);
        return;
      } else {
        console.error('Token acquisition failed', e);
        throw e;
      }
    }
    if (isAgentPortal && idToken) {
      return await handleTravelAgentToken(idToken, refreshCustomToken);
    } else {
      return idToken;
    }
  }

  async function logout() {
    if (!isCustomAuth) {
      instance.logout();
    } else {
      window.location.replace(appConfig.agentPortalSSORedirectUrl || '/');
    }

    await persistor.purge().then(() => {
      deleteTokenFromStorage();
      deleteSSOSessionFromStorage();
    });
  }

  async function requestToken(): Promise<string | undefined> {
    if (isCustomAuth) {
      return getTokenFromStorage();
    } else {
      return await requestMsalToken();
    }
  }

  return {
    requestToken,
    logout,
  };
}
