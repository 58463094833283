import { useIsMutating } from 'react-query';

import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { PromoCodeDefinition } from '@one/api-models/lib/Campaign/PromoCodeDefinition/PromoCodeDefinition';
import { Promotion } from '@one/api-models/lib/Campaign/Promotion/Promotion';

import { PromoCodeDefinitionForm } from 'modules/promoCodes/components/PromoCodeDefinitionForm';

interface AddPromotionPromoCodeDialogProps {
  promotion: Promotion;
  open: boolean;
  testId: string;
  handleClose: () => void;
  handleSavePromoCodeDefinition: (data: PromoCodeDefinition) => Promise<void>;
}

export const AddPromotionPromoCodeDialog = ({
  open,
  promotion,
  testId,
  handleClose,
  handleSavePromoCodeDefinition,
}: AddPromotionPromoCodeDialogProps) => {
  const listIsLoading = useIsMutating({ mutationKey: 'listPromotionsMutation' }) > 0;
  const handelAddPromoCodeDefinition = async (data: PromoCodeDefinition) => {
    await handleSavePromoCodeDefinition(data);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
      <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        Add Promo Code
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          size="small"
          disableRipple
          data-testid={`${testId}DialogCloseButton`}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ my: 1 }}>
        <PromoCodeDefinitionForm
          promotionId={promotion.id}
          hideSubmitButton
          handleSavePromoCodeDefinition={handelAddPromoCodeDefinition}
          testId={testId}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="outlined"
          size="small"
          disableRipple
          data-testid={`${testId}CancelButton`}
        >
          Cancel
        </Button>
        <Button
          form={'add-promo-code-definition-form'}
          type="submit"
          variant="contained"
          size="small"
          disabled={listIsLoading}
          data-testid={`${testId}AddButton`}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
