import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { Backdrop, CircularProgress } from '@mui/material';
import { ApplicationContext } from '@one/api-models/lib/Admin/Common/ApplicationContext';
import { Partner } from '@one/api-models/lib/Admin/Common/Partner';

import {
  selectPartners,
  setActiveBrand,
  setActivePartner,
  setAgencies,
  setApplicationDataIsLoading,
  setPartners,
  setPermissions,
} from 'store/slices/applicationDataSlice';
import { setCAStatesList, setCountryList, setUSStatesList } from 'store/slices/customersDataSlice';

import { ApiError } from 'apiAccess/api-client';
import { getTokenFromStorage } from 'config/localStorage';
import { getActiveBrandFromStorage, getActivePartnerFromStorage } from 'config/sessionStorage';
import { isSSOSession } from 'core/auth/auth';
import { useApiHelpers } from 'hooks/useApiHelpers';
import { useToastMessage } from 'hooks/useToastMessage';

type Props = { children: React.ReactNode };

export const InitWrapper = ({ children }: Props) => {
  const { addApiError } = useToastMessage();
  const { api } = useApiHelpers();
  const isCustomLogin = isSSOSession();
  const token = getTokenFromStorage();

  const dispatch = useDispatch();
  const partners = useSelector(selectPartners);
  const [isLoadingApplicationContext, setIsLoadingApplicationContext] = useState(true);

  const applicationContextMutation = useMutation<ApplicationContext, ApiError>(
    'appContext',
    () => {
      return api.admin.contextLoad({});
    },
    {
      onSuccess: (value: ApplicationContext) => {
        const partners = [...value.partners];
        partners
          .sort((a, b) => a.name.localeCompare(b.name))
          .forEach((item) => {
            item.brands.sort((a, b) => {
              const getLocale = (str: string) => (str.includes('-') ? str.split('-')[1].trim() : null);

              const localeA = getLocale(a.name);
              const localeB = getLocale(b.name);

              if (!localeA && !localeB) {
                return a.name.localeCompare(b.name);
              } else if (!localeA) {
                // A has no locale, B does: A comes first
                return -1;
              } else if (!localeB) {
                // B has no locale, A does: B comes first
                return 1;
              } else {
                // if both have locale, compare them
                return localeA.localeCompare(localeB);
              }
            });
          });
        dispatch(setPartners(partners));

        const agencies = [...value.agencies];
        dispatch(setAgencies(agencies));

        const permissionsList = value?.roles.map((r) => r.permissions).flat() || [];
        dispatch(setPermissions(permissionsList));
      },
      onError: (error) => addApiError(error),
      onSettled: () => setIsLoadingApplicationContext(false),
    },
  );

  const getCountryList = async () => {
    const response = await fetch('/countrylist.json');
    const data = await response.json();

    dispatch(setCountryList(data?.map((d: any) => ({ code: d.code, label: d.name }))));
  };

  const getStatesList = async () => {
    const responseUSStateCodes = await fetch('/usStateCodes.json');
    const dataUSStateCodes = await responseUSStateCodes.json();

    const responseCAStateCodes = await fetch('/caStateCodes.json');
    const dataCAStateCodes = await responseCAStateCodes.json();

    dispatch(setUSStatesList(dataUSStateCodes?.map((d: any) => ({ code: d.code, label: d.name }))));
    dispatch(setCAStatesList(dataCAStateCodes?.map((d: any) => ({ code: d.code, label: d.name }))));
  };

  useEffect(() => {
    getCountryList();
    getStatesList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isCustomLogin || (isCustomLogin && !!token)) {
      applicationContextMutation.mutateAsync();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomLogin, token]);

  useEffect(() => {
    if (partners && partners.length > 0) {
      const storagePartnerKey = getActivePartnerFromStorage();
      const storageBrandKey = getActiveBrandFromStorage();

      let defaultPartner: Partner | undefined;
      if (storagePartnerKey) {
        const foundPartner = partners.find((el) => el.key === storagePartnerKey);
        if (foundPartner) {
          defaultPartner = foundPartner;
        }
      }

      if (defaultPartner) {
        dispatch(setActivePartner(defaultPartner));
        const foundBrand = defaultPartner.brands.find((brand) => brand.key === storageBrandKey);
        dispatch(setActiveBrand(foundBrand || defaultPartner.brands[0]));
      } else {
        dispatch(setActivePartner(partners[0] || undefined));
        dispatch(setActiveBrand(partners[0].brands[0] || undefined));
      }
    }
  }, [dispatch, partners]);

  useEffect(() => {
    dispatch(setApplicationDataIsLoading(isLoadingApplicationContext));
  }, [dispatch, isLoadingApplicationContext]);

  if (isLoadingApplicationContext) {
    return (
      <Backdrop open sx={{ backgroundColor: '#fff' }}>
        <CircularProgress color="primary" data-testid="LoadingSpinner" />
      </Backdrop>
    );
  }

  return <>{children}</>;
};
