import { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import { Box, Button, Divider, Skeleton, Typography } from '@mui/material';
import { AccountSummary } from '@one/api-models/lib/Membership/Account/AccountSummary';

import { AddNoteDialog } from './AddNoteDialog';

interface NotesProps {
  isLoading: boolean;
  onViewAllNotesClick: () => void; //Will be used when proper solution is in place
  accountSummary?: AccountSummary; //Will be removed in V2
  testId: string;
}
export const NotesSection = ({ isLoading, accountSummary, testId }: NotesProps) => {
  const [isAddNoteOpen, setIsAddNoteOpen] = useState<boolean>(false);
  const memberNotes = accountSummary?.profileSummary?.holderAccountNotes;

  return (
    <Box width="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center" my={1}>
        <Typography variant="h5">Notes</Typography>
        <Button
          startIcon={<AddIcon />}
          variant="outlined"
          size="small"
          sx={{
            color: '#363A43',
            border: '1px solid #363A43',
            backgroundColor: '#fff',
            '&:hover': {
              backgroundColor: '#fafafa',
            },
          }}
          disabled={isLoading}
          onClick={() => setIsAddNoteOpen(true)}
          data-testid={`${testId}OpenAddNoteButton`}
        >
          Note
        </Button>
      </Box>
      {(memberNotes || isLoading) && (
        <Box sx={{ pt: 1 }}>
          <Divider />
          <Box py={2}>
            {isLoading ? (
              <Skeleton variant="rectangular" width="100%" height={25} />
            ) : (
              <>
                <Box sx={{ py: 1, display: 'flex' }}>
                  <StickyNote2OutlinedIcon sx={{ color: '#89909E', fontSize: '1rem', mr: 1, mt: 0.5 }} />
                  <Typography
                    variant="body1"
                    sx={{
                      whiteSpace: 'pre-line',
                      wordBreak: 'break-word',
                      overflowWrap: 'break-word',
                    }}
                  >
                    {memberNotes}
                  </Typography>
                  {/*
                    TODO update styling
                    <Box display="flex" flexDirection="column" pb={1}>
                      <Typography variant="caption" lineHeight={1.2}>
                      date and time + author
                      </Typography>
                    </Box>
                  */}
                </Box>
                {/*
                  <Divider />
                  <Box display="flex" >
                    <Button onClick={onViewAllNotesClick} variant="text">
                      View all notes
                    </Button>
                  </Box> */}
              </>
            )}
          </Box>
        </Box>
      )}
      {isAddNoteOpen && (
        <AddNoteDialog
          open={isAddNoteOpen}
          handleClose={() => setIsAddNoteOpen(false)}
          accountSummary={accountSummary}
          testId={testId}
        />
      )}
    </Box>
  );
};
