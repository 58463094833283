import { lazy, useEffect, useState } from 'react';

import { InteractionStatus } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Backdrop, CircularProgress, Typography } from '@mui/material';

const AppAuthenticated = lazy(() => import('./AppAuthenticated'));
const AppUnauthenticated = lazy(() => import('./AppUnauthenticated'));

export function MsalAuthWrapper() {
  const { instance, inProgress } = useMsal();
  const [isRedirectHandled, setIsRedirectHandled] = useState(false);

  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isRedirectHandled) {
      instance
        .handleRedirectPromise()
        .then(() => {
          setIsRedirectHandled(true);
        })
        .catch((error) => {
          console.error('Redirect error:', error);
          setIsRedirectHandled(true);
        });
    }
  }, [instance, inProgress, isRedirectHandled]);

  if (inProgress === InteractionStatus.HandleRedirect || !isRedirectHandled) {
    return (
      <Backdrop open sx={{ backgroundColor: '#fff', flexDirection: 'column' }}>
        <CircularProgress color="primary" data-testid="LoadingSpinner" />
        <Typography
          mt={2}
          variant="h6"
          sx={{
            '&::after': {
              content: '"..."',
              position: 'absolute',
              ml: 0.5,
            },
          }}
        >
          Authorizing
        </Typography>
      </Backdrop>
    );
  }

  return (
    <>
      <AuthenticatedTemplate>
        <AppAuthenticated />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <AppUnauthenticated />
      </UnauthenticatedTemplate>
    </>
  );
}
