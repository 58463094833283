import { Dispatch, SetStateAction } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ContentCut from '@mui/icons-material/ContentCut';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Divider, ListItemIcon, Menu, MenuItem } from '@mui/material';
import Fade from '@mui/material/Fade';
import { TagEditOperation } from '@one/api-models/lib/Admin/Tags/TagEditOperation';
import { Tag } from '@one/api-models/lib/Admin/Tags/View/Tag';

import { ExtendedTag } from 'hooks/useTagsHelpers';

import { TreeEditAction } from './TreeEditAction';

type Props = {
  tagState: [Tag | undefined, Dispatch<SetStateAction<Tag | undefined>>];
  tagToMoveState: [Tag | undefined, Dispatch<SetStateAction<Tag | undefined>>];
  contextMenuAnchor: any;
  onAction: (tag: Tag, action: TreeEditAction, newParentTag?: Tag) => void;
};

export function TagTreeContextMenu({ tagState, tagToMoveState, contextMenuAnchor, onAction }: Props) {
  const [tag, setTag] = tagState;
  const [tagToMove, setTagToMove] = tagToMoveState;

  const sendAction = (action: TreeEditAction) => {
    if (onAction && tag) {
      if (action === TreeEditAction.MoveTo) {
        if (tagToMove) {
          onAction(tagToMove, action, tag);
          setTagToMove(undefined);
        }
      } else {
        onAction(tag, action);
      }
    }
    setTag(undefined);
  };

  const allowOperation = (operation: TagEditOperation, onParent?: boolean): boolean => {
    if (!tag) return false;

    const theTag = onParent ? (tag as ExtendedTag).parent : tag;

    return theTag && theTag.allowedOperations
      ? theTag.allowedOperations.findIndex((op) => op === operation) > -1
      : false;
  };

  return (
    <Menu
      id="fade-menu"
      anchorEl={contextMenuAnchor}
      keepMounted
      open={!!contextMenuAnchor && !!tag}
      onClose={() => {
        setTag(undefined);
      }}
      TransitionComponent={Fade}
    >
      <MenuItem
        onClick={() => sendAction(TreeEditAction.AddChild)}
        disabled={!allowOperation(TagEditOperation.AddChild)}
      >
        <ListItemIcon>
          <AddIcon fontSize="small" />
        </ListItemIcon>
        Add Child
      </MenuItem>
      <MenuItem
        onClick={() => sendAction(TreeEditAction.AddSibling)}
        disabled={!allowOperation(TagEditOperation.AddChild, true)}
      >
        <ListItemIcon>
          <AddIcon fontSize="small" />
        </ListItemIcon>
        Add Sibling
      </MenuItem>
      <Divider />
      <MenuItem
        onClick={() => {
          if (tag) setTagToMove({ ...tag });
          setTag(undefined);
        }}
        disabled={(tagToMove && tagToMove.key === tag?.key) || !allowOperation(TagEditOperation.Move)}
      >
        <ListItemIcon>
          <ContentCut fontSize="small" />
        </ListItemIcon>
        Cut
      </MenuItem>
      <MenuItem disabled={!tagToMove || tagToMove.key === tag?.key} onClick={() => sendAction(TreeEditAction.MoveTo)}>
        <ListItemIcon>
          <ContentPasteIcon fontSize="small" />
        </ListItemIcon>
        Paste{tagToMove ? ` [${tagToMove.name}]` : ''}
      </MenuItem>
      <Divider />
      <MenuItem
        sx={{ pl: 1.8 }}
        onClick={() => sendAction(TreeEditAction.Delete)}
        disabled={!allowOperation(TagEditOperation.Delete)}
      >
        <ListItemIcon>
          <DeleteOutlinedIcon fontSize="medium" />
        </ListItemIcon>
        Delete
      </MenuItem>
    </Menu>
  );
}
