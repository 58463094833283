import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';

import { ReactComponent as CreditCardIcon } from 'assets/icons/creditCard.svg';
import { ReactComponent as PeopleIcon } from 'assets/icons/people.svg';
import { useAppHelpers } from 'hooks/useAppHelpers';
import { RouteKey } from 'models/RouteKey';
import { AddCustomerDialog } from 'modules/customer/components/AddCustomerDialog';
import { routes } from 'router/Routes';
import { Button, Menu, MenuItem, Tooltip } from 'styled';
import { getMatchRoute } from 'utils/matchRoutes';

interface AddMenuProps {
  testId: string;
}

export const AddMenu = ({ testId }: AddMenuProps) => {
  const [addCustomerOpen, setAddCustomerOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [matchedPath, setMatchedPath] = useState(getMatchRoute(location));
  const { hasPermission } = useAppHelpers();
  const hasCreateSalesOrderPermissions = hasPermission(
    routes.find((item) => item.insideElements?.find((elem) => elem.key === RouteKey.CreateSalesOrder))?.permissions ||
      [],
  );
  const hasCreateCustomerPermissions = hasPermission(
    routes.find((item) => item.key === RouteKey.Customers)?.permissions || [],
  );

  useEffect(() => {
    setMatchedPath(getMatchRoute(location));
  }, [location]);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Tooltip title="Create">
        <Button
          onClick={handleMenu}
          variant="contained"
          color="primary"
          size="small"
          sx={{ minWidth: '24px', width: '24px', height: '24px', p: 1, borderRadius: 8 }}
          data-testid={`${testId}AddButton`}
        >
          <AddIcon fontSize="small" />
        </Button>
      </Tooltip>
      <Menu
        data-testid={`${testId}AddButton`}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        elevation={3}
      >
        {hasCreateCustomerPermissions && (
          <MenuItem
            data-testid={`${testId}AddCustomerButton`}
            onClick={() => setAddCustomerOpen(true)}
            disabled={!matchedPath?.layoutConfig?.showPartnerSelect}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', width: '25px', mr: 1 }}>
              <PeopleIcon />
            </Box>
            Add Customer
          </MenuItem>
        )}
        {hasCreateSalesOrderPermissions && (
          <MenuItem data-testid={`${testId}CreateSalesOrderButton`} onClick={() => navigate('/sales/new')}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '25px', mr: 1 }}>
              <CreditCardIcon height="18px" />
            </Box>
            Create Sales Order
          </MenuItem>
        )}
      </Menu>
      <AddCustomerDialog open={addCustomerOpen} handleClose={() => setAddCustomerOpen(false)} />
    </>
  );
};
