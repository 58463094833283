import { useMutation } from 'react-query';

import { Box } from '@mui/material';
import { AddFile } from '@one/api-models/lib/Admin/Files/AddFile';
import { BulkEnrollmentIngestRequest } from '@one/api-models/lib/Feed/Files/Member/Enrollment/BulkEnrollmentIngestRequest';
import { BulkEnrollmentIngestResponse } from '@one/api-models/lib/Feed/Files/Member/Enrollment/BulkEnrollmentIngestResponse';

import { ApiError } from 'apiAccess/api-client';
import { useApiHelpers } from 'hooks/useApiHelpers';
import { useToastMessage } from 'hooks/useToastMessage';

import { FileDropZone } from './FileDropZone';

interface Props {
  testId: string;
  partnerKey?: string;
  brandKey?: string;
  onSuccess?: (response: BulkEnrollmentIngestResponse) => void;
  successMessage?: string;
}

export const FileManager: React.FC<Props> = ({ testId, partnerKey, brandKey, onSuccess, successMessage }: Props) => {
  const { addApiError, showMessage } = useToastMessage();
  const { api } = useApiHelpers();

  const membersEnrollmentMutation = useMutation<
    BulkEnrollmentIngestResponse,
    ApiError,
    {
      request: BulkEnrollmentIngestRequest;
    },
    unknown
  >(
    async ({ request }: { request: BulkEnrollmentIngestRequest }) => {
      return await api.members.bulkEnrollment(request);
    },
    {
      onSuccess: (value, variables) => {
        if (value) {
          if (value.validationErrors?.length) {
            showMessage('File(s) could not be processed', 'error');
            if (onSuccess) onSuccess(value);
            return;
          }
          if (value) {
            showActionSuccessMessage(variables.request);
            if (onSuccess) onSuccess(value);
          }
        }
      },
      onError: (error: ApiError) => {
        addApiError(error);
      },
    },
  );

  const showActionSuccessMessage = (request?: BulkEnrollmentIngestRequest) => {
    if (successMessage) showMessage(successMessage, 'success');
  };

  return (
    <Box sx={{ width: '100%' }}>
      <FileDropZone
        mutation={membersEnrollmentMutation}
        requestBase={
          {
            $Type: BulkEnrollmentIngestRequest.$type,
            partnerKey,
            brandKey,
            artifactType: 'Partner',
            files: [] as AddFile[],
          } as BulkEnrollmentIngestRequest
        }
        buttonText="Process file(s)"
        multiple
        helperText={`The file(s) must have a .csv extension and follow this naming convention: ${partnerKey}_${brandKey}_*.csv`}
        testId={testId}
      />
    </Box>
  );
};

FileManager.defaultProps = {
  successMessage: 'Files Saved',
};
