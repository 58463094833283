import { useSelector } from 'react-redux';

import { AgentAccess } from '@one/api-models/lib/AgentAccess';

import { selectActiveBrand } from 'store/slices/applicationDataSlice';

import { isTravelAgentPortal } from 'core/auth/auth';

type AgentAccessTypes = keyof AgentAccess;

export const useAgentAccess = () => {
  const isTravelAgent = isTravelAgentPortal();
  const brandAgentAccessConfiguration = useSelector(selectActiveBrand)?.brandConfiguration?.agentAccess;

  const agentAccessRequired = (permission: boolean): boolean => !isTravelAgent || (isTravelAgent && !permission);

  const agentAccessRequiredBrandConfiguration = (permission: AgentAccessTypes): boolean =>
    brandAgentAccessConfiguration ? agentAccessRequired(brandAgentAccessConfiguration[permission]) : true;

  return { agentAccessRequired, agentAccessRequiredBrandConfiguration };
};
