import { useMutation } from 'react-query';

import CloseIcon from '@mui/icons-material/Close';
import { Alert, Box, CircularProgress, Dialog } from '@mui/material';
import { EditOrderRequest } from '@one/api-models/lib/Admin/Orders/Common/EditOrderRequest';
import { EditOrderResponse } from '@one/api-models/lib/Admin/Orders/Common/EditOrderResponse';
import { CruiseOrderItemSummary } from '@one/api-models/lib/Admin/Sales/Travel/Cruise/CruiseOrderItemSummary';
import { EditTraveler } from '@one/api-models/lib/Admin/Sales/Travel/Cruise/EditTraveler';

import { ApiError } from 'apiAccess/api-client';
import { AccessControlGuard } from 'common/AccessControlGuard';
import { useApiHelpers } from 'hooks/useApiHelpers';
import { useToastMessage } from 'hooks/useToastMessage';
import { DialogContent, DialogTitle, IconButton } from 'styled';

import { EditTravelersForm } from './EditTravelersForm';

interface Props {
  orderNumber: string;
  cruiseOrderItem?: CruiseOrderItemSummary;
  testId: string;
  handleOnTravelersEditFinished: () => void;
  handleCloseEditTravelersForm: () => void;
  isEditTravelersOpen: boolean;
  editTravelerSupportedEditOperation?: EditTraveler;
}

export const EditTravelersDialog = ({
  cruiseOrderItem,
  orderNumber,
  testId,
  handleOnTravelersEditFinished,
  handleCloseEditTravelersForm,
  isEditTravelersOpen,
  editTravelerSupportedEditOperation,
}: Props) => {
  const { api } = useApiHelpers();
  const { apiErrorHandler, addMessage } = useToastMessage();

  const {
    isLoading,
    data: editTravelerResponse,
    mutate,
  } = useMutation<any, ApiError, EditOrderRequest, unknown>((request) => api.sales.editOrder(request), {
    mutationKey: 'editTravelersMutation',
    onSuccess: (value: EditOrderResponse) => {
      if (value.isSuccessful) {
        addMessage({
          label: 'Traveler names changed successfully.',
          severity: 'success',
        });
      }
      handleOnTravelersEditFinished();
    },
    onError: (error) => {
      apiErrorHandler(error);
      handleOnTravelersEditFinished();
    },
  });

  if (!cruiseOrderItem && !editTravelerSupportedEditOperation) return null;

  const handleSubmitForm = (request: EditOrderRequest): void => {
    mutate(request);
  };

  return (
    <Dialog
      open={isEditTravelersOpen}
      onClose={handleCloseEditTravelersForm}
      scroll="paper"
      sx={{
        '.MuiDialog-paper': {
          width: '100%',
          maxWidth: '800px',
        },
      }}
    >
      <DialogTitle>
        Change Traveler Names
        <IconButton
          onClick={handleCloseEditTravelersForm}
          size="small"
          disableRipple
          data-testid={`${testId}CloseButton`}
        >
          <CloseIcon sx={{ color: (theme) => theme.customPalette.icons.light }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <AccessControlGuard permission={['Sales.Orders:Edit']}>
          {isEditTravelersOpen && (
            <>
              {isLoading ? (
                <Box sx={{ height: 150 }} justifyContent="center" alignItems="center" display="flex">
                  <CircularProgress data-testid="LoadingSpinner" />
                </Box>
              ) : (
                <Box>
                  {editTravelerResponse != null ? (
                    <Box mb={2}>
                      <Alert
                        variant="standard"
                        sx={{ mb: 2 }}
                        severity={editTravelerResponse.isSuccessful ? 'success' : 'error'}
                      >
                        {editTravelerResponse.isSuccessful ? (
                          'Traveler names changed successfully.'
                        ) : (
                          <>
                            <strong>An error has occurred!</strong> The traveler names are not updated.
                          </>
                        )}
                      </Alert>

                      {editTravelerResponse.messages != null && (
                        <ul>
                          {editTravelerResponse.messages.map((message: string) => (
                            <li key={message}>{message}</li>
                          ))}
                        </ul>
                      )}
                    </Box>
                  ) : (
                    <Box>
                      <EditTravelersForm
                        cruiseOrderItem={cruiseOrderItem}
                        orderNumber={orderNumber}
                        onCloseForm={handleCloseEditTravelersForm}
                        onSubmitForm={handleSubmitForm}
                        editTravelerSupportedEditOperation={editTravelerSupportedEditOperation}
                        testId={testId}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </>
          )}
        </AccessControlGuard>
      </DialogContent>
    </Dialog>
  );
};
