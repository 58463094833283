import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import {
  Box,
  Button,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import { LoadProductsResponse } from '@one/api-models/lib/Admin/Tags/LoadProductsResponse';
import { Product } from '@one/api-models/lib/Admin/Tags/View/Product';

import { ApiError } from 'apiAccess/api-client';
import { useApiHelpers } from 'hooks/useApiHelpers';
import { useToastMessage } from 'hooks/useToastMessage';

interface Props {
  tagKey: string;
  testId: string;
}

export const ProductList = ({ tagKey, testId }: Props) => {
  const { addApiError } = useToastMessage();
  const { api } = useApiHelpers();
  const [products, setProducts] = useState<Product[] | undefined>(undefined);
  const [loadMore, setLoadMode] = useState(true);
  const [page, setPage] = useState(0);

  const PAGE_SIZE = 100;

  const loadProductsMutation = useMutation<LoadProductsResponse, ApiError, number, unknown>(
    async (page: number) => {
      return api.tags.loadProducts({
        tagKey: tagKey,
        listingCriteria: { take: PAGE_SIZE, skip: page * PAGE_SIZE },
      });
    },
    {
      onSuccess: (value) => {
        setProducts([...(products || []), ...value.items.items]);
        setLoadMode(value.items.hasMoreItems);
      },
      onError: addApiError,
    },
  );

  const doLoadMore = () => {
    loadProductsMutation.mutate(page + 1);
    setPage(page + 1);
  };

  useEffect(() => {
    loadProductsMutation.mutate(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let listItems: React.ReactNode[] = [];

  if (products && products.length > 0) {
    listItems = products.map((prod) => (
      <ListItem disabled={!prod.enabled} title="disabled" key={prod.key}>
        <ListItemButton>
          <ListItemText primary={<Link href={`/products/${prod.key}`}>{prod.name}</Link>} secondary={prod.type} />
        </ListItemButton>
      </ListItem>
    ));
    if (loadMore) {
      listItems.push(
        <ListItem title="disabled" key="loadMore">
          <ListItemText>
            <Button
              fullWidth
              onClick={doLoadMore}
              disabled={loadProductsMutation.isLoading}
              data-testid={`${testId}LoadMoreButton`}
            >
              Load More
            </Button>
            <LinearProgress
              sx={{
                visibility: loadProductsMutation.isLoading ? 'visible' : 'hidden',
                mt: 1,
              }}
            />
          </ListItemText>
        </ListItem>,
      );
    }
  }

  return products ? (
    <Box>
      {listItems.length > 0 && (
        <Box>
          <List dense sx={{ maxHeight: 500, overflow: 'auto', m: 0 }}>
            {listItems}
          </List>
        </Box>
      )}

      {products.length === 0 && (
        <Box sx={{ p: 2 }}>
          <Typography>No products with this tag.</Typography>
        </Box>
      )}
    </Box>
  ) : (
    <Box sx={{ pt: 1 }}>
      <Skeleton variant="rectangular" height="48px"></Skeleton>
    </Box>
  );
};
