import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Paper } from '@mui/material';
import { TranslationItem } from '@one/api-models/lib/Admin/Common/TranslationItem';
import { CreateDetails } from '@one/api-models/lib/Admin/Tags/Edit/CreateDetails';
import { TagType } from '@one/api-models/lib/Admin/Tags/TagType';

import { selectSettings } from 'store/slices/tagsDataSlice';

import { DetailsForm, FormData } from './DetailsForm';

export interface TagBasic {
  key: string;
  name: string;
  enabled: boolean;
}

interface Props {
  parentKey: string;
  tagTypes: TagType[];
  testId: string;
  onSave: (tag: CreateDetails) => void;
  setEditing: (editing: boolean) => void;
}

export function Create({ parentKey, tagTypes, testId, onSave, setEditing }: Props) {
  const settings = useSelector(selectSettings);
  const [formData, setFormData] = useState<FormData>({
    enabled: false,
    rank: '',
    displayName: [{ cultureCode: 'en-US', value: 'test' }],
    type: TagType.Detail,
  });

  useEffect(() => {
    if (tagTypes && settings && settings.translationCultureCodes) {
      const displayNames = settings.translationCultureCodes.map(
        (langCode) => ({ cultureCode: langCode, value: '' } as TranslationItem),
      );

      setFormData({
        enabled: true,
        rank: '50',
        type: tagTypes[0],
        displayName: displayNames,
      });
    }
  }, [settings, tagTypes]);

  if (tagTypes.length === 0) {
    return <Paper>No allowed tags received. Cannot create new tag.</Paper>;
  }

  const handleSave = (data: FormData) => {
    if (data) {
      const displayNames: TranslationItem[] =
        data.displayName
          ?.map((val, index) => {
            return {
              cultureCode: settings?.translationCultureCodes[index] || '',
              value: val.value,
            } as TranslationItem;
          })
          .filter((x) => x.value) || [];

      onSave({
        name: data.name,
        displayName: displayNames,
        rank: parseInt(data.rank, 10),
        enabled: data.enabled,
        type: data.type,
        parentKey: parentKey,
      } as CreateDetails);
    }
  };

  return (
    <DetailsForm
      data={formData}
      onSave={handleSave}
      tagTypes={tagTypes}
      setEditing={setEditing}
      testId={`${testId}DetailsForm`}
    />
  );
}
