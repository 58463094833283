import { AccessControlPermissions } from 'apiAccess/accessControlAccess';
import { Layout } from 'common/layout/Layout';
import { AppNoPermissions } from 'core/auth/AppNoPermissions';
import { useAppHelpers } from 'hooks/useAppHelpers';
import { LayoutConfig } from 'models/LayoutConfig';

interface Props {
  routePermissions?: AccessControlPermissions[];
  children: any;
  layout?: LayoutConfig;
}

const GuardedRoute: React.FC<Props> = ({ routePermissions, layout, children }: Props) => {
  const { hasPermission } = useAppHelpers();
  const hasRoutePermission = (permissions: AccessControlPermissions[]) => {
    return permissions !== undefined && hasPermission(permissions);
  };

  if (routePermissions != null && !hasRoutePermission(routePermissions)) {
    return <AppNoPermissions />;
  }
  return <Layout config={layout}>{children}</Layout>;
};

export default GuardedRoute;
