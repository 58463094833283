import { useDispatch } from 'react-redux';

import { GridFilterModel } from '@mui/x-data-grid-pro';

import {
  selectAllBrandsEnabled,
  selectCurrentPage,
  selectFiltersModel,
  setAllBrandsEnabled,
  setCurrentPage,
  setFiltersModel,
} from 'store/slices/salesOrderListDataSlice';

import { OrderDataGrid } from 'modules/sales/components/OrderDataGrid';

export const Orders = () => {
  const dispatch = useDispatch();

  return (
    <OrderDataGrid
      title="Sales Orders"
      testId="SalesOrders"
      label="Show orders for all brands"
      selectAllBrandsEnabled={selectAllBrandsEnabled}
      selectFiltersModel={selectFiltersModel}
      selectCurrentPage={selectCurrentPage}
      setCurrentPage={(page: number) => dispatch(setCurrentPage(page))}
      setAllBrandsEnabled={(enableAllBrands: boolean) => dispatch(setAllBrandsEnabled(enableAllBrands))}
      setFiltersModel={(filters: GridFilterModel) => dispatch(setFiltersModel(filters))}
    />
  );
};
