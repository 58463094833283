import { AddFilesBase } from '@one/api-models/lib/Feed/Files/AddFilesBase';

import { FileDetails } from 'common/fileManager/FileDetails';
import { getFileContent } from 'common/fileManager/FileHelper';

export interface FileConfigurationItem {
  typeName: string;
  isOfThisType: (fileType: string) => boolean;
  fileDetailsToAddFileRequest: (fileDetails: FileDetails) => Promise<AddFilesBase>;
}

const fileConfigs: FileConfigurationItem[] = [
  {
    typeName: 'text/csv',
    isOfThisType: (fileType: string) => {
      return fileType.startsWith('text/csv');
    },

    fileDetailsToAddFileRequest: async (fileDetails: FileDetails): Promise<AddFilesBase> =>
      new Promise(function (resolve, reject) {
        getFileContent(fileDetails.file)
          .then(function (fileContentResult) {
            resolve({
              $type: AddFilesBase.$type,
              fileName: fileDetails.name,
              size: fileDetails.size,
              contentType: fileDetails.type,
              content: fileContentResult.content,
            } as AddFilesBase);
          })
          .catch(function (err) {
            reject(err);
          });
      }),
  },
  {
    typeName: 'application/vnd.ms-excel',
    isOfThisType: (fileType: string) => {
      return fileType.startsWith('application/vnd.ms-excel');
    },

    fileDetailsToAddFileRequest: async (fileDetails: FileDetails): Promise<AddFilesBase> =>
      new Promise(function (resolve, reject) {
        getFileContent(fileDetails.file)
          .then(function (fileContentResult) {
            resolve({
              $type: AddFilesBase.$type,
              fileName: fileDetails.name,
              size: fileDetails.size,
              contentType: fileDetails.type,
              content: fileContentResult.content,
            } as AddFilesBase);
          })
          .catch(function (err) {
            reject(err);
          });
      }),
  },
];

const getFileConfiguration = (fileType: string): FileConfigurationItem | undefined => {
  return fileConfigs.find((x) => x.isOfThisType(fileType));
};

const fileDetailsToAddFileRequest = (fileDetails: FileDetails): Promise<AddFilesBase> | undefined => {
  const config = fileConfigs.find((x) => x.isOfThisType(fileDetails.type));

  if (config) {
    return config.fileDetailsToAddFileRequest(fileDetails);
  }
  return undefined;
};

export const FileConfiguration = {
  getFileConfiguration,
  fileDetailsToAddFileRequest,
};
