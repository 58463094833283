import { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Button, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Promotion } from '@one/api-models/lib/Campaign/Promotion/Promotion';
import { PromotionAward } from '@one/api-models/lib/Campaign/Promotion/PromotionAward';

import { ConfirmationDialog } from 'common/ConfirmationDialog';
import { DataGrid } from 'common/dataGrid/DataGrid';
import { useFormat } from 'hooks/useFormat';

import { AddPromotionAwardDialog } from './AddPromotionAwardDialog';
import { AwardTargetTypeValues, AwardTypeValues } from './promotionHelpers';

interface PromotionAwardsViewProps {
  promotion: Promotion;
  testId: string;
  paddingTop?: number;
  handleRemoveAward: (id: number) => Promise<void>;
  handleAddAward: (item: PromotionAward) => Promise<void>;
}

export const PromotionAwardsView = ({
  promotion,
  testId,
  paddingTop,
  handleRemoveAward,
  handleAddAward,
}: PromotionAwardsViewProps) => {
  const { formatDate } = useFormat();
  const [removeConfirmationOpen, setRemoveConfirmationOpen] = useState<boolean>(false);
  const [addAwardOpen, setAddAwardOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<PromotionAward | undefined>(undefined);

  const handleRemove = (item: PromotionAward) => {
    setSelectedItem(item);
    setRemoveConfirmationOpen(true);
  };

  const handleConfirmRemoveAward = async (isConfirmed: boolean) => {
    if (selectedItem && isConfirmed) {
      await handleRemoveAward(selectedItem.id);
    }
    setRemoveConfirmationOpen(false);
    setSelectedItem(undefined);
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Award ID',
      sortable: false,
      flex: 1,
    },
    {
      field: 'awardType',
      headerName: 'Award Type',
      sortable: false,
      flex: 1.5,
      renderCell: (params: any) => <>{AwardTypeValues[params?.row?.awardType]?.label}</>,
    },
    {
      field: 'targetId',
      headerName: 'Award',
      sortable: false,
      flex: 1,
      renderCell: (params: any) => <>{params?.row?.targetId}</>,
    },
    {
      field: 'targetType',
      headerName: 'Target Type',
      sortable: false,
      flex: 1.5,
      renderCell: (params: any) => <>{AwardTargetTypeValues[params?.row?.targetType]?.label}</>,
    },
    {
      field: 'targetName',
      headerName: 'Target',
      sortable: false,
      flex: 1.5,
    },
    {
      field: 'targetDescription',
      headerName: 'Target Description',
      sortable: false,
      flex: 2,
    },
    {
      field: 'targetAdjustmentAmount',
      headerName: 'Amount',
      sortable: false,
      flex: 1.2,
    },
    {
      field: 'targetQuantityLimit',
      headerName: 'Max Qty',
      sortable: false,
    },
    {
      field: 'targetAdjustmentPercentage',
      headerName: 'Percentage',
      sortable: false,
      renderCell: (params: any) => (
        <>{params?.row?.targetAdjustmentPercentage && `${params?.row?.targetAdjustmentPercentage}%`}</>
      ),
    },
    {
      field: 'targetFixedPrice',
      headerName: 'Fixed Price',
      sortable: false,
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      sortable: false,
    },
    {
      field: 'updatedAt',
      headerName: 'Update Date (UTC)',
      sortable: false,
      flex: 2,
      renderCell: (params: any) => <>{formatDate(params?.row?.updatedAt, false, 'MMM dd, yyyy') || ''}</>,
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      align: 'right',
      renderCell: (params: any) => (
        <IconButton onClick={() => handleRemove(params?.row)} data-testid={`${testId}CloseButton`}>
          <CloseIcon color="primary" />
        </IconButton>
      ),
    },
  ];

  return (
    <Box>
      <Box mb={2} mt={paddingTop} display="flex" justifyContent="space-between">
        <Typography variant="h5">Awards</Typography>
        <Button
          variant="contained"
          size="small"
          onClick={() => setAddAwardOpen(true)}
          startIcon={<AddIcon />}
          data-testid={`${testId}AwardButton`}
        >
          Award
        </Button>
      </Box>
      {promotion.awards && promotion.awards.length > 0 ? (
        <DataGrid columns={columns} rows={promotion.awards} hideFooter={true} rowHeight={40} />
      ) : (
        <Alert severity="info" variant="outlined">
          No promotion awards available.
        </Alert>
      )}
      {addAwardOpen && (
        <AddPromotionAwardDialog
          open={addAwardOpen}
          promotion={promotion}
          handleAddAward={handleAddAward}
          handleClose={() => setAddAwardOpen(false)}
          testId={`${testId}AwardDialog`}
        />
      )}

      <ConfirmationDialog
        open={removeConfirmationOpen}
        title={`Remove ${selectedItem?.targetName}`}
        question="Are you sure you want to remove this award?"
        confirmButton="Remove"
        abortButton="Cancel"
        onClose={handleConfirmRemoveAward}
        testId={`${testId}RemoveDialog`}
      />
    </Box>
  );
};
