import { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Alert,
  Box,
  Button,
  Grid,
  Link,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { BulkEnrollmentIngestResponse } from '@one/api-models/lib/Feed/Files/Member/Enrollment/BulkEnrollmentIngestResponse';

import { selectActiveBrand, selectActivePartner } from 'store/slices/applicationDataSlice';

import templateFile from 'assets/files/PartnerKey_BrandKey_Enrollment.csv';
import { PageHeader } from 'common/layout/components/PageHeader';

import { FileManager } from './components/FileManager';

export const BulkMembersEnrollment = () => {
  const testIdPrefix = 'BulkMemberEnrollment';
  const [result, setResult] = useState<any | undefined>(undefined);
  const handleSuccess = (result: BulkEnrollmentIngestResponse) => setResult(result as any);

  const activePartner = useSelector(selectActivePartner);
  const activeBrand = useSelector(selectActiveBrand);
  const partnerKey = activePartner?.key;
  const brandKey = activeBrand?.key;

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = templateFile;
    link.setAttribute('download', `${partnerKey}_${brandKey}_Enrollment.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box>
      <PageHeader title="Bulk Member Enrollment" testId={testIdPrefix} />
      <Paper sx={{ mb: 2, p: 3 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12}>
            <Stepper orientation="vertical">
              <Step active={result == null}>
                <StepLabel>Download and fill the template</StepLabel>
                <StepContent>
                  <Typography>
                    Click{' '}
                    <Link
                      sx={{ cursor: 'pointer' }}
                      onClick={handleDownload}
                      data-testid={`${testIdPrefix}DownloadTemplateLink`}
                    >
                      here
                    </Link>{' '}
                    to download the template.
                  </Typography>
                </StepContent>
              </Step>
              <Step active={result == null}>
                <StepLabel>Upload the completed file</StepLabel>
                <StepContent>
                  <FileManager
                    successMessage="File(s) processed with success"
                    onSuccess={handleSuccess}
                    partnerKey={partnerKey}
                    brandKey={brandKey}
                    testId={testIdPrefix}
                  />
                </StepContent>
              </Step>
              <Step active={result != null}>
                <StepLabel>Results</StepLabel>
                <StepContent>
                  {result != null && (
                    <>
                      <Box sx={{ mb: 2 }}>
                        <Alert severity="success">
                          Your upload was processed.{' '}
                          <b>
                            {result.numberOfImportedItems}/{result.numberOfImportedItems} were imported.
                          </b>
                        </Alert>
                        <Box sx={{ display: 'flex' }}>
                          <Box
                            sx={{
                              backgroundColor: 'success.main',
                              width: result.numberOfImportedItems / result.numberOfItemsInFile,
                              height: '4px',
                              borderBottomLeftRadius: 4,
                              borderBottomRightRadius:
                                result.numberOfImportedItems === result.numberOfItemsInFile ? 4 : undefined,
                            }}
                          ></Box>
                          <Box
                            sx={{
                              backgroundColor: 'error.main',
                              width: 1 - result.numberOfImportedItems / result.numberOfItemsInFile,
                              height: '4px',
                              borderBottomLeftRadius: result.numberOfImportedItems === 0 ? 4 : undefined,
                              borderBottomRightRadius: 4,
                            }}
                          ></Box>
                        </Box>
                      </Box>
                      {result.validationErrors != null && result.validationErrors.length > 0 && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                          Issues found in your file:
                          {result.validationErrors.map((e: any) => (
                            <Typography variant="caption" component={Box}>
                              {e.message}
                            </Typography>
                          ))}
                        </Alert>
                      )}
                    </>
                  )}

                  <Button
                    onClick={() => setResult(undefined)}
                    variant="outlined"
                    data-testid={`${testIdPrefix}UploadNewFileButton`}
                  >
                    Upload new file
                  </Button>
                </StepContent>
              </Step>
            </Stepper>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};
