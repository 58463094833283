import { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ListingData } from '@one/api-models/lib/Admin/Common/ListingData';
import { MemberStatus } from '@one/api-models/lib/Admin/Members/MemberStatus';
import { EditMemberRequest } from '@one/api-models/lib/Admin/Members/Request/EditMemberRequest';
import { SearchMemberRequest as SearchCriteria } from '@one/api-models/lib/Admin/Members/Request/SearchMemberRequest';
import { EditMemberResponse } from '@one/api-models/lib/Admin/Members/Response/EditMemberResponse';
import { MemberResponse as Customer } from '@one/api-models/lib/Admin/Members/Response/MemberResponse';
import { SearchMemberResponse as SearchResponse } from '@one/api-models/lib/Admin/Members/Response/SearchMemberResponse';
import { Contact } from '@one/api-models/lib/Admin/Proxy/Princess/Contact';
import { GetContactsRequest } from '@one/api-models/lib/Admin/Proxy/Princess/GetContactsRequest';

import { selectActiveBrand, selectActivePartner } from 'store/slices/applicationDataSlice';
import {
  selectCountryList,
  selectCustomerList,
  selectSearchCriteria,
  setCustomerList,
  setSearchCriteria,
} from 'store/slices/customersDataSlice';

import { ApiError } from 'apiAccess/api-client';
import appConfig from 'config/appConfig';
import { useApiHelpers } from 'hooks/useApiHelpers';
import { useFormat } from 'hooks/useFormat';
import { useToastMessage } from 'hooks/useToastMessage';
import { customerSpecificSearchBrands } from 'models/CustomerSpecificSearch';

export const useCustomerSearch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addApiError, apiErrorHandler } = useToastMessage();
  const { api } = useApiHelpers();
  const { currentZonedDateToISODate } = useFormat();

  const activePartner = useSelector(selectActivePartner);
  const activeBrand = useSelector(selectActiveBrand);

  const searchCriteria = useSelector(selectSearchCriteria);
  const customerList = useSelector(selectCustomerList);
  const countryList = useSelector(selectCountryList);

  const [allowBrandSpecific, setAllowBrandSpecific] = useState<boolean>(
    activeBrand
      ? customerSpecificSearchBrands.includes(activeBrand?.key) && !!appConfig.isMCMSingleIdentityEnabled
      : false,
  );
  const [hasResults, setHasResults] = useState<boolean>(false);
  const shouldClear = useRef(false);

  //[Princess Fast Track] - to be removed after proper implementation with Workflow
  const [fullCustomersList, setFullCustomersList] = useState<Customer[]>([]);
  const [princessGuests, setPrincessGuests] = useState<Contact[]>([]);
  const [showAdditionalCustomerDetails, setShowAdditionalCustomerDetails] = useState<boolean>(false);
  const [additionalDetailsRequest, setAdditionalDetailsRequest] = useState<EditMemberRequest | undefined>(undefined);

  useEffect(() => {
    setAllowBrandSpecific(
      activeBrand
        ? customerSpecificSearchBrands.includes(activeBrand?.key) && !!appConfig.isMCMSingleIdentityEnabled
        : false,
    );

    if (shouldClear.current) {
      clearSearch();
    }
    shouldClear.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBrand?.key]);

  const searchCriteriaChangedHandler = (data: SearchCriteria) => {
    dispatch(setSearchCriteria(data));
  };

  const clearSearch = () => {
    setFullCustomersList([]);
    dispatch(setSearchCriteria(undefined));
    dispatch(setCustomerList([]));
  };

  useEffect(() => {
    if (searchCriteria && !allowBrandSpecific) {
      customerSearchMutation.mutate(searchCriteria);
    } else if (searchCriteria && allowBrandSpecific) {
      princessCustomerSearchMutation.mutate(searchCriteria);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCriteria]);

  useEffect(() => {
    setHasResults(allowBrandSpecific ? fullCustomersList.length > 0 : !!customerList && customerList.length > 0);
  }, [allowBrandSpecific, fullCustomersList, customerList]);

  const customerSearchMutation = useMutation<SearchResponse, ApiError, SearchCriteria, unknown>(
    (request) => {
      let selectedBirthDate = undefined;
      if (request.dateOfBirth) {
        selectedBirthDate = currentZonedDateToISODate(new Date(request.dateOfBirth));
      }

      return api.members.search({
        ...request,
        dateOfBirth: selectedBirthDate,
        partnerKey: activePartner?.key ?? '',
        brandKey: activeBrand?.key ?? '',
        listingCriteria: {},
      });
    },
    {
      onSuccess: (value: SearchResponse) => {
        if (value) {
          dispatch(setCustomerList((value.members as ListingData<Customer>).items));
        }
      },
      onError: (error) => {
        dispatch(setCustomerList([]));
        addApiError(error);
      },
    },
  );

  //[Princess Fast Track] - to be removed after proper implementation with Workflow
  const princessCustomerSearchMutation = useMutation<Customer[], ApiError, SearchCriteria, unknown>(
    async (request) => {
      const isOneSpecific =
        !!request.oneMemberKey ||
        (!!request.partnerMemberKey && !request.firstName && !request.lastName && !request.dateOfBirth);

      if (!!request.partnerLoyaltyId) {
        const princessGuest = await api.singleIdentityPrincess.loadGuest(request.partnerLoyaltyId);
        return mapPrincessGuestsByCCN(princessGuest?.contact);
      } else {
        let selectedBirthDate = undefined;
        if (request.dateOfBirth) {
          selectedBirthDate = currentZonedDateToISODate(new Date(request.dateOfBirth));
        }

        const searchGuestRequest: GetContactsRequest = {
          ...request,
          firstName: request.firstName || '',
          lastName: request.lastName || '',
          dateOfBirth: selectedBirthDate || new Date(), //to avoid TypeScript errors (Princess search vs ONE search)
        };

        const [oneCustomers, princessCustomers] = await Promise.all([
          //search in ONE all the time
          api.members.search({
            ...request,
            dateOfBirth: undefined, //hack to avoid date of birth issues
            partnerKey: activePartner?.key ?? '',
            brandKey: activeBrand?.key ?? '',
            listingCriteria: {},
          }),

          //if search by oneMemberKey -> do not search in Princess
          isOneSpecific ? Promise.resolve(null) : api.singleIdentityPrincess.loadGuests({ ...searchGuestRequest }),
        ]);

        const customers: Customer[] = oneCustomers ? oneCustomers.members?.items : [];

        return mapPrincessGuests(customers, princessCustomers ? princessCustomers.contacts : []);
      }
    },
    {
      onSuccess: (value: Customer[]) => {
        if (value) {
          value.sort((a, b) => (a.firstName < b.firstName ? -1 : a.firstName === b.firstName ? 0 : 1));
          setFullCustomersList(value);
        }
      },
      onError: (error) => {
        setFullCustomersList([]);
        addApiError(error);
      },
    },
  );

  const mapPrincessGuestsByCCN = async (contact?: Contact) => {
    if (!contact) return [];

    //first get ONE customers with given CCN
    const oneCustomer = await api.members.search({
      partnerLoyaltyId: contact?.id,
      partnerKey: activePartner?.key ?? '',
      brandKey: activeBrand?.key ?? '',
      listingCriteria: {},
    });

    const oneCustomers: Customer[] = [];
    const customers: Customer[] = oneCustomer ? oneCustomer.members?.items : [];

    oneCustomers.push(...customers);

    return mapPrincessGuests(oneCustomers, contact ? [contact] : []);
  };

  const mapPrincessGuests = (oneCustomers: Customer[], guests: Contact[]): Customer[] => {
    const allCustomers: Customer[] = [...oneCustomers];
    const princessGuests: Contact[] = [];

    guests.forEach((item) => {
      const oneCustomer = oneCustomers?.find((customer) => customer.partnerLoyaltyId?.trim() === item.id);
      if (!oneCustomer && !!item.id) {
        const newCustomer: Customer = {
          firstName: item.firstName || '',
          lastName: item.lastName || '',
          country: item.country || '',
          dateOfBirth: item.birthDate ? new Date(item.birthDate) : undefined,
          homePhone: item.phoneNumber,
          mobilePhone: item.phoneNumber,
          email: item.email || '',
          status: MemberStatus.Inactive,
          memberKey: '',
          partnerLoyaltyId: item.id,
          externalMemberKey: '',
        };
        princessGuests.push(item);
        allCustomers.push(newCustomer);
        return item;
      }
    });

    setPrincessGuests(princessGuests);
    return allCustomers;
  };

  //[Princess Fast Track] - to be removed after proper implementation with Workflow
  const createCustomerMutation = useMutation<EditMemberResponse, ApiError, EditMemberRequest, unknown>(
    async (request) => api.members.edit(request),
    {
      onSuccess: (value: EditMemberResponse) => {
        if (value.memberKey) {
          navigate(`/customers/${value.memberKey}`);
        }
      },
      onError: apiErrorHandler,
    },
  );

  //[Princess Fast Track] - to be removed after proper implementation with Workflow
  const createCustomer = async (data: Customer) => {
    const princessUserData = princessGuests.find((g) => g.id === data.partnerLoyaltyId);

    const countryCode =
      princessUserData?.country === 'USA'
        ? 'US'
        : countryList?.map((d: any) => d.code).find((c) => c === princessUserData?.country);

    const request: EditMemberRequest = {
      $Type: EditMemberRequest.$type,
      memberKey: data.memberKey,
      firstName: data.firstName,
      lastName: data.lastName,
      emailAddress: data.email,
      phoneNumber: data.mobilePhone || data.homePhone,
      dateOfBirth: new Date(data.dateOfBirth as Date),
      brandKey: activeBrand?.key ?? '',
      zipCode: princessUserData?.postCode,
      state: princessUserData?.stateOrCounty,
      city: princessUserData?.city,
      address: princessUserData?.address1,
      address2: '',
      country: countryCode,
      status: MemberStatus.Inactive,
      externalMemberKey: data.externalMemberKey,
      partnerLoyaltyId: princessUserData?.id,
      leadSource: '',
    };

    if (!request.emailAddress || !request.lastName || !request.firstName || !request.phoneNumber || !request.zipCode) {
      setAdditionalDetailsRequest(request);
      setShowAdditionalCustomerDetails(true);
    } else {
      createCustomerMutation.mutate(request);
    }
  };

  const handleCloseAddCustomer = () => {
    setShowAdditionalCustomerDetails(false);
    setAdditionalDetailsRequest(undefined);
  };

  const isLoading =
    customerSearchMutation.isLoading || princessCustomerSearchMutation.isLoading || createCustomerMutation.isLoading;

  return {
    isLoading,
    fullCustomersList,
    customerList,
    allowBrandSpecific,
    hasResults,
    showAdditionalCustomerDetails,
    additionalDetailsRequest,
    searchCriteriaChangedHandler,
    clearSearch,
    createCustomer,
    handleCloseAddCustomer,
  };
};
