import { FC, Fragment } from 'react';

import AcUnitIcon from '@mui/icons-material/AcUnit';
import EvStationIcon from '@mui/icons-material/EvStation';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import LuggageIcon from '@mui/icons-material/Luggage';
import PersonIcon from '@mui/icons-material/Person';
import { alpha, Box, Divider, SvgIcon, SxProps, Theme, Typography } from '@mui/material';
import { CertificatePayment } from '@one/api-models/lib/Admin/Sales/CertificatePayment';
import { PaymentOptionBase } from '@one/api-models/lib/Admin/Sales/PaymentOptionBase';
import { PurchaseSummary } from '@one/api-models/lib/Admin/Sales/PurchaseSummary';
import { PurchaseSummaryItem } from '@one/api-models/lib/Admin/Sales/PurchaseSummaryItem';
import { PurchaseSummaryItemType } from '@one/api-models/lib/Admin/Sales/PurchaseSummaryItemType';
import { Title } from '@one/api-models/lib/Admin/Sales/Title';
import { CarOrderItemSummary } from '@one/api-models/lib/Admin/Sales/Travel/Car/CarOrderItemSummary';
import { Driver } from '@one/api-models/lib/Admin/Sales/Travel/Car/Driver';

import { DataDisplay } from 'common';
import { SectionTitle } from 'common/SectionTitle';
import { useFormat } from 'hooks/useFormat';

import { EarnOptionsDisplay } from './EarnOptionsDisplay';
import { PaymentOptionsDisplay } from './PaymentOptionsDisplay';

interface Props {
  orderItem: CarOrderItemSummary;
  purchaseSummary?: PurchaseSummary;
}
const iconStyle: SxProps = { width: '0.8em', mr: 0.5 };

export const CarOrderItemDetails = ({ orderItem, purchaseSummary }: Props) => {
  const { productSummary, drivers, supplierVendorDetails, extraEquipmentPreferences, cancellationPolicy } = orderItem;
  const { passengerQuantity, baggageQuantity, doorCount, airCondition, vendor, fuelType, batteryProperties } =
    productSummary;
  const { formatDate } = useFormat();

  return (
    <>
      <SectionTitle title="Overview" variant="h6" />
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={{ xs: 1, md: 2 }}>
        <DataDisplay
          label="Name"
          value={
            <>
              <Typography>{productSummary.name}</Typography>
              <Typography>
                or similar {productSummary.size?.name} {productSummary.category.name}
              </Typography>
            </>
          }
        />
        <Divider orientation="vertical" flexItem />
        <DataDisplay
          label="Infos"
          value={
            <Box>
              {passengerQuantity != null && (
                <Typography display="inline-flex" alignItems="center" mr={1}>
                  <PersonIcon sx={iconStyle} />
                  {passengerQuantity}
                </Typography>
              )}
              {baggageQuantity != null && (
                <Typography display="inline-flex" alignItems="center" mr={1}>
                  <LuggageIcon sx={iconStyle} />
                  {baggageQuantity}
                </Typography>
              )}
              {doorCount != null && (
                <Typography display="inline-flex" alignItems="center" fontSize="0.9rem" mr={1}>
                  <Door sx={iconStyle} />
                  {doorCount}
                </Typography>
              )}
              {airCondition === true && (
                <Typography display="inline-flex" alignItems="center" fontSize="0.9rem">
                  <AcUnitIcon sx={iconStyle} />
                  A/C
                </Typography>
              )}
            </Box>
          }
        />
        {fuelType != null && (
          <>
            <Divider orientation="vertical" flexItem />
            <DataDisplay
              label="Fuel Type"
              value={
                <Box display="inline-flex" alignItems="center" mt="0.5">
                  {fuelType.name === 'Electric' ? <EvStationIcon /> : <LocalGasStationIcon />}
                  <Typography variant="subtitle2" fontWeight={500} lineHeight={1.2} sx={{ ml: 1 }}>
                    {fuelType.name}
                    {batteryProperties?.ranges != null && (
                      <Typography component="span" fontSize="0.875rem" lineHeight={1.2}>
                        {` (`}
                        {batteryProperties?.ranges?.map((range, index) => (
                          <>{`${range.distance} ${range.unit}
                                  ${
                                    batteryProperties?.ranges && index === batteryProperties?.ranges?.length - 1
                                      ? 'Range'
                                      : '/'
                                  }`}</>
                        ))}
                        {`)`}
                      </Typography>
                    )}
                  </Typography>
                </Box>
              }
            />
          </>
        )}
      </Box>

      <SectionTitle title="Vendor details" variant="h6" sx={{ mt: 3, mb: 1 }} />

      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={{ xs: 1, md: 2, lg: 4 }}>
        <DataDisplay label="Vendor" value={vendor.name} />

        {supplierVendorDetails?.details != null &&
          supplierVendorDetails.details.length > 0 &&
          supplierVendorDetails.details.map((detail) =>
            detail.value.startsWith('http') ? null : <DataDisplay label={`${detail.key}:`} value={detail.value} />,
          )}
      </Box>

      <SectionTitle title="Booking details" variant="h6" sx={{ mt: 3 }} />

      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={{ xs: 1, md: 2, lg: 4 }}>
        <DataDisplay
          label="Pick-up"
          value={
            <Box>
              <Typography variant="body2">{productSummary.itinerary.pickUpLocation.name}</Typography>
              {formatDate(productSummary.itinerary.pickUpDateTime, true, 'EEE, d MMM - h:mm a')}
            </Box>
          }
        />

        <DataDisplay
          label="Drop-off"
          value={
            <Box>
              <Typography variant="body2"> {productSummary.itinerary.dropOffLocation.name}</Typography>
              {formatDate(productSummary.itinerary.dropOffDateTime, true, 'EEE, d MMM - h:mm a')}
            </Box>
          }
        />
      </Box>

      <SectionTitle title="Drivers" variant="h6" sx={{ mt: 3, mb: 1 }} />
      {drivers?.map((driver: Driver, i: number) => (
        <Box key={`${driver.firstName}_${driver.lastName}_${i}`} pt={1}>
          <Typography
            variant="subtitle1"
            sx={{
              color: (theme) => alpha(theme.palette.common.black, 0.7),
              opacity: 1,
              fontWeight: 600,
            }}
          >
            {driver.title && Title[driver.title]} {driver.firstName} {driver.lastName}
          </Typography>
          {driver.email && <Typography>{driver.email}</Typography>}
          {driver.phone && <Typography>{driver.phone}</Typography>}
          {driver.dateOfBirth && <Typography>{formatDate(driver.dateOfBirth, false, 'PP')}</Typography>}
          {driver.flightNumber && <Typography component={Box}>Flight number: {driver.flightNumber}</Typography>}
        </Box>
      ))}

      {extraEquipmentPreferences?.length > 0 && (
        <>
          <SectionTitle title="Extras" variant="h6" sx={{ mt: 3, mb: 1 }} />
          {extraEquipmentPreferences.map((equipment) => (
            <Typography key={equipment.equipmentSupplierReference}>{equipment.name}</Typography>
          ))}
        </>
      )}

      {cancellationPolicy && cancellationPolicy?.cancellationFees?.length > 0 && (
        <>
          <SectionTitle title="Cancellation" variant="h6" sx={{ mt: 3, mb: 1 }} />
          {cancellationPolicy?.cancellationFees.map((fee) => {
            const start = formatDate(fee.startDateTime, false, 'PP');
            const end = formatDate(fee.endDateTime, false, 'PP');
            return <Typography key={`${start}-${end}`}>{fee.description}</Typography>;
          })}
        </>
      )}

      <PricingSummary purchaseSummary={purchaseSummary} orderItem={orderItem} />
    </>
  );
};

const PricingSummary: FC<Props> = ({ purchaseSummary, orderItem }: Props) => {
  const { formatCurrency: formatCurrencyFunc } = useFormat();

  const vehicle = orderItem?.productSummary;

  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };

  const subtotal = purchaseSummary?.summaryItems?.find(
    (item: PurchaseSummaryItem) => item.type === PurchaseSummaryItemType.Subtotal,
  )?.totals.fiatAmount;

  const payDuringPurchaseFlow = purchaseSummary?.summaryItems.find(
    (i: any) => i.type === PurchaseSummaryItemType.PayDuringPurchaseFlow,
  );

  const payDuringPickUp = purchaseSummary?.summaryItems.find((i) => i.type === PurchaseSummaryItemType.PayAtProperty);
  const taxes = purchaseSummary?.summaryItems.find((i) => i.type === PurchaseSummaryItemType.Taxes);

  const numberOfDays = vehicle?.itinerary.numberOfDays ?? 0;

  const payDuringPurchaseFlowAmount = payDuringPurchaseFlow?.totals.fiatAmount;

  const paymentOptions: PaymentOptionBase[] = payDuringPurchaseFlow
    ? payDuringPurchaseFlow.totals.paymentOptions.filter(
        (paymentOption: PaymentOptionBase) => paymentOption.$type === CertificatePayment.$type,
      )
    : [];

  const totalPickUp = payDuringPickUp?.totals.fiatAmount ?? {
    amount: 0,
    currency: payDuringPickUp?.totals.fiatAmount.currency,
    isEstimated: false,
  };

  return (
    <>
      <SectionTitle title="Pricing details" variant="h6" sx={{ mt: 3 }} />

      {paymentOptions.length > 0 && subtotal != null ? (
        <>
          {paymentOptions.map((paymentOption: PaymentOptionBase) => (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }} key={paymentOption.displayName}>
              <Typography>{paymentOption.displayName} certificate</Typography>
            </Box>
          ))}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography>+</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography>Additional charge</Typography>
            <Typography>{formatCurrency(subtotal.amount, subtotal.currency)}</Typography>
          </Box>
        </>
      ) : (
        <>
          {payDuringPurchaseFlowAmount && (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>Car rental fee</Typography>
                <Typography>
                  {formatCurrency(payDuringPurchaseFlowAmount.amount, payDuringPurchaseFlowAmount.currency)}
                </Typography>
              </Box>
              <Typography variant="body2" sx={{ mt: -0.5, pb: 0.5 }}>
                {numberOfDays > 1 ? `for ${numberOfDays} days` : `for 1 day`}
              </Typography>
            </>
          )}
        </>
      )}

      {taxes ? (
        <>
          {taxes.components.map((component) => (
            <Fragment key={`pricing-summary-equipment-${component.name}`}>
              <Box key={component.name} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>{component.name}</Typography>
                <Typography>{formatCurrency(component.value.amount, component.value.currency)}</Typography>
              </Box>
            </Fragment>
          ))}
        </>
      ) : (
        <Box sx={{ mb: 0.8 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 0.5 }}>
            <Typography>Taxes and fees</Typography>
            <Typography>included</Typography>
          </Box>
        </Box>
      )}

      {purchaseSummary && purchaseSummary?.totals?.earn?.length > 0 ? (
        <Box>
          <Divider sx={{ mt: 1, mb: 1 }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography>Rewards</Typography>
            <Box>
              <EarnOptionsDisplay options={purchaseSummary?.totals.earn} />
            </Box>
          </Box>
          <Divider sx={{ mt: 1, mb: 1 }} />
        </Box>
      ) : (
        <Divider sx={{ my: 1 }} />
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
        <Typography fontWeight={700} fontSize="1.2rem">
          Total
        </Typography>
        <Box>
          <Typography fontWeight={700} fontSize="1.2rem" textAlign="right">
            {formatCurrency(purchaseSummary?.totals.fiatAmount.amount, purchaseSummary?.totals.fiatAmount.currency)}
          </Typography>
          <PaymentOptionsDisplay options={purchaseSummary?.totals.paymentOptions} />
        </Box>
      </Box>

      {payDuringPurchaseFlowAmount && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
          <Typography fontWeight={600}>Total Paid</Typography>
          <Typography fontWeight={600} textAlign="right">
            {formatCurrency(payDuringPurchaseFlowAmount.amount, payDuringPurchaseFlowAmount.currency)}
          </Typography>
        </Box>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
        <Typography fontWeight={600}>Pay at pick-up</Typography>
        <Typography fontWeight={600}>{formatCurrency(totalPickUp.amount, totalPickUp.currency)}</Typography>
      </Box>
    </>
  );
};

interface IconProps {
  sx?: SxProps<Theme>;
}
const Door: FC<IconProps> = (props) => (
  <SvgIcon
    aria-hidden="true"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M10.41 2.62 2.6 10.86c-.38.4-.59.93-.59 1.49v7.54C2 21.06 2.9 22 4 22h16c1.1 0 2-.94 2-2.1V4.1c0-1.16-.9-2.1-2-2.1h-8.17c-.53 0-1.04.22-1.42.62zM20 11.47V4.11h-8L7 9.37v2.1h13zM15 15v2h4v-2h-4z"
      clipRule="evenodd"
    ></path>
  </SvgIcon>
);
