import { useDispatch } from 'react-redux';

import { GridFilterModel } from '@mui/x-data-grid-pro';
import { OrderFulfillmentStatus } from '@one/api-models/lib/OrderFulfillmentStatus';

import {
  selectAllBrandsEnabled,
  selectCurrentPage,
  selectFiltersModel,
  setAllBrandsEnabled,
  setCurrentPage,
  setFiltersModel,
} from 'store/slices/salesReservationListDataSlice';

import { Filters, OrderDataGrid } from 'modules/sales/components/OrderDataGrid';

const defaultOrderFilters: Filters = {
  orderStatuses: [],
  orderFulfillmentStatuses: [OrderFulfillmentStatus.Reserved],
  orderPaymentStatuses: [],
};

export const Reservations = () => {
  const dispatch = useDispatch();

  return (
    <OrderDataGrid
      title="Reservations"
      testId="Reservations"
      label="Show reservations for all brands"
      selectAllBrandsEnabled={selectAllBrandsEnabled}
      selectFiltersModel={selectFiltersModel}
      selectCurrentPage={selectCurrentPage}
      setCurrentPage={(page: number) => dispatch(setCurrentPage(page))}
      setAllBrandsEnabled={(enableAllBrands: boolean) => dispatch(setAllBrandsEnabled(enableAllBrands))}
      setFiltersModel={(filters: GridFilterModel) => dispatch(setFiltersModel(filters))}
      hideFulfillmentStatusFilter
      defaultOrderFilters={defaultOrderFilters}
    />
  );
};
