import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Autocomplete, TextField } from '@mui/material';

import { selectActiveAgency, selectAgencies, setActiveAgency } from 'store/slices/applicationDataSlice';

interface Agency {
  key: string;
  name: string;
}

type AgencyTypeAheadProps = {
  onChange: (value: Agency | null) => void;
  defaultValue: Agency | null;
  options: Agency[];
  testId: string;
  autoFocus?: boolean;
};

const AgencyTypeAhead: FC<AgencyTypeAheadProps> = ({ onChange, options, defaultValue, testId, autoFocus = false }) => {
  const [value, setValue] = useState<Agency | null>(defaultValue == null ? null : defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <Autocomplete
      options={options || []}
      noOptionsText="No available agencies"
      value={value}
      isOptionEqualToValue={(option: Agency, value: Agency) => option.key === value?.key}
      getOptionLabel={(option) => option.name}
      onChange={(_, value) => onChange(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select Agency"
          autoComplete="off"
          focused={autoFocus}
          size="small"
          sx={{
            width: '100%',
            pb: 3,
          }}
          onChange={(e) => {
            e.preventDefault();
          }}
          inputProps={{
            ...params.inputProps,
            'data-testid': `${testId}SelectAgencyTextInput`,
          }}
        />
      )}
    />
  );
};

type Props = {
  testId: string;
  autoFocus?: boolean;
};

export const AgencyHeaderSelect: FC<Props> = ({ autoFocus, testId }) => {
  const dispatch = useDispatch();
  const agencies = useSelector(selectAgencies);
  const activeAgency = useSelector(selectActiveAgency);

  const onChange = (agency: Agency | null) => {
    dispatch(setActiveAgency(agency));
  };

  return (
    <AgencyTypeAhead
      options={agencies}
      onChange={onChange}
      defaultValue={activeAgency}
      autoFocus={autoFocus}
      testId={testId}
    />
  );
};
