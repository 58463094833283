import { Box, Divider, Typography } from '@mui/material';
import { PaymentOptionBase } from '@one/api-models/lib/Admin/Sales/PaymentOptionBase';
import { PurchaseSummaryItemType } from '@one/api-models/lib/Admin/Sales/PurchaseSummaryItemType';
import { OrderItemSummary as MerchandiseOrderItemSummary } from '@one/api-models/lib/Admin/Sales/Retail/Merchandise/OrderItemSummary';
import { OrderItemSummary as WineOrderItemSummary } from '@one/api-models/lib/Admin/Sales/Retail/Wine/OrderItemSummary';

import { DataDisplay } from 'common';
import { SectionTitle } from 'common/SectionTitle';
import { useFormat } from 'hooks/useFormat';
import { getCertificateRedemptionPaymentOptions, getPurchaseSummaryItem } from 'utils/pricing';

import { EarnOptionsDisplay } from './EarnOptionsDisplay';
import { PaymentOptionsDisplay } from './PaymentOptionsDisplay';

interface Props {
  orderItem: WineOrderItemSummary | MerchandiseOrderItemSummary;
}

export const RetailOrderItemDetails = ({ orderItem }: Props) => {
  const { formatCurrency } = useFormat();

  const { productSummary, quantity } = orderItem;
  const wine = orderItem.$type === WineOrderItemSummary.$type ? (orderItem as WineOrderItemSummary) : undefined;

  const purchaseSummary = orderItem.purchaseSummary;
  const summaryItems = purchaseSummary.summaryItems;
  const subtotal = getPurchaseSummaryItem(summaryItems, PurchaseSummaryItemType.Subtotal)?.totals.fiatAmount;
  const certificateOptions = getCertificateRedemptionPaymentOptions(purchaseSummary);

  return (
    <>
      <SectionTitle title="Overview" variant="h6" />
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={{ xs: 1, md: 2, lg: 4 }}>
        <DataDisplay label="Name" value={productSummary.name} />
        <Divider orientation="vertical" flexItem /> <DataDisplay label="Quantity" value={quantity} />
        {orderItem.$type === WineOrderItemSummary.$type && wine?.productSummary.volume && (
          <>
            <Divider orientation="vertical" flexItem />
            <DataDisplay label="Volume" value={wine?.productSummary.volume} />
          </>
        )}
      </Box>

      <Box sx={{ my: 4 }}>
        <SectionTitle title="Pricing details" variant="h6" />
        {certificateOptions.length > 0 && subtotal != null ? (
          <>
            {certificateOptions.map((paymentOption: PaymentOptionBase) => (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }} key={paymentOption.displayName}>
                <Typography>{paymentOption.displayName} certificate</Typography>
              </Box>
            ))}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
              <Typography>+</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
              <Typography>Additional charge</Typography>
              <Typography>{formatCurrency(subtotal.amount, subtotal.currency, 2)}</Typography>
            </Box>
          </>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography>Subtotal</Typography>
            <Typography>{formatCurrency(subtotal?.amount, subtotal?.currency, 2)}</Typography>
          </Box>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'end' }}>
          <PaymentOptionsDisplay options={orderItem?.purchaseSummary.totals.paymentOptions} fontSize="0.875rem" />
        </Box>
        {orderItem?.purchaseSummary?.totals?.earn.length > 0 && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
            <Typography>Earned Rewards</Typography>
            <EarnOptionsDisplay options={orderItem?.purchaseSummary?.totals?.earn} />
          </Box>
        )}
      </Box>
    </>
  );
};
