import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import { SailingContent } from '@one/api-models/lib/Admin/Products/Travel/Cruise/SailingContent';
import { UpdateSailing } from '@one/api-models/lib/Admin/Products/Travel/Cruise/UpdateSailing';
import { UpdateSailingDetails } from '@one/api-models/lib/Admin/Products/Travel/Cruise/UpdateSailingDetails';

import { ActionButton } from 'common/ActionButton';
import { useFormat } from 'hooks/useFormat';

import { FieldBox } from '../FieldBox';
import { ProductBaseFields } from '../ProductBaseFields';

import { DetailsTabProps, FormProps } from './DetailsBase';

type SailingDetailsForm = {
  name?: string;
  displayName?: string;
  supplierReferences?: { reference?: string }[];
};

const validationSchema: yup.SchemaOf<SailingDetailsForm> = yup.object().shape({
  name: yup.string().trim().matches(/.{3,}/, {
    excludeEmptyString: true,
    message: 'Use at least 3 characters.',
  }),
  displayName: yup.string().trim().matches(/.{3,}/, {
    excludeEmptyString: true,
    message: 'Use at least 3 characters.',
  }),
  supplierReferences: yup.array().of(
    yup.object().shape({
      reference: yup.string().trim(),
    }),
  ),
});

export const SailingDetailsTab = ({
  product: productBase,
  onSave,
  isLoading,
  onFormDirty,
  suppliers,
  testId,
}: DetailsTabProps): React.ReactNode => {
  const { formatDate } = useFormat();
  const product = productBase as SailingContent;

  const defaultValues = (product: SailingContent) => ({
    name: product.name,
    displayName: product.displayName,
    supplierReferences: product.supplierReferences,
  });

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
  } = useForm<UpdateSailingDetails>({
    mode: 'onBlur',
    defaultValues: defaultValues(product),
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    onFormDirty(isDirty);
  }, [onFormDirty, isDirty]);

  useEffect(() => {
    reset(defaultValues(product));
  }, [reset, product]);

  const formProps = { register, errors, control, getValues } as unknown as FormProps;

  const saveHandler = (data: UpdateSailingDetails) => {
    const details = data as any;
    delete details.$type;
    if (onSave) {
      const req = {
        $type: UpdateSailing.$Type,
        productId: product.id,
        cultureCode: product.cultureCode,
        details: {
          $type: UpdateSailingDetails.$Type,
          ...details,
        },
      } as UpdateSailing;

      onSave(req);
    }
  };

  return (
    <Box sx={{ mt: 1, width: '100%' }}>
      <form onSubmit={handleSubmit(saveHandler)} autoComplete="off">
        <Grid container direction="row" alignItems="stretch">
          <Grid item xs={12} sx={{ mb: 3 }}>
            <ProductBaseFields formProps={formProps} suppliers={suppliers} isLoading={isLoading} testId={testId} />
          </Grid>

          <Grid item xs={12}>
            <FieldBox>
              <Grid container>
                <Grid container item xs={6}>
                  <List sx={{ width: '100%' }}>
                    <ListItem>
                      <ListItemText
                        primary="Ship"
                        primaryTypographyProps={{ variant: 'subtitle2' }}
                        secondary={
                          <Typography variant="body1">{product?.shipName ? product.shipName : 'N/A'}</Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Destination"
                        primaryTypographyProps={{ variant: 'subtitle2' }}
                        secondary={
                          <Typography variant="body1">{product?.destination ? product.destination : 'N/A'}</Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={6}>
                  <List sx={{ width: '100%' }}>
                    <ListItem>
                      <ListItemText
                        primary="Sailing date"
                        primaryTypographyProps={{ variant: 'subtitle2' }}
                        secondary={
                          <Typography variant="body1">
                            {product?.sailDate ? formatDate(product.sailDate, true, 'dd MMM yyyy') : 'N/A'}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Number of Days"
                        primaryTypographyProps={{ variant: 'subtitle2' }}
                        secondary={<Typography variant="body1">{product?.numberOfDays}</Typography>}
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </FieldBox>
          </Grid>

          <Grid
            item
            sx={{ pt: 3, pr: 3 }}
            xs={12}
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="baseline"
          >
            <Grid item>
              <ActionButton icon="edit" loading={isLoading} type="submit" disabled={!isDirty} testId={`${testId}Save`}>
                SAVE
              </ActionButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
