import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import debounce from 'lodash.debounce';

import SearchIcon from '@mui/icons-material/Search';
import { Box, ButtonProps, InputAdornment, Menu, MenuItem, styled, Typography } from '@mui/material';
import { Brand } from '@one/api-models/lib/Admin/Common/Brand';
import { Partner } from '@one/api-models/lib/Admin/Common/Partner';

import { selectActiveBrand, selectActivePartner, selectPartners } from 'store/slices/applicationDataSlice';

import { ReactComponent as ChevronDown } from 'assets/icons/chevronDown.svg';
import { ReactComponent as ChevronUp } from 'assets/icons/chevronUp.svg';
import { FlagIcon } from 'common/FlagIcon';
import { SearchTextField } from 'common/layout/components/AppSearchAutocomplete';
import { useAppHelpers } from 'hooks/useAppHelpers';
import { Button } from 'styled';
import { getBrandCountryCode, getPartnerLogoPath } from 'utils/getBrandDetails';

const buttonBorderColor = '#e8ebf0';
const chevronColor = '#9CA3AF';

interface Props {
  disabled?: boolean;
  testId: string;
}

export const PartnerHeaderSelect = ({ disabled, testId }: Props) => {
  const { setActivePartner } = useAppHelpers();

  const partners = useSelector(selectPartners);
  const activePartner = useSelector(selectActivePartner);
  const testIdPrefix = `${testId}PartnersDropdown`;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filteredPartners, setFilteredPartners] = useState(partners ?? []);
  const [searchValue, setSearchValue] = useState<string>('');

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!partners) return;

    if (!searchValue) {
      setFilteredPartners(partners);
      return;
    }

    const searchValueLL = searchValue.toLowerCase();
    const filtered = partners.filter((partner) => {
      if ([partner.name, partner.key].join(' ').toLowerCase().includes(searchValueLL)) return true;

      return partner.brands.some(
        (brand: Brand) =>
          brand.name.toLowerCase().includes(searchValueLL) || brand.key.toLowerCase().includes(searchValueLL),
      );
    });

    setFilteredPartners(filtered);
  }, [searchValue, partners]);

  useEffect(() => {
    if (!open) {
      setSearchValue('');
      setFilteredPartners(partners ?? []);
    }
  }, [open, partners]);

  const changeHandler = (selectedPartner: Partner) => {
    if (selectedPartner) setActivePartner(selectedPartner, selectedPartner.brands[0]);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleOnChange = debounce((value: string) => {
    setSearchValue(value);
  }, 250);

  return (
    <>
      <SwitcherButton
        id="partner-button"
        aria-controls={open ? 'partner-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disableRipple
        variant="outlined"
        customColor={buttonBorderColor}
        startIcon={<img src={getPartnerLogoPath(activePartner?.key)} width="35" alt="logo" />}
        endIcon={
          disabled ? null : open ? (
            <ChevronUp height="12px" color={chevronColor} />
          ) : (
            <ChevronDown height="12px" color={chevronColor} />
          )
        }
        disabled={disabled}
        data-testid={`${testIdPrefix}Button`}
      >
        <Typography
          sx={(theme) => ({
            display: 'block',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            fontSize: '0.875rem',
            color: disabled ? theme.palette.text.secondary : theme.palette.text.primary,
          })}
        >
          {`${activePartner?.name}` || 'Select Partner'}
        </Typography>
      </SwitcherButton>
      <Menu
        id="partner-menu"
        aria-labelledby="partner-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiList-root': {
            paddingBottom: '0px',
            paddingTop: '0px',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <SearchField handleOnChange={handleOnChange} />

        {filteredPartners?.map((partner) => {
          return (
            <Box key={partner.key}>
              <MenuItem
                sx={{
                  maxHeight: '45px',
                  overflow: 'hidden',
                  minWidth: '320px',
                  '&:hover': {
                    backgroundColor: '#e0e0e0',
                  },
                  borderTop: '1px solid #e0e0e0',
                }}
                onClick={() => {
                  changeHandler(partner);
                  handleClose();
                }}
                data-testid={`${testIdPrefix}MenuItem`}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        pr: 2,
                      }}
                    >
                      <img src={getPartnerLogoPath(partner.key)} alt="logo" width="40" />
                    </Box>
                    <Box>{partner.name}</Box>
                  </Box>
                </Box>
              </MenuItem>
            </Box>
          );
        })}

        {!filteredPartners.length ? <NoResultMessage /> : null}
      </Menu>
    </>
  );
};

export const BrandHeaderSelect = ({ disabled, testId }: Props) => {
  const { setActiveBrand } = useAppHelpers();

  const activePartner = useSelector(selectActivePartner);
  const activeBrand = useSelector(selectActiveBrand);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredBrands, setFilteredBrands] = useState(activePartner?.brands ?? []);

  const open = Boolean(anchorEl);
  const testIdPrefix = `${testId}BrandDropdown`;

  useEffect(() => {
    if (!activePartner?.brands) return;

    if (!searchValue) {
      setFilteredBrands(activePartner?.brands);
      return;
    }

    const filtered = activePartner?.brands.filter((brand) => {
      return [brand.name, brand.key].join(' ').toLowerCase().includes(searchValue.toLowerCase());
    });

    setFilteredBrands(filtered);
  }, [searchValue, activePartner?.brands]);

  useEffect(() => {
    if (!open) {
      setSearchValue('');
      setFilteredBrands(activePartner?.brands ?? []);
    }
  }, [open, activePartner?.brands]);

  const handleOnChange = debounce((value: string) => {
    setSearchValue(value);
  }, 250);

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <SwitcherButton
        id="brand-button"
        aria-controls={open ? 'brand-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        variant="outlined"
        customColor={buttonBorderColor}
        disableRipple
        startIcon={
          <Box sx={{ width: '25px' }}>
            <FlagIcon countryValue={getBrandCountryCode(activeBrand?.key)} />
          </Box>
        }
        endIcon={
          disabled ? null : open ? (
            <ChevronUp height="12px" color={chevronColor} />
          ) : (
            <ChevronDown height="12px" color={chevronColor} />
          )
        }
        disabled={disabled}
        data-testid={`${testIdPrefix}Button`}
      >
        <Typography
          sx={(theme) => ({
            display: 'block',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            fontSize: '0.875rem',
            color: disabled ? theme.palette.text.secondary : theme.palette.text.primary,
          })}
        >
          {`${activeBrand?.name}` || 'Select Brand'}
        </Typography>
      </SwitcherButton>

      <Menu
        id="brand-menu"
        aria-labelledby="brand-button"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        sx={{
          '& .MuiList-root': {
            paddingBottom: '0px',
            paddingTop: '0px',
          },
        }}
      >
        <SearchField handleOnChange={handleOnChange} />

        {filteredBrands.map((brand) => {
          return (
            <Box key={brand.key}>
              <MenuItem
                sx={{
                  maxHeight: '45px',
                  overflow: 'hidden',
                  minWidth: '320px',
                  '&:hover': {
                    backgroundColor: '#e0e0e0',
                  },
                  borderTop: '1px solid #e0e0e0',
                }}
                data-testid={`${testIdPrefix}MenuItem`}
                onClick={() => {
                  setActiveBrand(brand);
                  onClose();
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        mr: 2,
                        width: '25px',
                      }}
                    >
                      <FlagIcon countryValue={getBrandCountryCode(brand?.key)} />
                    </Box>
                    <Typography>{brand.name}</Typography>
                  </Box>
                </Box>
              </MenuItem>
            </Box>
          );
        })}

        {!filteredBrands.length ? <NoResultMessage /> : null}
      </Menu>
    </>
  );
};

const SearchField = React.forwardRef(({ handleOnChange }: { handleOnChange: (value: string) => void }, ref) => {
  return (
    <SearchTextField
      size="small"
      autoFocus
      sx={{ width: '100%' }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
      }}
      placeholder="Search..."
      autoComplete="off"
      onChange={(e) => {
        e.preventDefault();
        handleOnChange(e.target.value);
      }}
    />
  );
});

const NoResultMessage = () => {
  return (
    <Typography
      sx={{
        height: '42px',
        overflow: 'hidden',
        minWidth: '320px',
        p: 1,
        borderTop: '1px solid #e0e0e0',
      }}
    >
      No results found
    </Typography>
  );
};

const SwitcherButton = styled(
  Button,
  {},
)<ButtonProps>(({ theme }) => ({
  textTransform: 'none',
  width: '100%',
  color: 'black',
  lineHeight: '1.5em',
  justifyContent: 'left',
  ':focus': {
    color: theme.palette.text.primary,
  },
  '&.Mui-disabled': {
    opacity: 0.5,
  },
  '.MuiButton-endIcon': {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
  },
  '&.MuiButtonBase-root': {
    boxShadow: 'none',
  },
}));
