import { FC } from 'react';

import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { ExperienceOrderItemSummary } from '@one/api-models/lib/Admin/Sales/Travel/Experience/ExperienceOrderItemSummary';
import { BookingQuestionAnswer } from '@one/api-models/lib/Sales/Travel/Experience/BookingQuestionAnswer';
import { BookingQuestionType } from '@one/api-models/lib/Sales/Travel/Experience/BookingQuestionType';

import { SectionTitle } from 'common/SectionTitle';
import { mapTravelersPerAgeBand } from 'utils/mapTravelersPerAgeBand';

interface TravelersDetailsProps {
  orderItem: ExperienceOrderItemSummary;
}

const getAnswer = (
  type: string,
  bookingQuestionAnswers: BookingQuestionAnswer[],
): BookingQuestionAnswer | undefined => {
  const answerObj = bookingQuestionAnswers.find((q) => q.type === type);
  return answerObj || undefined;
};

export const TravelersDetails: FC<TravelersDetailsProps> = ({ orderItem }) => {
  const travelers = orderItem.travelersBookingQuestionAnswers;
  const primaryTraveler = orderItem.travelers[0];

  return (
    <>
      {(!!travelers.length || !!primaryTraveler.firstName) && (
        <>
          <Divider
            sx={{
              mb: 2,
            }}
          />
          <SectionTitle title="Travelers" variant="h6" />
        </>
      )}
      <Box>
        {!!travelers.length
          ? travelers.map((traveler) => (
              <div key={traveler.travelerIndex} style={{ marginBottom: '20px' }}>
                <Typography variant="h6" mt={1}>
                  {`Traveler ${traveler.travelerIndex}
                 ${getAnswer(BookingQuestionType.AgeBand, traveler.bookingQuestionAnswers)?.answer || ''}`}
                </Typography>
                <TravelerInfo bookingQuestionAnswers={traveler.bookingQuestionAnswers} />
              </div>
            ))
          : !!primaryTraveler.firstName && (
              <>
                <Stack>
                  <Typography variant="h6">Total Travelers:</Typography>
                  <Typography variant="body1" sx={(theme) => ({ color: theme.customPalette.text.primary })}>
                    {mapTravelersPerAgeBand(orderItem.travelers)}
                  </Typography>
                  <Divider sx={{ mt: 2 }} />
                </Stack>
                <Typography variant="h6" mt={1}>
                  Primary Traveler
                </Typography>
                <TravelerInfo
                  bookingQuestionAnswers={[
                    { type: BookingQuestionType.FullNameFirst, answer: primaryTraveler.firstName },
                    { type: BookingQuestionType.FullNameLast, answer: primaryTraveler.lastName },
                  ]}
                />
              </>
            )}
      </Box>
    </>
  );
};

interface TravelerInfoProps {
  bookingQuestionAnswers: BookingQuestionAnswer[];
}

const TravelerInfo: FC<TravelerInfoProps> = ({ bookingQuestionAnswers }) => {
  return (
    <Grid container>
      {travelerBookingQuestions['personal-info'].map((field, index) => {
        const value = getAnswer(field.type, bookingQuestionAnswers);

        if (value) {
          return (
            <Grid item xs={6} key={index}>
              {field.customLabel && <Typography variant="caption">{field.customLabel}:</Typography>}
              <Typography
                fontWeight={600}
                sx={{ opacity: 1, color: (theme) => alpha(theme.palette.common.black, 0.7) }}
              >
                {value.answer}
                {((field.type === BookingQuestionType.Height && value.unit !== 'ft') ||
                  field.type === BookingQuestionType.Weight) &&
                  value.unit}
              </Typography>
            </Grid>
          );
        }
        return null;
      })}
    </Grid>
  );
};

interface StructuredBookingQuestions {
  type: BookingQuestionType;
  customLabel?: string;
}
const travelerBookingQuestions: Record<string, StructuredBookingQuestions[]> = {
  'primary-traveler': [
    { type: BookingQuestionType.FullNameFirst, customLabel: 'First name' },
    { type: BookingQuestionType.FullNameLast, customLabel: 'Last name' },
  ],
  'personal-info': [
    { type: BookingQuestionType.FullNameFirst, customLabel: 'First name' },
    { type: BookingQuestionType.FullNameLast, customLabel: 'Last name' },
    { type: BookingQuestionType.DateOfBirth, customLabel: 'Date of birth' },
    { type: BookingQuestionType.PassportNumber, customLabel: 'Passport number' },
    { type: BookingQuestionType.PassportNationality, customLabel: 'Passport nationality' },
    { type: BookingQuestionType.PassportExpiry, customLabel: 'Passport expiry date' },
    { type: BookingQuestionType.Height, customLabel: 'Height' },
    { type: BookingQuestionType.Weight, customLabel: 'Weight' },
  ],
};
