import { useEffect } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Grid } from '@mui/material';
import { SearchMemberRequest as SearchCriteria } from '@one/api-models/lib/Admin/Members/Request/SearchMemberRequest';

import { selectActiveBrand, selectActivePartner } from 'store/slices/applicationDataSlice';

import ControlledDatePicker from 'common/inputs/labeledFields/ControlledDatePicker';
import ControlledTextField from 'common/inputs/labeledFields/ControlledTextField';
import { Button } from 'styled';

interface CustomerSearchFormProps {
  onSearchCriteriaChange: (criteria: SearchCriteria) => void;
  allowBrandSpecific?: boolean;
  onFormClear?: () => void;
  defaultSearchCriteria?: SearchCriteria;
  testId: string;
}

type SearchForm = {
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  oneMemberKey?: string;
  partnerMemberKey?: string;
  dateOfBirth?: Date | null;
  partnerLoyaltyId?: string;
};

const partnerValidationSchema: yup.SchemaOf<SearchForm> = yup.object().shape(
  {
    firstName: yup
      .string()
      .trim()
      .when(['partnerMemberKey', 'partnerLoyaltyId', 'oneMemberKey'], {
        is: null || undefined || '',
        then: yup.string().required('First name is required'),
      })
      .matches(/.{2,}/, {
        excludeEmptyString: true,
        message: 'Use at least 2 characters',
      }),
    lastName: yup
      .string()
      .trim()
      .when(['partnerMemberKey', 'partnerLoyaltyId', 'oneMemberKey'], {
        is: null || undefined || '',
        then: yup.string().required('Last name is required'),
      })
      .matches(/.{2,}/, {
        excludeEmptyString: true,
        message: 'Use at least 2 characters',
      }),
    email: yup.string().trim().matches(/.{3,}/, {
      excludeEmptyString: true,
      message: 'Use at least 3 characters',
    }),
    phone: yup.string().trim().matches(/.{3,}/, {
      excludeEmptyString: true,
      message: 'Use at least 3 characters',
    }),
    oneMemberKey: yup.string().trim().matches(/^\S+$/, {
      excludeEmptyString: true,
      message: 'White spaces not allowed',
    }),
    partnerMemberKey: yup.string().trim().matches(/^\S+$/, {
      excludeEmptyString: true,
      message: 'White spaces not allowed',
    }),
    dateOfBirth: yup
      .date()
      .when(['partnerMemberKey', 'partnerLoyaltyId', 'oneMemberKey'], {
        is: null || undefined || '',
        then: yup.date().nullable().required('Date of birth is required').typeError('Invalid date'),
      })
      .typeError('Invalid date')
      .nullable(),
    partnerLoyaltyId: yup.string().trim().matches(/^\S+$/, {
      excludeEmptyString: true,
      message: 'White spaces not allowed',
    }),
  },
  [
    ['firstName', 'partnerMemberKey'],
    ['lastName', 'partnerMemberKey'],
    ['dateOfBirth', 'partnerMemberKey'],
    ['firstName', 'partnerLoyaltyId'],
    ['lastName', 'partnerLoyaltyId'],
    ['dateOfBirth', 'partnerLoyaltyId'],
    ['firstName', 'oneMemberKey'],
    ['lastName', 'oneMemberKey'],
    ['dateOfBirth', 'oneMemberKey'],
  ],
);

const validationSchema: yup.SchemaOf<SearchForm> = yup.object().shape({
  firstName: yup.string().trim().matches(/.{2,}/, {
    excludeEmptyString: true,
    message: 'Use at least 2 characters.',
  }),
  lastName: yup.string().trim().matches(/.{2,}/, {
    excludeEmptyString: true,
    message: 'Use at least 2 characters.',
  }),
  email: yup.string().trim().matches(/.{3,}/, {
    excludeEmptyString: true,
    message: 'Use at least 3 characters.',
  }),
  phone: yup.string().trim().matches(/.{3,}/, {
    excludeEmptyString: true,
    message: 'Use at least 3 characters.',
  }),
  oneMemberKey: yup.string().trim().matches(/^\S+$/, {
    excludeEmptyString: true,
    message: 'White spaces not allowed',
  }),
  partnerMemberKey: yup.string().trim().matches(/^\S+$/, {
    excludeEmptyString: true,
    message: 'White spaces not allowed',
  }),
  dateOfBirth: yup.date().typeError('Invalid date.').nullable(),
  partnerLoyaltyId: yup.string().trim().matches(/^\S+$/, {
    excludeEmptyString: true,
    message: 'White spaces not allowed',
  }),
});

export const CustomerSearchForm = ({
  allowBrandSpecific,
  onSearchCriteriaChange,
  onFormClear,
  defaultSearchCriteria,
  testId,
}: CustomerSearchFormProps) => {
  const activePartner = useSelector(selectActivePartner);
  const activeBrand = useSelector(selectActiveBrand);
  const mdGridSize = allowBrandSpecific ? 3 : 4;
  const testIdPrefix = `${testId}SearchForm`;

  const defaultValues = () => ({
    firstName: defaultSearchCriteria?.firstName ?? '',
    lastName: defaultSearchCriteria?.lastName ?? '',
    email: defaultSearchCriteria?.email ?? '',
    phone: defaultSearchCriteria?.phone ?? '',
    oneMemberKey: defaultSearchCriteria?.oneMemberKey ?? '',
    partnerMemberKey: defaultSearchCriteria?.partnerMemberKey ?? '',
    dateOfBirth: defaultSearchCriteria?.dateOfBirth ?? null,
    partnerLoyaltyId: defaultSearchCriteria?.partnerLoyaltyId ?? '',
  });

  const { control, handleSubmit, formState, reset } = useForm<SearchForm>({
    mode: 'onBlur',
    defaultValues: defaultValues(),
    resolver: yupResolver(allowBrandSpecific ? partnerValidationSchema : validationSchema),
  });

  const { errors } = formState;

  useEffect(() => {
    reset(defaultValues());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBrand, defaultSearchCriteria]);

  const handleSearchFormSubmit = (data: SearchForm) => {
    const values = Object.values(data).filter((v) => !!v);
    const isValid = allowBrandSpecific ? true : values && values.length > 0;

    if (isValid) {
      const request: SearchCriteria = {
        brandKey: activeBrand?.key ?? '',
        partnerKey: activePartner?.key ?? '',
        listingCriteria: defaultSearchCriteria?.listingCriteria ?? {},
        cultureCode: defaultSearchCriteria?.cultureCode,
        ...data,
        dateOfBirth: data.dateOfBirth || undefined,
      };

      onSearchCriteriaChange(request);
    }
  };

  const handleFormClear = () => {
    onFormClear && onFormClear();
    reset(defaultValues());
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(handleSearchFormSubmit)} autoComplete="off">
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6} md={mdGridSize}>
            <ControlledTextField
              control={control as unknown as Control<FieldValues, object>}
              name="firstName"
              fullWidth
              label="First Name"
              isAutoFocused
              error={errors.firstName != null}
              helperText={errors.firstName?.message}
              testId={`${testIdPrefix}FirstName`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={mdGridSize}>
            <ControlledTextField
              control={control as unknown as Control<FieldValues, object>}
              name="lastName"
              fullWidth
              label="Last Name"
              error={errors.lastName != null}
              helperText={errors.lastName?.message}
              testId={`${testIdPrefix}LastName`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={mdGridSize}>
            <ControlledDatePicker
              control={control as unknown as Control<FieldValues, object>}
              name="dateOfBirth"
              label="Date of Birth"
              placeholder="mm/dd/yyyy"
              disableFuture
              openTo="year"
              error={errors?.dateOfBirth?.message != null}
              helperText={errors?.dateOfBirth?.message}
              fullWidth
              testId={`${testIdPrefix}DateOfBirth`}
            />
          </Grid>
          {!allowBrandSpecific && (
            <Grid item xs={12} sm={6} md={mdGridSize}>
              <ControlledTextField
                control={control as unknown as Control<FieldValues, object>}
                name="phone"
                fullWidth
                label="Phone"
                error={errors.phone != null}
                helperText={errors.phone?.message}
                testId={`${testIdPrefix}Phone`}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={6} md={mdGridSize}>
            <ControlledTextField
              control={control as unknown as Control<FieldValues, object>}
              name="email"
              fullWidth
              label="Email"
              error={errors.email != null}
              helperText={errors.email?.message}
              testId={`${testIdPrefix}Email`}
            />
          </Grid>
        </Grid>

        <Grid container direction="row" sx={{ mb: 3 }} justifyContent="flex-start" alignItems="flex-start" spacing={2}>
          <Grid item xs={12} sm={6} md={mdGridSize}>
            <ControlledTextField
              control={control as unknown as Control<FieldValues, object>}
              name="oneMemberKey"
              fullWidth
              label="ONE INTERNAL Customer Id"
              error={errors.oneMemberKey != null}
              helperText={errors.oneMemberKey?.message}
              testId={`${testIdPrefix}OneInternalCustomerId`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={mdGridSize}>
            <ControlledTextField
              control={control as unknown as Control<FieldValues, object>}
              name="partnerMemberKey"
              fullWidth
              label="PARTNER Customer Id"
              error={errors.partnerMemberKey != null}
              helperText={errors.partnerMemberKey?.message}
              testId={`${testIdPrefix}PartnerCustomerId`}
            />
          </Grid>
          {allowBrandSpecific && (
            <Grid item xs={12} sm={6} md={mdGridSize}>
              <ControlledTextField
                control={control as unknown as Control<FieldValues, object>}
                name="partnerLoyaltyId"
                fullWidth
                label="CCN"
                error={errors.partnerLoyaltyId != null}
                helperText={errors.partnerLoyaltyId?.message}
                testId={`${testIdPrefix}CCN`}
              />
            </Grid>
          )}
        </Grid>
        <Button
          type="submit"
          variant="contained"
          tabIndex={7}
          startIcon={<SearchIcon />}
          data-testid={`${testIdPrefix}Search`}
        >
          SEARCH
        </Button>
        {onFormClear && (
          <Button
            type="reset"
            variant="outlined"
            tabIndex={8}
            onClick={handleFormClear}
            sx={{ ml: 1 }}
            data-testid={`${testIdPrefix}ClearButton`}
          >
            Clear
          </Button>
        )}
      </form>
    </Box>
  );
};
