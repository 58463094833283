import { useEffect, useRef, useState } from 'react';
import { useIsMutating, useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addMinutes, format } from 'date-fns';

import EditIcon from '@mui/icons-material/Edit';
import { Button, Link, Paper } from '@mui/material';
import { Box } from '@mui/system';
import { PromoCode } from '@one/api-models/lib/Campaign/PromoCode/PromoCode';
import { ExportRequest } from '@one/api-models/lib/Campaign/PromoCode/Request/ExportRequest';
import { ListRequest } from '@one/api-models/lib/Campaign/PromoCode/Request/ListRequest';
import { ListResponse } from '@one/api-models/lib/Campaign/PromoCode/Response/ListResponse';
import { PromoCodeDefinition } from '@one/api-models/lib/Campaign/PromoCodeDefinition/PromoCodeDefinition';
import { RetrieveRequest } from '@one/api-models/lib/Campaign/PromoCodeDefinition/Request/RetrieveRequest';
import { RetrieveResponse } from '@one/api-models/lib/Campaign/PromoCodeDefinition/Response/RetrieveResponse';

import { selectActiveBrand } from 'store/slices/applicationDataSlice';

import { ApiError, FileResponse } from 'apiAccess/api-client';
import { PageHeader } from 'common/layout/components/PageHeader';
import { LoadingScreen } from 'common/LoadingScreen';
import { setBreadcrumbsInStorage } from 'config/sessionStorage';
import { useApiHelpers } from 'hooks/useApiHelpers';
import { useToastMessage } from 'hooks/useToastMessage';
import { RouteKey } from 'models/RouteKey';

import { PromoCodeDefinitionDetails } from './components/PromoCodeDefinitionDetails';
import { PromoCodeDefinitionDialog } from './components/PromoCodeDefinitionDialog';

const defaultPromoCodesPageSize = 25;

export const PromoCodeDefinitionDetailView = () => {
  const { id } = useParams();
  const { api } = useApiHelpers();
  const { apiErrorHandler } = useToastMessage();
  const [promoCodeDefinitionDetails, setPromoCodeDefinitionDetails] = useState<PromoCodeDefinition | undefined>(
    undefined,
  );
  const isLoadingPromoCodeDefinitionDetails = useIsMutating({ mutationKey: 'retrievePromoCodeDefMutation' }) > 0;
  const isLoadingPromoCodes = useIsMutating({ mutationKey: 'listPromoCodesMutation' }) > 0;
  const isExportingPromoCodes = useIsMutating({ mutationKey: 'exportPromoCodesMutation' }) > 0;
  const [isEditPromoCodeDefinitionDialogOpen, setIsEditPromoCodeDefinitionDialogOpen] = useState<boolean>(false);
  const [promoCodesPage, setPromoCodesPage] = useState(0);
  const [promoCodesTotalRowsCount, setPromoCodesTotalRowsCount] = useState(0);
  const [promoCodes, setPromoCodes] = useState<PromoCode[] | undefined>(undefined);
  const [isGeneratePromoCodeDialogOpen, setIsGeneratePromoCodeDialogOpen] = useState<boolean>(false);
  const activeBrand = useSelector(selectActiveBrand);
  const brandKey = activeBrand?.key || '';
  const promoCodeDefinitionId = id !== '' && id != null ? Number(id) : 0;
  const { redirectUrl } = useParams<{ redirectUrl: string }>() as { redirectUrl: string };
  const linkRef = useRef<HTMLAnchorElement>(null);
  const testIdPrefix = 'PromoCodeDefinitionDetails';

  useEffect(() => {
    // initial PromoCodeDef load
    retrievePromoCodeDefMutation.mutateAsync({
      $Type: RetrieveRequest.$type,
      id: promoCodeDefinitionId,
      brandKey,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    listPromoCodesMutation.mutateAsync({
      brandKey,
      promoCodeDefinitionIds: [promoCodeDefinitionId],
      listHandling: {
        skip: promoCodesPage * defaultPromoCodesPageSize,
        take: defaultPromoCodesPageSize,
        ascending: true,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoCodesPage]);

  const listPromoCodesMutation = useMutation<ListResponse, ApiError, ListRequest, unknown>(
    (request) => api.promoCode.load(request),
    {
      mutationKey: 'listPromoCodesMutation',
      onSuccess: (value: ListResponse) => {
        if (value) {
          setPromoCodes(value.promoCodes.items);
          setPromoCodesTotalRowsCount(value.promoCodes.itemCount);
        }
      },
      onError: apiErrorHandler,
    },
  );

  const retrievePromoCodeDefMutation = useMutation<RetrieveResponse, ApiError, RetrieveRequest, unknown>(
    (request) => api.promoCodeDefinition.retrieve(request),
    {
      mutationKey: 'retrievePromoCodeDefMutation',
      onSuccess: (value: RetrieveResponse) => {
        if (value) {
          const promoCodeDef = value.promoCodeDefinition as PromoCodeDefinition;
          setPromoCodeDefinitionDetails(promoCodeDef);

          setBreadcrumbsInStorage(RouteKey.PromoCodeDefinitionDetails, {
            id: promoCodeDef.id,
            label: promoCodeDef.name,
          });

          if (redirectUrl) {
            setBreadcrumbsInStorage(RouteKey.PromotionPromoCodeDefinitionDetails, {
              id: promoCodeDef.id,
              label: promoCodeDef.name,
            });
          }
        }
      },
      onError: apiErrorHandler,
    },
  );

  const exportPromoCodesMutation = useMutation<FileResponse, ApiError, ExportRequest, unknown>(
    async (request: ExportRequest) => {
      return await api.promoCode.export(request);
    },
    {
      mutationKey: 'exportPromoCodesMutation',
      onSuccess: async (response: FileResponse, request: ExportRequest) => {
        if (linkRef?.current == null) return;

        const fileName =
          response.fileName ??
          `PromoCodes_${brandKey}_${promoCodeDefinitionId}_UTC${format(
            addMinutes(new Date(), new Date().getTimezoneOffset()),
            'yyyy-MM-dd_HH;mm',
          )}.csv`;

        linkRef.current.href = window.URL.createObjectURL(response.blob);
        linkRef.current.setAttribute('download', fileName);
        linkRef.current.click();
      },
      onError: apiErrorHandler,
    },
  );

  const handleCloseEditPromoCodeDefinitionDialogOpen = (shouldRefresh: boolean) => {
    setIsEditPromoCodeDefinitionDialogOpen(false);

    if (shouldRefresh) {
      setPromoCodesPage(0);
      retrievePromoCodeDefMutation.mutateAsync({
        id: promoCodeDefinitionId,
        brandKey,
      });
      listPromoCodesMutation.mutateAsync({
        $Type: ListRequest.$type,
        brandKey,
        promoCodeDefinitionIds: [promoCodeDefinitionId],
        listHandling: {
          skip: promoCodesPage * defaultPromoCodesPageSize,
          take: defaultPromoCodesPageSize,
          ascending: true,
        },
      });
    }
  };

  const handleCloseGeneratePromoCode = (shouldRefresh: boolean) => {
    setIsGeneratePromoCodeDialogOpen(false);

    if (shouldRefresh) {
      setPromoCodesPage(0);
      listPromoCodesMutation.mutateAsync({
        $Type: ListRequest.$type,
        brandKey,
        promoCodeDefinitionIds: [promoCodeDefinitionId],
        listHandling: {
          skip: promoCodesPage * defaultPromoCodesPageSize,
          take: defaultPromoCodesPageSize,
          ascending: true,
        },
      });
    }
  };

  const handleExportPromoCodes = () => {
    exportPromoCodesMutation.mutate({
      brandKey,
      promoCodeDefinitionIds: [promoCodeDefinitionId],
      // listHandling is ignored on the backend,
      // we are sending it because the ExporRequest model inherits ListingCriteriaBase which has listHandling required
      listHandling: {
        skip: 0,
        take: 0,
        ascending: true,
      },
    });
  };

  return (
    <Box>
      <LoadingScreen
        open={isLoadingPromoCodeDefinitionDetails || isLoadingPromoCodes || isExportingPromoCodes}
        message={'Loading...'}
      />
      <PageHeader
        backButtonUrl={redirectUrl || '/marketing/promo-codes'}
        title={promoCodeDefinitionDetails?.promoCode}
        testId={testIdPrefix}
        titleAside={
          <Button
            variant="text"
            startIcon={<EditIcon />}
            onClick={() => {
              setIsEditPromoCodeDefinitionDialogOpen(true);
            }}
            data-testid={`${testIdPrefix}EditButton`}
          >
            Edit
          </Button>
        }
      />
      <Paper sx={{ p: 3 }}>
        <PromoCodeDefinitionDetails
          promoCodes={promoCodes}
          promoCodesPage={promoCodesPage}
          setPromoCodesPage={setPromoCodesPage}
          promoCodesTotalRowsCount={promoCodesTotalRowsCount}
          promoCodeDefinitionDetails={promoCodeDefinitionDetails}
          handleCloseGeneratePromoCode={handleCloseGeneratePromoCode}
          handleExportPromoCodes={handleExportPromoCodes}
          isGeneratePromoCodeDialogOpen={isGeneratePromoCodeDialogOpen}
          setIsGeneratePromoCodeDialogOpen={setIsGeneratePromoCodeDialogOpen}
          testId={testIdPrefix}
        />
        <Link ref={linkRef} style={{ display: 'none' }} data-testid={`${testIdPrefix}DownloadLink`}>
          download link
        </Link>
      </Paper>
      <PromoCodeDefinitionDialog
        open={isEditPromoCodeDefinitionDialogOpen}
        onClose={handleCloseEditPromoCodeDefinitionDialogOpen}
        promoCodeDefinitionDetails={promoCodeDefinitionDetails}
        testId={`${testIdPrefix}Dialog`}
      />
    </Box>
  );
};
