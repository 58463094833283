import { ReactElement } from 'react';
import { Control, FieldValues, useFormContext } from 'react-hook-form';
import { kebabCase, startCase, toLower } from 'lodash';

import { InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ControlledTextField from 'common/inputs/defaultFields/ControlledTextField';

interface Props {
  label: string;
  name: string;
  icon: ReactElement | string;
  minValue: number;
  maxValue: number;
  testId: string;
}

export const CancellationItem = ({ label, name, icon, minValue, maxValue, testId }: Props) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const handleBlur = () => {
    const newValue = typeof value === 'string' ? parseFloat(value) : value;
    if (typeof newValue === 'number') {
      if (newValue < minValue) {
        setValue(name, minValue, { shouldDirty: true });
      } else if (newValue > maxValue) {
        setValue(name, maxValue, { shouldDirty: true });
      }
    }
  };

  const id = kebabCase(label);

  const value = watch(name);

  return (
    <Box>
      <Typography id={id} gutterBottom variant="subtitle1">
        {label}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <ControlledTextField
            variant="standard"
            control={control as unknown as Control<FieldValues, object>}
            name={name}
            error={errors?.email?.message != null}
            helperText={<>{errors?.email?.message}</>}
            InputProps={{
              startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
            }}
            inputProps={{
              min: minValue,
              max: maxValue,
              type: 'number',
              'aria-labelledby': id,
            }}
            onBlur={handleBlur}
            sx={{ width: 156 }}
            testId={`${testId}${startCase(toLower(id))}`}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
