import { Box, Typography } from '@mui/material';
import { CustomerDetails } from '@one/api-models/lib/Admin/PaymentPlan/CustomerDetails';

import { SectionTitle } from 'common/SectionTitle';

interface Props {
  customerDetails: CustomerDetails | undefined;
}

export const PaymentPlanCustomerSection = (props: Props) => {
  const { customerDetails } = props;

  return (
    <Box sx={{ mb: 6 }}>
      <SectionTitle title="Customer" variant="h4" />

      {!!customerDetails && (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="body2" color="#6A7383">
            {customerDetails?.firstName} {customerDetails?.lastName}
          </Typography>
          <Typography variant="subtitle1">{customerDetails?.email}</Typography>
        </Box>
      )}
    </Box>
  );
};
