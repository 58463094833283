import React, { Suspense, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';
import { PersistGate } from 'redux-persist/integration/react';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { Backdrop, CircularProgress } from '@mui/material';
import { LicenseInfo } from '@mui/x-data-grid-pro';

import { persistor, store } from 'store/store';

import { Error } from 'common/errors/Error';
import appConfig from 'config/appConfig';
import { reactPlugin } from 'config/applicationInsights';
import AppAuthenticated from 'core/auth/AppAuthenticated';
import { isSSOSession, isTravelAgentPortal } from 'core/auth/auth';
import { MsalAuthWrapper } from 'core/auth/MsalAuthWrapper';
import { msalConfig } from 'core/auth/msalConfig';
import { Toast, ToastContainer } from 'hooks/useToastMessage';
import { Maintenance } from 'modules/maintenance/Maintenance';

if (appConfig.muiProKey) {
  LicenseInfo.setLicenseKey(appConfig.muiProKey);
}

const queryClient = new QueryClient();
const nonProdFavicons = {
  dev: 'DEV',
  uat: 'UAT',
  localhost: 'DEV',
};

type Props = {
  children?: React.ReactNode;
};

const ErrorBoundary: React.FC<Props> = ({ children }) => {
  if (process.env.NODE_ENV === 'production')
    return (
      <AppInsightsErrorBoundary onError={() => <Error />} appInsights={reactPlugin}>
        <>{children}</>
      </AppInsightsErrorBoundary>
    );

  return <>{children}</>;
};

const msalInstance = new PublicClientApplication(msalConfig);

export default function App() {
  const isTravelAgent = isTravelAgentPortal();

  useEffect(() => {
    const initMap = () => {};
    (window as any).initMap = initMap;

    if (appConfig.googleMapsKey) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://maps.googleapis.com/maps/api/js?key=${appConfig.googleMapsKey}&libraries=places&callback=initMap`;
      document.body.appendChild(script);
    }

    if (isTravelAgent) {
      document.title = 'Agent Portal';

      if (window.location.href.includes('princess')) {
        document.title = 'Princess Partners Admin Portal';
        updateFavicon('princess');
      }
    } else {
      const currentBaseUrl = window.location.host;
      const environment = Object.entries(nonProdFavicons).find(([env]) => currentBaseUrl.includes(env));

      if (environment) {
        const [, favicon] = environment;
        updateFavicon(favicon);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateFavicon = (iconName: string) => {
    const links = document.querySelectorAll("link[rel~='icon']");
    links?.forEach((link) => {
      const sizes = link.getAttribute('sizes');
      link.setAttribute('href', sizes ? `/${iconName}-${sizes}.png` : `/${iconName}.ico`);
    });
  };

  if (appConfig.isMaintenanceMode) return <Maintenance />;

  const isCustomAuth = isSSOSession();

  return (
    <Suspense
      fallback={
        <Backdrop open>
          <CircularProgress color="inherit" data-testid="LoadingSpinner" />
        </Backdrop>
      }
    >
      <AppInsightsContext.Provider value={reactPlugin}>
        <ErrorBoundary>
          <ToastProvider placement="top-right" autoDismissTimeout={8000} components={{ ToastContainer, Toast }}>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <MsalProvider instance={msalInstance}>
                  <QueryClientProvider client={queryClient}>
                    {isCustomAuth ? <AppAuthenticated /> : <MsalAuthWrapper />}
                  </QueryClientProvider>
                </MsalProvider>
              </PersistGate>
            </Provider>
          </ToastProvider>
        </ErrorBoundary>
      </AppInsightsContext.Provider>
    </Suspense>
  );
}
