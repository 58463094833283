import { Box, Typography } from '@mui/material';

import { AlternativeCurrenciesIcon } from 'common/alternativeCurrency/AlternativeCurrenciesIcon';
import { useFormat } from 'hooks/useFormat';

type Props = {
  value: number;
  name: string;
  code?: string;
  fontSize: string;
  textAlign?: 'right' | 'left' | undefined;
};

export const PointsDisplay = ({ value, name, fontSize, code, textAlign }: Props) => {
  const { formatNumber } = useFormat();

  if (value === 0) return null;

  return (
    <>
      <Typography fontWeight={700} lineHeight={0.5} textAlign={textAlign ?? 'right'}>
        +
      </Typography>

      <Box
        sx={(theme) => ({
          backgroundColor: theme.pricing.dark,
          fontWeight: 700,
          borderRadius: 2,
          mt: 1,
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            color: theme.pricing.accentColor,
            p: 1,
          })}
        >
          <AlternativeCurrenciesIcon alternativeCurrency={code} />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography fontSize={fontSize} sx={{ mx: 0.5, fontWeight: 700, lineHeight: '0.8rem' }}>
              {formatNumber(value)}
            </Typography>
            <Typography
              variant="body1"
              fontSize={fontSize}
              fontWeight={700}
              whiteSpace="nowrap"
              sx={{ lineHeight: '0.8rem' }}
            >
              {name}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};
