import { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { Box, Divider } from '@mui/material';
import { Program } from '@one/api-models/lib/Admin/ProgramSales/ChooseProgram/Program';
import { InstallmentPlan } from '@one/api-models/lib/Admin/ProgramSales/Purchase/InstallmentPlan';
import { SplitPaymentPlan } from '@one/api-models/lib/Admin/ProgramSales/Purchase/SplitPaymentPlan';

import { selectActiveBrand, selectActivePartner } from 'store/slices/applicationDataSlice';
import {
  selectBillingDetails,
  selectCalculatedInstallmentPlan,
  selectPaymentPlan,
  selectSelectedPrograms,
} from 'store/slices/salesOrderDataSlice';

import { useFormat } from 'hooks/useFormat';
import { Typography } from 'styled';
import { getPartnerLogoPath } from 'utils/getBrandDetails';

import { ProgramItemsList } from './ProgramItemsList';

export const SalesOrderPreview = () => {
  const selectedPrograms = useSelector(selectSelectedPrograms);
  const paymentPlan = useSelector(selectPaymentPlan);
  const billingDetails = useSelector(selectBillingDetails);
  const activeBrand = useSelector(selectActiveBrand);
  const activePartner = useSelector(selectActivePartner);

  const { formatCurrency, formatDate, formatCurrencyWithCode } = useFormat();

  const total = selectedPrograms.reduce(
    (partialSum: number, program: Program) => partialSum + program?.price?.amount,
    0,
  );

  const addressItems = [
    billingDetails?.state || billingDetails?.stateCode,
    billingDetails?.city,
    billingDetails?.zipCode,
  ];

  return (
    <>
      <Typography variant="h5">Preview</Typography>

      <Box
        sx={{
          p: { xs: 2, sm: 3, lg: 5 },
          mt: 3,
          boxShadow: 'rgba(65, 69, 82, 0.08) 0px 15px 35px 0px, rgba(0, 0, 0, 0.12) 0px 5px 15px 0px',
          backgroundColor: '#fff',
          minHeight: 'calc(100vh - 193px)',
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">Invoice</Typography>
          <Box display="flex">
            <Typography variant="h5">{activeBrand?.name}</Typography>
            {activePartner && (
              <Box component="img" sx={{ height: '28px', ml: 2 }} src={getPartnerLogoPath(activePartner?.key)} />
            )}
          </Box>
        </Box>

        <Box mt={3} display="flex" flexDirection="column">
          {/* <Typography variant="caption" sx={{ fontWeight: 600 }}>
            Invoice number: TBD
          </Typography> */}
          <Typography variant="caption">Date due: {formatDate(new Date(), true, 'MMM dd, yyyy')}</Typography>
        </Box>
        {billingDetails && (
          <Box mt={3} display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="space-between">
            {/* <Box flexBasis="50%">Insert partner details. NO API</Box> */}
            <Box flexBasis="50%" mt={{ xs: 3, md: 0 }} display="flex" flexDirection="column">
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                Bill to
              </Typography>
              <Typography variant="caption">{`${billingDetails?.firstName} ${billingDetails?.lastName}`}</Typography>
              <Typography variant="caption">{billingDetails?.email}</Typography>
              <Box display="flex" flexDirection="column">
                <Typography variant="caption">
                  {billingDetails?.streetAddress}
                  {billingDetails?.aptNumber ? ', ' + billingDetails?.aptNumber : ''}
                </Typography>
                <Typography variant="caption">{addressItems.filter((item) => !!item).join(', ')}</Typography>
                <Typography variant="caption">{billingDetails?.country}</Typography>
              </Box>
            </Box>
          </Box>
        )}
        {selectedPrograms.length > 0 && (
          <Box mt={3}>
            <Typography variant="h6">
              {`${formatCurrencyWithCode(
                paymentPlan?.totalDueToday?.amount,
                paymentPlan?.totalDueToday?.currency,
              )} due ${formatDate(new Date(), true, 'MMM dd, yyyy')}`}
            </Typography>
          </Box>
        )}

        {selectedPrograms?.length > 0 && (
          <>
            <Box mt={3}>
              <ProgramItemsList programs={selectedPrograms} variant="caption" />
            </Box>

            <Box display="flex" justifyContent="flex-end" width="100%">
              <Box flexBasis={{ xs: '100%', md: '50%' }}>
                <Typography variant="h6" mt={3} mb={2} display={{ xs: 'flex', md: 'none' }}>
                  Payment details
                </Typography>

                <PriceInfoRow
                  label="Subtotal"
                  value={formatCurrency(selectedPrograms[0]?.price?.amount, selectedPrograms[0]?.price?.currency)}
                />

                <PriceInfoRow
                  label="Total"
                  value={formatCurrency(selectedPrograms[0]?.price?.amount, selectedPrograms[0]?.price?.currency)}
                />

                {paymentPlan && paymentPlan?.paymentPlan?.$type === InstallmentPlan.$Type ? (
                  <DisplayInstallmentPaymentDetails paymentPlan={paymentPlan?.paymentPlan as InstallmentPlan} />
                ) : paymentPlan && paymentPlan?.paymentPlan?.$type === SplitPaymentPlan.$Type ? (
                  <DisplaySplitPaymentDetails
                    paymentPlan={paymentPlan?.paymentPlan as SplitPaymentPlan}
                    total={selectedPrograms[0]?.price?.amount}
                    currency={selectedPrograms[0]?.price?.currency}
                  />
                ) : (
                  <PriceInfoRow
                    label="Amount Due"
                    hideDivider
                    value={formatCurrencyWithCode(
                      selectedPrograms[0]?.price?.amount,
                      selectedPrograms[0]?.price?.currency,
                    )}
                  />
                )}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

interface PriceInfoRowProps {
  label: string;
  value: ReactElement | string | null;
  hideDivider?: boolean;
}
const PriceInfoRow = ({ label, value, hideDivider }: PriceInfoRowProps) => (
  <>
    <Box display="flex" justifyContent="space-between" py={1}>
      <Typography variant="caption">{label}</Typography>
      <Typography variant="caption">{value}</Typography>
    </Box>
    {!hideDivider && <Divider />}
  </>
);

const DisplaySplitPaymentDetails = (props: { paymentPlan: SplitPaymentPlan; total: number; currency: string }) => {
  const { formatCurrency, formatCurrencyWithCode } = useFormat();
  const { paymentPlan, total, currency } = props;
  const balanceAmount = total - paymentPlan?.downPaymentAmount?.amount;
  return (
    <>
      <PriceInfoRow
        label="Amount Due"
        hideDivider
        value={formatCurrencyWithCode(paymentPlan?.downPaymentAmount?.amount, paymentPlan?.downPaymentAmount?.currency)}
      />
      <PriceInfoRow
        label="Down Payment"
        value={formatCurrency(paymentPlan?.downPaymentAmount?.amount, paymentPlan?.downPaymentAmount?.currency)}
      />

      <PriceInfoRow label="Balance" value={formatCurrency(balanceAmount >= 0 ? balanceAmount : total, currency)} />
    </>
  );
};

const DisplayInstallmentPaymentDetails = (props: { paymentPlan: InstallmentPlan }) => {
  const { formatCurrency, formatDate, getRecurringIntervalTypeLabel, formatRecurringInterval, formatCurrencyWithCode } =
    useFormat();
  const calculatedInstallmentPlan = useSelector(selectCalculatedInstallmentPlan);
  const { paymentPlan } = props;
  return (
    <>
      <PriceInfoRow
        label="Amount Due"
        hideDivider
        value={formatCurrencyWithCode(
          paymentPlan?.downPaymentAmounts[0]?.amount,
          paymentPlan?.downPaymentAmounts[0]?.currency,
        )}
      />
      <PriceInfoRow
        label="Down Payment"
        value={formatCurrency(paymentPlan?.downPaymentAmounts[0]?.amount, paymentPlan?.downPaymentAmounts[0]?.currency)}
      />

      <PriceInfoRow
        label="Balance"
        value={formatCurrency(calculatedInstallmentPlan?.balance.amount, calculatedInstallmentPlan?.balance.currency)}
      />
      <PriceInfoRow
        label="Term"
        value={formatRecurringInterval(paymentPlan?.intervalCount, paymentPlan?.recurringInterval, true)}
      />

      <PriceInfoRow
        label={`${getRecurringIntervalTypeLabel(paymentPlan.recurringInterval)} Payment:`}
        value={
          !calculatedInstallmentPlan?.installments[0] ? (
            'N/A'
          ) : (
            <>
              {formatCurrency(
                calculatedInstallmentPlan?.installments[0].amount.amount,
                calculatedInstallmentPlan?.installments[0].amount.currency,
                2,
              )}
              /{formatRecurringInterval(1, paymentPlan.recurringInterval)}
            </>
          )
        }
      />

      <PriceInfoRow
        label="Next Payment"
        value={
          !calculatedInstallmentPlan?.installments[0]
            ? 'N/A'
            : formatDate(calculatedInstallmentPlan?.installments[0].billingCycleBegin, true)
        }
      />

      <PriceInfoRow
        label="End Term"
        value={
          !calculatedInstallmentPlan?.installments[0] ? 'N/A' : formatDate(calculatedInstallmentPlan?.termEnd, true)
        }
      />
    </>
  );
};
