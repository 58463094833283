import { Box, Divider, Grid, Skeleton, Typography } from '@mui/material';

export const SummaryViewSkeleton = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <Skeleton variant="circular" width={38} height={38} />
      </Grid>
      <Grid item xs={10}>
        {/* name */}
        <Skeleton variant="text" width={'70%'} />
        {/* email */}
        <Skeleton variant="text" width={'80%'} />
        {/* status */}
        <Skeleton variant="rectangular" height={20} width={30} />
        {/* created on */}
        <Skeleton variant="text" width={'70%'} />
        <Divider sx={{ my: 2 }} />
        <Typography variant="overline" display="block">
          MEMBERSHIP
        </Typography>
        {/* membership name */}
        <Skeleton variant="text" width={'60%'} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 0.5 }}>
          <Typography variant="caption">
            <b>Expiry Date:</b> <Skeleton variant="text" width={'100%'} />
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
