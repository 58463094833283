import { Chip } from '@mui/material';
import { PaymentPlanStatus as PaymentPlanStatusModel } from '@one/api-models/lib/Admin/PaymentPlan/PaymentPlanStatus';

const statusSettings = [
  { id: 0, name: 'Unknown', backgroundColor: '#bbdffb', color: '#829caf' },
  { id: 1, name: 'Active', backgroundColor: '#DDF6C7', color: '#2D681E' },
  { id: 2, name: 'Incomplete', backgroundColor: '#FFC55C', color: '#b28940' },
  { id: 3, name: 'InCompleteExpired', backgroundColor: '#FFEDB5', color: '#b28940' },
  { id: 4, name: 'Canceled', backgroundColor: '#6A7383', color: '#000' },
  { id: 5, name: 'PastDue', backgroundColor: '#ffcdd2', color: '#c62828' },
  { id: 6, name: 'UnPaid', backgroundColor: '#ffcdd2', color: '#c62828' },
  { id: 7, name: 'Completed', backgroundColor: '#a3c585', color: '#414e35' },
  { id: 8, name: 'Deprecated', backgroundColor: '#bbdffb', color: '#829caf' },
];

export const PaymentPlanStatus = ({ status }: { status: PaymentPlanStatusModel }) => {
  const statusSetting = statusSettings.find((el) => el.id === status) || {
    name: status,
    backgroundColor: '#f5f5f5',
    color: '#000',
  };
  return (
    <Chip
      sx={{
        maxHeight: '20px',
        backgroundColor: statusSetting.backgroundColor,
        color: statusSetting.color,
        borderRadius: '4px',
        fontWeight: 500,
      }}
      size="small"
      label={statusSetting.name}
    />
  );
};
