import { useEffect, useState } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material';
import { AwardType } from '@one/api-models/lib/Campaign/Promotion/AwardType';
import { Promotion } from '@one/api-models/lib/Campaign/Promotion/Promotion';
import { PromotionAward } from '@one/api-models/lib/Campaign/Promotion/PromotionAward';
import { ListTargetsRequest } from '@one/api-models/lib/Campaign/Promotion/Request/ListTargetsRequest';
import { ListTargetsResponse } from '@one/api-models/lib/Campaign/Promotion/Response/ListTargetsResponse';
import { TargetType } from '@one/api-models/lib/Campaign/Promotion/TargetType';

import { selectActiveBrand } from 'store/slices/applicationDataSlice';

import { ApiError } from 'apiAccess/api-client';
import ControlledAutocomplete, {
  ControlledAutocompleteOption,
} from 'common/inputs/defaultFields/ControlledAutocomplete';
import ControlledSelect from 'common/inputs/defaultFields/ControlledSelect';
import ControlledTextField from 'common/inputs/defaultFields/ControlledTextField';
import { LoadingScreen } from 'common/LoadingScreen';
import { useApiHelpers } from 'hooks/useApiHelpers';
import { useToastMessage } from 'hooks/useToastMessage';

import { AwardTargetTypeValues, AwardTypeValues } from './promotionHelpers';

interface PromotionAwardForm {
  targetCategory: number;
  targetId: string;
  targetType: number;
  awardType: number;
  targetQuantityLimit?: number | null;
  targetAmount?: number | null;
  targetAdjustmentPercentage?: number | null;
}

interface AddPromotionAwardDialogProps {
  promotion: Promotion;
  open: boolean;
  testId: string;
  handleClose: () => void;
  handleAddAward: (award: PromotionAward) => Promise<void>;
}

const validationSchema: yup.SchemaOf<PromotionAwardForm> = yup.object().shape({
  targetCategory: yup
    .number()
    .transform((_, val) => (val !== '' ? Number(val) : undefined))
    .required('Target is required.')
    .typeError('Invalid number'),
  targetId: yup.string().trim().max(64, 'Maximum 64 characters allowed.').required('Target ID is required.'),
  targetType: yup
    .number()
    .transform((_, val) => (val !== '' ? Number(val) : undefined))
    .required('Target type is required.')
    .max(64, 'Maximum 64 characters allowed.')
    .typeError('Invalid number'),
  awardType: yup
    .number()
    .transform((_, val) => (val !== '' ? Number(val) : undefined))
    .required('Award type is required.')
    .max(64, 'Maximum 64 characters allowed.')
    .typeError('Invalid number'),
  targetQuantityLimit: yup
    .number()
    .integer('Decimals are not allowed.')
    .transform((_, val) => (val !== '' ? Number(val) : undefined))
    .nullable()
    .min(1, 'Minimum value is 1.')
    .max(5, 'Maximum value is 5.')
    .typeError('Invalid number'),
  targetAmount: yup
    .number()
    .integer('Decimals are not allowed.')
    .transform((_, val) => (val !== '' ? Number(val) : undefined))
    .nullable()
    .min(1, 'Minimum value is 1.')
    .max(1000, 'Maximum amount is 1000.')
    .typeError('Invalid number'),
  targetAdjustmentPercentage: yup
    .number()
    .integer('Decimals are not allowed.')
    .transform((_, val) => (val !== '' ? Number(val) : undefined))
    .nullable()
    .min(1, 'Minimum value is 1.')
    .max(100, 'Maximum value is 100.')
    .typeError('Invalid number'),
});

export const AddPromotionAwardDialog = ({
  open,
  promotion,
  testId,
  handleAddAward,
  handleClose,
}: AddPromotionAwardDialogProps) => {
  const { control, handleSubmit, formState } = useForm<PromotionAwardForm>({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });
  const activeBrand = useSelector(selectActiveBrand);
  const { errors } = formState;
  const { api } = useApiHelpers();
  const { apiErrorHandler } = useToastMessage();

  const [programs, setPrograms] = useState<ControlledAutocompleteOption[]>([]);
  const [selectedTargetCategory, setSelectedTargetCategory] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (selectedTargetCategory === TargetType.Program) {
      loadTargets.mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTargetCategory]);

  const loadTargets = useMutation<ListTargetsResponse, ApiError>(
    () =>
      api.promotions.listTargets({
        brandKey: activeBrand?.key,
        type: selectedTargetCategory,
      } as ListTargetsRequest),
    {
      mutationKey: 'loadTargets',
      onSuccess: (value: ListTargetsResponse) => {
        const programOptions = value.items.map((item) => ({
          code: item.id,
          label: item.name,
        }));
        setPrograms(programOptions);
      },
      onError: apiErrorHandler,
    },
  );

  const handleAddAwardToPromotion = async (data: PromotionAwardForm) => {
    const targetAdjustmentAmount = data.awardType !== AwardType.FixedPrice ? data.targetAmount : undefined;
    const targetFixedPrice = data.awardType === AwardType.FixedPrice ? data.targetAmount : undefined;

    await handleAddAward({
      id: 0,
      promotionId: promotion.id,
      targetType: data.targetType,
      targetId: data.targetId,
      targetName: AwardTargetTypeValues[data.targetCategory].label,
      awardType: data.awardType,
      targetAdjustmentAmount: targetAdjustmentAmount ?? undefined,
      targetAdjustmentPercentage: data.targetAdjustmentPercentage ?? undefined,
      targetQuantityLimit: data.targetQuantityLimit ?? undefined,
      targetFixedPrice: targetFixedPrice ?? undefined,
    });
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '750px',
          },
        },
      }}
    >
      <LoadingScreen open={loadTargets.isLoading} />
      <form onSubmit={handleSubmit(handleAddAwardToPromotion)} autoComplete="off">
        <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          {promotion.promotionSummary.name}
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            size="small"
            disableRipple
            data-testid={`${testId}CloseButton`}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ my: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={2} display="flex" alignItems="center">
              <Typography variant="subtitle1" fontWeight="bold">
                Target:
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <ControlledSelect
                control={control as unknown as Control<FieldValues, object>}
                name="targetCategory"
                label="Target"
                fullWidth
                size="small"
                onChange={(value) => {
                  setSelectedTargetCategory(value ? parseInt(value) : undefined);
                }}
                error={errors.targetCategory != null}
                helperText={errors.targetCategory?.message}
                testId={`${testId}Target`}
              >
                {AwardTargetTypeValues.map((opt: any) => (
                  <MenuItem key={opt.id} value={opt.id} data-testid={`${testId}TargetSelectItem`}>
                    {opt.label}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </Grid>
            <Grid item xs={12} md={6}>
              {selectedTargetCategory === TargetType.Program ? (
                <ControlledAutocomplete
                  control={control as unknown as Control<FieldValues, object>}
                  name="targetId"
                  label=""
                  placeholder="Select a Program"
                  options={programs}
                  error={errors.targetId != null}
                  helperText={errors.targetId?.message}
                  testId={`${testId}TargetId`}
                />
              ) : (
                <ControlledTextField
                  control={control as unknown as Control<FieldValues, object>}
                  name="targetId"
                  fullWidth
                  label="Target ID"
                  placeholder="Target Item ID"
                  error={errors.targetId != null}
                  helperText={errors.targetId?.message}
                  testId={`${testId}TargetId`}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ my: 1 }} />
            </Grid>

            <Grid item xs={12} sm={2} display="flex" alignItems="center">
              <Typography variant="subtitle1" fontWeight="bold">
                Target Type:
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <ControlledSelect
                control={control as unknown as Control<FieldValues, object>}
                name="targetType"
                label="Target Type"
                fullWidth
                size="small"
                error={errors.targetType != null}
                helperText={errors.targetType?.message}
                testId={`${testId}TargetType`}
              >
                {AwardTargetTypeValues.map((opt: any) => (
                  <MenuItem key={opt.id} value={opt.id} data-testid={`${testId}TargetTypeItem`}>
                    {opt.label}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </Grid>

            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12} sm={2} display="flex" alignItems="center">
                <Typography variant="subtitle1" fontWeight="bold">
                  Award Type:
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <ControlledSelect
                  control={control as unknown as Control<FieldValues, object>}
                  name="awardType"
                  label="Award Type"
                  fullWidth
                  size="small"
                  error={errors.awardType != null}
                  helperText={errors.awardType?.message}
                  testId={`${testId}AwardType`}
                >
                  {AwardTypeValues.map((opt: any) => (
                    <MenuItem key={opt.id} value={opt.id} data-testid={`${testId}AwardTypeItem`}>
                      {opt.label}
                    </MenuItem>
                  ))}
                </ControlledSelect>
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12} sm={2} display="flex" alignItems="center">
                <Typography variant="subtitle1" fontWeight="bold">
                  Percentage:
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <ControlledTextField
                  control={control as unknown as Control<FieldValues, object>}
                  name="targetAdjustmentPercentage"
                  fullWidth
                  label="%"
                  placeholder="Percentage"
                  type="number"
                  error={errors.targetAdjustmentPercentage != null}
                  helperText={errors.targetAdjustmentPercentage?.message}
                  testId={`${testId}Percentage`}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={6} sm={2} display="flex" alignItems="center">
                <Typography variant="subtitle1" fontWeight="bold">
                  Amount:
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <ControlledTextField
                  control={control as unknown as Control<FieldValues, object>}
                  name="targetAmount"
                  fullWidth
                  label="Amount"
                  placeholder="Amount"
                  type="number"
                  error={errors.targetAmount != null}
                  helperText={errors.targetAmount?.message}
                  testId={`${testId}Amount`}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={6} sm={2} display="flex" alignItems="center">
                <Typography variant="subtitle1" fontWeight="bold">
                  Quantity Limit:
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <ControlledTextField
                  control={control as unknown as Control<FieldValues, object>}
                  name="targetQuantityLimit"
                  fullWidth
                  label="Qty"
                  placeholder="Qty"
                  type="number"
                  error={errors.targetQuantityLimit != null}
                  helperText={errors.targetQuantityLimit?.message}
                  testId={`${testId}Quantity`}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            size="small"
            disableRipple
            data-testid={`${testId}CancelButton`}
          >
            Cancel
          </Button>
          <Button type="submit" variant="contained" size="small" data-testid={`${testId}AddButton`}>
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
