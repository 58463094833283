import { useSelector } from 'react-redux';

import { Box, Typography, useTheme } from '@mui/material';

import { selectUsername } from 'store/slices/userDataSlice';

export const AdminDashboard = () => {
  const theme = useTheme();
  const username = useSelector(selectUsername);

  const timeOfTheDayMessages = () => {
    const time = new Date().getHours();
    if (time <= 11) return '🌅 Good Morning,';
    else if (time > 11 && time <= 17) return `☀️ Good Afternoon, `;
    else if (time > 17 && time <= 20) return '🌙 Good Evening,  ';
    else if (time > 20) return `Wow, you're working late...`;
  };

  return (
    <Box sx={{ textAlign: { xs: 'center', md: 'start' } }}>
      <Box data-testid="AdminDashboardMessage">
        <Typography variant="h1" display="inline">
          {timeOfTheDayMessages()}
        </Typography>
        <Typography variant="h1" display="inline" sx={{ color: `${theme.palette.primary.main}`, pl: 1 }}>
          {username}!
        </Typography>
      </Box>
    </Box>
  );
};
