import { useState } from 'react';
import { Control, Controller, FieldValues, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { EditOrderRequest } from '@one/api-models/lib/Admin/Orders/Common/EditOrderRequest';
import { CruiseOrderItemSummary } from '@one/api-models/lib/Admin/Sales/Travel/Cruise/CruiseOrderItemSummary';
import { EditTraveler } from '@one/api-models/lib/Membership/Account/Travel/Cruise/EditTraveler';
import { EditTravelerCriteria } from '@one/api-models/lib/Sales/Travel/Cruise/EditTravelerCriteria';

import { ConfirmationDialog } from 'common/ConfirmationDialog';
import ControlledTextField from 'common/inputs/defaultFields/ControlledTextField';
import { EditTravelers } from 'models/customers/profile/EditTravelers';
import { EditTravelersItem } from 'models/customers/profile/EditTravelersItem';

interface Props {
  cruiseOrderItem?: CruiseOrderItemSummary;
  orderNumber: string;
  editTravelerSupportedEditOperation?: EditTraveler;
  testId: string;
  onCloseForm: () => void;
  onSubmitForm: (request: EditOrderRequest) => void;
}

export const EditTravelersForm = ({
  cruiseOrderItem,
  orderNumber,
  testId,
  editTravelerSupportedEditOperation,
  onCloseForm,
  onSubmitForm,
}: Props) => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [isCloseConfirmationOpen, setIsCloseConfirmationOpen] = useState<boolean>(false);
  const travelers = cruiseOrderItem ? cruiseOrderItem.travelers : [];

  const editValidationSchema: yup.SchemaOf<EditTravelersItem> = yup.object().shape({
    orderItemId: yup.number().required(),
    index: yup.number().required(),
    traveler: yup.object().shape({
      firstName: yup.string().trim().required('First Name is required.').matches(/.{2,}/, {
        excludeEmptyString: true,
        message: 'Use at least 2 characters.',
      }),
      lastName: yup.string().trim().required('Last Name is required.').matches(/.{2,}/, {
        excludeEmptyString: true,
        message: 'Use at least 2 characters.',
      }),
    }),
  });

  const validationSchema: yup.SchemaOf<EditTravelers> = yup.object().shape({
    orderNumber: yup.string().required(),
    supportedAtSupplier: yup.boolean().required(),
    editAtSupplier: yup.boolean().when('supportedAtSupplier', {
      is: true,
      then: yup.boolean().required(),
      otherwise: yup.boolean(),
    }),
    edits: yup.array().of(editValidationSchema),
  });

  const defaultValues = {
    orderNumber,
    supportedAtSupplier: editTravelerSupportedEditOperation?.supportedAtSupplier ?? false,
    editAtSupplier: false,
    edits: travelers.map((t, index) => ({
      orderItemId: cruiseOrderItem?.id,
      index,
      traveler: {
        firstName: t.firstName,
        lastName: t.lastName,
      },
    })),
  };

  const methods = useForm<EditTravelers>({
    mode: 'onBlur',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty, errors },
    watch,
  } = methods;

  const values: EditTravelers = watch();

  const validateAndConfirm = () => {
    setIsConfirmationOpen(true);
  };

  const handleCloseForm = (): void => {
    if (isDirty) setIsCloseConfirmationOpen(true);
    else onCloseForm();
  };

  const handleAbort = (isConfirmed: boolean): void => {
    if (isConfirmed) onCloseForm();
    else setIsCloseConfirmationOpen(false);
  };

  const handleTravelerNamesChange = (isConfirmed: boolean): void => {
    if (isConfirmed) {
      const request: EditOrderRequest = {
        orderNumber: values.orderNumber,
        edits: values.edits.map((edit) => ({
          $type: EditTravelerCriteria.$type,
          ...edit,
          traveler: { ...edit.traveler, ageGroup: travelers[edit.index].ageGroup },
          editAtSupplier: values.editAtSupplier === true,
        })),
      };
      onSubmitForm(request);
    }

    setIsConfirmationOpen(false);
  };

  const { fields } = useFieldArray({
    control,
    name: 'edits',
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(validateAndConfirm)} noValidate>
        {fields.map((field, index) => (
          <Grid key={field.id} spacing={2} container>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h6">Traveler {index + 1}</Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <ControlledTextField
                control={control as unknown as Control<FieldValues, object>}
                name={`edits.${index}.traveler.firstName`}
                fullWidth
                label="First Name"
                placeholder="First Name"
                error={errors?.edits && errors?.edits[index]?.traveler?.firstName?.message != null}
                helperText={errors?.edits && errors?.edits[index]?.traveler?.firstName?.message}
                testId={`${testId}FirstName${index}`}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <ControlledTextField
                control={control as unknown as Control<FieldValues, object>}
                name={`edits.${index}.traveler.lastName`}
                fullWidth
                label="Last Name"
                placeholder="Last Name"
                error={errors?.edits && errors?.edits[index]?.traveler?.lastName?.message != null}
                helperText={errors?.edits && errors?.edits[index]?.traveler?.lastName?.message}
                testId={`${testId}LastName${index}`}
              />
            </Grid>
          </Grid>
        ))}
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
            <FormControlLabel
              control={
                <Controller
                  name="editAtSupplier"
                  control={control}
                  render={({ field: props }) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      disabled={!values.supportedAtSupplier}
                      inputProps={{
                        //eslint-disable-next-line
                        //@ts-ignore
                        'data-testid': `${testId}EditTravelersAtSupplierCheckboxInput`,
                      }}
                    />
                  )}
                />
              }
              label={
                values.supportedAtSupplier ? (
                  'Change traveler names at supplier'
                ) : (
                  <Typography color={(theme) => theme.palette.action.disabled}>
                    The traveler names have to be manually changed at supplier.
                  </Typography>
                )
              }
            />
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <Box>
            <Button
              variant="outlined"
              sx={{ mr: 2 }}
              onClick={handleCloseForm}
              data-testid={`${testId}DoNotEditTravelersButton`}
            >
              Do Not Change Traveler Names
            </Button>
            <Button variant="contained" type="submit" data-testid={`${testId}EditTravelersButton`}>
              Change Traveler Names
            </Button>
          </Box>
        </Box>

        <ConfirmationDialog
          open={isConfirmationOpen}
          isLoading={false}
          title="Change traveler names"
          question={
            <Box>
              <Typography variant="h6">You are going to change the traveler names:</Typography>
              <ul>
                <li>
                  <Typography variant="subtitle1">
                    {values.supportedAtSupplier ? (
                      <>
                        The traveler names <strong>will {values.editAtSupplier ? '' : 'not'} be changed</strong> at the
                        supplier.
                      </>
                    ) : (
                      'The traveler names have to be manually changed at supplier.'
                    )}
                  </Typography>
                </li>

                {values.edits.map((edit) => (
                  <li key={edit.index}>
                    <Typography variant="subtitle2">For Traveler {edit.index + 1}</Typography>
                    <Typography variant="subtitle1">
                      <strong>First Name</strong> changes from{' '}
                      <strong>{defaultValues.edits[edit.index].traveler.firstName}</strong> to{' '}
                      <strong>{edit.traveler.firstName}</strong>.
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>Last Name</strong> changes from{' '}
                      <strong>{defaultValues.edits[edit.index].traveler.lastName}</strong> to{' '}
                      <strong>{edit.traveler.lastName}</strong>.
                    </Typography>
                  </li>
                ))}
              </ul>
            </Box>
          }
          confirmButton="Confirm"
          abortButton="Close"
          onClose={handleTravelerNamesChange}
          testId={`${testId}CancelEditTravelersDialog`}
        />

        <ConfirmationDialog
          open={isCloseConfirmationOpen}
          isLoading={false}
          title="Abort traveler names change"
          question={
            <>
              Are you sure you want to abort the traveler names change?
              <br />
              All changes will be lost.
            </>
          }
          confirmButton="Abort"
          abortButton="Continue"
          onClose={handleAbort}
          testId={`${testId}AbortEditTravelersDialog`}
        />
      </form>
    </FormProvider>
  );
};
