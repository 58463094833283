import { ChangeEvent, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Divider, FormControlLabel, Grid, Radio, RadioGroup, useTheme } from '@mui/material';
import { InstallmentPlanSettings } from '@one/api-models/lib/Admin/ProgramSales/Purchase/InstallmentPlanSettings';
import { ReservationPlan } from '@one/api-models/lib/Admin/ProgramSales/Purchase/ReservationPlan';
import { ReservationSettings } from '@one/api-models/lib/Admin/ProgramSales/Purchase/ReservationSettings';
import { SplitPaymentPlan } from '@one/api-models/lib/Admin/ProgramSales/Purchase/SplitPaymentPlan';
import { SplitPaymentSettings } from '@one/api-models/lib/Admin/ProgramSales/Purchase/SplitPaymentSettings';

import {
  PaymentOptions,
  selectPurchaseData,
  selectSelectedPaymentOption,
  selectSelectedPrograms,
  setPaymentPlan,
  setSelectedPaymentOption,
} from 'store/slices/salesOrderDataSlice';

import { useFormat } from 'hooks/useFormat';
import { Typography } from 'styled';

import { PaymentPlanForm } from './PaymentPlanForm';
import { SplitPaymentForm } from './SplitPaymentForm';

interface PaymentFormProps {
  validatePaymentForm: any;
  testId: string;
}

export const PaymentForm = ({ validatePaymentForm, testId }: PaymentFormProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { formatDate } = useFormat();
  const purchaseData = useSelector(selectPurchaseData);
  const selectedPrograms = useSelector(selectSelectedPrograms);
  const selectedPaymentOption = useSelector(selectSelectedPaymentOption);

  const paymentPlanSettings = useMemo(() => {
    const installmentsPlanSettings = purchaseData?.paymentPlanConfig?.find(
      (pp) => pp.$type === InstallmentPlanSettings.$Type,
    ) as InstallmentPlanSettings;

    const reservationSettings = purchaseData?.paymentPlanConfig?.find(
      (pp) => pp.$type === ReservationSettings.$Type,
    ) as ReservationSettings;

    const splitPaymentSettings = purchaseData?.paymentPlanConfig?.find(
      (pp) => pp.$type === SplitPaymentSettings.$Type,
    ) as SplitPaymentSettings;

    return new Map([
      [
        PaymentOptions.OneTime,
        {
          settings: undefined,
          enabled: true,
          paymentPlan: undefined,
          totalDueToday: {
            amount: selectedPrograms[0]?.price?.amount,
            currency: selectedPrograms[0]?.price?.currency,
            isEstimated: false,
          },
          label: 'Charge Customer in Full Now',
          description: '',
        },
      ],
      [
        PaymentOptions.SplitPayment,
        {
          settings: splitPaymentSettings,
          enabled: !!splitPaymentSettings,
          paymentPlan: {
            $type: SplitPaymentPlan.$Type,
            paymentPlanConfigId: splitPaymentSettings?.id,
            downPaymentAmount: splitPaymentSettings?.minDownPaymentAmount,
          },
          totalDueToday: splitPaymentSettings?.minDownPaymentAmount,
          label: 'Split Payment on Multiple Cards',
          description: '',
        },
      ],
      [
        PaymentOptions.PaymentPlan,
        {
          settings: installmentsPlanSettings,
          enabled: !!installmentsPlanSettings,
          totalDueToday: {
            amount: 0,
            currency: installmentsPlanSettings?.minDownPaymentAmount?.currency,
            isEstimated: false,
          },
          label: 'Payment Plans',
          description: 'Deposits + Monthly Installments',
        },
      ],
      [
        PaymentOptions.Reservation,
        {
          settings: reservationSettings,
          enabled: !!reservationSettings,
          paymentPlan: {
            $type: ReservationPlan.$Type,
            paymentPlanConfigId: reservationSettings?.id,
          },
          totalDueToday: {
            amount: 0,
            currency: selectedPrograms[0]?.price?.currency,
            isEstimated: false,
          },
          label: 'Reservation',
          description: reservationSettings
            ? `Reserve now for free and pay until ${formatDate(reservationSettings?.downPaymentDueDate, true)}`
            : '',
        },
      ],

      [
        PaymentOptions.RequestPayment,
        {
          settings: undefined,
          enabled: false,
          paymentPlan: undefined,
          totalDueToday: {
            amount: 0,
            currency: selectedPrograms[0]?.price?.currency,
            isEstimated: false,
          },
          label: 'Request Payment',
          description: 'Create an invoice requesting payment by a specific date',
        },
      ],
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseData?.paymentPlanConfig, selectedPrograms]);

  const handlePaymentOption = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    const paymentOption = value as PaymentOptions;
    dispatch(setSelectedPaymentOption(value as PaymentOptions));

    const settings = paymentPlanSettings.get(paymentOption);
    dispatch(
      setPaymentPlan({
        paymentPlan: settings?.paymentPlan,
        totalDueToday: settings?.totalDueToday ?? {
          amount: 0,
          currency: selectedPrograms[0]?.price?.currency,
          isEstimated: false,
        },
      }),
    );
  };

  return (
    <Grid container direction="row" justifyContent="space-between" spacing={1} rowGap={1} sx={{ alignItems: 'center' }}>
      <Grid item xs={12}>
        <RadioGroup onChange={handlePaymentOption} value={selectedPaymentOption} defaultValue={PaymentOptions.OneTime}>
          {Array.from(paymentPlanSettings.entries()).map(([key, value]) => (
            <>
              <FormControlLabel
                value={key}
                disabled={!value.enabled}
                control={
                  <Radio
                    size="small"
                    inputProps={{
                      //eslint-disable-next-line
                      //@ts-ignore
                      'data-testid': `${testId}OneTimeRadioInput`,
                    }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    sx={{
                      color: value.enabled ? theme.palette.text.primary : theme.palette.text.disabled,
                    }}
                  >
                    {value.label}
                  </Typography>
                }
              />
              <Typography
                variant="body1"
                sx={{
                  color: value.enabled ? theme.palette.text.secondary : theme.palette.text.disabled,
                  ml: 3.25,
                  mb: 1,
                }}
              >
                {value.description}
              </Typography>
            </>
          ))}
        </RadioGroup>
      </Grid>
      <Grid item xs={12} my={2}>
        <Divider />
      </Grid>
      {selectedPaymentOption === PaymentOptions.PaymentPlan && (
        <PaymentPlanForm
          installmentsPlanSettings={
            paymentPlanSettings.get(PaymentOptions.PaymentPlan)?.settings as InstallmentPlanSettings
          }
          validatePaymentForm={validatePaymentForm}
          testId={testId}
        />
      )}
      {selectedPaymentOption === PaymentOptions.SplitPayment && (
        <SplitPaymentForm
          splitPaymentSettings={paymentPlanSettings.get(PaymentOptions.SplitPayment)?.settings as SplitPaymentSettings}
          validatePaymentForm={validatePaymentForm}
          testId={testId}
        />
      )}
    </Grid>
  );
};
