import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { Box } from '@mui/material';
import { AddImage } from '@one/api-models/lib/Admin/Media/AddImage';
import { ComponentType } from '@one/api-models/lib/Admin/Media/ComponentType';
import { GenerateImageVariantsRequest } from '@one/api-models/lib/Admin/Media/GenerateImageVariantsRequest';
import { ImageRecord } from '@one/api-models/lib/Admin/Media/ImageRecord';
import { LoadCriteria } from '@one/api-models/lib/Admin/Media/LoadCriteria';
import { LoadResponse } from '@one/api-models/lib/Admin/Media/LoadResponse';
import { MediaRecord } from '@one/api-models/lib/Admin/Media/MediaRecord';
import { RemoveRequest } from '@one/api-models/lib/Admin/Media/RemoveRequest';
import { SetPrimaryRequest } from '@one/api-models/lib/Admin/Media/SetPrimaryRequest';
import { StoreRequestBase } from '@one/api-models/lib/Admin/Media/StoreRequestBase';
import { StoreResponse } from '@one/api-models/lib/Admin/Media/StoreResponse';
import { UpdateDetailsRequest } from '@one/api-models/lib/Admin/Media/UpdateDetailsRequest';

import { ApiError } from 'apiAccess/api-client';
import { ConfirmationDialog } from 'common/ConfirmationDialog';
import { GeneratingImageVariant } from 'common/mediaManager/GeneratingImageVariant';
import { MediaDropZone, MediaDropZoneSkeleton } from 'common/mediaManager/MediaDropZone';
import { MediaListing, MediaListingSkeleton } from 'common/mediaManager/MediaListing';
import { useApiHelpers } from 'hooks/useApiHelpers';
import { useToastMessage } from 'hooks/useToastMessage';

type MediaManagerProps = {
  entityId: string;
  partitionKey: string;
  componentType: ComponentType;
  testId: string;
};

export const MediaManager = ({ entityId, partitionKey, componentType, testId }: MediaManagerProps) => {
  const { addApiError, showMessage } = useToastMessage();
  const { api } = useApiHelpers();
  const [items, setItems] = useState<MediaRecord[] | undefined>(undefined);
  const [itemToDelete, setItemToDelete] = useState<string | undefined>(undefined);
  const cultureCode = 'en-US';

  //#region Load
  const loadQuery = useQuery<LoadResponse, ApiError>(
    'media',
    () => {
      return api.products.mediaLoad({
        $Type: LoadCriteria.$type,
        entityId,
        cultureCode,
        componentType,
        partitionKey,
      } as LoadCriteria);
    },
    {
      enabled: true,
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: true,
      onError: (error: ApiError) => addApiError(error),
      onSuccess: (data: LoadResponse) => {
        setItems([...data?.items]);
      },
    },
  );
  const { isLoading } = loadQuery;

  //#region Store
  const storeRecordMutation = useMutation<
    StoreResponse,
    ApiError,
    {
      storeRequest: StoreRequestBase;
    },
    unknown
  >(
    async ({ storeRequest }: { storeRequest: StoreRequestBase }) => {
      return await api.products.mediaStore(storeRequest);
    },
    {
      onSuccess: (value, variables) => {
        if (value && items) {
          if (value && items) {
            value.mediaRecords?.forEach((changedItem) => {
              const idx = items?.findIndex((x) => x.id === changedItem.id);
              if (idx === -1) {
                items.push(changedItem);
              } else {
                items[idx] = changedItem;
              }
              setItems(items);
            });

            showActionSuccessMessage(variables.storeRequest);
          }
        }
      },
      onError: (error: ApiError) => {
        addApiError(error);
        loadQuery.refetch();
      },
    },
  );

  const showActionSuccessMessage = (storeRequest?: StoreRequestBase) => {
    if (storeRequest?.$Type === AddImage.$type) showMessage('Image saved', 'success');
    if (storeRequest?.$Type === UpdateDetailsRequest.$type) showMessage('Media record saved', 'success');
    if (storeRequest?.$Type === RemoveRequest.$type) showMessage('Media record deleted', 'success');
  };
  //#endregion Store

  //#region OperationHandlers
  const storeRequestBase = {
    entityId,
    componentType,
    cultureCode,
    partitionKey,
  } as StoreRequestBase;

  const onNoteChangedHandler = (id: string, newValue: string) => {
    const item = items?.find((x) => x.id === id);
    if (item) {
      item.notes = newValue;
    }
    setItems(items);

    storeRecordMutation.mutate({
      storeRequest: {
        $Type: UpdateDetailsRequest.$type,
        mediaRecordId: id,
        notes: newValue,
        caption: item?.caption,
        ...storeRequestBase,
      } as UpdateDetailsRequest,
    });
  };

  const onCaptionChangedHandler = (id: string, newValue: string) => {
    const item = items?.find((x) => x.id === id);
    if (item) {
      item.caption = newValue;
    }
    setItems(items);

    storeRecordMutation.mutate({
      storeRequest: {
        $Type: UpdateDetailsRequest.$type,
        mediaRecordId: id,
        caption: newValue,
        notes: item?.notes,
        ...storeRequestBase,
      } as UpdateDetailsRequest,
    });
  };

  const onSetPrimaryHandler = (id: string) => {
    storeRecordMutation.mutate({
      storeRequest: {
        $Type: SetPrimaryRequest.$type,
        mediaRecordId: id,
        ...storeRequestBase,
      } as SetPrimaryRequest,
    });
    items?.forEach((x) => {
      const f = x.id === id;
      x.isPrimary = f;
    });
    setItems(items);
  };

  const onGenerateVariantsHandler = (id: string) => {
    storeRecordMutation.mutate({
      storeRequest: {
        $Type: GenerateImageVariantsRequest.$type,
        mediaRecordIds: [id],
        ...storeRequestBase,
      } as GenerateImageVariantsRequest,
    });
    const item = items?.find((x) => x.id === id);
    if (item) {
      (item as ImageRecord).variants = [{ generating: true, name: '' } as GeneratingImageVariant];
    }
  };

  const deleteHandler = (id: string) => {
    storeRecordMutation.mutate({
      storeRequest: {
        $Type: RemoveRequest.$type,
        mediaRecordIds: [id],
        ...storeRequestBase,
      } as RemoveRequest,
    });
    items?.splice(
      items.findIndex((x) => x.id === itemToDelete),
      1,
    );
    setItems(items);
  };
  //#endregion OperationHandlers

  const onDeleteHandler = (id: string) => {
    setItemToDelete(id);
  };

  const deleteConfirmationClose = (isConfirmed: boolean) => {
    if (isConfirmed && itemToDelete) {
      deleteHandler(itemToDelete);
    }
    setItemToDelete(undefined);
  };

  return (
    <Box>
      {!isLoading && items && (
        <>
          <MediaDropZone
            storeRequestBase={storeRequestBase}
            storeRecordMutation={storeRecordMutation}
            testId={testId}
          />
          <Box sx={{ pt: 3 }}>
            {items && items.length > 0 && (
              <MediaListing
                items={items}
                onNoteChanged={onNoteChangedHandler}
                onCaptionChanged={onCaptionChangedHandler}
                onDelete={onDeleteHandler}
                onSetPrimary={onSetPrimaryHandler}
                onGenerateVariants={onGenerateVariantsHandler}
                testId={`${testId}MediaListing`}
              />
            )}
          </Box>
          <ConfirmationDialog
            confirmButton="Yes, Delete"
            abortButton="No, Abort"
            onClose={deleteConfirmationClose}
            open={!!itemToDelete}
            title="Delete media file"
            question="Are you sure you want to delete the file?"
            testId={`${testId}DeleteMediaFileDialog`}
          />
        </>
      )}

      {(isLoading || !items) && (
        <>
          <MediaDropZoneSkeleton />
          <Box sx={{ pt: 3 }}>
            <MediaListingSkeleton />
          </Box>
        </>
      )}
    </Box>
  );
};
