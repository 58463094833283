import { Paper, Skeleton, Typography } from '@mui/material';

export const DetailsSkeleton = () => (
  <Paper sx={{ p: 3 }}>
    <Skeleton variant="rectangular" height={720} />
  </Paper>
);

export const TreeSkeleton = () => (
  <Paper sx={{ p: 3 }}>
    <Typography variant="h4" sx={{ mb: 3 }}>
      Tags
    </Typography>
    <Skeleton variant="rectangular" height={95} />
  </Paper>
);
