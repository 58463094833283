interface AirlineLoyaltyProgram {
  airlineCode: string;
  value: string;
  name: string;
}

// Keep this list in sync with the airlinesFrequentFlyer list in Storefronts
export const airlinesFrequentFlyer: AirlineLoyaltyProgram[] = [
  {
    airlineCode: 'EI',
    value: 'EI-0',
    name: 'Aer Lingus AerClub',
  },
  {
    airlineCode: 'SU',
    value: 'SU-0',
    name: 'Aeroflot Bonus',
  },
  {
    airlineCode: 'AR',
    value: 'AR-0',
    name: 'Aerolíneas Plus',
  },
  {
    airlineCode: 'AM',
    value: 'AM-0',
    name: 'Aeromexico Rewards',
  },
  {
    airlineCode: 'AH',
    value: 'AH-0',
    name: 'Air Algerie Plus',
  },
  {
    airlineCode: 'KC',
    value: 'KC-0',
    name: 'Air Astana Nomad Club',
  },
  {
    airlineCode: 'UU',
    value: 'UU-0',
    name: 'Air Austral - MyCapricorne',
  },
  {
    airlineCode: 'AC',
    value: 'AC-0',
    name: 'Air Canada Aeroplan',
  },
  {
    airlineCode: 'CA',
    value: 'CA-0',
    name: 'Air China PhoenixMiles',
  },
  {
    airlineCode: 'YN',
    value: 'YN-0',
    name: 'Air Creebec Aeroplan',
  },
  {
    airlineCode: 'EN',
    value: 'EN-0',
    name: 'Air Dolomiti Miles & More',
  },
  {
    airlineCode: 'UX',
    value: 'UX-0',
    name: 'Air Europa SUMA',
  },
  {
    airlineCode: 'AF',
    value: 'AF-0',
    name: 'Air France Flying Blue',
  },
  {
    airlineCode: 'A5',
    value: 'A5-0',
    name: 'Air France Hop - Flying Blue',
  },
  {
    airlineCode: 'AI',
    value: 'AI-0',
    name: 'Air India Flying Returns',
  },
  {
    airlineCode: 'NX',
    value: 'NX-0',
    name: 'Air Macau - Phoenix Miles',
  },
  {
    airlineCode: 'MK',
    value: 'MK-0',
    name: 'Air Mauritius KestrelFlyer',
  },
  {
    airlineCode: 'MD',
    value: 'MD-0',
    name: 'Air Moldova - MyCapricorne',
  },
  {
    airlineCode: 'NZ',
    value: 'NZ-0',
    name: 'Air New Zealand Air Points',
  },
  {
    airlineCode: 'YW',
    value: 'YW-0',
    name: 'Air Nostrum Iberia Plus',
  },
  {
    airlineCode: 'JU',
    value: 'JU-0',
    name: 'Air Serbia - Etihad Guest',
  },
  {
    airlineCode: 'TN',
    value: 'TN-0',
    name: 'Air Tahiti Nui Club Tiare',
  },
  {
    airlineCode: 'NF',
    value: 'NF-0',
    name: 'Air Vanuatu -Qantas Frequent Flyer',
  },
  {
    airlineCode: 'UM',
    value: 'UM-0',
    name: 'Air Zimbabwe Rainbow Club',
  },
  {
    airlineCode: 'BT',
    value: 'BT-0',
    name: 'AirBaltic Club',
  },
  {
    airlineCode: 'SB',
    value: 'SB-0',
    name: 'Aircalin Flying Blue',
  },
  {
    airlineCode: 'AS',
    value: 'AS-MV',
    name: 'Alaska - MVP',
  },
  {
    airlineCode: 'AS',
    value: 'AS-MG',
    name: 'Alaska - MVP Gold',
  },
  {
    airlineCode: 'AS',
    value: 'AS-0',
    name: 'Alaska Airlines - Alaska Mileage Plan',
  },
  {
    airlineCode: 'SV',
    value: 'SV-0',
    name: 'AlFursan',
  },
  {
    airlineCode: 'AA',
    value: 'AA-AA',
    name: 'American - AAdvantage',
  },
  {
    airlineCode: 'AA',
    value: 'AA-EXP',
    name: 'American AAdvantage - Executive Platinum',
  },
  {
    airlineCode: 'AA',
    value: 'AA-GLD',
    name: 'American AAdvantage - Gold',
  },
  {
    airlineCode: 'AA',
    value: 'AA-PLT',
    name: 'American AAdvantage - Platinum',
  },
  {
    airlineCode: 'AA',
    value: 'AA-0',
    name: 'American AAdvantage - Platinum Pro',
  },
  {
    airlineCode: 'NH',
    value: 'NH-0',
    name: 'ANA Mileage Club',
  },
  {
    airlineCode: 'K6',
    value: 'K6-0',
    name: 'AngkorWards',
  },
  {
    airlineCode: 'KQ',
    value: 'KQ-0',
    name: 'Asante Rewards',
  },
  {
    airlineCode: 'OZ',
    value: 'OZ-0',
    name: 'Asiana Club',
  },
  {
    airlineCode: '5T',
    value: '5T-0',
    name: 'Aurora Rewards',
  },
  {
    airlineCode: 'OS',
    value: 'OS-0',
    name: 'Austrian Miles & More',
  },
  {
    airlineCode: '2K',
    value: '2K-0',
    name: 'Avianca Ecuador - LifeMiles',
  },
  {
    airlineCode: 'GU',
    value: 'GU-0',
    name: 'Avianca Guatemala - LifeMiles',
  },
  {
    airlineCode: 'AV',
    value: 'AV-0',
    name: 'Avianca LifeMiles',
  },
  {
    airlineCode: 'AD',
    value: 'AD-0',
    name: 'Azul TudoAzul',
  },
  {
    airlineCode: 'UP',
    value: 'UP-0',
    name: 'Bahamasair Frequent Flyer Program',
  },
  {
    airlineCode: 'PG',
    value: 'PG-0',
    name: 'Bangkok Airways FlyerBonus',
  },
  {
    airlineCode: 'B2',
    value: 'B2-0',
    name: 'Belavia Leader',
  },
  {
    airlineCode: 'OM',
    value: 'OM-0',
    name: 'Blue Sky Mongolia',
  },
  {
    airlineCode: 'MX',
    value: 'MX-0',
    name: 'BreezePoints',
  },
  {
    airlineCode: 'BA',
    value: 'BA-0',
    name: 'British Airways Executive Club',
  },
  {
    airlineCode: 'SN',
    value: 'SN-SN',
    name: 'Brussels Airlines - Miles & More',
  },
  {
    airlineCode: 'JY',
    value: 'JY-0',
    name: 'Cacique Rewards',
  },
  {
    airlineCode: 'BW',
    value: 'BW-0',
    name: 'Caribbean Miles',
  },
  {
    airlineCode: 'CX',
    value: 'CX-0',
    name: 'Cathay',
  },
  {
    airlineCode: 'KX',
    value: 'KX-0',
    name: 'Cayman Sir Turtle Rewards',
  },
  {
    airlineCode: 'QV',
    value: 'QV-0',
    name: 'Champa Muang Lao',
  },
  {
    airlineCode: 'CI',
    value: 'CI-0',
    name: 'China Airlines Dynasty Flyer',
  },
  {
    airlineCode: 'CZ',
    value: 'CZ-0',
    name: 'China Southern Sky Pearl Club',
  },
  {
    airlineCode: 'DE',
    value: 'DE-0',
    name: 'Condor - Alaska Mileage Plan',
  },
  {
    airlineCode: 'CM',
    value: 'CM-CCM',
    name: 'Copa Airlines ConnectMiles',
  },
  {
    airlineCode: 'JX',
    value: 'JX-0',
    name: 'COSMILE',
  },
  {
    airlineCode: 'OU',
    value: 'OU-0',
    name: 'Croatia Miles & More',
  },
  {
    airlineCode: 'OK',
    value: 'OK-0',
    name: 'Czech OK Plus',
  },
  {
    airlineCode: '9J',
    value: '9J-0',
    name: 'Dana Miles',
  },
  {
    airlineCode: 'DL',
    value: 'DL-SKY',
    name: 'Delta SkyMiles',
  },
  {
    airlineCode: 'DL',
    value: 'DL-0',
    name: 'Delta SkyMiles - Diamond Medallion',
  },
  {
    airlineCode: 'DL',
    value: 'DL-GLD',
    name: 'Delta SkyMiles - Gold Medallion',
  },
  {
    airlineCode: 'DL',
    value: 'DL-PLT',
    name: 'Delta SkyMiles - Platinum Medallion',
  },
  {
    airlineCode: 'DL',
    value: 'DL-MDL',
    name: 'Delta SkyMiles - Silver Medallion',
  },
  {
    airlineCode: 'PX',
    value: 'PX-0',
    name: 'Destinations',
  },
  {
    airlineCode: 'MU',
    value: 'MU-0',
    name: 'Eastern Miles',
  },
  {
    airlineCode: 'MF',
    value: 'MF-0',
    name: 'Egret Miles',
  },
  {
    airlineCode: 'MS',
    value: 'MS-0',
    name: 'EgyptAir Plus',
  },
  {
    airlineCode: 'LY',
    value: 'LY-0',
    name: 'El Al Matmid Club',
  },
  {
    airlineCode: 'EK',
    value: 'EK-0',
    name: 'Emirates Skywards',
  },
  {
    airlineCode: 'EY',
    value: 'EY-0',
    name: 'Etihad Airways - Etihad Guest',
  },
  {
    airlineCode: 'EW',
    value: 'EW-0',
    name: 'Eurowings - Miles & More',
  },
  {
    airlineCode: 'BR',
    value: 'BR-0',
    name: 'EVA Air Infinity MileageLands',
  },
  {
    airlineCode: 'AY',
    value: 'AY-0',
    name: 'Finnair - Finnair Plus',
  },
  {
    airlineCode: 'FB',
    value: 'FB-0',
    name: 'FLY MORE',
  },
  {
    airlineCode: '7H',
    value: '7H-0',
    name: 'Flycoin',
  },
  {
    airlineCode: 'JD',
    value: 'JD-0',
    name: 'Fortune Wings Club',
  },
  {
    airlineCode: 'F9',
    value: 'F9-0',
    name: 'FRONTIER Miles',
  },
  {
    airlineCode: 'GA',
    value: 'GA-GIA',
    name: 'Garuda Miles',
  },
  {
    airlineCode: 'G3',
    value: 'G3-0',
    name: 'GOL Smiles',
  },
  {
    airlineCode: 'GF',
    value: 'GF-0',
    name: 'Gulf Air FalconFlyer',
  },
  {
    airlineCode: 'HU',
    value: 'HU-0',
    name: 'Hainan Fortune Wings Club',
  },
  {
    airlineCode: 'HA',
    value: 'HA-0',
    name: 'Hawaiian Airlines HawaiianMiles',
  },
  {
    airlineCode: 'HA',
    value: 'HA-HGP',
    name: 'Hawaiian Airlines HawaiianMiles',
  },
  {
    airlineCode: 'HX',
    value: 'HX-0',
    name: 'Hong Kong Airlines Fortune Wings Club',
  },
  {
    airlineCode: 'QX',
    value: 'QX-0',
    name: 'Horizon Air Mileage Plan',
  },
  {
    airlineCode: 'BU',
    value: 'BU-0',
    name: 'Horizons',
  },
  {
    airlineCode: 'IB',
    value: 'IB-0',
    name: 'Iberia Plus',
  },
  {
    airlineCode: 'QI',
    value: 'QI-0',
    name: 'Ibom Flyer',
  },
  {
    airlineCode: 'FI',
    value: 'FI-0',
    name: 'Icelandair Saga Club',
  },
  {
    airlineCode: 'IR',
    value: 'IR-0',
    name: 'IranAir SkyGift',
  },
  {
    airlineCode: 'AZ',
    value: 'AZ-0',
    name: 'ITA Airways Volare',
  },
  {
    airlineCode: 'JM',
    value: 'JM-0',
    name: 'Jambojet Rewards',
  },
  {
    airlineCode: 'JL',
    value: 'JL-0',
    name: 'Japan Airlines Mileage Bank',
  },
  {
    airlineCode: 'NU',
    value: 'NU-0',
    name: 'Japan Transocean Air - JAL Mileage Bank',
  },
  {
    airlineCode: 'JC',
    value: 'JC-0',
    name: 'JC International Airlines - JAL Mileage Bank',
  },
  {
    airlineCode: 'B6',
    value: 'B6-0',
    name: 'JetBlue TrueBlue',
  },
  {
    airlineCode: 'JQ',
    value: 'JQ-0',
    name: 'Jetstar - Jetstar Airways Qantas Frequent Flyer',
  },
  {
    airlineCode: 'GK',
    value: 'GK-0',
    name: 'Jetstar Japan - Jetstar Airways Qantas Frequent Flyer',
  },
  {
    airlineCode: 'KL',
    value: 'KL-0',
    name: 'KLM Flying Blue',
  },
  {
    airlineCode: 'KM',
    value: 'KM-0',
    name: 'KM Rewards',
  },
  {
    airlineCode: 'KS',
    value: 'KS-0',
    name: 'KONnectibles',
  },
  {
    airlineCode: 'KE',
    value: 'KE-0',
    name: 'Korean Airlines Skypass',
  },
  {
    airlineCode: 'TR',
    value: 'TR-0',
    name: 'KrisFlyer',
  },
  {
    airlineCode: 'KU',
    value: 'KU-0',
    name: 'Kuwait Airways Oasis Club',
  },
  {
    airlineCode: 'LR',
    value: 'LR-0',
    name: 'Lacsa LifeMiles',
  },
  {
    airlineCode: 'TM',
    value: 'TM-0',
    name: 'LAM Mozambique Airlines Flamingo Club',
  },
  {
    airlineCode: 'LP',
    value: 'LP-0',
    name: 'LAN Peru LATAM Pass',
  },
  {
    airlineCode: 'LA',
    value: 'LA-0',
    name: 'LATAM Airlines - LATAM Pass',
  },
  {
    airlineCode: 'JJ',
    value: 'JJ-0',
    name: 'LATAM Airlines Brasil - LATAM Pass',
  },
  {
    airlineCode: 'PZ',
    value: 'PZ-0',
    name: 'LATAM Airlines Paraguay - LATAM Pass',
  },
  {
    airlineCode: 'IW',
    value: 'IW-0',
    name: 'Lion Air Passport Club',
  },
  {
    airlineCode: 'LO',
    value: 'LO-0',
    name: 'LOT Miles & More',
  },
  {
    airlineCode: 'LH',
    value: 'LH-0',
    name: 'Lufthansa Miles and More',
  },
  {
    airlineCode: 'LG',
    value: 'LG-0',
    name: 'Luxair Miles & More',
  },
  {
    airlineCode: 'MH',
    value: 'MH-0',
    name: 'Malaysia Airlines - Enrich',
  },
  {
    airlineCode: 'OD',
    value: 'OD-0',
    name: 'Malindo Miles',
  },
  {
    airlineCode: 'AE',
    value: 'AE-0',
    name: 'Mandarin Dynasty Flyer',
  },
  {
    airlineCode: '4M',
    value: '4M-0',
    name: 'Mavi Gök Airlines - LATAM Pass',
  },
  {
    airlineCode: 'ME',
    value: 'ME-0',
    name: 'Middle East Cedar Miles',
  },
  {
    airlineCode: 'XE',
    value: 'XE-0',
    name: 'MileagePlus',
  },
  {
    airlineCode: 'A3',
    value: 'A3-0',
    name: 'Miles+Bonus',
  },
  {
    airlineCode: 'OV',
    value: 'OV-0',
    name: 'More on Air',
  },
  {
    airlineCode: 'N7',
    value: 'N7-0',
    name: 'Nordic Airlines - Finnair Plus',
  },
  {
    airlineCode: 'DY',
    value: 'DY-0',
    name: 'norwegianreward',
  },
  {
    airlineCode: 'PK',
    value: 'PK-0',
    name: 'Pakistan Awards Plus',
  },
  {
    airlineCode: 'PC',
    value: 'PC-0',
    name: 'Pegasus BolBol',
  },
  {
    airlineCode: 'PR',
    value: 'PR-0',
    name: 'Philippine Mabuhay Miles',
  },
  {
    airlineCode: 'YP',
    value: 'YP-0',
    name: 'Premia Members',
  },
  {
    airlineCode: 'PU',
    value: 'PU-0',
    name: 'PREMIER',
  },
  {
    airlineCode: 'UA',
    value: 'UA-PRX',
    name: 'Premier 1K',
  },
  {
    airlineCode: 'UA',
    value: 'UA-PRE',
    name: 'Premier Gold',
  },
  {
    airlineCode: 'UA',
    value: 'UA-PRK',
    name: 'Premier Platinum',
  },
  {
    airlineCode: 'UA',
    value: 'UA-MPL',
    name: 'Premier Silver',
  },
  {
    airlineCode: 'QF',
    value: 'QF-0',
    name: 'Qantas Frequent Flyer',
  },
  {
    airlineCode: 'QR',
    value: 'QR-0',
    name: 'Qatar Privilege Club',
  },
  {
    airlineCode: 'ZL',
    value: 'ZL-0',
    name: 'Regional Express Rex Flyer',
  },
  {
    airlineCode: 'AT',
    value: 'AT-0',
    name: 'Royal Air Maroc Safar Flyer',
  },
  {
    airlineCode: 'BI',
    value: 'BI-0',
    name: 'Royal Brunei Royal Skies',
  },
  {
    airlineCode: 'RJ',
    value: 'RJ-0',
    name: 'Royal Club',
  },
  {
    airlineCode: 'S7',
    value: 'S7-0',
    name: 'S7 Priority',
  },
  {
    airlineCode: 'IY',
    value: 'IY-0',
    name: 'Sama Club',
  },
  {
    airlineCode: 'SK',
    value: 'SK-0',
    name: 'SAS EuroBonus',
  },
  {
    airlineCode: 'S4',
    value: 'S4-0',
    name: 'SATA Imagine',
  },
  {
    airlineCode: 'FM',
    value: 'FM-0',
    name: 'Shanghai Airlines Eastern Miles',
  },
  {
    airlineCode: 'ET',
    value: 'ET-0',
    name: 'ShebaMiles',
  },
  {
    airlineCode: 'ZH',
    value: 'ZH-0',
    name: 'Shenzhen Airlines - Phoenix Miles',
  },
  {
    airlineCode: '3U',
    value: '3U-0',
    name: 'Sichuan Airlines Golden Panda',
  },
  {
    airlineCode: 'WY',
    value: 'WY-0',
    name: 'Sindbad',
  },
  {
    airlineCode: 'SQ',
    value: 'SQ-0',
    name: 'Singapore Airlines KrisFlyer',
  },
  {
    airlineCode: '8M',
    value: '8M-0',
    name: 'Sky Smile',
  },
  {
    airlineCode: 'HF',
    value: 'HF-0',
    name: 'sMiles',
  },
  {
    airlineCode: 'SA',
    value: 'SA-0',
    name: 'South African Airways Voyager',
  },
  {
    airlineCode: 'WN',
    value: 'WN-0',
    name: 'Southwest Airlines Rapid Rewards',
  },
  {
    airlineCode: 'NK',
    value: 'NK-0',
    name: 'Spirit Airlines Free Spirit',
  },
  {
    airlineCode: 'UL',
    value: 'UL-0',
    name: 'SriLankan FlySmiLes',
  },
  {
    airlineCode: 'SY',
    value: 'SY-0',
    name: 'Sun Country Rewards',
  },
  {
    airlineCode: 'LX',
    value: 'LX-0',
    name: 'Swiss Miles & More',
  },
  {
    airlineCode: 'DT',
    value: 'DT-0',
    name: 'TAAG Umbi Umbi Club',
  },
  {
    airlineCode: 'TA',
    value: 'TA-0',
    name: 'Taca LifeMiles',
  },
  {
    airlineCode: 'TP',
    value: 'TP-0',
    name: 'TAP Miles&Go',
  },
  {
    airlineCode: 'RO',
    value: 'RO-0',
    name: 'TAROM - Flying Blue',
  },
  {
    airlineCode: 'TG',
    value: 'TG-0',
    name: 'Thai Royal Orchid Plus',
  },
  {
    airlineCode: 'IT',
    value: 'IT-0',
    name: 'Tigerclub',
  },
  {
    airlineCode: 'TU',
    value: 'TU-0',
    name: 'Tunis Air Fidelys',
  },
  {
    airlineCode: 'TK',
    value: 'TK-0',
    name: 'Turkish Airlines Miles & Smiles',
  },
  {
    airlineCode: 'T7',
    value: 'T7-0',
    name: 'Twin Jet Flying Blue',
  },
  {
    airlineCode: 'UA',
    value: 'UA-0',
    name: 'United MileagePlus',
  },
  {
    airlineCode: 'VN',
    value: 'VN-0',
    name: 'Vietnam Airlines Lotusmiles',
  },
  {
    airlineCode: 'PD',
    value: 'PD-0',
    name: 'VIPorter',
  },
  {
    airlineCode: 'VS',
    value: 'VS-0',
    name: 'Virgin Atlantic Flying Club',
  },
  {
    airlineCode: 'VA',
    value: 'VA-0',
    name: 'Virgin Australia (VA) Velocity',
  },
  {
    airlineCode: 'VY',
    value: 'VY-0',
    name: 'Vueling Club',
  },
  {
    airlineCode: 'WS',
    value: 'WS-0',
    name: 'WestJet Rewards',
  },
  {
    airlineCode: 'WF',
    value: 'WF-0',
    name: 'Widerøe Eurobonus',
  },
  {
    airlineCode: 'U6',
    value: 'U6-0',
    name: 'Wings',
  },
  {
    airlineCode: 'XL',
    value: 'XL-0',
    name: 'XL Airways France - LATAM Pass',
  },
];
