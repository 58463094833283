import { useMutation } from 'react-query';

import { Box } from '@mui/material';
import { AddFile } from '@one/api-models/lib/Admin/Files/AddFile';
import { ImportRatesRequest } from '@one/api-models/lib/Admin/Files/Product/Travel/Accommodation/ImportRatesRequest';
import { StoreRequestBase } from '@one/api-models/lib/Admin/Files/StoreRequestBase';
import { StoreResponseBase } from '@one/api-models/lib/Admin/Files/StoreResponseBase';

import { ApiError } from 'apiAccess/api-client';
import { FileDropZone } from 'common/fileManager/FileDropZone';
import { useApiHelpers } from 'hooks/useApiHelpers';
import { useToastMessage } from 'hooks/useToastMessage';

interface Props {
  acceptedFiles: string;
  testId: string;
  onSuccess?: (response: StoreResponseBase) => void;
  successMessage?: string;
}

export const FileManager: React.FC<Props> = ({ acceptedFiles, testId, onSuccess, successMessage }: Props) => {
  const { addApiError, showMessage } = useToastMessage();
  const { api } = useApiHelpers();

  const storeRecordMutation = useMutation<
    StoreResponseBase,
    ApiError,
    {
      storeRequest: StoreRequestBase;
    },
    unknown
  >(
    async ({ storeRequest }: { storeRequest: StoreRequestBase }) => {
      return await api.products.filesStore(storeRequest);
    },
    {
      onSuccess: (value, variables) => {
        if (value) {
          if (value) {
            showActionSuccessMessage(variables.storeRequest);
            if (onSuccess) onSuccess(value);
          }
        }
      },
      onError: (error: ApiError) => {
        addApiError(error);
      },
    },
  );

  const showActionSuccessMessage = (storeRequest?: StoreRequestBase) => {
    if (successMessage) showMessage(successMessage, 'success');
  };

  return (
    <Box sx={{ width: '100%' }}>
      <FileDropZone<ImportRatesRequest>
        acceptedFiles={acceptedFiles}
        storeRecordMutation={storeRecordMutation}
        storeRequestBase={{ $Type: ImportRatesRequest.$type, files: [] as AddFile[] } as ImportRatesRequest}
        buttonText="Process file"
        helperText='* by pressing the "PROCESS FILE" button, you are going to add new, or overwrite the existing rates of the product.'
        testId={testId}
      />
    </Box>
  );
};

FileManager.defaultProps = {
  successMessage: 'File Saved',
};
