import { useEffect } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import { DestinationContent } from '@one/api-models/lib/Admin/Products/Travel/Cruise/DestinationContent';
import { UpdateDestination } from '@one/api-models/lib/Admin/Products/Travel/Cruise/UpdateDestination';
import { UpdateDestinationDetails } from '@one/api-models/lib/Admin/Products/Travel/Cruise/UpdateDestinationDetails';

import { ActionButton } from 'common/ActionButton';
import ControlledTextField from 'common/inputs/defaultFields/ControlledTextField';
import { AddressFields } from 'modules/products/components/AddressFields';

import { FieldBox } from '../FieldBox';
import { ProductBaseFields } from '../ProductBaseFields';

import { DetailsTabProps, FormProps } from './DetailsBase';

type DestinationDetailsForm = {
  name?: string;
  displayName?: string;
  'address.line1'?: string;
  'address.line2'?: string;
  'address.city'?: string;
  'address.stateProvinceName'?: string;
  'address.postalCode'?: string;
  'address.countryCode'?: string;
  latitude?: number;
  longitude?: number;
  supplierReferences?: { reference?: string }[];
};

const validationSchema: yup.SchemaOf<DestinationDetailsForm> = yup.object().shape({
  name: yup.string().trim().matches(/.{3,}/, {
    excludeEmptyString: true,
    message: 'Use at least 3 characters.',
  }),
  displayName: yup.string().trim().matches(/.{3,}/, {
    excludeEmptyString: true,
    message: 'Use at least 3 characters.',
  }),
  'address.line1': yup.string().trim(),
  'address.line2': yup.string().trim(),
  'address.city': yup.string().trim(),
  'address.stateProvinceName': yup.string().trim(),
  'address.postalCode': yup.string().trim(),
  'address.countryCode': yup.string().trim(),
  latitude: yup.number().transform((_, val) => (val !== '' ? Number(val) : undefined)),
  longitude: yup.number().transform((_, val) => (val !== '' ? Number(val) : undefined)),
  supplierReferences: yup.array().of(
    yup.object().shape({
      reference: yup.string().trim(),
    }),
  ),
});

export const DestinationDetailsTab = ({
  product: productBase,
  onSave,
  isLoading,
  onFormDirty,
  suppliers,
  testId,
}: DetailsTabProps): React.ReactNode => {
  const product = productBase as DestinationContent;

  const defaultValues = (product: DestinationContent) => ({
    name: product.name,
    displayName: product.displayName,
    address: product.address,
    latitude: product.latitude,
    longitude: product.longitude,
    supplierReferences: product.supplierReferences,
  });

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    reset,
    control,
  } = useForm<UpdateDestinationDetails>({
    mode: 'onBlur',
    defaultValues: defaultValues(product),
    resolver: yupResolver(validationSchema),
  });

  const formProps = {
    register: register,
    errors: errors,
    control: control,
    getValues,
  } as unknown as FormProps;

  useEffect(() => {
    onFormDirty(isDirty && dirtyFields && Object.keys(dirtyFields).length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, dirtyFields, Object.keys(dirtyFields).length]);

  useEffect(() => {
    reset(defaultValues(product));
  }, [reset, product]);

  const saveHandler = (data: UpdateDestinationDetails) => {
    const details = data as any;
    delete details.$type;
    if (onSave) {
      const req = {
        $type: UpdateDestination.$Type,
        productId: product.id,
        cultureCode: product.cultureCode,
        details: {
          $type: UpdateDestinationDetails.$Type,
          ...details,
        },
      } as UpdateDestination;

      onSave(req);
    }
  };

  return (
    <Box sx={{ mt: 1, width: '100%' }}>
      <form onSubmit={handleSubmit(saveHandler)} autoComplete="off">
        <Grid container direction="row" alignItems="stretch">
          <Grid item xs={12} sx={{ mb: 2 }}>
            <ProductBaseFields formProps={formProps} suppliers={suppliers} isLoading={isLoading} testId={testId} />
          </Grid>

          <Grid item xs={7}>
            <AddressFields address={product?.address} formProps={formProps} isLoading={isLoading} testId={testId} />
          </Grid>

          <Grid item xs={5} sx={{ pl: 2 }}>
            <FieldBox title="Location">
              <Grid container direction="row" justifyContent="space-evenly" alignItems="stretch">
                <Grid item xs={12} sx={{ mb: 3 }}>
                  <ControlledTextField
                    control={control as unknown as Control<FieldValues, object>}
                    name="latitude"
                    fullWidth
                    label="Latitude"
                    disabled={isLoading}
                    type="number"
                    error={errors?.latitude?.message != null}
                    helperText={errors?.latitude?.message}
                    testId={`${testId}Latitude`}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mb: 3 }}>
                  <ControlledTextField
                    control={control as unknown as Control<FieldValues, object>}
                    name="longitude"
                    fullWidth
                    label="Longitude"
                    disabled={isLoading}
                    type="number"
                    error={errors?.longitude?.message != null}
                    helperText={errors?.longitude?.message}
                    testId={`${testId}Longitude`}
                  />
                </Grid>
              </Grid>
            </FieldBox>
          </Grid>

          <Grid item sx={{ pr: 3 }} xs={12} container direction="row" justifyContent="flex-end" alignItems="baseline">
            <Grid item>
              <ActionButton
                icon="edit"
                loading={isLoading}
                type="submit"
                disabled={!(isDirty && dirtyFields && Object.keys(dirtyFields).length > 0)}
                testId={`${testId}Save`}
              >
                SAVE
              </ActionButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
