import { SelectField } from 'models/SelectField';

export const SALUTATIONS: SelectField[] = [
  { value: 'Mrs', label: 'Mrs' },
  { value: 'Dr', label: 'Dr' },
  { value: 'Miss', label: 'Miss' },
  { value: 'Mx', label: 'Mx' },
  { value: 'Ms', label: 'Ms' },
  { value: 'Mr', label: 'Mr' },
];
