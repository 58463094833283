import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { PageHeader } from 'common/layout/components/PageHeader';

import { DataGridStyleGuide } from './components/DataGridStyleGuide';
import { NormalModalDialog } from './components/NormalModalDialog';
import { SideModalDialog } from './components/SideModalDialog';

type StyleGuideForm = {
  textField?: string;
  requiredTextField?: string;
  datePicker?: Date | null;
  datePicker2?: Date | null;
};

export const StyleGuide = () => {
  // const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);
  const [openNormalModal, setOpenNormalModal] = useState<boolean>(false);
  const [openSideModal, setOpenSideModal] = useState<boolean>(false);
  const testIdPrefix = 'StyleGuide';

  const validationSchema: yup.SchemaOf<StyleGuideForm> = yup.object().shape({
    textField: yup.string(),
    requiredTextField: yup.string(),
    datePicker: yup.date().typeError('Invalid date').nullable(),
    datePicker2: yup.date().typeError('Invalid date').nullable(),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<StyleGuideForm>({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  const handleFormSubmit = (data: StyleGuideForm) => {
    console.log(data);
  };

  return (
    <Box>
      <PageHeader title="Style Guide" testId={testIdPrefix} />
      <Box display="flex" flexDirection="column" gap={2} mb={3}>
        <Typography variant="h1">H1 - Page Title (fontSize = 28px, fontWeight = 700)</Typography>
        <Typography variant="h2">H2 - Lorem Ipsum (fontSize = 26px, fontWeight = 700)</Typography>
        <Typography variant="h3">H3 - Lorem Ipsum (fontSize = 22px, fontWeight = 400)</Typography>
        <Typography variant="h4">H4 - Lorem Ipsum (fontSize = 20px, fontWeight = 700)</Typography>
        <Typography variant="h5">H5 - Section title (fontSize = 16px, fontWeight = 700)</Typography>
        <Typography variant="h6">H6 - Dialog Title (fontSize = 16px, fontWeight = 500)</Typography>
        <Typography variant="subtitle1">subtitle1 (fontSize = 14px, fontWeight = 500)</Typography>
        <Typography variant="subtitle2">subtitle2 (fontSize = 14px, fontWeight = 600)</Typography>
        <Typography variant="body1">body1 (fontSize = 14px, fontWeight = 400, color: #424251)</Typography>
        <Typography variant="body2">body2 (fontSize = 14px, fontWeight = 400)</Typography>
        <Typography variant="caption">caption (fontSize = 12px, fontWeight = 400, color: #424251)</Typography>
      </Box>

      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Box>
          <Typography variant="h5">Buttons</Typography>

          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
            <Grid item md={12} sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box sx={{ pt: 2, mb: 3, mr: 2 }}>
                <Button variant="contained" size="small" disableRipple>
                  Contained
                </Button>
              </Box>
              <Box sx={{ pt: 2, mb: 3, mr: 2 }}>
                <Button variant="contained" size="small" disabled disableRipple>
                  Disabled
                </Button>
              </Box>
              <Box sx={{ pt: 2, mb: 3, mr: 2 }}>
                <Button variant="outlined" size="small" disableRipple>
                  Outlined
                </Button>
              </Box>
              <Box sx={{ pt: 2, mb: 3, mr: 2 }}>
                <Button variant="outlined" size="small" disableRipple disabled>
                  Outlined Disabled
                </Button>
              </Box>
              <Box sx={{ pt: 2, mb: 3, mr: 2 }}>
                <Button variant="text" size="small" disableRipple>
                  Text
                </Button>
              </Box>
              <Box sx={{ pt: 2, mb: 3, mr: 2 }}>
                <Button variant="text" size="small" disableRipple disabled>
                  Text Disabled
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Typography variant="h5">Text Field</Typography>

          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
            <Grid item md={2}>
              <Box sx={{ pt: 2, mb: 3 }}>
                <Controller
                  control={control}
                  name="textField"
                  render={({ field }) => (
                    <>
                      <Typography variant="subtitle2" pb={1}>
                        Text Field
                      </Typography>
                      <TextField
                        {...field}
                        placeholder="Text Field"
                        fullWidth
                        error={errors?.textField?.message != null}
                        helperText={errors?.textField?.message}
                        size="small"
                      />
                    </>
                  )}
                />
              </Box>
            </Grid>
            <Grid item md={2}>
              <Box sx={{ pt: 2, mb: 3 }}>
                <Controller
                  control={control}
                  name="requiredTextField"
                  render={({ field }) => (
                    <>
                      <Typography variant="subtitle2" pb={1}>
                        Required Text Field
                      </Typography>
                      <TextField
                        {...field}
                        placeholder="Text Field"
                        fullWidth
                        error
                        helperText="Text Field is required"
                        size="small"
                      />
                    </>
                  )}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Typography variant="h5">Date-Picker</Typography>

          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
            {/* <Grid item md={2}>
              <Box sx={{ pt: 2, mb: 3 }}>
                <Controller
                  control={control}
                  name="datePicker2"
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        {...field}
                        label="Date-Picker"
                        openTo="day"
                        OpenPickerButtonProps={{
                          size: 'small',
                          tabIndex: -1,
                          disableRipple: true,
                          disableFocusRipple: true,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={errors?.datePicker2?.message != null}
                            helperText={errors?.datePicker2?.message}
                            fullWidth
                            size="small"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Box>
            </Grid>
            <Grid item md={2}>
              <Box sx={{ pt: 2, mb: 3 }}>
                <Controller
                  control={control}
                  name="datePicker"
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        {...field}
                        label="Date-Picker"
                        openTo="day"
                        OpenPickerButtonProps={{
                          size: 'small',
                          tabIndex: -1,
                          disableRipple: true,
                          disableFocusRipple: true,
                          sx: {
                            marginLeft: '-13px',
                          },
                        }}
                        InputAdornmentProps={{
                          position: 'start',
                        }}
                        open={openDatePicker}
                        onOpen={() => setOpenDatePicker(true)}
                        onClose={() => setOpenDatePicker(false)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={errors?.datePicker?.message != null}
                            helperText={errors?.datePicker?.message}
                            fullWidth
                            size="small"
                            onClick={() => setOpenDatePicker(true)}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Box>
            </Grid> */}

            <Grid item md={2}>
              <Box sx={{ pt: 2, mb: 3 }}>
                <Controller
                  control={control}
                  name="datePicker"
                  render={({ field }) => (
                    <>
                      <Typography variant="subtitle2" pb={1}>
                        Datepicker
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          {...field}
                          openTo="day"
                          OpenPickerButtonProps={{
                            size: 'small',
                            tabIndex: -1,
                            disableRipple: true,
                            disableFocusRipple: true,
                            sx: {
                              marginLeft: '-13px',
                            },
                          }}
                          InputAdornmentProps={{
                            position: 'start',
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={errors?.datePicker?.message != null}
                              helperText={errors?.datePicker?.message}
                              fullWidth
                              size="small"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </>
                  )}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Typography variant="h5">Modal Dialog</Typography>

          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
            <Grid item md={12} sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box sx={{ pt: 2, mb: 3, mr: 2 }}>
                <Button variant="outlined" size="small" disableRipple onClick={() => setOpenNormalModal(true)}>
                  Normal Modal
                </Button>

                <NormalModalDialog
                  open={openNormalModal}
                  onClose={() => setOpenNormalModal(false)}
                  testId={`${testIdPrefix}NormalModalDialog`}
                />
              </Box>

              <Box sx={{ pt: 2, mb: 3, mr: 2 }}>
                <Button variant="outlined" size="small" disableRipple onClick={() => setOpenSideModal(true)}>
                  Side Modal
                </Button>

                <SideModalDialog
                  open={openSideModal}
                  onClose={() => setOpenSideModal(false)}
                  testId={`${testIdPrefix}SideModalDialog`}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </form>

      <DataGridStyleGuide />
    </Box>
  );
};
