import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store/store';

interface UserData {
  username: string | undefined;
}

const initialState: UserData = {
  username: undefined,
};

const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setUsername: (state, action: PayloadAction<string | undefined>) => {
      state.username = action.payload;
    },
  },
});

export const { setUsername } = userDataSlice.actions;

export const selectUsername = (state: RootState) => state.localData.user.username;

export default userDataSlice.reducer;
