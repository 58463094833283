import qs from 'qs';

import { GridFilterModel } from '@mui/x-data-grid-pro';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store/store';

import { RouteKey } from 'models/RouteKey';
import { RoutesPaths } from 'models/RoutePath';
import { loadFiltersFromQueryParams } from 'utils/salesListFilters';

interface SalesOrderListData {
  allBrandsEnabled: boolean;
  filtersModel: GridFilterModel;
  page: number;
}

const getInitialState = (): SalesOrderListData => {
  const initialFilters: GridFilterModel = { items: [] };
  const routePath = window.location.pathname;

  if (routePath !== RoutesPaths[RouteKey.SalesOrders]) {
    return {
      allBrandsEnabled: false,
      filtersModel: initialFilters,
      page: 0,
    };
  }

  const parsed = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const showAllBrands = parsed.allBrands ? true : false;
  const currentPage = parsed.page ? parseInt(parsed.page as string, 10) : 0;
  const filters = !Object.keys(parsed).length ? initialFilters : loadFiltersFromQueryParams(parsed);

  return {
    allBrandsEnabled: showAllBrands,
    filtersModel: filters,
    page: currentPage,
  };
};

const salesOrderListDataSlice = createSlice({
  name: 'salesOrderListData',
  initialState: getInitialState(),
  reducers: {
    setAllBrandsEnabled: (state, action: PayloadAction<boolean>) => {
      state.allBrandsEnabled = action.payload;
    },
    setFiltersModel: (state, action: PayloadAction<GridFilterModel>) => {
      state.filtersModel = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
  },
});

export const { setAllBrandsEnabled, setFiltersModel, setCurrentPage } = salesOrderListDataSlice.actions;

export const selectAllBrandsEnabled = (state: RootState) => state.salesOrderListData.allBrandsEnabled;
export const selectFiltersModel = (state: RootState) => state.salesOrderListData.filtersModel;
export const selectCurrentPage = (state: RootState) => state.salesOrderListData.page;

export default salesOrderListDataSlice.reducer;
