import { Location, matchPath } from 'react-router-dom';

import { AppRoute, routes } from 'router/Routes';

export const getMatchRoute = (location: Location): AppRoute | undefined => {
  for (const route of routes) {
    if (matchPath(route.path, location.pathname)) {
      return route;
    } else {
      const matchedPath = route.insideElements?.find((sr) => !!matchPath(sr.path, location.pathname));
      if (matchedPath) {
        return matchedPath;
      }
    }
  }
  return undefined;
};

/**
 *
 * @returns the matched route from the sidebar for a given location
 * if it's a details route, like :id, it will return the route that matches the path and it's visible in the sidebar
 */
export const getSideMenuMatchRoute = (location: Location): AppRoute | undefined => {
  for (const route of routes) {
    if (matchPath(route.path, location.pathname)) {
      return route;
    } else {
      // look inside the elements of the route
      const matchedRoute = route.insideElements?.find((sr) => !!matchPath(sr.path, location.pathname));
      if (matchedRoute && matchedRoute.layoutConfig?.showInSidebar) {
        return matchedRoute;
      }

      // for details routes or add routes, only look for a route similar to the the main path
      const matchedMainPath = matchedRoute?.path?.split(/:|\/add/)[0].slice(0, -1); // Split at ":" or "/add"

      if (matchedMainPath) {
        const foundPath = [route, ...(route.insideElements ?? [])]?.find(
          (sr) => sr.path.startsWith(matchedMainPath) && sr.layoutConfig?.showInSidebar,
        );

        return foundPath;
      }
    }
  }
  return undefined;
};
