import { ReactElement, useMemo } from 'react';

import { Box, Divider, Typography } from '@mui/material';
import { AlternativeCurrencyPayment } from '@one/api-models/lib/Admin/Sales/AlternativeCurrencyPayment';
import { CertificatePayment } from '@one/api-models/lib/Admin/Sales/CertificatePayment';
import { Order } from '@one/api-models/lib/Admin/Sales/Order';
import { PaymentOptionBase } from '@one/api-models/lib/Admin/Sales/PaymentOptionBase';
import { EarnAlternativeCurrency } from '@one/api-models/lib/Membership/Account/Earn/EarnAlternativeCurrency';

import { AlternativeCurrency } from 'common/alternativeCurrency/AlternativeCurrency';
import { useFormat } from 'hooks/useFormat';

interface PaymentItem {
  id: string;
  title: string | ReactElement;
  amount: string | null | ReactElement;
}

interface OrderPricingSummaryProps {
  order: Order | undefined;
}
export const OrderPricingSummary = ({ order }: OrderPricingSummaryProps) => {
  const { formatCurrency: formatCurrencyFunc } = useFormat();
  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };

  const data: {
    items: PaymentItem[];
  } = useMemo(() => {
    const items: PaymentItem[] = [];

    const subtotal = order?.purchaseSummary.summaryItems.find((item) => item.type === 'Subtotal');
    const taxes = order?.purchaseSummary.summaryItems.find((item) => item.type === 'Taxes');
    const shipping = order?.purchaseSummary.summaryItems.find((item) => item.type === 'ShippingCosts');
    const paymentOptions = order?.purchaseSummary.totals.paymentOptions;

    items.push({
      id: 'subtotal',
      title: 'Subtotal',
      amount: subtotal
        ? formatCurrency(subtotal?.totals?.fiatAmount?.amount, subtotal?.totals?.fiatAmount?.currency)
        : '-',
    });
    if (shipping) {
      items.push({
        id: 'shipping',
        title: 'Shipping costs',
        amount: formatCurrency(shipping?.totals?.fiatAmount?.amount, shipping?.totals?.fiatAmount?.currency),
      });
    }
    items.push({
      id: 'taxes',
      title: 'Tax',
      amount: taxes ? formatCurrency(taxes?.totals?.fiatAmount?.amount, taxes?.totals?.fiatAmount?.currency) : '-',
    });

    paymentOptions?.forEach((paymentOption: PaymentOptionBase) => {
      const certificate = paymentOption as CertificatePayment;

      if (certificate?.displayName) {
        items.push({
          id: `certificate_${certificate.serialNumber}`,
          title: 'Certificate',
          amount: `${certificate.serialNumber ? `[${certificate.serialNumber}]` : ''} ${certificate.displayName} `,
        });
      } else {
        const alternativeCurrency = paymentOption as AlternativeCurrencyPayment;
        if (alternativeCurrency != null) {
          items.push({
            id: `burned_${alternativeCurrency.currency.code}_${alternativeCurrency.value}`,
            title: 'Burned',
            amount: (
              <AlternativeCurrency
                data={{
                  alternativeCurrencyDefinition: alternativeCurrency.currency,
                  total: alternativeCurrency.value,
                }}
              />
            ),
          });
        }
      }
    });
    order?.purchaseSummary.totals.earn.forEach((earn) => {
      const currency = earn as EarnAlternativeCurrency;
      items.push({
        id: `earn_${currency.alternativeCurrencyDefinition.code}`,
        title: 'Earned',
        amount: (
          <AlternativeCurrency
            data={{
              alternativeCurrencyDefinition: currency.alternativeCurrencyDefinition,
              total: currency.alternativeCurrencyValue,
            }}
          />
        ),
      });
    });

    items.push({
      id: 'grandTotal',
      title: 'Total',
      amount: order?.orderTotalPrice
        ? formatCurrency(order?.orderTotalPrice?.amount, order?.orderTotalPrice?.currency)
        : '-',
    });

    return { items };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  return (
    <Box display="flex" justifyContent="flex-end" width="100%" mt={1}>
      <Box flexBasis={{ xs: '100%', sm: '80%', lg: '40%', xl: '30%' }}>
        {data.items.map((item) => (
          <Box key={item.id} display="flex" justifyContent="space-between" mt={0.5}>
            <Typography variant="body1" textAlign="right">
              {item.title}
            </Typography>
            <Typography variant="body1" textAlign="right">
              {item.amount}
            </Typography>
          </Box>
        ))}
        <Divider sx={{ my: 1 }} />
        <Box display="flex" justifyContent="space-between" mt={0.5}>
          <Typography variant="body1" fontWeight="500">
            Amount paid
          </Typography>
          <Typography variant="body1" textAlign="right" fontWeight="500">
            {formatCurrency(order?.amountPaid?.amount, order?.amountPaid?.currency)}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" mt={0.5}>
          <Typography variant="body1" fontWeight="500">
            Amount due
          </Typography>
          <Typography variant="body1" textAlign="right" fontWeight="500">
            {order?.balance ? formatCurrency(order?.balance?.amount, order?.balance?.currency) : '-'}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
