import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Grid, Paper, Tab, Typography } from '@mui/material';
import { CreateDetails } from '@one/api-models/lib/Admin/Tags/Edit/CreateDetails';
import { UpdateDetails } from '@one/api-models/lib/Admin/Tags/Edit/UpdateDetails';
import { UpdateRelation } from '@one/api-models/lib/Admin/Tags/Edit/UpdateRelation';
import { LoadResponse } from '@one/api-models/lib/Admin/Tags/LoadResponse';
import { TagEditOperation } from '@one/api-models/lib/Admin/Tags/TagEditOperation';
import { Tag } from '@one/api-models/lib/Admin/Tags/View/Tag';

import {
  selectIsSavingTag,
  selectNewTag,
  selectSelectedTag,
  selectTagTree,
  setSettings,
  setTagTree,
} from 'store/slices/tagsDataSlice';

import { ApiError } from 'apiAccess/api-client';
import { ConfirmationDialog } from 'common/ConfirmationDialog';
import { PageHeader } from 'common/layout/components/PageHeader';
import { useApiHelpers } from 'hooks/useApiHelpers';
import { ExtendedTag, useTagsHelpers } from 'hooks/useTagsHelpers';
import { useToastMessage } from 'hooks/useToastMessage';

import { Create } from './components/CreateTag';
import { DetailsAndEdit } from './components/DetailsAndEditTag';
import { ProductList } from './components/ProductList';
import { DetailsSkeleton, TreeSkeleton } from './components/TagsSkeletons';
import { TagTree } from './components/TagTree';
import { TreeEditAction } from './components/TreeEditAction';

export const TagsView = () => {
  const dispatch = useDispatch();
  const { selectTag, createNewTag, abortNewTag, saveHandler, fixTreeStructure } = useTagsHelpers();
  const { addApiError } = useToastMessage();
  const { api } = useApiHelpers();

  const tagTree = useSelector(selectTagTree);
  const selectedTag = useSelector(selectSelectedTag);
  const newTag = useSelector(selectNewTag);
  const isSaving = useSelector(selectIsSavingTag);

  const [moveTag, setMoveTag] = useState<{ tag: Tag; newParent: Tag } | undefined>(undefined);
  const [isEditing, setEditing] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState('1');
  const testIdPrefix = 'Tags';

  const apiTagsLoad = useQuery<LoadResponse, ApiError>(
    'tag',
    () => {
      return api.tags.load({});
    },
    {
      enabled: true,
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      onError: (error: ApiError) => addApiError(error),
    },
  );

  const onAPILoadComplete = () => {
    if (apiTagsLoad.data?.tags) {
      const tTree = {
        key: 'tag-#-ubibmOAjcEudMH3DXkdVYA',
        name: 'Tags',
        descendants: apiTagsLoad.data?.tags,
      } as Tag;

      fixTreeStructure(tTree);
      dispatch(setTagTree(tTree));
      if (apiTagsLoad.data?.tags.length > 0) {
        selectTag(apiTagsLoad.data?.tags[0]);
      }
    }

    if (apiTagsLoad.data?.settings) {
      dispatch(setSettings(apiTagsLoad.data?.settings));
    }
  };

  useEffect(() => {
    if (apiTagsLoad.data) onAPILoadComplete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiTagsLoad.data, setSettings]);

  const handleTagSelect = (tag?: Tag) => {
    if (isEditing) {
      if (newTag) {
        setAbortAddConfirmationOpen(true);
      } else {
        setAbortEditConfirmationOpen(true);
        setAbortTargetTag(tag);
      }

      return;
    }

    if (newTag && !isEditing) {
      abortNewTag();
    }

    if (tag) {
      selectTag(tag);
      setTabValue('1');
    }
  };

  const actionHandler = (tag: Tag, action: TreeEditAction, newParentTag?: Tag) => {
    switch (action) {
      case TreeEditAction.AddChild:
        createNewTag();
        break;
      case TreeEditAction.AddSibling:
        const parent = (tag as ExtendedTag).parent;
        if (parent) {
          selectTag(parent);
          createNewTag();
        }
        break;
      case TreeEditAction.MoveTo:
        if (newParentTag) {
          setMoveTag({ tag: tag, newParent: newParentTag });
        }
        setOpenMoveConfirmationOpen(true);
        break;
      case TreeEditAction.Delete:
        setOpenDeleteConfirmationOpen(true);
        break;
    }
  };

  //#region DELETE CONFIRMATION
  const [openDeleteConfirmationOpen, setOpenDeleteConfirmationOpen] = useState(false);

  const deleteConfirmationHandler = (confirmed: boolean) => {
    if (confirmed) {
      saveHandler(selectedTag?.key, {}, TagEditOperation.Delete);
    } else {
      setOpenDeleteConfirmationOpen(false);
    }
  };

  useEffect(() => {
    if (isSaving === false) {
      setOpenDeleteConfirmationOpen(false);
    }
  }, [isSaving]);
  //#endregion DELETE CONFIRMATION

  //#region MOVE CONFIRMATION
  const [openMoveConfirmationOpen, setOpenMoveConfirmationOpen] = useState(false);

  const moveConfirmationHandler = (confirmed: boolean) => {
    if (confirmed && moveTag) {
      saveHandler(
        moveTag.tag.key,
        {
          parentKey: moveTag.newParent.key,
        } as UpdateRelation,
        TagEditOperation.Move,
      );
    } else {
      setOpenMoveConfirmationOpen(false);
    }
  };

  useEffect(() => {
    if (isSaving === false) {
      setOpenMoveConfirmationOpen(false);
    }
  }, [isSaving]);

  //#endregion MOVE CONFIRMATION

  const [abortTargetTag, setAbortTargetTag] = useState<Tag | undefined>(undefined);
  //#region ABORT EDIT CONFIRMATION
  const [abortEditConfirmationOpen, setAbortEditConfirmationOpen] = useState(false);

  const abortEditConfirmationHandler = (continueEdit: boolean) => {
    if (!continueEdit) {
      if (abortTargetTag) {
        selectTag(abortTargetTag);
        setEditing(false);
      }
    }
    setAbortEditConfirmationOpen(false);
  };

  //#endregion  ABORT EDIT CONFIRMATION

  //#region ABORT ADD CONFIRMATION
  const [abortAddConfirmationOpen, setAbortAddConfirmationOpen] = useState(false);

  const abortAddConfirmationHandler = (continueAdd: boolean) => {
    if (!continueAdd) {
      if (abortTargetTag) {
        selectTag(abortTargetTag);
        setEditing(false);
      }
      abortNewTag();
    }
    setAbortAddConfirmationOpen(false);
  };

  //#endregion  ABORT ADD CONFIRMATION

  const isAllowed = (operation: TagEditOperation, tag?: Tag): boolean => {
    return tag != null && tag.allowedOperations != null && tag.allowedOperations.indexOf(operation) > -1;
  };

  const content = tagTree ? (
    <>
      <Grid item xs={12} md={6}>
        <Paper sx={{ p: 3 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Tags
          </Typography>
          <TagTree
            onSelect={handleTagSelect}
            tagTree={tagTree}
            defaultSelected={selectedTag || tagTree.descendants[0]}
            onAction={actionHandler}
            //disabled={isEditing}
          />
        </Paper>
      </Grid>
      <Grid item md={6} xs={12}>
        {isSaving && <DetailsSkeleton />}

        {!isSaving && (
          <Paper sx={{ p: 3 }}>
            <TabContext value={tabValue}>
              <TabList onChange={(e, v) => setTabValue(v)} aria-label="simple tabs example">
                <Tab
                  label={newTag ? 'New Tag' : 'Details'}
                  value="1"
                  data-testid={`${testIdPrefix}${newTag ? 'NewTag' : 'Details'}TabButton`}
                />
                <Tab
                  label="Products"
                  value="2"
                  disabled={isEditing || newTag != null}
                  data-testid={`${testIdPrefix}ProductsTabButton`}
                />
              </TabList>

              <TabPanel value="1">
                <Box sx={{ flex: 1 }}>
                  {!newTag && selectedTag && (
                    <>
                      <DetailsAndEdit
                        basicData={{ ...selectedTag }}
                        details={selectedTag?.details}
                        onSave={(details: UpdateDetails) => {
                          saveHandler(selectedTag.key, details, TagEditOperation.Edit);
                        }}
                        disableEdit={
                          selectedTag && selectedTag.allowedOperations
                            ? selectedTag.allowedOperations.findIndex((op) => op === TagEditOperation.Edit) === -1
                            : true
                        }
                        setEditing={setEditing}
                        allowDelete={isAllowed(TagEditOperation.Delete, selectedTag)}
                        allowAddChild={isAllowed(TagEditOperation.AddChild, selectedTag)}
                        onToolbarButtonAction={(action: TreeEditAction) => {
                          actionHandler(selectedTag, action, undefined);
                        }}
                        testId={`${testIdPrefix}DetailsAndEdit`}
                      />
                    </>
                  )}
                  {newTag && selectedTag && (
                    <Create
                      parentKey={selectedTag.key}
                      tagTypes={selectedTag.allowedChildTypes}
                      onSave={(detail: CreateDetails) => {
                        saveHandler(undefined, detail, TagEditOperation.AddChild);
                      }}
                      setEditing={setEditing}
                      testId={testIdPrefix}
                    />
                  )}
                </Box>
              </TabPanel>
              <TabPanel value="2">
                <Box sx={{ flex: 1 }}>
                  {selectedTag && <ProductList tagKey={selectedTag?.key} testId={testIdPrefix} />}
                </Box>
              </TabPanel>
            </TabContext>
          </Paper>
        )}
      </Grid>
    </>
  ) : (
    <>
      <Grid item md={6} xs={12}>
        <TreeSkeleton />
      </Grid>
      <Grid item md={6} xs={12}>
        <DetailsSkeleton />
      </Grid>
    </>
  );

  return (
    <Box>
      <PageHeader title="Configure Tags" testId={testIdPrefix} />
      <Grid
        container
        direction={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        spacing={3}
        sx={{ alignItems: { xs: 'stretch', md: 'flex-start' } }}
      >
        {content}
      </Grid>
      <ConfirmationDialog
        open={openDeleteConfirmationOpen}
        title="Confirm"
        question={`Delete "${selectedTag ? selectedTag.name : ''}" tag?`}
        confirmButton="Yes, delete"
        abortButton="No, abort"
        onClose={deleteConfirmationHandler}
        isLoading={isSaving}
        testId={`${testIdPrefix}ConfirmationDialog`}
      />
      <ConfirmationDialog
        open={openMoveConfirmationOpen}
        title="Confirm"
        question={`Move "${selectedTag ? moveTag?.tag.name : ''}" to "${moveTag?.newParent.name}"?`}
        confirmButton="Yes, move"
        abortButton="No, abort"
        onClose={moveConfirmationHandler}
        isLoading={isSaving}
        testId={`${testIdPrefix}ConfirmationDialog`}
      />
      <ConfirmationDialog
        open={abortEditConfirmationOpen}
        title="Quit editing?"
        question={`Changes you made so far will not be saved.`}
        confirmButton="Keep editing"
        abortButton="Yes, quit"
        onClose={abortEditConfirmationHandler}
        isLoading={isSaving}
        testId={`${testIdPrefix}QuitEditingDialog`}
      />
      <ConfirmationDialog
        open={abortAddConfirmationOpen}
        title="Quit adding?"
        question={`Changes you made so far will not be saved.`}
        confirmButton="Keep adding"
        abortButton="Yes, quit"
        onClose={abortAddConfirmationHandler}
        isLoading={isSaving}
        testId={`${testIdPrefix}QuitAddingDialog`}
      />
    </Box>
  );
};
