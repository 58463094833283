import { Box, Paper, Typography } from '@mui/material';

import background from 'assets/background.jpg';
import oneLogo from 'assets/one-logo.png';
import princessLogo from 'assets/princess-logo.png';
import { isTravelAgentPortal } from 'core/auth/auth';

export const Maintenance = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundImage: `url(${background})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      position: 'relative',
      height: '100vh',
    }}
  >
    <Paper
      sx={{
        flexGrow: 0,
        py: { xs: 4, md: 6 },
        px: { xs: 2, md: 8 },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
      }}
    >
      <Box sx={{ objectFit: 'cover', mb: 4 }}>
        <Box component="img" src={isTravelAgentPortal() ? princessLogo : oneLogo} sx={{ height: '100px' }} />
      </Box>
      <Typography fontSize={'1.85rem'}>We're upgrading our systems</Typography>
      <Typography sx={{ mt: 2 }}>We are performing scheduled maintenance. We should be back shortly.</Typography>
    </Paper>
  </Box>
);
