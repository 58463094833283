import { FC, RefObject, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@mui/material';
import { CybersourceGatewayConfiguration } from '@one/api-models/lib/Sales/Payment/Transaction/CybersourceGatewayConfiguration';

import {
  PaymentOptions,
  selectBillingDetails,
  selectIsLoadingOrderPayment,
  selectMetadata,
  selectPaymentError,
  selectPaymentPlan,
  selectPurchaseData,
  selectSelectedPaymentOption,
  setPaymentError,
} from 'store/slices/salesOrderDataSlice';

import { PaymentSkeleton } from 'common/payment';
import { useCybersourceProgramSale } from 'hooks/useCybersourceProgramSale';

import { CybersourceForm, CybersourceFormRefHandle } from './CybersourceForm';
import { PaymentBackdropLoading } from './PaymentBackdropLoading';
import { PaymentForm } from './PaymentForm';

interface PaymentDetailsCybersourceProps {
  paymentGatewayConfiguration: CybersourceGatewayConfiguration;
  isLoadingConfiguration: boolean;
  refetchConfiguration: () => void;
  formRef: RefObject<HTMLFormElement>;
}

export const PaymentDetailsCybersource: FC<PaymentDetailsCybersourceProps> = ({
  paymentGatewayConfiguration,
  refetchConfiguration,
  isLoadingConfiguration,
  formRef,
}) => {
  const dispatch = useDispatch();

  const { performCompleteOrderWithPayment } = useCybersourceProgramSale();
  const isLoading = useSelector(selectIsLoadingOrderPayment);
  const paymentError = useSelector(selectPaymentError);
  const selectedPaymentOption = useSelector(selectSelectedPaymentOption);
  const billingDetails = useSelector(selectBillingDetails);
  const purchaseData = useSelector(selectPurchaseData);
  const metadata = useSelector(selectMetadata);
  const paymentPlan = useSelector(selectPaymentPlan);

  const [isSubmittingPayment, setIsSubmittingPayment] = useState(false);

  const testIdPrefix = 'PaymentDetailsCybersource';

  const cybersourceFormRef = useRef<CybersourceFormRefHandle>(null);

  const validatePaymentPlanForm = useRef(() => {
    return false;
  });

  // Ref required for input attached events
  const selectedPaymentOptionRef = useRef(selectedPaymentOption);

  // Update the ref whenever selectedPaymentMethod changes
  useEffect(() => {
    selectedPaymentOptionRef.current = selectedPaymentOption;
  }, [selectedPaymentOption]);

  useEffect(() => {
    if (!isLoading) {
      setIsSubmittingPayment(false);
    }
  }, [isLoading]);

  const paymentSubmitHandler = async (token: string, paymentMethodExpiration: Date) => {
    dispatch(setPaymentError(undefined));

    if (selectedPaymentOption === PaymentOptions.OneTime) {
      performCompleteOrderWithPayment(token, paymentMethodExpiration, billingDetails, undefined, metadata);
    } else if (
      selectedPaymentOption === PaymentOptions.PaymentPlan ||
      selectedPaymentOption === PaymentOptions.SplitPayment
    ) {
      let isPaymentEnabled = false;

      if (!!validatePaymentPlanForm && !!validatePaymentPlanForm.current) {
        isPaymentEnabled = await validatePaymentPlanForm.current();
      }

      if (isPaymentEnabled) {
        performCompleteOrderWithPayment(
          token,
          paymentMethodExpiration,
          billingDetails,
          paymentPlan?.paymentPlan,
          metadata,
        );
      } else {
        setIsSubmittingPayment(false);
      }
    }
  };

  const updatePaymentData = () => {
    setIsSubmittingPayment(false);
    refetchConfiguration();
  };

  const submitForm = async () => {
    let isAdditionalFormValid = true;
    if (
      selectedPaymentOptionRef.current === PaymentOptions.PaymentPlan &&
      !!validatePaymentPlanForm &&
      !!validatePaymentPlanForm.current
    ) {
      isAdditionalFormValid = await validatePaymentPlanForm.current();
    }

    if (cybersourceFormRef.current) {
      cybersourceFormRef.current.handleFormSubmit(!isAdditionalFormValid);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitForm();
      }}
      ref={formRef}
    >
      {!isLoadingConfiguration || isSubmittingPayment ? (
        <>
          {!!purchaseData?.paymentPlanConfig && (
            <Grid item xs={12} key="pf">
              <PaymentForm validatePaymentForm={validatePaymentPlanForm} testId={testIdPrefix} />
            </Grid>
          )}
          {selectedPaymentOption !== PaymentOptions.Reservation && (
            <Grid item xs={12} md={10} lg={8} mt={4}>
              <CybersourceForm
                ref={cybersourceFormRef}
                paymentGatewayConfiguration={paymentGatewayConfiguration}
                updatePaymentData={updatePaymentData}
                submitPayment={paymentSubmitHandler}
                isSubmittingPayment={isSubmittingPayment}
                setIsSubmittingPayment={setIsSubmittingPayment}
                submitForm={submitForm}
                error={paymentError}
                testId={testIdPrefix}
              />
            </Grid>
          )}
        </>
      ) : (
        <PaymentSkeleton />
      )}

      <PaymentBackdropLoading
        isLoading={isLoading}
        isLoadingConfiguration={isLoadingConfiguration}
        isSubmittingPayment={isSubmittingPayment}
      />
    </form>
  );
};
