import React, { useState } from 'react';
import { Control, FieldValues, useFieldArray } from 'react-hook-form';

import { Add } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Chip, FormHelperText, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';

import { ActionButton } from 'common/ActionButton';
import { ConfirmationDialog } from 'common/ConfirmationDialog';
import ControlledTextField from 'common/inputs/defaultFields/ControlledTextField';

import { FormProps } from './DetailsTab/DetailsBase';

interface Props {
  formProps: FormProps;
  supplierIdentifier: string;
  isLoading: boolean;
  testId: string;
}

export const SupplierReferenceFields = ({ formProps, supplierIdentifier, isLoading, testId }: Props) => {
  const { errors, control, getValues } = formProps;
  const [indexToRemove, setIndexToRemove] = useState<number | undefined>(undefined);
  const [indexToEdit, setIndexToEdit] = useState<number | undefined>(undefined);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'supplierReferences',
  });

  const fieldValues = getValues('supplierReferences');

  const handleConfirmationClose = (result: boolean): void => {
    if (result) {
      remove(indexToRemove);
    }
    setIndexToRemove(undefined);
  };

  const handleAdd = (): void => {
    append({
      supplierIdentifier,
      reference: '',
    });
    setIndexToEdit(fieldValues.length);
  };

  const handleDelete = (value: string, index: number): void => {
    if (value == null || value.length === 0) remove(index);
    else setIndexToRemove(index);
  };

  return (
    <Box>
      {fields.map((field, index) => (
        <React.Fragment key={field.id}>
          {(field as any).supplierIdentifier === supplierIdentifier && (
            <Chip
              sx={{ mt: 0.5, mr: 1, py: 1.5 }}
              label={
                indexToEdit !== index ? (
                  errors?.supplierReferences && errors?.supplierReferences[index]?.reference?.message ? (
                    <Typography variant="body2" sx={{ color: (theme) => alpha(theme.palette.error.main, 0.84) }}>
                      {errors?.supplierReferences[index]?.reference?.message}
                    </Typography>
                  ) : (
                    fieldValues[index]?.reference
                  )
                ) : (
                  <>
                    <ControlledTextField
                      inputProps={{
                        tabIndex: index,
                        sx: { fontSize: '0.8rem' },
                      }}
                      name={`supplierReferences.${index}.reference`}
                      control={control as unknown as Control<FieldValues, object>}
                      disabled={isLoading}
                      error={
                        errors?.supplierReferences
                          ? errors?.supplierReferences[index]?.reference?.message != null
                          : undefined
                      }
                      variant="standard"
                      testId={`${testId}.${index}.reference.`}
                    />
                    <FormHelperText
                      sx={{
                        position: 'absolute',
                        top: '100%',
                        color: (theme) => alpha(theme.palette.error.main, 0.84),
                      }}
                    >
                      {errors?.supplierReferences && errors?.supplierReferences[index]?.reference?.message
                        ? errors?.supplierReferences[index]?.reference?.message
                        : undefined}
                    </FormHelperText>
                  </>
                )
              }
              onClick={() => setIndexToEdit(index)}
              onDelete={() => handleDelete((field as any).reference, index)}
              deleteIcon={<ClearIcon style={{ color: 'black' }} />}
              disabled={isLoading}
            />
          )}
        </React.Fragment>
      ))}
      <ActionButton
        type="button"
        onClick={handleAdd}
        disabled={isLoading}
        icon={<Add />}
        size="small"
        sx={{ mt: 0.5 }}
        testId={`${testId}Add`}
      >
        Add
      </ActionButton>

      <ConfirmationDialog
        confirmButton="Remove"
        abortButton="Cancel"
        onClose={handleConfirmationClose}
        open={indexToRemove != null}
        title="Remove the reference?"
        question="You are about to remove a Supplier reference."
        testId={`${testId}RemoveReferenceDialog`}
      />
    </Box>
  );
};
